import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Events } from '../models/events';
import { ServerStatus } from '../models/ServerStatus';
import { TelemetryService } from './telemetry.service';

@Injectable({
  providedIn: 'root'
})
export class StatusService {

  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  constructor(
    private http: HttpClient,
    private telemetryService: TelemetryService
  ) { }

  /**
   * getEntities
   */
  public getStatus(): Observable<ServerStatus> {
    this.telemetryService.logEvent(Events.GetStatus);
    return this.http.get<ServerStatus>('/api/Status', this.httpOptions);
  }
}
