import { Injectable, ElementRef, Injector, InjectionToken } from '@angular/core';
import { Overlay, OverlayConfig, ConnectionPositionPair, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal, ComponentType, PortalInjector } from '@angular/cdk/portal';
import { PopoverDialogRef } from './popover-dialog-ref';

export const DIALOG_DATA = new InjectionToken<any>('DIALOG_DATA');

interface PopoverDialogConfig {
  panelClass?: string;
  hasBackdrop?: boolean;
  backdropClass?: string;
  width?: any;
  height?: any;
  positions?: ConnectionPositionPair[];
  data?: any;
}

const DEFALUT_POSITIONS: ConnectionPositionPair[] =
  [
    {
      originX: 'center',
      originY: 'bottom',
      overlayX: 'center',
      overlayY: 'top',
    },
    {
      originX: 'center',
      originY: 'top',
      overlayX: 'center',
      overlayY: 'bottom'
    },
    {
      originX : 'end',
      originY : 'center',
      overlayX: 'start',
      overlayY: 'center',
    },
    {
      originX : 'start',
      originY : 'center',
      overlayX: 'end',
      overlayY: 'center',
    }
  ];

const DEFAULT_CONFIG: PopoverDialogConfig = {
  hasBackdrop: true,
  backdropClass: 'white-pop-over-backdrop',
  panelClass: 'pop-over-panel',
  width: '211px',
  height: '347px',
  positions: DEFALUT_POSITIONS,
  data: {}
};

@Injectable({
  providedIn: 'root'
})
export class PopoverDialogService {
  constructor(private overlay: Overlay, private injector: Injector) { }

  open<T>(origin: ElementRef, componentRef: ComponentType<T>, config: PopoverDialogConfig = {}) {
    const dialogConfig = { ...DEFAULT_CONFIG, ...config };
    const overlayRef = this.createOverlay(dialogConfig, origin);
    const dialogRef = new PopoverDialogRef(overlayRef, dialogConfig.data);
    this.attachDialogContainer<T>(overlayRef, dialogConfig, dialogRef, componentRef);
    return dialogRef;
  }

  private attachDialogContainer<T>( overlayRef: OverlayRef,
    config: PopoverDialogConfig,
    dialogRef: PopoverDialogRef,
    componentRef: ComponentType<T>) {
    const injector = this.createInjector(config, dialogRef);

    const containerPortal = new ComponentPortal(componentRef, null, injector);
    const containerRef: any = overlayRef.attach(containerPortal);

    return containerRef.instance;
  }

  private createInjector(config: PopoverDialogConfig, dialogRef: PopoverDialogRef): PortalInjector {
    const injectionTokens = new WeakMap();

    injectionTokens.set(PopoverDialogRef, dialogRef);
    injectionTokens.set(DIALOG_DATA, config.data);

    return new PortalInjector(this.injector, injectionTokens);
  }

  private createOverlay(config: PopoverDialogConfig, origin: ElementRef) {
    const overlayConfig = this.getOverlayConfig(config, origin);
    return this.overlay.create(overlayConfig);
  }

  private getOverlayConfig(config: PopoverDialogConfig, origin: ElementRef): OverlayConfig {
    const positionStrategy = this.overlay.position()
      .flexibleConnectedTo(origin)
      .withPositions(config.positions)
      .withFlexibleDimensions(false)  // TODO: can make these two configable in the future
      .withPush(false);

    const overlayConfig = new OverlayConfig({
      width: config.width,
      height: config.height,
      hasBackdrop: config.hasBackdrop,
      backdropClass: config.backdropClass,
      panelClass: config.panelClass,
      scrollStrategy: this.overlay.scrollStrategies.block(),
      positionStrategy
    });
    return overlayConfig;
  }
}
