<ng-container [ngSwitch]="true">
    <ng-container *ngSwitchCase="fieldType === 'file'">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8 10.3333L8 3C8 1.89543 7.10457 0.999999 6 1V1C4.89543 1 4 1.89543 4 3L4 11C4 13.2091 5.79086 15 8 15V15C10.2091 15 12 13.2091 12 11L12 4.11111" stroke="#526069" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    </ng-container>
    <ng-container *ngSwitchCase="fieldType === 'autonumber'">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3 2.25L4.5 1V6H3H6" stroke="#526069" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M3 10.3636V10.25C3 9.55964 3.55964 9 4.25 9V9C4.94036 9 5.5 9.55964 5.5 10.25V10.3636L3 13.5455V14H6" stroke="#526069" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M12 1V14M12 14L10 11.4286H14L12 14Z" stroke="#526069" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    </ng-container>
    <ng-container *ngSwitchCase="fieldType === 'date' || fieldType === 'dateTime'">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.75 3.5C12.0404 3.5 12.3323 3.5 12.625 3.5C13.1772 3.5 13.625 3.94772 13.625 4.5C13.6249 7.39582 13.6249 10.2916 13.625 13.1875C13.625 13.7398 13.1773 14.1875 12.625 14.1875H3.375C2.82272 14.1875 2.375 13.7398 2.375 13.1875C2.375 10.2917 2.375 7.39583 2.37501 4.5C2.37501 3.94771 2.82275 3.5 3.37503 3.5C3.73293 3.5 4.08457 3.5 4.25 3.5C5.21493 3.5 5.5752 3.5 7.0625 3.5C7.66001 3.5 8.28811 3.5 8.9375 3.5M11.75 3.5C10.7879 3.5 9.84206 3.5 8.9375 3.5M11.75 3.5H8.9375" stroke="#526069" stroke-width="1.5" stroke-linecap="square"/>
            <path d="M11.375 2.25V4.375" stroke="#526069" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M4.625 2.25V4.375" stroke="#526069" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M2.375 6.875H13.625" stroke="#526069" stroke-width="1.5" stroke-linecap="square"/>
            <rect x="8" y="9.125" width="3.375" height="2.8125" rx="0.5" stroke="#526069" stroke-width="1.5" stroke-linecap="square"/>
        </svg>
    </ng-container>
    <ng-container *ngSwitchCase="fieldType === 'number'">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.66675 6H13.3334" stroke="#526069" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M2.66675 10H13.3334" stroke="#526069" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M6.66658 2L5.33325 14" stroke="#526069" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M10.6666 2L9.33325 14" stroke="#526069" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    </ng-container>
    <ng-container *ngSwitchCase="fieldType === 'choiceSetSingle' || fieldType === 'choiceSetMultiple'">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="2" y="1" width="12" height="4" stroke="#526069" stroke-width="1.5" stroke-linecap="square" stroke-linejoin="round"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M2 9C2 8.44772 2.44772 8 3 8V8C3.55228 8 4 8.44772 4 9V9C4 9.55228 3.55228 10 3 10V10C2.44772 10 2 9.55228 2 9V9Z" fill="#526069"/>
            <path d="M6 9H13.1111" stroke="#526069" stroke-width="1.5" stroke-linecap="round"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M1.77783 12.4446C1.77783 11.9536 2.1758 11.5557 2.66672 11.5557V11.5557C3.15764 11.5557 3.55561 11.9536 3.55561 12.4446V12.4446C3.55561 12.9355 3.15764 13.3334 2.66672 13.3334V13.3334C2.1758 13.3334 1.77783 12.9355 1.77783 12.4446V12.4446Z" fill="#526069"/>
            <path d="M6.22217 12.4443H13.3333" stroke="#526069" stroke-width="1.5" stroke-linecap="round"/>
        </svg>
    </ng-container>
    <ng-container *ngSwitchCase="fieldType === 'relationship'">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="1.77783" y="6.22217" width="4.44444" height="4.44444" rx="1" stroke="#526069" stroke-width="1.5" stroke-linecap="square"/>
            <rect x="9.77783" y="10.6665" width="4.44444" height="4.44444" rx="1" stroke="#526069" stroke-width="1.5" stroke-linecap="square"/>
            <rect x="9.77783" y="0.888672" width="4.44444" height="4.44444" rx="1" stroke="#526069" stroke-width="1.5" stroke-linecap="square"/>
            <path d="M6.22217 6.66667L9.4949 4" stroke="#526069" stroke-width="1.5" stroke-linecap="square"/>
            <path d="M6.22217 10.222L9.4949 12.8887" stroke="#526069" stroke-width="1.5" stroke-linecap="square"/>
        </svg>
    </ng-container>
    <ng-container *ngSwitchCase="fieldType === 'text' || fieldType === 'uniqueid'">
        <svg id="text" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.888916 11.6665L4.44447 3.6665L8.00003 11.6665" stroke="#526069" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M12.4445 11.6668C13.9173 11.6668 15.1112 10.4729 15.1112 9.00016C15.1112 7.5274 13.9173 6.3335 12.4445 6.3335C10.9717 6.3335 9.77783 7.5274 9.77783 9.00016C9.77783 10.4729 10.9717 11.6668 12.4445 11.6668Z" stroke="#526069" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M15.1111 6.3335V11.6668" stroke="#526069" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M2.6665 9H6.22206" stroke="#526069" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    </ng-container>
    <ng-container *ngSwitchCase="fieldType === 'boolean'">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.22217 1.77767L3.99995 4.44434L1.77772 1.77767" stroke="#526069" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M4 4.44434V7.111" stroke="#526069" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M10.6667 14.2223V9.77783L14.2223 14.2223V9.77783" stroke="#526069" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M3.55566 12.4446L12.4446 3.55566" stroke="#526069" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    </ng-container>
    <ng-container *ngSwitchCase="'calculatedFieldTypes'">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.99854 12.8494V12.9319C1.99854 14.075 2.92521 15.0016 4.06831 15.0016V15.0016C5.1542 15.0016 6.05546 14.1625 6.13283 13.0793L6.85746 2.93452C6.93482 1.85139 7.83609 1.01221 8.92197 1.01221V1.01221C10.0651 1.01221 10.9917 1.93888 10.9917 3.08198V3.16443" stroke="#526069" stroke-width="1.5" stroke-linecap="square" stroke-linejoin="round"/>
            <path d="M4.99634 7.00684L8.99332 7.00684" stroke="#526069" stroke-width="1.5" stroke-linecap="round"/>
            <path d="M10 10L14 15" stroke="#526069" stroke-width="1.5" stroke-linecap="round"/>
            <path d="M14 10L10 15" stroke="#526069" stroke-width="1.5" stroke-linecap="round"/>
        </svg>
    </ng-container>
    <ng-container *ngSwitchCase="'currency'">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.81812 1V15" stroke="#526069" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M11 3.54541H6.22727C5.63656 3.54541 5.07005 3.78007 4.65235 4.19776C4.23466 4.61546 4 5.18197 4 5.77268C4 6.36339 4.23466 6.92991 4.65235 7.3476C5.07005 7.7653 5.63656 7.99996 6.22727 7.99996H9.40909C9.9998 7.99996 10.5663 8.23461 10.984 8.65231C11.4017 9.07 11.6364 9.63652 11.6364 10.2272C11.6364 10.8179 11.4017 11.3845 10.984 11.8021C10.5663 12.2198 9.9998 12.4545 9.40909 12.4545H4" stroke="#526069" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    </ng-container>
    <ng-container *ngSwitchCase="'email'">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.2 4H12.8C13.46 4 14 4.46125 14 5.025V11.175C14 11.7387 13.46 12.2 12.8 12.2H3.2C2.54 12.2 2 11.7387 2 11.175V5.025C2 4.46125 2.54 4 3.2 4Z" stroke="#526069" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M13 5.3999L8 8.2999L3 5.3999" stroke="#526069" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    </ng-container>
    <ng-container *ngSwitchCase="'phone'">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.9996 11.733V13.69C14.0003 13.8717 13.9659 14.0515 13.8986 14.218C13.8313 14.3845 13.7325 14.5339 13.6087 14.6567C13.4849 14.7795 13.3387 14.873 13.1795 14.9312C13.0203 14.9894 12.8517 15.0111 12.6843 14.9947C10.8277 14.7766 9.04426 14.0907 7.47734 12.992C6.01953 11.9905 4.78356 10.6541 3.8572 9.07797C2.83752 7.37614 2.20294 5.43855 2.0049 3.42217C1.98982 3.24177 2.00965 3.05996 2.06312 2.88831C2.11659 2.71665 2.20254 2.55892 2.31548 2.42514C2.42843 2.29137 2.5659 2.18449 2.71915 2.1113C2.87239 2.03812 3.03805 2.00023 3.20558 2.00006H5.01565C5.30846 1.99695 5.59233 2.10906 5.81435 2.31549C6.38101 2.84238 6.84993 4.90263 6.69534 5.68637C6.64721 5.93032 6.53542 6.15425 6.3732 6.33161L5.60694 7.16008C6.46585 8.79325 7.71655 10.1455 9.22708 11.0741L9.99334 10.2457C10.1574 10.0703 10.3645 9.9494 10.5901 9.89737C11.317 9.72977 13.2282 10.2374 13.714 10.8572C13.9053 11.1014 14.007 11.4131 13.9996 11.733Z" stroke="#526069" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>        
    </ng-container>
    <ng-container *ngSwitchCase="'url'">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.59766 8.70468C6.90028 9.10925 7.28638 9.44402 7.72976 9.68625C8.17314 9.92849 8.66344 10.0725 9.16738 10.1086C9.67133 10.1447 10.1771 10.072 10.6505 9.89543C11.1239 9.71885 11.5538 9.44253 11.911 9.0852L14.025 6.97116C14.6668 6.30664 15.022 5.41662 15.0139 4.49279C15.0059 3.56896 14.6353 2.68525 13.9821 2.03198C13.3288 1.37871 12.4451 1.00816 11.5213 1.00013C10.5974 0.992105 9.70742 1.34725 9.0429 1.98906L7.83085 3.19407" stroke="#526069" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M9.4164 7.29529C9.11378 6.89071 8.72768 6.55595 8.2843 6.31371C7.84092 6.07147 7.35062 5.92742 6.84668 5.89133C6.34273 5.85523 5.83691 5.92795 5.36354 6.10453C4.89016 6.28111 4.4603 6.55744 4.10311 6.91476L1.98906 9.0288C1.34725 9.69333 0.992105 10.5833 1.00013 11.5072C1.00816 12.431 1.37871 13.3147 2.03198 13.968C2.68525 14.6212 3.56896 14.9918 4.49279 14.9998C5.41662 15.0079 6.30664 14.6527 6.97116 14.0109L8.17616 12.8059" stroke="#526069" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    </ng-container>
    <ng-container *ngSwitchCase="'user'">
        <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M8.00086 7.99188C9.38157 7.99292 10.5017 6.87448 10.5027 5.49376C10.5038 4.11305 9.38535 2.99292 8.00464 2.99188C6.62393 2.99084 5.50379 4.10928 5.50275 5.48999C5.50171 6.8707 6.62015 7.99083 8.00086 7.99188Z" stroke="#526069" stroke-width="1.5"/>
            <path d="M12.9993 14.99C12.9993 12.2286 10.7607 9.98999 7.99927 9.98999C5.23784 9.98999 2.99927 12.2286 2.99927 14.99" stroke="#526069" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    </ng-container>
</ng-container>