<mat-dialog-title>
    <mat-icon class="material-icons md-24 error-icon standard-icon">error</mat-icon>
    <div *ngIf="!data.errorTitle">{{'ErrorDialog.TitleText' | translate}}</div>
    <div *ngIf="data.errorTitle">{{data.errorTitle}}</div>
</mat-dialog-title>

<mat-dialog-content>
    <div fxLayout="column">
        <div class="error-text" [innerHtml]="data.errorText">
        </div>
        <div fxLayout="row" class="font-semi-small">
            <div>{{'ErrorDialog.SessionIdText' | translate}}:</div>
            <div>{{sessionId}}</div>
        </div>
        <div fxLayout="row" class="font-semi-small">
            <div>{{'ErrorDialog.TraceIdText' | translate}}:</div>
            <div>{{traceId}}</div>
        </div>
        <div fxLayout="row" class="font-semi-small">
            <div>{{'ErrorDialog.TimeStampText' | translate}}:</div>
            <div>{{currentTimeStamp}}</div>
        </div>
    </div>
</mat-dialog-content>
<div mat-dialog-actions fxLayout="row" fxLayoutAlign="end strech" class="button-section" *ngIf="!data.disableCloseBtn">
    <button mat-raised-button class="btn btn-success" (click)="onClick()">Close</button>
</div>