import { environment } from '../../environments/environment';
import * as urljoin from 'url-join';
import { RequestContext } from './models/context';
import { getLanguage } from './utilities';

export class UrlUtils {

  private static previousPath: string;
  private static homePath: string;

  public static buildUrl(path: string): string {
    return urljoin(UrlUtils.getApiBaseUrl(), path);
  }

  public static getCloudHost(): string {
    return environment.cloudHost || this.getOrigin() + '/';
  }

  public static getAssetUrl(): string {
    return environment.assetUrl || this.getOrigin();
  }

  public static getApiHost() {
    return environment.apiUrl || this.getCloudHost();
  }

  public static getApiBaseUrl() {
    return urljoin(UrlUtils.getApiHost(), UrlUtils.getBaseRouteUrl());
  }

  public static getPreviousPath(): string {
    return this.previousPath;
  }

  public static getBaseRouteUrl() {
    const context = UrlUtils.getRequestContext();
    const path = UrlUtils.getPathName();
    const paths = path.split('/');
    this.previousPath = `${context.account}/${context.tenant}/${paths[3]}`;
    return this.previousPath;
  }

  public static getHomeRouteUrl() {
    const context = UrlUtils.getRequestContext();
    this.homePath = `${context.account}/portal_/home`;
    return this.homePath;
  }

  public static getRequestContext(): RequestContext {
    const path = UrlUtils.getPathName();
    const paths = path.split('/');
    if (paths.length < 4 || paths[3] !== 'dataservice_') {
      throw new Error(`Invalid request path [${path}]`);
    }

    let requestUri = '';
    for (let i = 4; i < paths.length; i++) {
      requestUri = urljoin(requestUri, paths[i]);
    }
    const query = UrlUtils.getQueryString();
    if (requestUri && query) {
      requestUri = `${requestUri}${query}`;
    }
    return new RequestContext(paths[1], paths[2], requestUri, paths[3]);
  }

  public static getPathName() {
    return window.location.pathname;
  }

  public static getQueryString() {
    return window.location.search;
  }

  public static getOrigin() {
    return window.location.origin;
  }

  public static getUserManagementDataAccessDocLink(): string {
    const language = getLanguage();
    // eslint-disable-next-line max-len
    return `https://docs.uipath.com/${language}/data-service/automation-cloud/latest/user-guide/customizing-an-entity#enabling-or-disabling-role-based-field-access`;
  }

  public static getEntityCustomizationDocLink(): string {
    const language = getLanguage();
    // eslint-disable-next-line max-len
    return `https://docs.uipath.com/${language}/data-service/automation-cloud/latest/user-guide/managing-access`;
  }
}
