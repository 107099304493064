import { Component, Input } from '@angular/core';
import { Field } from 'src/app/shared/models/field';
import { EntitiesDataService } from '../../services/entities-data.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackBarComponent } from '../snack-bar/snack-bar.component';
import { downloadBlob } from '../../utilities';

@Component({
  selector: 'app-file-attachment',
  templateUrl: './file-attachment.component.html',
  styleUrls: ['./file-attachment.component.scss']
})
export class FileAttachmentComponent {

  @Input('field')
  public field: Field;

  @Input('onFileSelected')
  public onFileSelected: (fieldName: string, files: File[]) => void;

  public mostRecentFileName: string | undefined;

  public isSelectedFileDismissed: boolean;

  private downloadFn = downloadBlob;

  constructor(
    private entityDataService: EntitiesDataService,
    private snackBar: MatSnackBar
  ) {
    this.isSelectedFileDismissed = false;
  }

  public onDrop(event: DragEvent): void {
    event.preventDefault(); // Prevent default behavior of opening file.
    this.selectFile(this.field.name, event.dataTransfer.files);
  }

  public onDragOver(event: Event): void {
    event.preventDefault(); // Prevent default behavior of opening file.
  }

  public onDismissSelectedFile(): void {
    this.isSelectedFileDismissed = true;
    this.onFileSelected(this.field.name, []);
  }

  public shouldShowUploadBox(): boolean {
    return !this.getFileName() || this.isSelectedFileDismissed;
  }

  public getFileName(): string | undefined {
    return this.mostRecentFileName || this.field.value && (this.field.value as any).Name || undefined;
  }

  public onDownload(): void {
    this.entityDataService.downloadFileByPath((this.field.value as any).Path).subscribe(
      (blob) => {
        this.downloadFn(blob);
      },
      (error) => {
        this.snackBar.openFromComponent(SnackBarComponent, { duration: 5000, verticalPosition: 'top', data: error });
      }
    );
  }

  public selectFile(fieldName: string, fileList: FileList): void {
    this.isSelectedFileDismissed = false;
    this.mostRecentFileName = fileList[0].name;

    const files: File[] = [];
    for (let i = 0; i < fileList.length; i++) {
      files.push(fileList[i]);
    }

    this.onFileSelected(fieldName, files);
  }

}
