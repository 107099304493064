<div class="side-pane-container">
    <h1 mat-dialog-title class="bold font-large">{{isCreateMode()? ('AssignRoles.Title' | translate) :
        ('EditRoles.Title' |
        translate)}}</h1>
    <div mat-dialog-content class="dialog-content">
        <ng-container *ngIf="data.mode === 'Create'">
            <portal-people-picker [token]="accessToken" source-filters='[]'
                people-picker-id="portal-people-picker" theme="autoTheme" label-text="{{'AssignRoles.Title' | translate}}"
                (peoplePickerChanged)="onPeoplePickerChanged($event)">
            </portal-people-picker>
            <div class="sub-text font-semi-small">
                {{'AddUser.HelpText' | translate}}
            </div>
            <div class="font-semi-small bold">
              <mat-icon class="icon-warning">
                warning
            </mat-icon>
              {{'AddUser.Warning' | translate}}
          </div>
        </ng-container>

        <ng-container *ngIf="data.mode === 'Update'">
            <mat-form-field floatLabel="always" class="full-width">
                <mat-label>{{'AssignRoles.Title' | translate}}</mat-label>
                <input matInput id="name" name="name" disabled [value]="data.user.name" />
            </mat-form-field>
        </ng-container>
        <div fxLayout="column" fxLayoutAlign="space-around start">
            <div class="select-role bold font-medium">{{'AddUser.SlectRoleText' | translate}}</div>
            <ng-container *ngFor="let role of roles">
                <ng-container *ngIf="shouldDisableCheckbox(role)">
                    <mat-checkbox (change)="onCheckRole($event, role)" disabled [checked]="shouldRoleChecked(role)"
                        class="role-check font-medium" [matTooltip]="getCheckBoxDisableMessage() | async">
                        {{role.displayName}}
                    </mat-checkbox>
                </ng-container>
                <ng-container *ngIf="!shouldDisableCheckbox(role)">
                    <mat-checkbox (change)="onCheckRole($event, role)" [checked]="shouldRoleChecked(role)"
                        class="role-check font-medium">
                        {{role.displayName}}
                    </mat-checkbox>
                </ng-container>

            </ng-container>
        </div>
    </div>

    <div mat-dialog-actions fxLayout="row" fxLayoutAlign="end strech" class="btn-section">
        <button mat-stroked-button color="primary" class="btn bold" (click)="onDismiss()">{{'CloseButtonText' |
            translate}}</button>
        <button *ngIf="!isLoading" mat-flat-button color="primary" type="submit" class="btn bold"
            [disabled]="isSubmitDisabled()" (click)="onSubmit()">{{'SaveButtonText' | translate}}</button>
        <div class="btn" *ngIf="isLoading">
            <mat-spinner role="alert" [attr.aria-label]="'Loading' | translate" diameter="24" class="spinner"></mat-spinner>
        </div>
    </div>
</div>
