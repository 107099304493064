import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Environment } from '../models/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TelemetryService } from './telemetry.service';
import { Events } from '../models/events';

@Injectable({
  providedIn: 'root'
})
export class EnvironmentsService {

  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  constructor(
    private http: HttpClient,
    private telemetryService: TelemetryService) { }

  public createEnvironment(): Observable<string> {
    this.telemetryService.logEvent(Events.CreateEnvironment);
    return this.http.post<string>(`/api/Environment/Provision`, {}, this.httpOptions);
  }

  public getEnvironment(): Observable<Environment> {
    this.telemetryService.logEvent(Events.GetEnvironment);
    return this.http.get<Environment>(`/api/Environment`, this.httpOptions);
  }
}
