
import { OverlayRef } from '@angular/cdk/overlay';
import { Subject } from 'rxjs';

export interface PopoverCloseEvent<T = any> {
    type: 'backdropClick' | 'close';
    data: T;
}

export class PopoverDialogRef<T = any> {
  public afterClosed = new Subject<PopoverCloseEvent<T>>();
  public afterClosed$ = this.afterClosed.asObservable();

  constructor(public overlay: OverlayRef, public data: T) {
    overlay.backdropClick().subscribe(() => {
      this._close('backdropClick', null);
    });
  }

  close(data?: T) {
    this._close('close', data);
  }

  private _close(type: PopoverCloseEvent['type'], data?: T) {
    this.overlay.dispose();
    this.afterClosed.next({
      type,
      data
    });
    this.afterClosed.complete();
  }
}
