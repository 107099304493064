import { Injectable } from '@angular/core';
import { ApplicationInsights, DistributedTracingModes, Exception, ITelemetryItem } from '@microsoft/applicationinsights-web';
import { environment } from 'src/environments/environment';
import { ErrorDetails, Event, Events } from '../models/events';
import { UserLoginProfile } from './../models/user';
import { Constants } from './../constants';
import { Traceparent } from '../models/traceparent';
import { getLanguage } from '../utilities';

@Injectable({
  providedIn: 'root'
})
export class TelemetryService {
  private logger: ApplicationInsights;
  private timedEventStack: Event[];

  constructor() {
    sessionStorage.setItem(Constants.SessionId, new Traceparent().traceId);
    this.logger = new ApplicationInsights({
      config: {
        instrumentationKey: environment.instrumentationKey,
        connectionString: environment.appInsightConnectionString,
        enableAutoRouteTracking: true,
        enableResponseHeaderTracking: true,
        distributedTracingMode: DistributedTracingModes.W3C,
        disableTelemetry: environment.name === 'development' || environment.name === 'onPrem',
        /* ...Other Configuration Options... */
      }
    });
    this.logger.loadAppInsights();
    this.logger.trackPageView();
    const telemetryInitializer = (envelope) => {
      if (this.isPingdomLaunchDarklyError(envelope)) {
        return false;
      }

      if (envelope.baseType === "PageviewData"){
        envelope.baseData.name = envelope.baseData.name.replace(/[^a-zA-Z0-9]/g, '');
      }

      envelope.tags['ai.cloud.role'] = `entities-designer-${environment.name}-${environment.region}`;
      return true;
    };
    this.logger.addTelemetryInitializer(telemetryInitializer);
    this.timedEventStack = [];
  }

  public startTimedEvent(event: Event) {
    event = this.assembleEventProperties(event);

    this.timedEventStack.push(event);
  }

  public endTimedEvent(event: Event) {
    const tmpStack = [];
    while (this.timedEventStack.length > 0) {
      const evt = this.timedEventStack.pop();
      if (evt.Name === event.Name) {
        // find start event, log it.
        const duration = Date.now() - evt.Time;
        evt.DurationMilliSeconds = duration;
        this.logger.trackEvent(
          {
            name: evt.Name,
            properties: evt,
          });
      } else {
        tmpStack.unshift(evt);
      }
    }
    this.timedEventStack = tmpStack;
  }

  public setUserId(user: UserLoginProfile) {
    this.logger.setAuthenticatedUserContext(user.id);
    sessionStorage.setItem(Constants.UserId, user.id);
    sessionStorage.setItem(Constants.AccountId, user.accountId);
    sessionStorage.setItem(Constants.TenantId, user.tenantId);

    const telemetryInitializer = (envelope: ITelemetryItem) => {
      envelope.data[Constants.AccountId] = user.accountId;
      envelope.data[Constants.TenantId] = user.tenantId;
      envelope.data[Constants.UserId] = user.id;
      envelope.data[Constants.SessionId] = sessionStorage.getItem(Constants.SessionId);
    };
    this.logger.addTelemetryInitializer(telemetryInitializer);
  }

  public clearUserId() {
    this.logger.clearAuthenticatedUserContext();
  }

  public logError(errorDetails: ErrorDetails): void {
    const event = {
      SessionId: sessionStorage.getItem(Constants.SessionId),
      CloudUserId: sessionStorage.getItem(Constants.UserId),
      CloudTenantId: sessionStorage.getItem(Constants.TenantId),
      CloudOrganizationId: sessionStorage.getItem(Constants.AccountId),
      Message: errorDetails.message
    };

    this.logger.trackEvent(
      {
        name: Events.ClientError.Name,
        properties: event
      });
  }

  public logEvent(event: Event, customProperties?: Record<string, unknown>): void {
    event = this.assembleEventProperties(event);

    const properties = { ...event, ...customProperties };

    this.logger.trackEvent(
      {
        name: event.Name,
        properties,
      });
  }

  public trackPageView(pageName: string): void {
    this.logger.trackPageView({ name: pageName });
  }

  private assembleEventProperties(event: Event): Event {
    event.SessionId = sessionStorage.getItem(Constants.SessionId);
    event.CloudUserId = sessionStorage.getItem(Constants.UserId);
    event.CloudTenantId = sessionStorage.getItem(Constants.TenantId);
    event.CloudOrganizationId = sessionStorage.getItem(Constants.AccountId);
    event.LanguageCode = getLanguage();
    event.Time = Date.now();
    return event;
  }

  private isPingdomLaunchDarklyError(envelope: ITelemetryItem): boolean {
    if (envelope.name === Exception.envelopeType) {
      // Ignore Pingdom feature flag error
      const msg = JSON.stringify(envelope);
      return (msg.indexOf('PingdomBot') > -1) && (msg.indexOf('https://events.launchdarkly.com') > -1);
    }
    return false;
  }

}
