import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'entities-import-bar',
  templateUrl: './entities-import-bar.component.html',
  styleUrls: ['./entities-import-bar.component.scss']
})
export class EntitiesImportBarComponent implements OnInit {

  public isVisible = true;

  constructor() { }

  ngOnInit(): void {
  }

  public onDismissConflictBar() {
    this.isVisible = false;
  }

}
