export class Constants {
  static readonly Authorization: string = 'Authorization';
  static readonly Bearer: string = 'Bearer';
  static readonly UserName: string = 'user_name';
  static readonly DataView: string = 'data_view';
  static readonly AccountIdHeaderName: string = 'X-UiPath-Internal-AccountId';
  static readonly TenantIdHeaderName: string = 'X-UiPath-Internal-TenantId';
  static readonly LocalizationHeader: string = 'X-UIPATH-Localization';
  static readonly OrganizationAdmin: string = '35551807-06b1-4cda-90a1-2fb84851eee7';
  static readonly DefaultNumberMaxValue: number = 1000000000000;
  static readonly DefaultNumberMinValue: number = -1000000000000;
  static readonly DefaultDecimalPlace: number = 2;
  static readonly DefaultLengthLimit: number = 200;
  static readonly Admin: string = 'Admin';
  static readonly Designer: string = 'Designer';
  static readonly DataWriter: string = 'DataWriter';
  static readonly DataReader: string = 'DataReader';
  static readonly ManagePermission: number = 0;
  static readonly ReadEntityScheme: number = 1;
  static readonly UpdateEntityScheme: number = 2;
  static readonly CreateEntityData: number = 3;
  static readonly ReadEntityData: number = 4;
  static readonly UpdateEntityData: number = 5;
  static readonly DeleteEntityData: number = 6;
  static readonly UserNotAuthorizedError: string = 'user is not logged in';
  static readonly UserTokenExpiredError: string = 'IDX10223:';
  static readonly FirstRun: string = 'firstRun';
  static readonly AccountId: string = 'CloudOrganizationId';
  static readonly TenantId: string = 'CloudTenantId';
  static readonly UserId: string = 'CloudUserId';
  static readonly Traceparent: string = 'traceparent';
  static readonly SessionIdHeader: string = 'x-SessionId';
  static readonly SessionId: string = 'SessionId';
  static readonly TraceId: string = 'traceId';
  static readonly Basic: string = 'Basic';
  static readonly Relationship: string = 'Relationship';
  static readonly File: string = 'File';
  static readonly Autonumber: string = 'AutoNumber';
  static readonly ChoiceSetSingle: string = 'ChoiceSetSingle';
  static readonly ChoiceSetMultiple: string = 'ChoiceSetMultiple';
  static readonly PortalLang: string = 'PORTAL_LOC_CONTEXT';
  static readonly Lang: string = 'lang';
  static readonly En: string = 'en';
  static readonly CreatedBy = 'CreatedBy';
  static readonly BuiltInFieldNames: string[] = ['Id', 'CreatedBy', 'CreateTime', 'UpdatedBy', 'UpdateTime'];
  static readonly FieldDisplayTypeBasic = 'Basic';
  static readonly SystemUserEntity = 'SystemUser';
  static readonly SystemUserIsActiveField = 'IsActive';
  static readonly SystemUserNameField = 'Name';
  static readonly RecordOwner = 'RecordOwner';
  static readonly UserType = 'UserType';
  static readonly BandwidthThrottleMessage = 'Out of bandwidth quota';
  static readonly InvalidIdentifiersFirstDimiss = 'InvalidIdentifiersFirstDimiss';
  static readonly SavedTenant = 'SavedTenant';
  static readonly PortalShellEventLogout = 'logout';
  static readonly PortalShellEventLanguageChanged = 'languageChanged';
  static readonly PortalShellEventTenantChanged = 'tenantChanged';
  static readonly ReadAll: string = 'Read All';
  static readonly ReadOwn: string = 'Read Own';
  static readonly CannotRead: string = 'Cannot Read';
  static readonly EditAll: string = 'Edit All';
  static readonly EditOwn: string = 'Edit Own';
  static readonly CannotEdit: string = 'Cannot Edit';
  static readonly DeleteAll: string = 'Delete All';
  static readonly DeleteOwn: string = 'Delete Own';
  static readonly CannotDelete: string = 'Cannot Delete';
  static readonly DataServiceDesigner: string = 'UiPath.DataService.Designer';
  static readonly ReadData: string = 'ReadData';
  static readonly UpdateData: string = 'UpdateData';
  static readonly CreateData: string = 'CreateData';
  static readonly DeleteData: string = 'DeleteData';
}
