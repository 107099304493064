import { Component, Inject } from '@angular/core';
import { UserService } from 'src/app/shared/services/user.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GetRolePermissionResponse } from 'src/app/shared/models/user';
import { Constants } from 'src/app/shared/constants';

export interface RoleDialogData {
  role: GetRolePermissionResponse;
}

@Component({
  selector: 'app-view-system-role-dialog',
  templateUrl: './view-system-role-dialog.component.html',
  styleUrls: ['./view-system-role-dialog.component.scss']
})
export class ViewSystemRoleDialogComponent {

  public Admin: string = Constants.Admin;
  public Designer: string = Constants.Designer;
  public DataReader: string = Constants.DataReader;
  public DataWriter: string = Constants.DataWriter;

  constructor(
    public usersService: UserService,
    public dialogRef: MatDialogRef<ViewSystemRoleDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: RoleDialogData
  ) { }

  public onDismiss(): void {
    this.dialogRef.close();
  }
}
