import { ChoiceSetMember } from './choiceset';
import { EntityType } from './entity';
import { Field } from './field';

export class ExportEntitySchema {
  public entities: EntitySchema[];
  public choicesets: ChoiceSetMember[];
}

export class EntitySchema {
  public description?: string;
  public displayName?: string;
  public entityType: EntityType;
  public entityTypeId: number;
  public fields?: Field[];
  public isRbacEnabled: boolean;
  public name: string;
}

export class ChoiceSetSchema {
  public name: string;
  public displayName: string;
  public description: string;
  public data: string[];
}

export function isEntityKeyValidate(object: unknown): boolean {
  return Object.prototype.hasOwnProperty.call(object, 'entities');
}

export function isEntityValueValidate(object: unknown): boolean {
  return Array.isArray(object['entities']);
}

export function isChoiceSetKeyValidate(object: unknown): boolean {
  return Object.prototype.hasOwnProperty.call(object, 'choicesets');
}

export function isChoiceSetValueValidate(object: unknown): boolean {
  return Array.isArray(object['choicesets']);
}

export function isEntitySchemaValidate(object: unknown): boolean {
  return Object.prototype.hasOwnProperty.call(object, 'name')
        && Object.prototype.hasOwnProperty.call(object, 'isRbacEnabled')
        && Object.prototype.hasOwnProperty.call(object, 'entityTypeId')
        && Object.prototype.hasOwnProperty.call(object, 'entityType')
        && Object.prototype.hasOwnProperty.call(object, 'fields');
}

export function isChoiceSetSchemaValidate(object: unknown): boolean {
  return Object.prototype.hasOwnProperty.call(object, 'name')
        && Object.prototype.hasOwnProperty.call(object, 'displayName')
        && Object.prototype.hasOwnProperty.call(object, 'description')
        && Object.prototype.hasOwnProperty.call(object, 'data');
}
