import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-html-tooltip',
  templateUrl: './html-tooltip.component.html', // Reference to your HTML template file
  styleUrls: ['./html-tooltip.component.scss']  // Reference to your styles
})
export class HtmlTooltipComponent {
  @Input() text = ''; // This remains a single string of HTML passed in
}
