import { Input } from '@angular/core';
import { Component } from '@angular/core';

export enum MiscIconType {
  UniqueWarning = 'uniqueWarning',
  ApiAccess = 'apiAccess',
  Admin = 'admin',
  LocalUser = 'localUser',
  LocalGroup = 'localGroup',
  Application = 'application',
  Robot = 'robot',
}
@Component({
  selector: 'app-misc-icon',
  templateUrl: './misc-icon.component.html',
  styleUrls: ['./misc-icon.component.scss']
})
export class MiscIconComponent {
  @Input('miscIconType')
  public miscIconType: MiscIconType;
}
