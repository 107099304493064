import { Directive, Input, ViewContainerRef, ElementRef, HostListener } from '@angular/core';
import { RbacTooltipComponent } from './rbac-tooltip.component';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';

@Directive({
  selector: '[appRbacTooltip]'
})
export class RbacTooltipDirective {
  @Input('appRbacTooltip') tooltipText: string;
  @Input() hrefLink: string;

  private overlayRef: OverlayRef;
  private hideTimeout: any;

  constructor(
    private overlay: Overlay,
    private viewContainerRef: ViewContainerRef,
    private elementRef: ElementRef
  ) {}

  @HostListener('mouseenter')
  onMouseEnter() {
    if (this.hideTimeout) {
      clearTimeout(this.hideTimeout);
    }
    if (!this.overlayRef) {
      const positionStrategy = this.overlay.position()
        .flexibleConnectedTo(this.elementRef)
        .withPositions([{
          originX: 'center',
          originY: 'bottom',
          overlayX: 'center',
          overlayY: 'top',
        }]);

      this.overlayRef = this.overlay.create({
        positionStrategy
      });
    }

    if (!this.overlayRef.hasAttached()) {
      const tooltipPortal = new ComponentPortal(RbacTooltipComponent, this.viewContainerRef);
      const tooltipRef = this.overlayRef.attach(tooltipPortal);
      const instance = tooltipRef.instance as RbacTooltipComponent;
      instance.tooltipText = this.tooltipText;
      instance.hrefLink = this.hrefLink;
      instance.mouseEnter.subscribe(() => this.onMouseEnter());
      instance.mouseLeave.subscribe(() => this.onMouseLeave());
    }
  }

  @HostListener('mouseleave')
  onMouseLeave() {
    this.hideTimeout = setTimeout(() => {
      if (this.overlayRef) {
        this.overlayRef.detach();
      }
    }, 300);
  }
}
