import { Injectable } from '@angular/core';
import LaunchDarkly from 'launchdarkly-js-client-sdk';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UrlUtils } from '../url.utils';
import { AuthService } from '../guards/auth.service';

export enum FeatureKey {
    EntitySchemaPermission = 'entity-schema-permission',
    EntityColumnPermission = 'entity-column-permission',
    EntityDataRecordPermission = 'entity-data-record-permission',

    FileAttachment = 'file-attachment',
    ChoiceSet = 'choice-set',
    OpenApi = 'open-api',
    SystemUserEntity = 'system-user-entity',
    EntitySchemaImportEmport = 'entity-schema-import-export',
    AutoNumber = 'auto-number',
    QueryBuilder = 'query-builder',
    Cdm = 'cdm-entity',
    AdminConsistency = 'admin-consistency',
}

const allFeatureKeys = Object.values(FeatureKey);

@Injectable({
  providedIn: 'root'
})
export class FeatureService {

  private featureEnablementSubjects: Record<string, BehaviorSubject<boolean>>;

  constructor(
        private authService: AuthService,
  ) {
    this.featureEnablementSubjects = allFeatureKeys.reduce((subjects, key) => {
      subjects[key] = new BehaviorSubject(false);
      return subjects;
    }, {});

    if (environment.name.indexOf('onPrem') > -1 || environment.name.indexOf('gov') > -1) {
      allFeatureKeys.forEach(key => {
        const subject = this.featureEnablementSubjects[key];
        if (key !== FeatureKey.EntityDataRecordPermission && key !== FeatureKey.Cdm) {
          subject.next(true);
        }
      });
    } else {
      const { account, tenant } = UrlUtils.getRequestContext();
      const userProfile = this.authService.userProfile;

      const launchDarklyClient = LaunchDarkly.initialize(
        environment.launchDarklyClientId, {
          key: `${account}/${tenant}`,
          custom: {
            AccountName: account,
            TenantName: tenant,
            Email: userProfile && userProfile.email,
          },
        });

      launchDarklyClient.on('ready', () => {
        allFeatureKeys.forEach(key => {
          const subject = this.featureEnablementSubjects[key];
          const isEnabled = launchDarklyClient.variation(key);
          subject.next(isEnabled);
        });
      });
    }
  }

  public isEnabled(featureKey: FeatureKey): Observable<boolean> {
    return this.featureEnablementSubjects[featureKey].asObservable();
  }
}
