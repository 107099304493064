<portal-alert-bar *ngIf="isVisible" status="info" (alertDismissed)="onDismissUpgradeBar()">
    <div fxLayout="row" fxLayoutAlign="center">
        <div class="text-height">{{'UpgradeEntityBar.UpgradeMessage' | translate}}</div>
        <a class="alert-bar-button text-height" tabindex="0"
            href="https://docs.uipath.com/data-service/docs/system-user-entity" target="_blank"
            rel="noreferrer noopener">
            {{'LearnMoreText' | translate}}
        </a>
        <button mat-button class="text-height" color="primary" (click)="onClickUpgrade()">
            {{'UpgradeEntityBar.UpgradeAction' | translate}}
        </button>
    </div>
</portal-alert-bar>
