import { Component, Input } from '@angular/core';
import { Params, Router } from '@angular/router';

export class BreadcrumbSecondary {
  key: string;
  url?: string;
  queryParams?: Params;
  customNavigate?: () => void;;
}

@Component({
  selector: 'app-breadcrumb-secondary',
  templateUrl: './breadcrumb-secondary.component.html',
  styleUrls: ['./breadcrumb-secondary.component.scss']
})
export class BreadcrumbSecondaryComponent{
  @Input() breadcrumbs: BreadcrumbSecondary[] = [];

  constructor(
    private router: Router
  ) { }

  public navigate(breadcrumb: BreadcrumbSecondary) {
    if (breadcrumb.customNavigate) {
      breadcrumb.customNavigate();
    } else {
      this.router.navigate([breadcrumb.url], { queryParams: breadcrumb.queryParams });
    }
  }
}
