import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { zip } from 'rxjs';
import { ConfirmationDialogComponent, ConfirmationType } from 'src/app/shared/components/confirmation-dialog/confirmation-dialog.component';
import { SnackBarComponent, SnackBarMode } from 'src/app/shared/components/snack-bar/snack-bar.component';
import { Constants } from 'src/app/shared/constants';
import { Entity } from 'src/app/shared/models/entity';
import { FieldType } from 'src/app/shared/models/field';
import { EntitiesService } from 'src/app/shared/services/entities.service';
import { FeatureKey, FeatureService } from 'src/app/shared/services/feature.service';
import { UserService } from 'src/app/shared/services/user.service';

@Component({
  selector: 'app-upgrade-entity-bar',
  templateUrl: './upgrade-entity-bar.component.html',
  styleUrls: ['./upgrade-entity-bar.component.scss']
})
export class UpgradeEntityBarComponent implements OnInit {
  @Input('entity')
  public entity: Entity;

  @Output()
  public postUpgradeAction: EventEmitter<any> = new EventEmitter<any>();

  public isVisible = false;

  constructor(
    private entitiesService: EntitiesService,
    private featureService: FeatureService,
    private translateService: TranslateService,
    private userService: UserService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
  ) {
  }

  public ngOnInit() {
    const createdByField = this.entity && this.entity.fields.find(f => f.name === Constants.CreatedBy);
    const isOldVersion = createdByField && createdByField.fieldDisplayType === Constants.FieldDisplayTypeBasic;
    const isCreatedByFieldUniqueId = createdByField && createdByField.type === FieldType.uniqueid;

    zip(
      this.featureService.isEnabled(FeatureKey.SystemUserEntity),
      this.userService.checkPermission([Constants.UpdateEntityScheme], this.entity.id)
    ).subscribe(result => {
      const [isEnabled, hasPermission] = result;
      this.isVisible = isEnabled && hasPermission && isOldVersion && isCreatedByFieldUniqueId;
    });
  }

  public onDismissUpgradeBar() {
    this.isVisible = false;
  }

  public onClickUpgrade() {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: 'auto',
      data: {
        title: this.translateService.get('UpgradeEntityBar.ConfirmationTitle'),
        yesText: this.translateService.get('UpgradeEntityBar.ConfirmationUpgradeButton'),
        noText: this.translateService.get('CancelText'),
        confirmationText: this.translateService.get('UpgradeEntityBar.ConfirmationBody'),
        doubleConfirmNeeded: false,
        asyncFunc: () => this.entitiesService.convertEntity(this.entity)
      }
    });

    dialogRef.afterClosed().subscribe(confirmation => {
      if (confirmation.result === ConfirmationType.yes) {
        this.isVisible = false;
        this.postUpgradeAction.emit();

        this.snackBar.openFromComponent(
          SnackBarComponent,
          {
            duration: 5000, verticalPosition: 'top', data: {
              mode: SnackBarMode.success,
              text: this.translateService.get('UpgradeEntityBar.SucccessText')
            }
          }
        );
      }
    });
  }
}
