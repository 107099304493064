<app-migration-bar></app-migration-bar>
<ng-container *ngIf="showNoPermission">
  <app-no-permission></app-no-permission>
</ng-container>
<ng-container *ngIf="!showNoPermission">
  <portal-alert-bar *ngIf="shouldShowInvalidIdentifiers()" status="warning" cancelable="true"
    (alertDismissed)="invalidIdentifierOnDimiss()">{{getInvalidIdentifiers() | async}}</portal-alert-bar>
  <div *ngIf="!isEntitiesImport()" fxLayout="row" fxLayoutAlign="space-between center" class="title-section">
    <app-breadcrumb [breadcrumbs]="breadcrumbs"></app-breadcrumb>
    <div fxLayout="row" fxLayoutAlign="none">
      <div>
        <ng-container *ngIf="isAdminConsistencyEnabled">
          <button mat-stroked-button [attr.aria-label]="'ManageChoicesets' | translate" color="primary"
            class="new-field-button bold choiceset-button" (click)="onClickChoicesets()">
            {{'ManageChoicesets' | translate}}
          </button>
        </ng-container>
      </div>
      <div
        [matTooltip]="(userService.checkPermission([UpdateEntityScheme]) | async) === false? ('NoPermissionToolTip' | translate) : ''">
        <button mat-flat-button [attr.aria-label]="'CreateNewEntity' | translate" color="primary"
          class="new-field-button bold" (click)="onUpsertEntity()"
          [class.disabled]="(userService.checkPermission([UpdateEntityScheme]) | async) === false">
          {{'CreateNewEntity' | translate}}
        </button>
      </div>
    </div>
  </div>

  <div class="entity-tool-bar list-tool-bar" fxLayout="row" fxLayoutAlign=" center">
    <mat-form-field floatLabel="never" class="apollo-textfield-small search-bar"
      [attr.aria-label]="'SearchText' | translate">
      <mat-icon matSuffix>search</mat-icon>
      <input matInput autocomplete="false" [placeholder]="'SearchText' | translate" [(ngModel)]="searchValue"
        (keyup)="onSearch()" />
    </mat-form-field>
    <ng-container *ngIf="isCdmEnabled && !isEntitiesImport() && !isLoadingEntity && currentTemplates.length > 1">
      <span class="find-template-text"> {{'ListEntities.FindTemplate' | translate}}: </span>
      <mat-form-field class="apollo-textfield-small search-column template-filter" floatLabel="never">
        <mat-select [placeholder]="'ListEntities.NoTemplate' | translate" [(value)]="appliedTemplate">
          <mat-option *ngFor="let template of currentTemplates" [value]="template" (click)="onSelectTemplate(template)">
            {{template.name | translate}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </ng-container>
    <ng-container *ngIf="isEntitiesImport()">
      <mat-checkbox (change)="toggleShowOnlyConflictEntities()" class="conflict-box">
        {{'EntitiesConflict.ShowOnlyConflicting' | translate}}
      </mat-checkbox>
      <portal-custom-icon name="warning" size="24px" [matTooltip]="'EntitiesConflict.MessageTooltip' | translate"
        class=conflict-box-icon></portal-custom-icon>
    </ng-container>
    <span class="spacer"></span>
    <ng-container *ngIf="!isEntitiesImport()">
      <ng-container *ngIf="isExportImportVisible() | async">
        <button mat-button [matMenuTriggerFor]="menu" class="bold">
          <span class="export-btn-label">
            <mat-icon class="material-icons md-24">swap_vert</mat-icon>
            <span>{{'ExportImport.Title' | translate}}</span>
            <mat-icon class="material-icons md-24 standard-icon expand-more-icon">expand_more</mat-icon>
          </span>
        </button>
      </ng-container>
      <mat-menu #menu="matMenu">
        <button mat-menu-item class="menu-item" (click)="onExportEntities()">
          <div class="menu-button-text">{{'ExportImport.Export' | translate}}</div>
        </button>
        <button mat-menu-item class="menu-item" (click)="file.click()">
          <div>{{'ExportImport.Import' | translate}}</div>
          <input #file type="file" (change)="selectFile($event.srcElement.files)" style="display:none;"
            accept=".json" />
        </button>
      </mat-menu>

      <button class="bold" mat-button [attr.aria-label]="'ListEntities.UseInStudio' | translate"
        (click)="onClickUseInStudio()" [matTooltip]="'Tooltip.UseInStudio' | translate">
        <mat-icon class="material-icons md-24 standard-icon">link</mat-icon>
        <span>{{'ListEntities.UseInStudio' | translate}}</span>
      </button>
      <button class="refresh bold" mat-button [attr.aria-label]="'ListEntities.Refresh' | translate"
        (click)="onClickRefresh()">
        <mat-icon class="material-icons md-24 standard-icon">refresh</mat-icon>
        <span>{{'ListEntities.Refresh' | translate}}</span>
      </button>
    </ng-container>
  </div>

  <ng-container *ngIf="isLoadingEntity">
    <mat-spinner class="list-entity-spinner" role="alert" [attr.aria-label]="'Loading' | translate" diameter="48"></mat-spinner>
  </ng-container>
  <div *ngIf="searchValue || selectedTemplates.length > 0" class="search-result font-medium" fxLayout="row"
    fxLayoutAlign=" center">
    <div class="search-text">
      <span class="bold">{{entities.length}} {{'ListEntities.ResultsFor' | translate}}:</span>
      {{searchValue}}
    </div>
    <div *ngIf="selectedTemplates.length > 0">
      <div fxLayout="row" fxLayoutAlign=" center">
        <div class="template-label">
          {{'ListEntities.Templates' | translate}}:
        </div>
        <ng-container *ngFor="let template of selectedTemplates">
          <mat-chip-option selectable="false" removable="true" disableRipple="true" class="filter-chip"
            (removed)="removeTemplate(template)">
            {{template.name | translate}}
            <mat-icon matChipRemove class="chip-cancel">cancel</mat-icon>
          </mat-chip-option>
        </ng-container>

        <button *ngIf="selectedTemplates.length>0" mat-button [attr.aria-label]="'ListEntities.ClearAll' | translate"
          (click)="onClearAllTemplates()">
          <span class="clear-all bold">
            {{'ListEntities.ClearAll' | translate}}
          </span>
        </button>
      </div>
    </div>
  </div>

  <div *ngIf="!isLoadingEntity && !(searchValue && entities.length ===0)" class="table-scroll-wrapper">
    <ng-container>
      <table mat-table [dataSource]="dataSource" matSort (matSortChange)="sortData($event)">
        <caption></caption>
        <ng-container matColumnDef="displayName">
          <th scope="cell" mat-header-cell *matHeaderCellDef mat-sort-header
            class="table-header-overflow table-header-text font-medium bold">
            {{'ListEntities.DisplayName' | translate}}
            <span role="status" [attr.aria-label]="ariaHelper.getOrderKey('displayName') | translate"></span>
          </th>
          <th mat-cell *matCellDef="let element" (click)="onClickEntity(element)"
            class="th-first-cell display-name font-medium bold" tabindex="0" (keydown.enter)="onClickEntity(element)">
            <span *ngIf="isEntitiesImport() && isConflictEntity(element); else display_name">
              <portal-custom-icon name="warning" size="24px"
                [matTooltip]="'EntitiesConflict.MessageTooltip' | translate"
                class=conflict-entity-icon></portal-custom-icon>
              <span class="conflict-entity-name">{{element.displayName}}</span>
            </span>
            <ng-template #display_name>
              <div fxLayout="row" fxLayoutAlign=" center" [class.chip-header]="element.categoryId">
                {{element.displayName}}
              </div>
            </ng-template>
          </th>
        </ng-container>

        <ng-container matColumnDef="name">
          <th scope="cell" mat-header-cell *matHeaderCellDef mat-sort-header
            class="table-header-overflow table-header-text font-medium bold">
            {{'ListEntities.Name' | translate}}
            <span role="status" [attr.aria-label]="ariaHelper.getOrderKey('name') | translate"></span>
          </th>
          <td mat-cell *matCellDef="let element" class="table-name-text"> {{element.name}} </td>
        </ng-container>

        <ng-container *ngIf="isCdmEnabled && appliedTemplate?.id" matColumnDef="isModelReserved">
          <th scope="cell" mat-header-cell *matHeaderCellDef mat-sort-header
            class="table-header-overflow table-header-text font-medium bold">
            {{'ListEntities.IsModelReserved' | translate}}
            <span role="status" [attr.aria-label]="ariaHelper.getOrderKey('isModelReserved') | translate"></span>
          </th>
          <td mat-cell *matCellDef="let element" class="table-name-text"> {{(element.isModelReserved ?
            'ListField.UniqueYes' : 'ListField.UniqueNo') | translate}} </td>
        </ng-container>

        <ng-container matColumnDef="description">
          <th scope="cell" mat-header-cell *matHeaderCellDef mat-sort-header
            class="table-header-overflow description-column table-header-text  font-medium bold">
            {{'ListEntities.Description' | translate}}
            <span role="status" [attr.aria-label]="ariaHelper.getOrderKey('description') | translate"></span>
          </th>
          <td mat-cell *matCellDef="let element" class="table-text">
            {{element.description}}
          </td>
        </ng-container>

        <ng-container matColumnDef="fieldNumber">
          <th scope="cell" mat-header-cell *matHeaderCellDef mat-sort-header
            class="table-header-overflow table-header-text  font-medium bold">
            {{'ListEntities.NumberOfFields' | translate}}
            <span role="status" [attr.aria-label]="ariaHelper.getOrderKey('fieldNumber') | translate"></span>
          </th>
          <td mat-cell *matCellDef="let element" class="table-text">
            {{element.fields && element.fields.length}} </td>
        </ng-container>

        <ng-container *ngIf="!isEntitiesImport()" matColumnDef="recordCount">
          <th scope="cell" mat-header-cell *matHeaderCellDef mat-sort-header
            class="table-header-overflow table-header-text  font-medium bold">
            {{'ListEntities.NumberOfRecords' | translate}}
            <span role="status" [attr.aria-label]="ariaHelper.getOrderKey('recordNumber') | translate"></span>
          </th>
          <td mat-cell *matCellDef="let element" class="table-text"> {{element.recordCount}} </td>
        </ng-container>

        <ng-container *ngIf="!isEntitiesImport()" matColumnDef="updateTime">
          <th scope="cell" mat-header-cell *matHeaderCellDef mat-sort-header
            class="table-header-overflow table-header-text font-medium bold">
            {{'ListEntities.LastModifiedOn' | translate}}
            <span role="status" [attr.aria-label]="ariaHelper.getOrderKey('updateTime') | translate"></span>
          </th>
          <td mat-cell *matCellDef="let element" class="table-text"> {{element.updateTime | relativeTime}}
          </td>
        </ng-container>

        <ng-container *ngIf="!isEntitiesImport()" matColumnDef="edit">
          <th scope="cell" mat-header-cell *matHeaderCellDef class="action-column"> </th>
          <td mat-cell *matCellDef="let element">
            <div fxLayout="row" fxLayoutAlign="end">
              <div [matTooltip]="getEditTooltip(element, 'EditText') | async">
                <button mat-icon-button [attr.aria-label]="'EditEntityText' | translate:{value: element.displayName}"
                  [disabled]="isSystemEntity(element) || (userService.checkPermission([UpdateEntityScheme]) | async) === false"
                  (click)="onUpsertEntity(element)" tabindex="0">
                  <mat-icon class="material-icons md-24 standard-icon menu-button">edit</mat-icon>
                </button>
              </div>
              <div [matTooltip]="getDeleteTooltip(element, 'DeleteText') | async">
                <button mat-icon-button [attr.aria-label]="'DeleteEntityText' | translate:{value: element.displayName}"
                  [disabled]="isSystemEntity(element) || (userService.checkPermission([UpdateEntityScheme]) | async) === false"
                  (click)="onDeleteEntity(element)" tabindex="0">
                  <mat-icon class="material-icons md-24 standard-icon menu-button"
                    [innerText]="isReservedEntity(element) ? 'delete_sweep' : 'delete'"></mat-icon>
                </button>
              </div>
              <!-- Can be reused in case we need to enable a menu per entity. -->
              <!-- <div *ngIf="isDataLevelPermissionEnabled" [matTooltip]="getDeleteTooltip(element, 'MoreTooltip') | async">
                <button mat-icon-button [matMenuTriggerFor]="menu" [attr.aria-label]="'MoreTooltipOn' | translate:{value: element.displayName}"
                  [disabled]="isSystemEntity(element) || (userService.checkPermission([UpdateEntityScheme]) | async) === false"
                  class="more" tabindex="0" (click)="focusHelper.onLauncherClick($event)">
                  <mat-icon class="menu-button">more_vert</mat-icon>
                </button>
              </div> -->
              <!-- <mat-menu #menu="matMenu">
                <button *ngIf="!element.isRbacEnabled" mat-menu-item class="menu-item"
                  [attr.aria-label]="'EnableRbacText' | translate" (click)="onEnableRoleBasedDataAccess(element, true)">
                  {{'EnableRbacText' | translate}}
                </button>
                <button *ngIf="element.isRbacEnabled" mat-menu-item class="menu-item"
                  [attr.aria-label]="'DisableRbacText' | translate"
                  (click)="onEnableRoleBasedDataAccess(element, false)">
                  {{'DisableRbacText' | translate}}
                </button>
                <button mat-menu-item class="menu-item" [attr.aria-label]="'DeleteText' | translate"
                  (click)="onDeleteEntity(element)"
                  [innerText]="isReservedEntity(element) ? ('ListEntities.DeleteTemplateOption' | translate) : ('DeleteText' | translate)">
                </button>
              </mat-menu> -->
            </div>
          </td>
        </ng-container>

        <ng-container *ngIf="isEntitiesImport()" matColumnDef="action">
          <th scope="cell" mat-header-cell *matHeaderCellDef class="checkbox-column">
            <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()"
              [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()">
              <span class="font-medium bold">
                {{ 'Import' | translate }}?
              </span>
            </mat-checkbox>

          </th>
          <td mat-cell *matCellDef="let row" class="checkbox-column">
            <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? toggleRow($event, row) : null"
              (keydown.enter)="$event ? toggleRow($event, row) : null" [checked]="selection.isSelected(row)"
              [aria-label]="checkboxLabel(row)">
            </mat-checkbox>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns" class="table-header themed-list-header"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="table-row" (dblclick)="onClickEntity(row)">
        </tr>
      </table>
    </ng-container>
    <div *ngIf="!(entities.length>0) && !searchValue && isEntitiesImport()" class="no-entities" fxLayout="column"
      fxLayoutAlign="space-around center">
      <div class="no-entities-text">
        {{'ListEntities.NoEntitiesText' | translate}}
      </div>
      <button mat-flat-button class="create-entity-button create-entity-text"
        [attr.aria-label]="'ListEntities.CreateNewEntity' | translate"
        [class.disabled]="(userService.checkPermission([UpdateEntityScheme]) | async) === false"
        (click)="onUpsertEntity(null)" disableRipple="true">
        <span class="font-medium bold">
          {{'ListEntities.CreateNewEntity' | translate}}
        </span>
        <mat-icon class="material-icons md-12 plus">add</mat-icon>
      </button>
    </div>

  </div>
  <mat-paginator *ngIf="(entities.length>0)" [length]="entities.length" [pageSize]="20" [pageSizeOptions]="[10, 20, 50]"
    (page)="onChangePagination($event)">
  </mat-paginator>
</ng-container>
