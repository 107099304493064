import { Entity } from './entity';

export enum FieldType {
    uniqueid = 'uniqueid',
    text = 'text',
    number = 'number',
    boolean = 'boolean',
    date = 'date',
    dateTime = 'dateTime',
    autonumber = 'autonumber',
    relationship = 'relationship',
    file = 'file',
    choiceSetSingle = 'choiceSetSingle',
    choiceSetMultiple = 'choiceSetMultiple',
}

export enum RelationshipType {
    oneToMany = 'oneToMany',
    manyToOne = 'manyToOne'
}

export class SqlType {
  constructor(
        public name: string,
        public lengthLimit?: number,
        public decimalPrecision?: number,
        public maxValue?: number,
        public minValue?: number,
  ) { }
}

export class FieldUpdateRequest {
  constructor(
        public entityId: string,
        public id: string,
        public fieldName: string,
        public displayName: string,
        public description: string,
        public isRequired: boolean,
        public isUnique?: boolean,
        public defaultValue?: string,
        public sqlType?: SqlType,
        public referenceFieldId?: string,
        public isRbacEnabled?: boolean,
  ) {
  }
}

export interface FilterField {
    fieldName: string;
    operator: string;
    value: string;
}

export class Field {
  constructor(
        public id?: string,
        public name?: string,
        public entityId?: string,
        public displayName?: string,
        public type?: FieldType,
        public description?: string,
        public isRequired?: boolean,
        public fieldDisplayType?: string,
        public isSystemField?: boolean,
        public isModelReserved?: boolean,
        public createTime?: Date,
        public updateTime?: Date,
        public value?: string,
        public sqlType?: SqlType,
        public referenceEntity?: Entity,
        public referenceField?: Field,
        public definition?: FieldDefinition,
        public isForeignKey?: boolean,
        public choiceSetId?: string,
        public defaultValue?: string,
        public constraint?: FieldConstraint,
        public fieldConstraint?: string,
        public createdBy?: string,
        public updatedBy?: string,
        public isRbacEnabled?: boolean,
        public isUnique?: boolean,
  ) { }
}

export class FieldDefinition {
  constructor(
        public Name?: string,
        public IsPrimaryKey?: boolean,
        public IsForeignKey?: boolean,
        public SqlType?: SqlType,
        public IsRequired?: boolean,
        public FieldDisplayType?: string,
        public DisplayName?: string,
        public Description?: string,
        public IsSystemField?: boolean,
        public CreateTime?: Date,
        public UpdateTime?: Date,
        public ReferenceEntity?: Entity,
        public ReferenceField?: Field,
        public DefaultValue?: string,
        public FieldConstraint?: string,
        public ChoiceSetId?: string,
        public IsRbacEnabled?: boolean,
        public IsUnique?: boolean,
  ) { }
}

export class FieldConstraint {
  constructor(
        public DecimalPrecision?: number,
        public LengthLimit?: number,
        public MaxValue?: number,
        public MinValue?: number
  ) { }
}

export class FieldCreateRequest {
  constructor(
        public entityId?: string,
        public fieldDefinition?: FieldDefinition
  ) { }
}

export class Relationship {
  constructor(
        public id: string,
        public type: RelationshipType,
        public entity: Entity,
        public field: Field,
        public referencedEntity: Entity,
        public referencedField: Field
  ) { }
}
