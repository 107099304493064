import { Component, Inject } from '@angular/core';
import { UserService } from 'src/app/shared/services/user.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GetRoleResponse } from 'src/app/shared/models/user';
import { DataMode } from '../upsert-user-dialog/upsert-user-dialog.component';
import { Constants } from 'src/app/shared/constants';

export interface RoleDialogData {
  mode: DataMode;
  role: GetRoleResponse;
}

@Component({
  selector: 'app-upsert-role-dialog',
  templateUrl: './upsert-role-dialog.component.html',
  styleUrls: ['./upsert-role-dialog.component.scss']
})
export class UpsertRoleDialogComponent {

  public isLoading = false;

  public Admin: string = Constants.Admin;
  public ReadEntityScheme: number = Constants.ReadEntityScheme;
  public UpdateEntityScheme: number = Constants.UpdateEntityScheme;
  public ReadEntityData: number = Constants.ReadEntityData;
  public CreateEntityData: number = Constants.CreateEntityData;
  public UpdateEntityData: number = Constants.UpdateEntityData;
  public DeleteEntityData: number = Constants.DeleteEntityData;

  constructor(
    public usersService: UserService,
    public dialogRef: MatDialogRef<UpsertRoleDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: RoleDialogData
  ) { }

  public shouldShowCheck(permission: number, role: GetRoleResponse) {
    for (const perm of role.permissions) {
      if (perm === permission) {
        return true;
      }
    }
    return false;
  }

  public onDismiss(): void {

    this.dialogRef.close();
  }
}
