import { Component, SimpleChanges, Input, OnChanges, OnInit, EventEmitter, Output, OnDestroy } from '@angular/core';
import { GetRoleResponse, GetRolePermissionResponse } from 'src/app/shared/models/user';
import { MatTableDataSource } from '@angular/material/table';
import { Pagination } from '../../entities/entity-details/data/list-data/list-data.component';
import { UserService } from 'src/app/shared/services/user.service';
import { RolesService } from 'src/app/shared/services/roles.service';
import { MatDialog } from '@angular/material/dialog';
import { SortData } from 'src/app/shared/models/environment';
import { DataMode } from '../upsert-user-dialog/upsert-user-dialog.component';
import { TelemetryService } from 'src/app/shared/services/telemetry.service';
import { Events } from 'src/app/shared/models/events';
import { UpsertCustomRoleDialogComponent } from '../upsert-custom-role-dialog/upsert-custom-role-dialog.component';
import { ViewSystemRoleDialogComponent } from '../view-system-role-dialog/view-system-role-dialog.component';
import { ConfirmationDialogComponent } from 'src/app/shared/components/confirmation-dialog/confirmation-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subscription } from 'rxjs';
import { FeatureKey, FeatureService } from 'src/app/shared/services/feature.service';
import { SnackBarComponent } from 'src/app/shared/components/snack-bar/snack-bar.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { getTableSortAriaHelper } from 'src/app/shared/utilities';
import { Router } from '@angular/router';
import { UrlUtils } from 'src/app/shared/url.utils';

@Component({
  selector: 'app-list-roles',
  templateUrl: './list-roles.component.html',
  styleUrls: ['./list-roles.component.scss']
})
export class ListRolesComponent implements OnInit, OnChanges, OnDestroy {
  @Input('roles')
  public roles: GetRoleResponse[];

  @Output()
  public refreshRoles: EventEmitter<void> = new EventEmitter<void>();

  public dataSource: MatTableDataSource<GetRoleResponse>;
  public snapshot: GetRoleResponse[];
  public displayedColumns = ['role', 'type', 'directoryEntityCount', 'action'];
  public ariaHelper = getTableSortAriaHelper();
  public searchValue: string;
  public isLoading = true;
  public pagination: Pagination;
  public isViewLoading: boolean;
  public isEditLoading: boolean;
  public isAdminConsistencyEnabled = false;
  private subscription: Subscription = new Subscription();

  constructor(
    private router: Router,
    public userService: UserService,
    public rolesService: RolesService,
    public dialog: MatDialog,
    private telemetryService: TelemetryService,
    private translateService: TranslateService,
    private featureService: FeatureService,
    private snackBar: MatSnackBar,
  ) {
  }

  public ngOnInit() {
    const fsSub = this.featureService.isEnabled(FeatureKey.AdminConsistency)
      .subscribe(result => {
        this.isAdminConsistencyEnabled = result;
      });

    this.subscription.add(fsSub);
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (this.roles) {
      this.snapshot = [...this.roles];
      this.isLoading = false;
      this.syncDataRecord();
    }
  }

  public ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  public onUpsertRole() {
    this.telemetryService.logEvent(Events.CreateNewRoleClick);

    if(!this.featureService.isEnabled(FeatureKey.EntityDataRecordPermission)){
      const sub = this.rolesService.openUpsertRoleDialog().subscribe(result => {
        if (result) {
          this.loadRoles();
          this.loadRolesV2();
        }
      });
      this.subscription.add(sub);
    }
    else{
      // this.router.navigate(['/entity/IMDB/dataservice_/create-role'], {
      this.router.navigate([`${UrlUtils.getBaseRouteUrl()}/create-role`], {
        queryParams: {
          mode: DataMode.create,
          roleId: '' // or provide a default roleId if needed
        }
      });
    }
  }

  public loadRolesV2() {
    const sub = this.rolesService.loadRolesV2().subscribe(
      roles => this.roles = roles
    );
    this.subscription.add(sub);
  }

  public sortData(sort: SortData) {
    this.ariaHelper.setSortOrder(sort.active, sort.direction);

    const data = [...this.dataSource.data];
    data.sort((a, b) => {
      let valueA;
      let valueB;

      switch (sort.active) {
      case 'role':
        valueA = a['displayName'] || a['name'];
        valueB = b['displayName'] || b['name'];
        break;

      case 'type':
        valueA = a['type'];
        valueB = b['type'];
        break;

      case 'directoryEntityCount':
        valueA = +a['directoryEntityCount'];
        valueB = +b['directoryEntityCount'];
        break;

      default:
        valueA = a['action'];
        valueB = b['action'];
      }

      // If both values are numbers
      if (!isNaN(valueA) && !isNaN(valueB)) {
        return (sort.direction === 'asc' ? 1 : -1) * (valueA - valueB);
      }

      // If both values are strings
      if (typeof valueA === 'string' && typeof valueB === 'string') {
        return (sort.direction === 'asc' ? 1 : -1) * valueA.localeCompare(valueB);
      }

      // If one value is a string and the other is a number
      if (typeof valueA === 'string') {
        return sort.direction === 'asc' ? -1 : 1;
      }
      if (typeof valueB === 'string') {
        return sort.direction === 'asc' ? 1 : -1;
      }
    });

    this.dataSource = new MatTableDataSource(data);
  }

  public onViewRow(input?: any) {
    this.isViewLoading = true;
    const sub = this.userService.getRoleByIdAsync(input.id).subscribe((result: GetRolePermissionResponse) => {
      this.isViewLoading = false;
      result.displayName = this.userService.convertToRoleDisplayName(result.name);
      this.dialog.open(ViewSystemRoleDialogComponent, {
        width: 'auto',
        height: '100%',
        position: { top: '0', right: '0' },
        disableClose: true,
        panelClass: 'custom-sidepane',
        data: { role: result }
      });
    }, () => {
      this.isViewLoading = false;
    });
    this.subscription.add(sub);
  }

  public onSearch() {
    this.syncDataRecord();
  }

  public onClickRefresh() {
    this.loadRoles();
  }

  public onChangePagination(pagination: Pagination) {
    this.pagination = pagination;
    this.dataSource = new MatTableDataSource(this.getPagedData());
  }

  public onUpsertCustomRole(input?: GetRoleResponse) {
    this.telemetryService.logEvent(Events.CreateNewRoleClick);
    this.isEditLoading = true;
    if(!this.featureService.isEnabled(FeatureKey.EntityDataRecordPermission)){
      const sub = this.userService.getRoleByIdAsync(input.id).subscribe((result: GetRolePermissionResponse) => {
        this.isEditLoading = false;
        const dialogRef = this.dialog.open(UpsertCustomRoleDialogComponent, {
          width: 'auto',
          height: '100%',
          position: { top: '0', right: '0' },
          disableClose: true,
          panelClass: 'custom-sidepane',
          data: { mode: DataMode.update, role: result }
        });

        dialogRef.afterClosed().subscribe(result => {
          if (result) {
            this.loadRoles();
          }
        });
      }, (error) => {
        this.isEditLoading = false;
        this.snackBar.openFromComponent(SnackBarComponent,
          { duration: 5000, verticalPosition: 'top', data: error });
      });
      this.subscription.add(sub);
    }
    else{
      // this.router.navigate(['/entity/IMDB/dataservice_/create-role'], {
      this.router.navigate([`${UrlUtils.getBaseRouteUrl()}/create-role`], {
        queryParams: {
          mode: DataMode.update,
          roleId: input.id
        }
      });
    }
  }

  public onDeleteCustomRole(input?: GetRoleResponse) {
    this.telemetryService.logEvent(Events.CreateNewRoleClick);

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: 'auto',
      data: {
        title: this.translateService.get('ListRoles.DeleteTitle', { value: input.name }),
        yesText: this.translateService.get('DeleteText'),
        noText: this.translateService.get('CancelText'),
        confirmationText: this.translateService.get('ListRoles.DeleteConfirmText'),
        doubleConfirmNeeded: true,
        doubleConfirmText: input.name,
        asyncFunc: () => this.userService.deleteCustomRole(input.id),
      }
    });

    const sub = dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.loadRoles();
      }
    });
    this.subscription.add(sub);
  }

  public convertToDisplayRoleType(roleType: string): Observable<string> {
    switch (roleType) {
    case 'System':
      return this.translateService.get('ListRoles.StandardRoleType');
    default:
      return this.translateService.get('ListRoles.CustomRoleType');
    }
  }

  private getPagedData(): GetRoleResponse[] {
    if (this.pagination) {
      const start = this.pagination.pageIndex * this.pagination.pageSize;
      const end = start + this.pagination.pageSize;
      return [...this.snapshot].slice(start, end);
    } else {
      return [...this.snapshot].slice(0, 20);
    }
  }

  private loadRoles() {
    this.isLoading = true;
    this.refreshRoles.emit();
  }

  private syncDataRecord() {
    let newData = [...this.snapshot];

    if (this.searchValue) {
      newData = newData.filter(itm => JSON.stringify(itm).toLocaleLowerCase().indexOf(this.searchValue.toLocaleLowerCase()) >= 0);
    }

    this.dataSource = new MatTableDataSource(newData);
  }
}
