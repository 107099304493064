<div fxLayout="row" fxLayoutAlign="start stretch">
    <div fxLayout="row" fxLayoutAlign="start stretch" class="content">
        <ng-container *ngIf="isSuccessMode()">
            <mat-icon class="material-icons md-24 success-icon standard-icon">check_circle</mat-icon>
        </ng-container>
        <ng-container *ngIf="!isSuccessMode()">
            <mat-icon class="material-icons md-24 error-icon standard-icon" *ngIf="data.errorText">error</mat-icon>
            <mat-icon class="material-icons md-24 info-icon standard-icon" *ngIf="data.text">info</mat-icon>
        </ng-container>
        <div class="error-text font-medium">
            {{(data.errorText) || (data.text | async)}}
        </div>
    </div>
    <ng-container *ngIf="data.moreAction">
        <div fxLayout="column" fxLayoutAlign="start stretch" class="action-btns">
            <button mat-button class="action-btn" (click)="onManageRoles()">{{'ManageRolesText' | translate}}</button>
            <button mat-button (click)="onDismiss()">{{'DismissText' | translate}}</button>
        </div>
    </ng-container>
    <ng-container *ngIf="data.isDismissable">
        <button mat-icon-button (click)="onDismiss()" class="close-button" [attr.aria-label]="'CloseButtonText' | translate">
            <mat-icon class="font-medium">
                close
            </mat-icon>
        </button>
    </ng-container>
</div>