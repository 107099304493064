<app-migration-bar></app-migration-bar>
<entities-import-bar *ngIf="mode==='entitiesImport'"></entities-import-bar>
<fields-conflict-bar *ngIf="mode==='fieldsConflict'"></fields-conflict-bar>

<app-breadcrumb [breadcrumbs]="breadcrumbs"></app-breadcrumb>

<ng-container *ngIf="isLoadingData">
    <mat-spinner class="spinner" role="alert" [attr.aria-label]="'Loading' | translate" diameter="48"></mat-spinner>
</ng-container>
<ng-container *ngIf="!isFieldsConflict()">
    <mat-tab-group [selectedIndex]=viewIndex (selectedTabChange)="tabClick($event)">
        <mat-tab [label]="'EntitiesText' | translate">
            <list-entities [mode]="mode" [importSchema]="importSchema"></list-entities>
        </mat-tab>
        <mat-tab *ngIf="choicesets.length>0" [label]="'ChoicesetText' | translate">
            <list-choicesets [mode]="mode" [importSchema]="importSchema"></list-choicesets>
        </mat-tab>
    </mat-tab-group>
</ng-container>
<ng-container *ngIf="isFieldsConflict()" class="conflictFields">
    <list-fields [mode]="mode" [fields]="fields"></list-fields>
</ng-container>

<div class="bottom-buttons-container">
    <mat-divider></mat-divider>
    <div fxLayout="row" fxLayoutAlign="end" class="button-section">
        <button mat-button color="primary" class="btn close bold" (click)="onClose()">{{'CancelButtonText' |
            translate}}</button>
        <button *ngIf="!isFieldsConflict()" mat-flat-button color="primary" type="submit" class="import btn bold"
            (click)="continueImport()" [disabled]="isImportDisabled()">
            <div class="button-text">{{'Import' | translate}}</div>
        </button>
        <button *ngIf="isFieldsConflict()" mat-flat-button color="primary" type="submit" class="continueImport btn bold"
            (click)="conflictFieldsImport()">
            <div class="button-text">{{'FieldConflict.Import' | translate}}</div>
        </button>
    </div>
</div>