import { UrlUtils } from '../url.utils';
import { LoginStateStore } from '../services/login.state.service';
import * as urljoin from 'url-join';

const ClientId = 'dab206b7-0388-41c9-ab0f-208c102cf8c2';
const Scope = 'openid profile DataServiceApiUserAccess IdentityServerApi Directory offline_access';
const GrantType = 'code';
const OidcMetadataUrlPath = '.well-known/openid-configuration';

// Define custom interface for authentication configuration
export interface AuthConfig {
  authority: string;
  client_id: string;
  post_logout_redirect_uri: string
  response_type: string;
  scope: string;
  loadUserInfo: boolean;
  userStore: LoginStateStore;
  metadataUrl: string;
  redirect_uri: string;
  acr_values: string;
  automaticSilentRenew: boolean;
}

export function AuthConfigFactory(): AuthConfig {
  const authority = urljoin(UrlUtils.getCloudHost(), 'identity_');
  const metadataUrl = getMetadataUrl(authority);
  const host = UrlUtils.getOrigin();
  const context = UrlUtils.getRequestContext();
  const redirectUri = `${host}/${context.account}/${context.tenant}/${context.serviceType}/oidc-signin`;

  return {
    authority: `${authority}`,
    client_id: ClientId,
    post_logout_redirect_uri: UrlUtils.getCloudHost(),
    response_type: GrantType,
    scope: Scope,
    loadUserInfo: false,
    userStore: new LoginStateStore({ store: localStorage }),
    metadataUrl: `${metadataUrl}`,
    redirect_uri: redirectUri,
    acr_values: `tenantname:${context.account}`,
    automaticSilentRenew: true
  };
}

function getMetadataUrl(authority: string): string {
  const environment = {
    apiUrl: UrlUtils.getCloudHost() // Replace process.env.API_URL with the appropriate value from your environment
  };

  if (environment.apiUrl && (environment.apiUrl !== UrlUtils.getCloudHost())) {
    const baseUrl = urljoin(UrlUtils.getCloudHost(), UrlUtils.getBaseRouteUrl());
    return urljoin(baseUrl, `/api/v1/identity/${OidcMetadataUrlPath}`);
  } else {
    return urljoin(authority, OidcMetadataUrlPath);
  }
}
