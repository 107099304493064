import { Component, OnInit } from '@angular/core';
import { AuthService } from './../../shared/guards/auth.service';
import { TelemetryService } from 'src/app/shared/services/telemetry.service';
import { Events } from 'src/app/shared/models/events';

@Component({
  selector: 'app-login-callback',
  templateUrl: './login-callback.component.html',
  styleUrls: ['./login-callback.component.scss']
})
export class LoginCallbackComponent implements OnInit {

  constructor(
    private authService: AuthService,
    private telemetryService: TelemetryService) { }

  ngOnInit() {
    this.telemetryService.logEvent(Events.LogInSuccess);

    this.authService.handleLoginCallback();
  }

}
