<div tabindex="0" class="breadcrumb" fxLayout="row" fxLayoutAlign=" center">
    <ng-container *ngFor="let breadcrumb of breadcrumbs; index as i">
        <div fxLayout="row" fxLayoutAlign=" center">
            <ng-container *ngIf="i !== 0">
                <mat-icon class="material-icons">
                    keyboard_arrow_right
                </mat-icon>
            </ng-container>
            <h1 (click)="navigate(breadcrumb)" (keydown.enter)="navigate(breadcrumb)" tabindex="0"
                class="font-large bold" [class.title-url]="i !== breadcrumbs.length - 1">
                {{ breadcrumb.key | translate }}</h1>
        </div>
    </ng-container>
</div>                                          