<div class="file-attachment">
    <div *ngIf="!shouldShowUploadBox()" class="selected-file" fxLayout="row" fxLayoutAlign="space-between center">
        <div class="file" fxLayout="row" fxLayout fxLayoutAlign="space-between center">
            <span [matTooltip]="getFileName()">{{getFileName()}}</span>
            <mat-icon class="material-icons remove md-24 standard-icon" matTooltip="Discard and select another file" (click)="onDismissSelectedFile()">add_circle</mat-icon>
        </div>
        <div *ngIf="!mostRecentFileName" class="download" fxLayout="row" fxLayout fxLayoutAlign="end center" (click)="onDownload()">
            <mat-icon class="material-icons icon md-24 standard-icon">cloud_download</mat-icon>
            <span>{{'File.DownloadText' | translate}}</span>
        </div>
    </div>
    <div *ngIf="shouldShowUploadBox()" class="drop-zone" fxLayout="column" fxLayoutAlign="center center" (drop)="onDrop($event)" (dragover)="onDragOver($event)" tabindex="0" (keydown.enter)="file.click()">
        <label [for]="field.name" class="upload-button" fxLayout="row" fxLayoutAlign="center center">
            <mat-icon class="material-icons icon md-24 standard-icon">cloud_upload</mat-icon>
            <span class="text">{{'File.SelectFileHelpText' | translate}}</span>
        </label>
        <input #file [id]="field.name" type="file" [name]="field.name" (change)="selectFile(field.name, $event.srcElement.files)" />
        <div class="or">{{'File.OrText' | translate}}</div>
        <p>{{'File.DropFileHelpText' | translate}}...</p>
    </div>
</div>