/* eslint-disable max-len */
import { Component, Input, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Field, FieldType } from '../../../shared/models/field';
import { SortData } from '../../../shared/models/environment';
import { Constants } from '../../../shared/constants';
import { transformFieldType } from '../../../shared/utilities';
import { Entity, Trigger } from '../../../shared/models/entity';
import { TranslateService } from '@ngx-translate/core';
import { Pagination } from '../../entities/entity-details/data/list-data/list-data.component';
import { DataType } from '../template-data-model/template-data-model.component';
import { EntitiesService } from 'src/app/shared/services/entities.service';
import { Template } from 'src/app/shared/services/template.service';
import { map } from 'rxjs';

export enum EntityOperation {
  create = 0,
  update = 1,
  delete = 2,
}

@Component({
  selector: 'app-template-entity-details',
  templateUrl: './template-entity-details.component.html',
  styleUrls: ['./template-entity-details.component.scss']
})
export class TemplateEntityDetailsComponent implements OnInit {
  @Input('entity')
  public entity: Entity;

  @Input('entityType')
  public entityType: DataType;

  @Input('currentTemplate')
  public currentTemplate: Template;

  public usedData;
  public unsortedData;
  public dataSource: MatTableDataSource<Field>;
  public unsortedDataSource: MatTableDataSource<Field>;
  public displayedColumns: string[];
  public transformFieldType = transformFieldType;
  public displayTriggers: Trigger[];
  public pagination: Pagination;
  public selectedCondition: EntityOperation;
  public selectedField: Field;
  public selectedOperation: EntityOperation;
  public targetEntity: Entity;
  public targetField: Field;
  public targetValue: string;
  public baseField: Field;

  public conditions = [
    { value: EntityOperation.create, viewValue: 'Create new record' },
    { value: EntityOperation.update, viewValue: 'Update column' },
    { value: EntityOperation.delete, viewValue: 'Delete record' }
  ];

  constructor(
    private translateService: TranslateService,
    private entitiesService: EntitiesService,
  ) { }

  public ngOnInit(): void {
    if (this.entityType === DataType.Entity) {
      this.displayedColumns = ['displayName', 'name', 'isModelReserved', 'type', 'isRequired', 'isUnique', 'description'];
      this.entity.fields = this.entity.fields.map((field) => {
        return {
          ...field,
          type: this.entitiesService.convertSqlType(field.sqlType, field.isForeignKey, field.fieldDisplayType),
        };
      });
      this.usedData = this.entity.fields;
    } else {
      this.displayedColumns = ['displayName', 'name'];
      this.usedData = this.entity.data.map(item => {
        const updatedItem = {};
        for (const key in item) {
          if (item.hasOwnProperty(key)) {
            const newKey = key.charAt(0).toLowerCase() + key.slice(1);
            updatedItem[newKey] = item[key];
          }
        }
        return updatedItem;
      });
    }

    this.dataSource = new MatTableDataSource(this.getPagedData());
    this.unsortedDataSource = new MatTableDataSource(this.getPagedData());
    this.unsortedData = this.usedData.slice();
  }

  public getElementType(element: any): any {
    if (element.fieldDisplayType === Constants.File) {
      return FieldType.file;
    } else {
      return element.type;
    }
  }

  public getDisplayNameText(field: Field) {
    const key = this.currentTemplate.name + '.' + this.entity.name + '.' + field.name +  '.DisplayName';
    return this.translateService.get(key).pipe(map(value => value === key ? '': value));
  }

  public getDescriptionText(field: Field) {
    const key = this.currentTemplate.name + '.' + this.entity.name + '.' + field.name +  '.Description';
    return this.translateService.get(key).pipe(map(value => value === key ? '': value));
  }

  public sortData(sort: SortData): void {
    if (sort.direction === '') {
      this.usedData = this.unsortedData.slice();
      this.dataSource = new MatTableDataSource(this.getPagedData());
    } else {
      const isAsc = sort.direction === 'asc' ? 1 : -1;
      this.usedData.sort((a, b) => (a[sort.active] < b[sort.active] ? -1 : 1) * isAsc);
      this.dataSource = new MatTableDataSource(this.getPagedData());
    }
  }

  public onChangePagination(pagination: Pagination): void {
    this.pagination = pagination;
    this.dataSource = new MatTableDataSource(this.getPagedData());
  }

  private getPagedData(): any[] {
    if (this.pagination) {
      const start = this.pagination.pageIndex * this.pagination.pageSize;
      const end = start + this.pagination.pageSize;
      return  [...this.usedData].slice(start, end);
    } else {
      return [...this.usedData].slice(0, 10);
    }
  }

  /*
  TODO: triggers
  public onAddTrigger() {
    let description = '';
    //assemble description
    description += this.getConditionDescription(this.entity);
    description += ', ' + this.getSelectionDescription();

    const trigger: Trigger = {
      description
    };

    this.displayTriggers = [...this.displayTriggers, trigger];
    this.resetNewTrigger();
  }

  public onRemoveTrigger(trigger) {
    this.displayTriggers = this.displayTriggers.filter(trig => trig.description !== trigger.description);
  }

  private getConditionDescription(entity: Entity) {
    switch (this.selectedCondition) {
    case EntityOperation.create:
      return `When create record in entity: {${entity.displayName}}`;
    case EntityOperation.update:
      return `When update record in entity: {${entity.displayName}} on field ${this.selectedField}`;
    default:
      return `When delete record in entity: {${entity.displayName}}`;
    }
  }

  private getSelectionDescription() {
    switch (this.selectedOperation) {
    case EntityOperation.create:
      return `then create record in {${this.targetEntity.displayName}} based on field ${this.baseField.displayName}`;
    case EntityOperation.update:
      return `then update record in {${this.targetEntity.displayName}} on field ${this.targetField.displayName} with value ${this.targetValue} based on field ${this.baseField.displayName}`;
    default:
      return `then delete record in {${this.targetEntity.displayName}} based on field ${this.baseField.displayName}`;
    }
  }

  private resetNewTrigger() {
    this.selectedCondition = null;
    this.selectedField = null;
    this.selectedOperation = null;
    this.targetEntity = null;
    this.targetField = null;
    this.targetValue = null;
    this.baseField = null;
  }
  */
}
