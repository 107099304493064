<div class="dialog">
  <mat-icon class="font-large top-corner-button" [class.disabled]="isLoading" (click)="onClick('no')">
    close
  </mat-icon>
  <div fxLayout="row" fxLayoutAlign="none" class="title-section">
    <mat-icon class="large material-icons icon-warning">
      warning
    </mat-icon>
    <h1 mat-dialog-title class="title bold">{{data.title | async}}</h1>
  </div>
  <mat-dialog-content class="confirmation-text-wrapper">
    <div class="confirm-text font-medium">
      {{data.confirmationText | async}}
    </div>
    <ng-content></ng-content>
    <div *ngIf=isDoubleConfirmNeeded()>
      <div>
        <mat-form-field floatLabel="always" class="full-width">
          <mat-label> {{getDoubleConfirmNote() | async}}</mat-label>
          <input matInput
            [placeholder]="(getDoubleConfirmNote() | async)!==null? (getDoubleConfirmNote() | async): 'Placeholder.TypeEntityName' | translate"
            [(ngModel)]="doubleConfirmValue">
        </mat-form-field>
      </div>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions class="confirm-btns">
    <button mat-stroked-button color="primary" class="btn cancel bold" [autofocus] (click)="onClick('no')" [disabled]="isLoading">{{data.noText
      ? (data.noText | async) : 'ConfirmationDialog.No' |
      translate}}</button>
    <button *ngIf="!isLoading" mat-flat-button color="primary" type="submit" class="btn bold" (click)="onClick('yes')"
      [disabled]="isSubmitDisabled()">{{data.yesText ? (data.yesText | async) :
      'ConfirmationDialog.Yes' | translate}}</button>
    <div class="confirm-spinner" *ngIf="isLoading">
      <mat-spinner role="alert" [attr.aria-label]="'Loading' | translate" diameter="24"></mat-spinner>
    </div>
  </mat-dialog-actions>
</div>
