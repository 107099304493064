import { UserService } from 'src/app/shared/services/user.service';
import { Injectable, OnDestroy } from '@angular/core';
import {
  Entity, EntityDataQueryRequest, QueryFilter,
  SortOption, EntityDataQueryResponseWithExpansion, Expansion,
  QueryFilterGroup, FilterLogicalOperator, BatchDeleteResponse,
} from '../models/entity';
import { Observable, Subscription } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { Field } from '../models/field';
import { ChoiceSetMember } from '../models/choiceset';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { User } from '../models/user';
import { TelemetryService } from './telemetry.service';
import { Events } from '../models/events';

export interface FilterFieldEntry {
  field: Field;
  operator: string;
  filteredOptionFields: Field[];
}

export interface AdvancedQueryRequest {
  queryFilterFields: FilterFieldEntry[],
  filterLogicalOperator: number,
}

@Injectable({
  providedIn: 'root'
})
export class EntitiesDataService implements OnDestroy {
  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };
  private userFields = ['CreatedBy', 'UpdatedBy'];
  private subscription: Subscription = new Subscription();
  public advancedQueryRequest: any;

  constructor(
    private http: HttpClient,
    private userService: UserService,
    private telemetryService: TelemetryService) { }

  public ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  public getQueryUrl(entity: Entity): string {

    return `/api/EntityService/entity/${entity.id}/query`;
  }

  public getInsertUrl(entityId: string): string {

    return `/api/EntityService/entity/${entityId}/insert`;
  }

  public queryEntityData(
    entity: Entity, queryFields: Field[],
    filterFields: FilterFieldEntry[],
    filterLogicalOperator: FilterLogicalOperator,
    filterGroups: QueryFilterGroup[],
    start: number,
    limit: number, sortOptions: SortOption[],
    expansions: Expansion[]): Observable<EntityDataQueryResponseWithExpansion> {
    this.telemetryService.logEvent(Events.QueryEntityData);
    const queryFilters = filterFields.map(ff => new QueryFilter(ff.field.name, ff.operator, ff.field.value, ff.field.type));
    const request: EntityDataQueryRequest = {
      entityId: entity.id,
      selectedFields: queryFields && queryFields.map(field => field.name),
      filterGroup: new QueryFilterGroup(filterLogicalOperator, queryFilters, filterGroups),
      start,
      limit,
      sortOptions,
      expansions
    };

    const response = this.http.post<EntityDataQueryResponseWithExpansion>
    (`/api/EntityService/entity/${entity.id}/query_expansion`, request, this.httpOptions)
      .pipe(map(result => result = {
        totalRecordCount: result.totalRecordCount,
        jsonValue: result.jsonValue,
        value: JSON.parse(result.jsonValue),
      }));

    this.advancedQueryRequest = {
      queryFilterFields: filterFields,
      filterLogicalOperator: filterLogicalOperator === FilterLogicalOperator.and ? 0 : 1,
    } as AdvancedQueryRequest;
    return response.pipe(map(response => this.convertUserFileds(response)));
  }

  public queryChoiceSetMembers(choiceSetId: string): Observable<EntityDataQueryResponseWithExpansion> {
    return this.queryEntityData(
      { id: choiceSetId } as Entity,
      [],
      [],
      null,
      [],
      0,
      9999,
      [{ fieldName: 'DisplayName', isDescending: false }],
      []);
  }

  public deleteEntityData(entityName: string, fields: Field[]) {
    this.telemetryService.logEvent(Events.DeleteEntityData);

    const recordId = this.getRecordId(fields);
    return this.http.delete<Map<string, string>[]>
    (`/api/EntityService/entity/${entityName}/delete/${recordId}`, this.httpOptions);
  }

  public deleteEntityDataInBulk(entityName: string, ids: string[]) {
    this.telemetryService.logEvent(Events.DeleteEntityDataInBulk);

    return this.http.post<BatchDeleteResponse>
    (`/api/EntityService/entity/${entityName}/delete-batch`, ids, this.httpOptions);
  }

  public updateEntityData(entityName: string, recordId: string, fields: any): Observable<Record<string, string>> {
    this.telemetryService.logEvent(Events.UpdateEntityData);

    return this.http.post<Record<string, string>>(`/api/EntityService/entity/${entityName}/update/${recordId}`,
      fields, this.httpOptions);
  }

  public insertEntityData(entityId: string, fields: any): Observable<Record<string, string>> {
    this.telemetryService.logEvent(Events.InsertEntityData);

    const requestBody = {};
    for (const key of Object.keys(fields)) {
      if (fields[key] !== undefined) {
        requestBody[key] = fields[key];
      }
    }

    return this.http.post<Record<string, string>>(`/api/EntityService/entity/${entityId}/insert`, requestBody, this.httpOptions);
  }

  public downloadFileByPath(path: string): Observable<Blob> {
    this.telemetryService.logEvent(Events.DownloadFileAttachment);

    const prefix = '/dataservice_';
    const filepath = path.startsWith(prefix) ? path.slice(prefix.length) : 'api/Attachment/' + path;
    const options: any = { ...this.httpOptions, responseType: 'blob', observe: 'body' };
    return this.http.get<Blob>(filepath, options) as unknown as Observable<Blob>;
  }

  public uploadFile(entityName: string, fieldName: string, recordId: string, file: File, abortSignal?: any): Observable<undefined> {
    this.telemetryService.logEvent(Events.UploadFileAttachment);

    const data = new FormData();
    data.append('file', file);

    // Note: Don't use this.httpOptions for the last argument because its content type isn't appropriate for this request.
    const res = this.http.post<undefined>(`/api/Attachment/${entityName}/${recordId}/${fieldName}`, data, {});
    if (abortSignal) {
      // Todo: investigate why returning res below would throw an error.
      res.pipe(takeUntil(abortSignal));
    }

    return res;
  }

  public deleteFile(entityName: string, fieldName: string, recordId: string): Observable<undefined> {
    this.telemetryService.logEvent(Events.DeleteFileAttachment);

    return this.http.delete<undefined>(`/api/Attachment/${entityName}/${recordId}/${fieldName}`, this.httpOptions);
  }

  public getRecordId(fields: Field[]) {

    const idField = fields.find(field => field.name === 'Id');
    return idField && idField.value;
  }

  public insertChoiceSetData(entityName: string, member: ChoiceSetMember): Observable<Record<string, string>> {
    this.telemetryService.logEvent(Events.InsertChoiceSetData);

    return this.http.post<Record<string, string>>
    (`/api/EntityService/${entityName}/choiceset/insert`, member, this.httpOptions);
  }

  public updateChoiceSetData(entityName: string, recordId: string, fields: any): Observable<Record<string, string>> {
    this.telemetryService.logEvent(Events.UpdateChoiceSetData);
    return this.http.post<Record<string, string>>(`/api/EntityService/${entityName}/choiceset/${recordId}/update`,
      fields, this.httpOptions);
  }

  public deleteChoiceSetData(entityId: string, recordIdList: string[]) {
    this.telemetryService.logEvent(Events.DeleteChoiceSetData);
    return this.http.post<boolean>
    (`/api/EntityService/entity/${entityId}/choiceset/delete`, recordIdList, this.httpOptions);
  }

  private convertUserFileds(response: EntityDataQueryResponseWithExpansion) {
    const userIds = this.extractUserFieldsValue(response.value);
    if (userIds && userIds.length > 0) {
      const sub = this.userService.getUsersByIds(userIds)
        .subscribe(users => this.convertUserFieldsValue(response.value, users));
      this.subscription.add(sub);
    }
    return response;
  }

  private extractUserFieldsValue(entityData: Map<string, string>[]): string[] {
    const userIds = new Set<string>();
    if (entityData && entityData.length > 0) {
      for (const entity of entityData) {
        for (const field of this.userFields) {
          const value = entity[field];
          if (value && (typeof value === 'string' || value instanceof String)) {
            userIds.add(value as string);
          } else {
            return null;
          }
        }
      }
    }
    return Array.from(userIds);
  }

  private convertUserFieldsValue(entityData: Map<string, string>[], users: Map<string, User>) {
    if (entityData && entityData.length > 0 && users && users.size > 0) {
      for (const entity of entityData) {
        for (const field of this.userFields) {
          const value = entity[field];
          const user = users.get(value.toLowerCase());
          if (value && user) {
            entity[field] = user.name;
          }
        }
      }
    }
  }

}