import { AfterViewInit, Component, ElementRef, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LanguageChangedEvent } from '@uipath/portal-shell/dist/types/models/events/languageChangedEvent';
import { TenantChangedEvent } from '@uipath/portal-shell/dist/types/models/events/tenantChangedEvent';
import { Subscription } from 'rxjs';
import { ApiAccessDialogComponent } from './shared/components/api-access-dialog/api-access-dialog.component';
import { Constants } from './shared/constants';
import { AuthService } from './shared/guards/auth.service';
import { Events } from './shared/models/events';
import { FeatureKey, FeatureService } from './shared/services/feature.service';
import { TelemetryService } from './shared/services/telemetry.service';
import { UserService } from './shared/services/user.service';
import { UrlUtils } from './shared/url.utils';
import { getLanguage } from './shared/utilities';
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
  public title = 'UiPath Data Service';
  public accountName: string;
  public tenantName: string;
  public accessToken: string;
  public baseUrl: string;
  public firstRun = true;
  public isCdmEnabled = false;
  public isAdminConsistencyEnabled = false;

  public UpdateEntityScheme: number = Constants.UpdateEntityScheme;
  public ManagePermission: number = Constants.ManagePermission;

  private subscription: Subscription = new Subscription();

  constructor(
    private router: Router,
    public telemetryService: TelemetryService,
    public translateService: TranslateService,
    public userService: UserService,
    public dialog: MatDialog,
    private elementRef: ElementRef,
    private authService: AuthService,
    private featureService: FeatureService,
    private dateAdapter: DateAdapter<any>,
    @Inject(MAT_DATE_LOCALE) private matDateLocale: string
  ) {
    telemetryService.logEvent(Events.AppStart);
    telemetryService.startTimedEvent(Events.AppLoad);
    this.firstRun = sessionStorage.getItem(Constants.FirstRun) === 'true';

    this.baseUrl = UrlUtils.getCloudHost().substr(0, UrlUtils.getCloudHost().length - 1);

    this.accountName = UrlUtils.getRequestContext().account;
    this.tenantName = UrlUtils.getRequestContext().tenant;

    this.authService.userProfileDummy$.subscribe(result => {
      if (result) {
        this.authService.getUser().subscribe(result => {
          this.accessToken = result && result.access_token;
        });
      }
    });

  }

  public ngOnInit(): void {
    // Subscribe to the language change event from your language service
    this.subscription = this.translateService.onLangChange.subscribe((event) => {
      this.updateDateAdapterLocale(event.lang);
    });

    // this language will be used as a fallback when a translation isn't found in the current language
    this.translateService.setDefaultLang(Constants.En);

    // the lang to use, if the lang isn't available, it will use the current loader to get them
    this.translateService.use(getLanguage());

    const fsSub1 = this.featureService.isEnabled(FeatureKey.Cdm)
      .subscribe(result => {
        this.isCdmEnabled = result;
      });

    const fsSub2 = this.featureService.isEnabled(FeatureKey.AdminConsistency)
      .subscribe(result => {
        this.isAdminConsistencyEnabled = result;
      });

    this.subscription.add(fsSub1);
    this.subscription.add(fsSub2);
  }

  public onClickTemplates() {
    this.router.navigate([`${UrlUtils.getBaseRouteUrl()}/templates`]);
  }

  public onDismissFirstRunMessageBar() {
    this.firstRun = false;
    sessionStorage.setItem(Constants.FirstRun, 'false');
  }

  public ngAfterViewInit() {
    document.addEventListener(Constants.PortalShellEventLogout, this.onLogout.bind(this));
    document.addEventListener(Constants.PortalShellEventLanguageChanged, this.onLanguageChanged.bind(this));
    document.addEventListener(Constants.PortalShellEventTenantChanged, this.onTenantChanged.bind(this));
  }

  public onLogout(): void {
    this.authService.logout('OnLogout triggered');
  }

  public async onTenantChanged(evt: CustomEvent<TenantChangedEvent>): Promise<void> {
    if (evt && evt.detail && evt.detail.selectedTenantId) {
      // Workaround for CC-2896.
      // Portal-nav apparently would raise the tenant-changed event even if we're already in the context of the same tenant.
      const IsSameTenant = this.tenantName === evt.detail.selectedTenantId;

      this.telemetryService.logEvent(Events.OnTenantChange, { IsSameTenant });
      sessionStorage.setItem(Constants.FirstRun, 'false');

      if (!IsSameTenant) {
        this.tenantName = evt.detail.selectedTenantId;
        const requestContext = UrlUtils.getRequestContext();
        await this.router.navigate([`${this.accountName}/${this.tenantName}/${requestContext.serviceType}`]);
        this.reload();
      }
    }
  }

  public onLanguageChanged(event: CustomEvent<LanguageChangedEvent>) {
    const languageId = event.detail.selectedLanguageId;
    const existingLang = localStorage.getItem(Constants.Lang) || Constants.En;
    localStorage.setItem(Constants.Lang, languageId);

    if (existingLang !== languageId) {
      this.reload();
    }
  }

  public ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  public isChoiceSetVisible() {
    return this.featureService.isEnabled(FeatureKey.ChoiceSet);
  }

  public onClickChoicesets() {
    this.router.navigate([`${UrlUtils.getBaseRouteUrl()}/choicesets`]);
  }

  public onClickUserManagement() {
    this.telemetryService.logEvent(Events.PortalAdministrator);
    this.router.navigate([`${UrlUtils.getBaseRouteUrl()}/users`]);
  }

  public isApiAccessVisible() {
    return this.featureService.isEnabled(FeatureKey.OpenApi);
  }

  public onClickApiAccess() {
    this.dialog.open(ApiAccessDialogComponent);
  }

  public reload() {
    location.reload();
  }

  public direcToHome(vent: CustomEvent) {
    this.router.navigate([`${UrlUtils.getBaseRouteUrl()}/entities`]);
  }

  private updateDateAdapterLocale(newLocale: string): void {
    this.dateAdapter.setLocale(newLocale);
  }
}
