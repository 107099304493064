import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';

import { Observable } from 'rxjs';
import { UrlUtils } from '../url.utils';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HttpBaseHrefInterceptor implements HttpInterceptor {
  constructor() { }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url && req.url.indexOf('/assets/i18n') > -1) {
      if (environment.name === 'development') {
        return next.handle(req);
      }

      const url = UrlUtils.getAssetUrl() + req.url;
      return next.handle(req.clone({ url }));
    } else {
      const url = UrlUtils.buildUrl(req.url);
      return next.handle(req.clone({ url }));
    }
  }
}
