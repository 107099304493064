<div class="side-pane-container">
    <h1 mat-dialog-title class="bold font-large">{{'ViewSystemRole.ViewRole' | translate}}</h1>
    <div mat-dialog-content class="dialog-content">
        <mat-form-field floatLabel="always" class="full-width">
            <mat-label>{{'ViewSystemRole.RoleName' | translate}}</mat-label>
            <input matInput type="text" id="name" name="name" disabled [value]="data.role.displayName" />
        </mat-form-field>
        <div class="label ad-permission">{{'ViewSystemRole.AdministrativePermissions' | translate}}</div>
        <div fxLayout="row" class="manage-permissions">
            <div class="permission-mark">
                <mat-icon *ngIf="data.role.name===Admin" class="right">done</mat-icon>
                <mat-icon *ngIf="data.role.name!==Admin" class="wrong">clear</mat-icon>
            </div>
            <div class="permission-text font-medium">
                {{'ViewSystemRole.ManagePermissions' | translate}}
            </div>
            <mat-icon class="info-icon" [matTooltip]="'ViewSystemRole.ManagePermissionTooltip' | translate">
                info_outline
            </mat-icon>
        </div>

        <div fxLayout="row" class="manage-permissions">
            <div class="permission-mark">
                <mat-icon *ngIf="data.role.name!==Admin" class="right">done</mat-icon>
                <mat-icon *ngIf="data.role.name===Admin" class="wrong">clear</mat-icon>
            </div>
            <div class="permission-text font-medium">
                {{'ViewSystemRole.ViewSchema' | translate}}
            </div>
            <mat-icon class="info-icon" [matTooltip]="'ViewSystemRole.ViewSchemaTooltip' | translate">
                info_outline
            </mat-icon>
        </div>

        <div fxLayout="row" class="manage-permissions">
            <div class="permission-mark">
                <mat-icon *ngIf="data.role.name===Designer" class="right">done</mat-icon>
                <mat-icon *ngIf="data.role.name!==Designer" class="wrong">clear</mat-icon>
            </div>
            <div class="permission-text font-medium">
                {{'ViewSystemRole.CustomizeSchema' | translate}}
            </div>
            <mat-icon class="info-icon" [matTooltip]="'ViewSystemRole.CustomizeSchemaTooltip' | translate">
                info_outline
            </mat-icon>
        </div>

        <div class="label data-permission">
            {{'ViewSystemRole.DataAccessPermissions' | translate}}
        </div>
        <div fxLayout="row" class="manage-permissions" *ngIf="data.role.name===Designer || data.role.name===Admin">
            <div class="permission-mark">
                <mat-icon class="wrong">clear</mat-icon>
            </div>
            <div class="permission-text font-medium">
                {{'ViewSystemRole.NoAccess' | translate}}
            </div>
        </div>
        <div fxLayout="column" *ngIf="data.role.name===DataWriter">
            <div fxLayout="row" class="manage-permissions">
                <div class="permission-mark">
                    <mat-icon class="right">done</mat-icon>
                </div>
                <div class="permission-text font-medium">
                    {{'ViewSystemRole.EntityPermission' | translate}}
                </div>
            </div>
        </div>
        <div fxLayout="column" *ngIf="data.role.name===DataReader">
            <div fxLayout="row" class="manage-permissions">
                <div class="permission-mark">
                    <mat-icon class="right">done</mat-icon>
                </div>
                <div class="permission-text font-medium">
                    {{'ViewSystemRole.EntityReadPermission' | translate}}
                </div>
            </div>
        </div>
    </div>

    <div mat-dialog-actions class="close-button" fxLayout="row" fxLayoutAlign="end strech">
        <button mat-stroked-button color="primary" class="btn bold" (click)="onDismiss()">{{'CloseText' |
            translate}}</button>
    </div>
</div>