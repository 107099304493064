// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  name: 'alpha',
  region: 'westus',
  cloudHost: 'https://alpha.uipath.com/',
  loginProxy: null,
  assetUrl: 'https://shared-alp-svc-ne-01-b.alp.kubefabric.uipath.systems/',
  apiUrl: null,
  instrumentationKey: '0f6b9d39-ecb1-47f1-b582-ffb5c17b2766',
  appInsightConnectionString: null,
  launchDarklyClientId: '5f29e7cc959a950845490fbd',
  production: false
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
