import { Environment } from './environment';
import { EntityGridEntry } from 'src/app/pages/users/upsert-custom-role-dialog/upsert-custom-role-dialog.component';

export enum Role {
    admin = 'Admin',
    designer = 'Designer',
    dataReader = 'DataReader',
    dataWriter = 'DataWriter',
    custom = 'Custom',
}

export enum RoleType {
    System = 'System built-in role.',
    UserDefined = 'User defined role.'
}

export class UserLoginProfile {
  constructor(
        public id: string,
        public name: string,
        public email: string,
        public accountId: string,
        public tenantId: string,
        public isOrgAdmin: boolean,
        public roles?: RoleResponse[],
        public environment?: Environment,
        public profileIcon?: string,
        public adminPermissions?: number[],
        public dataPermissions?: {},
        public entityDataPermissions?: {},
  ) { }
}

export class UserGroup {
  constructor(
        public id: string,
        public name: string,
        public email: string,
        public type: number,
        public objectType: string,
        public resolved: boolean = true,
        public role?: RoleResponse[],
        public displayRoles?: string
  ) { }
}

export class User {
  constructor(
        public externalId: string,
        public name: string,
        public email: string
  ) { }
}

export class DirectoryEntity {
  constructor(
        public externalId: string, // 0 = User, 1 = Group
        public type: number,
        public resolved: boolean
  ) { }
}
export class AssignUserOrGroupRoleRequest {
  constructor(
        public directoryEntities: DirectoryEntity[], // 0 = User, 1 = Group
        public roles: string[],
  ) { }
}

export class RevokeRolesRequest {
  constructor(
        public externalIds: string[],
  ) { }
}

export class AssignRoleRequest {
  constructor(
        public roleId: string,
  ) { }
}

export class UserOrGroupRoleResponse {
  constructor(
        public externalId: string,
        public name: string,
        public email: string,
        public type: number,
        public roles: RoleResponse[],
        public objectType: string,
  ) { }
}

export class RoleResponse {
  constructor(
        public id: string,
        public name: string,
        public displayName?: string,
  ) { }
}

export class GetRolePermissionResponse {
  constructor(
        public id: string,
        public name: string,
        public type: RoleType,
        public adminPermissions: number[],
        public dataPermissions: GetEntityDataPermissionResponse[],
        public displayName?: string,
  ) { }
}

export class GetEntityDataPermissionResponse {
  recordLevelRbacPermissions: any;
  constructor(
        public entityId: string,
        public entityName: string,
        public entityDisplayName: string,
        public permissions: number[],
        public fieldPermissions?: GetFieldDataPermissionResponse[],
        public hasRbacEnabledFields?: boolean,
        public isRecordLevelPermissionEnabled?: boolean,
        public isRbacEnabled?: boolean
  ) { }
}

export class GetFieldDataPermissionResponse {
  name(name: any) {
    throw new Error('Method not implemented.');
  }
  displayName(displayName: any) {
    throw new Error('Method not implemented.');
  }
  constructor(
        public fieldId: string,
        public fieldName: string,
        public fieldDisplayName: string,
        public permissions: number[],
  ) { }
}

export class CustomizeRoleRequest {
  constructor(
        public name: string,
        public adminPermissions: number[],
        public dataPermissions: EntityDataPermission[],
  ) { }
}

export class EntityDataPermission {
  constructor(
        public entityId: string,
        public permissions: number[],
        public fieldPermissions: FieldPermission[],
        public recordLevelRbacPermissions?: { [key: string]: number } // Make this property optional
  ) { }
}

export class FieldPermission {
  constructor(
        public fieldId: string,
        public permissions: number[],
  ) { }
}

export class GetUsersResponse {
  constructor(
        public totalCount: number,
        public results: UserOrGroupRoleResponse[],
  ) {
  }
}

export class GetRoleResponse {
  constructor(
        public id: string,
        public name: string,
        public description: string,
        public permissions: number[],
        public type: RoleType,
        public displayName?: string,
        public directoryEntityCount?: number,
        public entityPermissions?: EntityGridEntry[]
  ) {
  }
}
