import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { UrlUtils } from 'src/app/shared/url.utils'; // Adjust the import path as needed

@Component({
  selector: 'warning-snackbar',
  templateUrl: 'enable-rbac.component.html',
  styleUrls: ['enable-rbac.component.scss']
})
export class EnableRbacSnackbarComponent {
  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: any,
    private snackBarRef: MatSnackBarRef<EnableRbacSnackbarComponent>,
    private router: Router
  ) {}

  onActionClick() {
    this.snackBarRef.dismiss();
    const url = `${UrlUtils.getBaseRouteUrl()}/users`;
    this.router.navigate([url]);
  }
}
