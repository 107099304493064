import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs/internal/Subscription';
import { Entity } from 'src/app/shared/models/entity';
import { UrlUtils } from 'src/app/shared/url.utils';
import { Template, TemplateData, TemplateService } from 'src/app/shared/services/template.service';
import { Breadcrumb } from 'src/app/shared/components/breadcrumb/breadcrumb.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackBarComponent } from 'src/app/shared/components/snack-bar/snack-bar.component';
import { DataType } from '../template-data-model/template-data-model.component';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent, ConfirmationType } from 'src/app/shared/components/confirmation-dialog/confirmation-dialog.component';
import { getDialogLauncherFocusHelper } from 'src/app/shared/utilities';
import { Location } from '@angular/common';

export enum CurrentView {
  Detail = "Detail",
  Schema = "Schema",
}

export const ENTITY = 'entity';
export const ENTITY_PAGINATION = 'entityPagination';
export const CHOICESET_PAGINATION = 'choicesetPagination';
export const TEMPLATE_ENTITY_TYPE = 'templateEntityType';

@Component({
  selector: 'app-template-details',
  templateUrl: './template-details.component.html',
  styleUrls: ['./template-details.component.scss']
})
export class TemplateDetailsComponent implements OnInit, OnDestroy {

  public templateData: TemplateData;
  public currentTemplate: Template;

  public selectedEntity: Entity;
  public selectedEntityType: DataType;
  public currentView = CurrentView.Detail;
  public isLoading: boolean;
  public shouldShowDeleteTemplates: boolean;
  public breadcrumbs: Breadcrumb[];
  public focusHelper = getDialogLauncherFocusHelper();
  public tabIndex = 0;
  public entityPagination = { pagination: undefined, sort: undefined };
  public choicesetPagination = { pagination: undefined, sort: undefined };
  private subscription: Subscription = new Subscription();

  constructor(
    public templateService: TemplateService,
    public dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar,
    private translateService: TranslateService,
    private location: Location,
  ) { }

  ngOnInit(): void {
    this.isLoading = true;
    this.breadcrumbs = [{ key: "TemplateList.Templates", url: `${UrlUtils.getBaseRouteUrl()}/templates`}];
    const sub = this.route.paramMap.subscribe(params => {
      this.templateService.getDataModel(Number(params.get('id'))).subscribe({
        next: (templateData) => {
          this.templateData = {
            ...templateData,
            entities: templateData.entities.map(entity => ({
              ...entity,
              version: templateData.schemaVersion
            })),
            choicesets: templateData.choicesets.map(choiceset => ({
              ...choiceset,
              version: templateData.schemaVersion
            }))
          };

          this.templateService.getTemplates().subscribe({
            next: (templates) => {
              this.templateService.getCurrentAppliedEntitiesFromTemplate(Number(params.get('id'))).subscribe({
                next: (appliedEntities) => {
                  if (appliedEntities.length > 0) {
                    this.shouldShowDeleteTemplates = true;
                  }
                  this.currentTemplate = templates.find(t => t.id === Number(params.get('id')));
                  this.breadcrumbs.push({ key: this.currentTemplate.name });
                  if (params.get(ENTITY)) {
                    let foundEntity = this.templateData.entities.find(e => e.name === params.get(ENTITY));
                    let foundEntityType = DataType.Entity;

                    if (!foundEntity) {
                      foundEntity = this.templateData.choicesets.find(e => e.name === params.get(ENTITY));
                      this.tabIndex = 1;
                      foundEntityType = DataType.Choiceset;
                    }

                    if (foundEntity) {
                      this.selectedEntityType = foundEntityType;
                      this.currentView = CurrentView.Schema;
                      this.breadcrumbs[1].customNavigate = () => this.setViewToDetail();
                      this.breadcrumbs.push({ key: foundEntity.name });
                      this.selectedEntity = foundEntity;
                      localStorage.setItem(ENTITY_PAGINATION, JSON.stringify({ pagination: undefined, sort: undefined }));
                      localStorage.setItem(CHOICESET_PAGINATION, JSON.stringify({ pagination: undefined, sort: undefined }));
                      localStorage.removeItem(TEMPLATE_ENTITY_TYPE);
                    }
                  }
                  this.isLoading = false;
                },
                error: (error) => {
                  this.throwInitError(error);
                }
              });
            },
            error: (error) => {
              this.throwInitError(error);
            }
          });
        },
        error: (error) => {
          this.throwInitError(error);
        }
      });
    });

    this.subscription.add(sub);
  }

  private throwInitError(error: any): void {
    this.isLoading = false;
    this.snackBar.openFromComponent(SnackBarComponent, { duration: 5000, verticalPosition: 'top', data: error });
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public onClickEntity = (row: Entity): void => {
    this.selectedEntityType = DataType.Entity;
    this.onClickRow(row);
  };

  public onClickChoiceset = (row: Entity): void => {
    this.selectedEntityType = DataType.Choiceset;
    this.onClickRow(row);
  };

  public changeTab(event: any) {
    this.tabIndex = event.index;
  }

  private onClickRow(row: Entity): void {
    this.location.replaceState(location.pathname + '/' + row.name);
    localStorage.setItem(TEMPLATE_ENTITY_TYPE, JSON.stringify(this.tabIndex));
    this.currentView = CurrentView.Schema;
    this.selectedEntity = row;
    this.breadcrumbs[1].customNavigate = () => this.setViewToDetail();
    this.breadcrumbs.push({ key: this.selectedEntity.name });
  }

  public onClickBackButton(): void {
    if (this.currentView === CurrentView.Schema) {
      this.currentView = CurrentView.Detail;
      this.breadcrumbs.pop();
    } else {
      this.router.navigate([`${UrlUtils.getBaseRouteUrl()}/templates`]);
    }
  }

  public setViewToDetail(): void {
    this.currentView = CurrentView.Detail;
    this.location.replaceState(location.pathname.substring(0, location.pathname.lastIndexOf('/')));

    const savedTabIndex = JSON.parse(localStorage.getItem(TEMPLATE_ENTITY_TYPE)) as number;

    if (savedTabIndex === 0) {
      this.entityPagination = JSON.parse(localStorage.getItem(ENTITY_PAGINATION));
    } else {
      this.choicesetPagination = JSON.parse(localStorage.getItem(CHOICESET_PAGINATION));
    }

    this.tabIndex = savedTabIndex ?? 0;
    this.breadcrumbs.pop();
  }

  public onApplyTemplate(): void {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: 'auto',
      data: {
        title: this.translateService.get('TemplateDetails.ApplyTemplateAlert', { templateName: this.currentTemplate.name }),
        yesText: this.translateService.get('TemplateDetails.ApplyTemplateButton'),
        noText: this.translateService.get('CancelText'),
        confirmationText: this.translateService.get('TemplateDetails.ApplyTemplateDescription'),
        doubleConfirmNeeded: false,
        asyncFunc: () => this.templateService.applyTemplate(this.templateData),
      }
    });

    const sub = dialogRef.afterClosed().subscribe({
      next: (confirmation) => {
        this.focusHelper.restoreLauncherFocus();

        if (confirmation.result === ConfirmationType.yes) {
          this.router.navigate([`${UrlUtils.getBaseRouteUrl()}/entities`], { queryParams: { templateId: this.currentTemplate.id }} );
        }
      },
      error: (error) => {
        this.snackBar.openFromComponent(SnackBarComponent, { duration: 5000, verticalPosition: 'top', data: error });
      }
    });
    this.subscription.add(sub);
  }

  public onDeleteTemplate(): void {
    const sub = this.route.paramMap.subscribe(params => {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        width: 'auto',
        data: {
          title: this.translateService.get('ListEntities.DeleteTemplateTitle', { templateName: this.currentTemplate.name }),
          yesText: this.translateService.get('DeleteText'),
          noText: this.translateService.get('CancelText'),
          confirmationText: this.translateService.get('TemplateDetails.DeleteTemplateDescription'),
          doubleConfirmNeeded: true,
          doubleConfirmText: this.currentTemplate.name,
          asyncFunc: () => this.templateService.deleteTemplate(Number(params.get('id'))),
        }
      });
      dialogRef.afterClosed().subscribe({
        next: (confirmation) => {
          this.focusHelper.restoreLauncherFocus();

          if (confirmation.result === ConfirmationType.yes) {
            this.router.navigate([`${UrlUtils.getBaseRouteUrl()}/entities`]);
          }
        },
        error: (error) => {
          this.snackBar.openFromComponent(SnackBarComponent, { duration: 5000, verticalPosition: 'top', data: error });
        }
      });
    });
    this.subscription.add(sub);
  }
}
