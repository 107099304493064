import { Component, Inject } from '@angular/core';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { UrlUtils } from '../../url.utils';

export enum SnackBarMode {
  success = 'success',
  error = 'error',
  info = 'info'
}

export interface SnackBarData {
  text?: Observable<string>;
  errorText?: string;
  mode?: SnackBarMode;
  moreAction?: boolean;
  isDismissable?: boolean;
}

@Component({
  selector: 'app-snack-bar',
  templateUrl: './snack-bar.component.html',
  styleUrls: ['./snack-bar.component.scss']
})
export class SnackBarComponent {

  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: SnackBarData,
    private snackRef: MatSnackBarRef<SnackBarComponent>,
    private router: Router,
    public translateService: TranslateService,
  ) { }

  public isSuccessMode(): boolean {
    return this.data.mode === SnackBarMode.success;
  }

  public onManageRoles(): void {
    this.router.navigate([`${UrlUtils.getBaseRouteUrl()}/users`], { queryParams: { index: 'role' } });
  }

  public onDismiss(): void {
    this.snackRef.dismiss();
  }
}
