import { Component, Input, OnInit, Output, EventEmitter, AfterViewInit, OnDestroy } from '@angular/core';
import { EntitiesDataService } from 'src/app/shared/services/entities-data.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackBarComponent, SnackBarMode } from 'src/app/shared/components/snack-bar/snack-bar.component';
import { downloadBlob } from 'src/app/shared/utilities';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-file-cell',
  templateUrl: './file-cell.component.html',
  styleUrls: ['./file-cell.component.scss']
})
export class FileCellComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input('disabled')
  public disabled = false;

  @Input('fileInfo')
  public fileInfo: Record<string, string>;

  @Input('fieldName')
  public fieldName: string;

  @Input('recordId')
  public recordId: string;

  @Input('entityName')
  public entityName: string;

  @Input('refreshDataFn')
  public refreshDataFn: () => void;

  @Output()
  public clickColumnEvent: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  public cancelEvent: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  public uploadEvent: EventEmitter<any> = new EventEmitter<any>();

  private downloadFn = downloadBlob;

  private subscription: Subscription = new Subscription();

  constructor(
    private entityDataService: EntitiesDataService,
    private snackBar: MatSnackBar,
    private translateService: TranslateService,
  ) { }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngOnInit() { }

  ngAfterViewInit() {
    document.getElementById(this.recordId) && document.getElementById(this.recordId).focus();
  }

  public getFileName(): string {
    const element = document.createElement('textarea');
    element.innerHTML = this.fileInfo['Name'];
    return element.value;
  }

  public onBlur(event: FocusEvent): void {
    const target = event.relatedTarget as Element;
    if (!target || !target.className.includes('mat-menu-item')) {
      this.cancelEvent.emit();
    }
  }

  public onDownload(): void {
    this.clickColumnEvent.emit();
    this.entityDataService.downloadFileByPath(this.fileInfo.Path).subscribe(
      (blob) => {
        this.downloadFn(blob);
      },
      (error) => {
        this.snackBar.openFromComponent(SnackBarComponent, { duration: 5000, verticalPosition: 'top', data: error });
      }
    );
  }

  public onUpload(): void {
    this.clickColumnEvent.emit();
    this.uploadEvent.emit();
  }

  public onDelete(): void {
    this.clickColumnEvent.emit();
    const sub = this.entityDataService.deleteFile(this.entityName, this.fieldName, this.recordId).subscribe(
      () => {
        this.refreshDataFn();
        this.snackBar.openFromComponent(
          SnackBarComponent,
          {
            duration: 5000,
            verticalPosition: 'top',
            data: { mode: SnackBarMode.success, text: this.translateService.get('FileCell.DeleteText') }
          }
        );
      },
      (error) => {
        this.snackBar.openFromComponent(SnackBarComponent, { duration: 5000, verticalPosition: 'top', data: error });
      }
    );
    this.subscription.add(sub);
  }

}
