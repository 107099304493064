import { Component } from '@angular/core';

@Component({
  selector: 'fields-conflict-bar',
  templateUrl: './fields-conflict-bar.component.html',
  styleUrls: ['./fields-conflict-bar.component.scss']
})
export class FieldsConflictBarComponent {
  public isVisible = true;

  constructor() { }

  public onDismissConflictBar() {
    this.isVisible = false;
  }
}
