<ng-container *ngIf="!(entity && entity.fields)">
  <div class="no-field">
    {{'ListData.NoFields' | translate}}
  </div>
</ng-container>
<ng-container *ngIf="showNoPermission">
  <app-no-permission></app-no-permission>
</ng-container>
<div *ngIf="entity && entity.fields && !showNoPermission" fxLayout="column" fxLayoutAlign="none" class="table-width">
  <div class="list-tool-bar" fxLayout="row" fxLayoutAlign=" center" *ngIf="showListToolBar()">
    <mat-form-field floatLabel="never" class="apollo-textfield-small search-bar"
      *ngIf="!(selection.selected && selection.selected.length>0)">
      <mat-label>{{'SearchText' | translate}}</mat-label>
      <mat-icon matSuffix>search</mat-icon>
      <input matInput autocomplete="false" [placeholder]="'SearchText' | translate" [(ngModel)]="searchValue"
        name="searchInput" (ngModelChange)="searchValueUpdate.next($event)" />
    </mat-form-field>
    <ng-container *ngIf="isQueryBuilderEnabled && !(selection.selected && selection.selected.length > 0)">
      <span [attr.aria-label]="'ListData.SearchIn' | translate" class="search-in"> {{'ListData.SearchIn' | translate}}
      </span>
      <mat-form-field floatLabel="never" class="apollo-textfield-small search-column "
        [ngClass]="{'no-column-error': shouldShowNoColumnError()}">
        <mat-select [(ngModel)]="selectedAcceptedSearchColumns" #acceptedColumnsSelected multiple panelWidth="null">
          <mat-select-trigger>
            <span *ngIf="areAllAcceptedColumnsSelected">{{'ListData.AllTextAndNumber' | translate}} </span>
            <span *ngIf="!areAllAcceptedColumnsSelected">{{acceptedColumnsSelected.value}} </span>
          </mat-select-trigger>
          <div class="select-all-columns">
            <mat-checkbox [(ngModel)]="areAllAcceptedColumnsSelected"
              (change)="toggleAllColumn()">{{'ListData.AllTextAndNumber' | translate }}</mat-checkbox>
          </div>
          <mat-option *ngFor="let acceptedSearchColumn of acceptedSearchColumns" [value]="acceptedSearchColumn"
            (click)="toggleColumn()">
            {{acceptedSearchColumn}}
          </mat-option>
        </mat-select>

        <mat-icon class="no-column-error-icon" matTooltip="{{'ListData.SelectAColumn' | translate}}"
          *ngIf="shouldShowNoColumnError()">error</mat-icon>
      </mat-form-field>
    </ng-container>

    <div *ngIf="isQueryBuilderEnabled && !(selection.selected && selection.selected.length > 0)" class="advanced-search"
      [matTooltip]="'AdvancedSearch.Title' | translate">
      <button mat-stroked-button class="search-border" [attr.aria-label]="'AdvancedSearch.Title' | translate"
        (click)="openAdvancedSearch()" color="primary">
        {{'AdvancedSearch.Title' | translate}}
      </button>
    </div>

    <div *ngIf="selection.selected && selection.selected.length>0" class="select-summary font-medium bold">
      {{selection.selected.length}} {{'ListData.Selected' | translate}}
    </div>
    <span class="spacer"></span>
    <div
      [matTooltip]="(checkPermissionWithSelection([UpdateEntityData], entity.id) | async) === false? ('NoPermissionToolTip' | translate) : ''">
      <button color="primary" *ngIf="!isItemSelected() && shouldDisableEdit()" mat-button
        [attr.aria-label]="'ListData.Edit' | translate"
        [class.disabled]="(checkPermissionWithSelection([UpdateEntityData], entity.id) | async) === false"
        class="data-menu-button bold" (click)="onClickEdit('selected')">
        <mat-icon class="material-icons md-24">edit</mat-icon>
        {{'ListData.Edit' | translate}}
      </button>
    </div>
    <div
      [matTooltip]="(checkPermissionWithSelection([DeleteEntityData], entity.id) | async) === false? ('NoPermissionToolTip' | translate) : ''">
      <button color="primary" *ngIf="!isItemSelected()" mat-button [attr.aria-label]="'ListData.Delete' | translate"
        class="data-menu-button bold refresh"
        [class.disabled]="(checkPermissionWithSelection([DeleteEntityData], entity.id) | async) === false"
        (click)="onDeleteData()">
        <mat-icon class="material-icons md-24">delete</mat-icon>
        {{'ListData.Delete' | translate}}
      </button>
    </div>
    <div>
      <button color="primary" *ngIf="isItemSelected()" mat-button [attr.aria-label]="'RefreshText' | translate"
        class="bold refresh" (click)="onClickRefresh()">
        <mat-icon class="material-icons md-24 standard-icon">refresh</mat-icon>
        <span>{{'RefreshText' | translate}}</span>
      </button>
    </div>
  </div>
  <div fxLayout="row" fxLayoutAlign="space-between" class="list-tool-bar themed-list-header"
    *ngIf="!isLoading && savedQueryRecords && isQueryBuilderEnabled">
    <div class="basic-search">
      <button color="primary" mat-button (click)="openBasicSearchWarning()">
        {{'ListData.BasicSearch' | translate}}
      </button>
    </div>
    <div *ngIf="selection.selected && selection.selected.length>0" class="select-summary font-medium bold">
      {{selection.selected.length}} {{'ListData.Selected' | translate}}
    </div>
    <span class="spacer"></span>
    <div
      [matTooltip]="(checkPermissionWithSelection([UpdateEntityData], entity.id) | async) === false? ('NoPermissionToolTip' | translate) : ''">
      <button color="primary" *ngIf="!isItemSelected() && shouldDisableEdit()" mat-button
        [attr.aria-label]="'ListData.Edit' | translate"
        [class.disabled]="(checkPermissionWithSelection([UpdateEntityData], entity.id) | async) === false"
        class="advanced-search-menu-buttom bold" (click)="onClickEdit('selected')">
        <mat-icon class="material-icons md-24">edit</mat-icon>
        {{'ListData.Edit' | translate}}
      </button>
    </div>
    <div
      [matTooltip]="(checkPermissionWithSelection([DeleteEntityData], entity.id) | async) === false? ('NoPermissionToolTip' | translate) : ''">
      <button color="primary" *ngIf="!isItemSelected()" mat-button [attr.aria-label]="'ListData.Delete' | translate"
        class="advanced-search-menu-buttom bold refresh"
        [class.disabled]="(checkPermissionWithSelection([DeleteEntityData], entity.id) | async) === false"
        (click)="onDeleteData()">
        <mat-icon class="material-icons md-24">delete</mat-icon>
        {{'ListData.Delete' | translate}}
      </button>
    </div>
    <div>
      <button color="primary" *ngIf="isItemSelected()" mat-button [attr.aria-label]="'RefreshText' | translate"
        class="advanced-search-refresh bold refresh" (click)="onClickRefresh()">
        <mat-icon class="material-icons md-24 standard-icon">refresh</mat-icon>
        {{'RefreshText' | translate}}
      </button>
    </div>
  </div>
  <div *ngIf="shouldDisplayMustUseNumbersForNumberTypes()" class="result-bar">
    <span class="bold">{{'ListData.MustUseNumbersForNumberTypes' | translate}}</span>
  </div>
  <div *ngIf="shouldDisplayResultsFor()" class="result-bar">
    <span class="bold">{{length}} {{'ListData.ResultsFor' | translate}}</span> {{searchValueDebounced}}
  </div>
  <div fxLayout="row" fxLayoutAlign="space-between" *ngIf="!isLoading && isQueryBuilderEnabled && savedQueryRecords"
    class="result-bar">
    <div fxLayout="row" fxLayoutAlign="start center">
      <div class="advanced-search-filter bold" [matTooltip]=getAdvancedSearchFilter(savedQueryRecords)>
        {{length}} {{'ListData.ResultsFor' | translate}} {{getAdvancedSearchFilter(savedQueryRecords)}}
      </div>
      <div>
        <button mat-icon-button class="edit-query" (click)="openAdvancedSearch(savedQueryRecords, savedOperator)">
          <mat-icon [attr.aria-label]="'EditText' | translate" [matTooltip]="'EditText' | translate">
            edit
          </mat-icon>
        </button>
      </div>
      <div class="advanced-search-item">
        <button color="primary" mat-button class="clear-search" (click)="openBasicSearchWarning()">
          {{'ListData.ClearSearch' | translate }}
        </button>
      </div>
    </div>
    <div>
      <button color="primary" mat-button (click)="copyAdvancedSearhFilterLinkToQuery()">
        {{'ListData.CopyLinkToQuery' | translate}}
      </button>
    </div>
  </div>
  <div *ngIf="!(data && data.length>0) && !isLoading" class="no-data" fxLayout="column"
    fxLayoutAlign="space-around center">
    <div *ngIf="isSystemUserEntity(entity)" class="no-data-text">
      {{'ListData.NoUserOrGroupText' | translate}}
    </div>
    <div *ngIf="!isSystemUserEntity(entity) && isSystemEntity(entity)" class="no-data-text">
      {{'ListData.NoSystemEntityDataText' | translate}}
    </div>
    <ng-container *ngIf="!isSystemEntity(entity)">
      <div class="no-data-text">
        {{'ListData.HelpText' | translate}}
      </div>
      <div [matTooltip]="getAddDataToolTip() | async">
        <button class="add-data-button" mat-button [attr.aria-label]="'ListData.AddNewDataButtonText' | translate"
          (click)="onClickEdit(null)"
          [class.disabled]="(checkPermissionWithSelection([CreateEntityData], entity.id) | async) === false || entity.fields.length === 5">
          {{'ListData.AddNewDataButtonText' | translate}}
          <mat-icon class="material-icons md-24 standard-icon">add_to_photos</mat-icon>
        </button>
      </div>
    </ng-container>
  </div>
  <ng-container *ngIf="isLoading">
    <mat-spinner class="spinner list-data-spinner" role="alert" [attr.aria-label]="'Loading' | translate" diameter="48"></mat-spinner>
  </ng-container>
  <ng-container>
    <div *ngIf='!isLoading && data && data.length>0' class="table-container"
      [class.search]="savedQueryRecords || shouldDisplayResultsFor()">
      <table mat-table [dataSource]="data" class="table-body" matSort (matSortChange)="sortData($event)">
        <caption></caption>
        <ng-container *ngFor="let column of columns">
          <ng-container *ngIf="column==='select'" [matColumnDef]="column" sticky>
            <th scope="cell" mat-header-cell *matHeaderCellDef class="th-first-cell checkbox-column">
              <mat-checkbox *ngIf="!isSystemEntity(entity)" (change)="$event ? masterToggle() : null"
                [checked]="selection.hasValue() && isAllSelected()"
                [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()">
              </mat-checkbox>
            </th>
            <th mat-cell *matCellDef="let row" class="th-first-cell checkbox-column">
              <mat-checkbox *ngIf="!isSystemEntity(entity)" (click)="$event.stopPropagation()"
                (change)="$event ? selection.toggle(row) : null" [checked]="selection.isSelected(row)"
                [aria-label]="checkboxLabel(row)">
              </mat-checkbox>
            </th>
          </ng-container>
          <ng-container *ngIf="column==='Id'" [matColumnDef]="column" sticky>
            <th scope="cell" mat-header-cell *matHeaderCellDef mat-sort-header
              [ngClass]="{'table-id-header-sticky': !isSystemEntity(entity), 'system-entity': isSystemEntity(entity), 'themed-list-header table-header-text font-medium bold': true}"
              mwlResizable mwlResizeHandle [enableGhostResize]="false" (resizeEnd)="onResizeEnd($event, column)"
              [resizeEdges]="{bottom: false, right: true, top: false, left: true}">
              {{convertNameToDisplayName(column)}}
              <span role="status" [attr.aria-label]="ariaHelper.getOrderKey(column) | translate"></span>
            </th>
            <th mat-cell tabindex="0" *matCellDef="let element"
              [ngClass]="{'table-id-header-sticky': !isSystemEntity(entity), 'system-entity': isSystemEntity(entity), 'table-id-column': true, 'th-first-cell': true}"
              style="font-weight:400;" [id]="element['Id']+'_'+column" (keydown.enter)="onClickColumn(element, column)"
              (click)="onClickColumn(element, column)" [matTooltip]="getEditTextTooltip(column) | async"
              matTooltipPosition="left">
              <div class="column-content-wrapper">
                <div class="tooltip-wrapper">
                  <div *ngIf="!isEditableField(column)"
                    [matTooltip]="getShowingValueWithToolTip(element, column) | async">
                    {{getShowingValue(element, column)}}
                  </div>
                </div>
              </div>
            </th>
          </ng-container>
          <ng-container *ngIf="column!=='select' && column!=='Id'" [matColumnDef]="column">
            <th scope="cell" mat-header-cell *matHeaderCellDef mat-sort-header
              class="table-header themed-list-header table-header-text font-medium bold" mwlResizable mwlResizeHandle
              [enableGhostResize]="false" (resizeEnd)="onResizeEnd($event, column)"
              [resizeEdges]="{bottom: false, right: true, top: false, left: true}">
              <div class="table-header-overflow">
                {{convertNameToDisplayName(column)}}
              </div>
              <span role="status" [attr.aria-label]="ariaHelper.getOrderKey(column) | translate"></span>
            </th>

            <td mat-cell tabindex="0" *matCellDef="let element" class="table-column" [id]="element['Id']+'_'+column"
              (keydown.enter)="onClickColumn(element, column)" (keydown.delete)="onClearValue(column,$event, element)"
              (click)="onClickColumn(element, column)"
              [attr.aria-label]="getShowingValue(element, column) + ' ' + (getEditTextLabel(column) | async)"
              [matTooltip]="getEditTextTooltip(column) | async" matTooltipPosition="left">
              <ng-container *ngIf="!shouldShow(element, column) && showInputType(column) !== fileAttachmentInputType">
                <div *ngIf="isEditableField(column)" class="column-block column-block-field"
                  [class.non-clickable]="(checkFieldEditPermission(column, element) | async) === false" fxLayout="row"
                  fxLayoutAlign="space-between stretch">
                  <div class="column-content-wrapper">
                    <div class="tooltip-wrapper">
                      <div class="column-value" [matTooltip]="getShowingValueWithToolTip(element, column) | async"
                        matTooltipPosition="below" [matTooltipDisabled]="showInputType(column) === relationInputType">
                        {{getShowingValue(element, column)}}
                      </div>
                    </div>
                  </div>
                  <div mat-icon-button class="edit-button" *ngIf="(checkFieldEditPermission(column, element) | async)">
                    <mat-icon class="material-icons" [attr.aria-label]="'EditText' | translate"
                      [matTooltip]="'EditText' | translate">
                      edit
                    </mat-icon>
                  </div>
                  <div mat-icon-button class="delete-button"
                    *ngIf="!hasNoValue(element, column) && (checkFieldEditPermission(column, element) | async) && showInputType(column) === choicesetType"
                    [autofocus] (click)="onClearValue(column, $event, element)">
                    <mat-icon class="material-icons" [attr.aria-label]="'ClearValueText' | translate"
                      [matTooltip]="'ClearValueText' | translate">
                      clear
                    </mat-icon>
                  </div>
                  <div class="edit-button-place">
                  </div>
                </div>
                <div class="column-content-wrapper">
                  <div class="tooltip-wrapper">
                    <div *ngIf="!isEditableField(column)"
                      [matTooltip]="getShowingValueWithToolTip(element, column) | async">
                      {{getShowingValue(element, column)}}
                    </div>
                  </div>
                </div>
              </ng-container>
              <ng-container *ngIf="showInputType(column) === fileAttachmentInputType">
                <div class="edit-content" [class.non-clickable]="(checkFieldEditPermission(column, element) | async) === false"
                [matTooltip]="(checkFieldEditPermission(column, element) | async) === false? ('NoPermissionToolTip' | translate) : ''">
                  <app-file-cell [id]="element['Id']" [fileInfo]="element[column]" [recordId]="element['Id']"
                    [disabled]="(checkFieldEditPermission(column, element) | async) === false" [entityName]="entity.name"
                    [fieldName]="column" [refreshDataFn]="assembleData" (cancelEvent)="onClickCancel()"
                    (clickColumnEvent)="onClickColumn(element, column)"
                    (uploadEvent)="onOpenUpsertFileDialog(getFieldByName(column), element['Id'])">
                  </app-file-cell>
                </div>
              </ng-container>
              <ng-container *ngIf="shouldShow(element, column) && showInputType(column) !== fileAttachmentInputType">
                <div *ngIf="!shouldShowColumnLoading(element, column)" class="edit-column" fxLayout="row"
                  fxLayoutAlign="space-between stretch">
                  <!-- show different type of input box based on actual data type -->
                  <ng-container [ngSwitch]="showInputType(column)">

                    <ng-container *ngSwitchCase="'DATETIMEOFFSET'">
                      <div class="edit-content-datetime">
                        <input matInput [(ngModel)]="editValue" type="datetime-local" [autofocus]
                          (blur)="onClickSave(element, column)" (keydown.enter)="onClickSave(element, column)"
                          (keydown.esc)="onClickCancel()" (keydown.control.z)="onClickCancel()">
                      </div>
                    </ng-container>

                    <ng-container *ngSwitchCase="'DATE'">
                      <div class="edit-content-date" #dateEditor>
                        <input placeholder="mm/dd/yy" id="date-input" matInput [matDatepicker]="picker"
                          [(ngModel)]="editValue" [autofocus]
                          (dateChange)="onClickSave(element, column); onBlur($event, dateEditor)"
                          (keydown.enter)="onClickSave(element, column)" (keydown.esc)="onClickCancel()"
                          (keydown.control.z)="onClickCancel()" (blur)="onBlur($event, dateEditor)"
                          class="edit-date-input">
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker (closed)="onClickSave(element, column)">
                        </mat-datepicker>
                      </div>
                    </ng-container>

                    <ng-container *ngSwitchCase="'BIT'">
                      <div class="edit-content" #bitEditor>
                        <mat-select [(value)]="editValue" [autofocus] (selectionChange)="onClickSave(element, column)"
                          (keydown.esc)="onClickCancel()" (keydown.control.z)="onClickCancel()"
                          (blur)="onBlur($event, bitEditor)" class="edit-yes-no">
                          <mat-option *ngIf="!getFieldByName(column).isRequired" [value]="null">
                            None
                          </mat-option>
                          <mat-option [value]="'yes'">
                            Yes
                          </mat-option>
                          <mat-option [value]="'no'">
                            No
                          </mat-option>
                        </mat-select>
                      </div>
                    </ng-container>

                    <ng-container *ngSwitchCase=relationInputType>
                      <div class="edit-content">
                        <app-record-flyout [entity]=getFieldByName(column).referenceEntity
                          [originField]=getFieldByName(column) [field]=getFieldByName(column).referenceField
                          [value]="element[column]" [isEditMode]=true
                          (selectedEvent)="onClickSaveRelation(element, column, $event)"
                          (blurEvent)="onClickCancel()"></app-record-flyout>
                      </div>
                    </ng-container>

                    <ng-container *ngSwitchCase="ownerType">
                      <div class="edit-content">
                        <app-record-flyout [entity]="getUserEntity()" [queryUserMode]=true
                          [originField]="getUserEntityNameField()" [field]="getUserEntityNameField()"
                          [value]="element[column]" [isEditMode]=true
                          (selectedEvent)="onClickSaveRelation(element, column, $event)"
                          (blurEvent)="onClickCancel()"></app-record-flyout>
                      </div>
                    </ng-container>

                    <ng-container *ngSwitchCase=choicesetType>
                      <div class="edit-content">
                        <app-choiceset-options [choiceSetId]="getFieldByName(column).choiceSetId"
                          [fieldDisplayType]="getFieldByName(column).fieldDisplayType" [value]="element[column]"
                          [choicesetMembers]="choiceSetMemberMap[getFieldByName(column).choiceSetId]"
                          (selectedEvent)="onClickSaveChoiceSetMember(element, column, $event)"
                          (blurEvent)="onClickCancel()"></app-choiceset-options>
                      </div>
                    </ng-container>

                    <ng-container *ngSwitchCase="'FLOAT'">
                      <div class="edit-content">
                        <mat-form-field>
                          <input type="text" matInput (blur)="onClickSave(element, column)" [autofocus]
                            (keydown.enter)="onClickSave(element, column)" (keydown.esc)="onClickCancel()"
                            (keydown.control.z)="onClickCancel()" (keyup)="validateEditValue('number', column)"
                            [(ngModel)]="editValue">
                        </mat-form-field>
                      </div>
                    </ng-container>

                    <ng-container *ngSwitchCase="'DECIMAL'">
                      <div class="edit-content">
                        <mat-form-field>
                          <input type="text" matInput (blur)="onClickSave(element, column)" [autofocus]
                            (keydown.enter)="onClickSave(element, column)" (keydown.esc)="onClickCancel()"
                            (keydown.control.z)="onClickCancel()" (keyup)="validateEditValue('number', column)"
                            [(ngModel)]="editValue">
                        </mat-form-field>
                      </div>
                    </ng-container>
                    <ng-container *ngSwitchDefault>
                      <div class="edit-content">
                        <mat-form-field>
                          <input type="text" matInput (blur)="onClickSave(element, column)" [autofocus]
                            (keydown.enter)="onClickSave(element, column)" (keydown.esc)="onClickCancel()"
                            (keydown.control.z)="onClickCancel()" (keyup)="validateEditValue('text', column)"
                            [(ngModel)]="editValue">
                        </mat-form-field>
                      </div>
                    </ng-container>
                  </ng-container>
                </div>
                <div *ngIf="shouldShowColumnLoading(element, column)" class="edit-column" fxLayout="row"
                  fxLayoutAlign="start center">
                  <mat-spinner role="alert" [attr.aria-label]="'Loading' | translate" diameter="24"></mat-spinner>
                </div>
                <div class="error-box" *ngIf="this.shouldDisableEditSave" fxLayout="row" fxLayoutAlign="start stretch">
                  <mat-icon class="material-icons md-18 error-icon">error</mat-icon>
                  <div class="error-text font-small">
                    {{columnErrorText | async}}
                  </div>
                </div>
              </ng-container>
            </td>
          </ng-container>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columns;sticky: true" class="table-header-block themed-list-header"></tr>
        <tr mat-row *matRowDef="let row; columns: columns;"
          [class]="selection.isSelected(row) ? 'selected-data-row' : 'data-row'"></tr>
      </table>
    </div>
    <mat-paginator *ngIf="data.length>0" [length]="length" [pageSize]="20" [pageSizeOptions]="[10, 20, 50]"
      (page)="onChangePagination($event)">
    </mat-paginator>
  </ng-container>
</div>
