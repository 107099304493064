import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ListEntitiesComponent } from './pages/entities/list-entities/list-entities.component';
import { EntityDetailsComponent } from './pages/entities/entity-details/entity-details.component';
import { ListChoicesetsComponent } from './pages/choicesets/list-choicesets/list-choicesets.component';
import { AuthGuard } from './shared/guards/auth.guard';
import { LoginCallbackComponent } from './pages/login/login-callback.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { UserManagementComponent } from './pages/users/user-management/user-management.component';
import { EntitiesImportComponent } from './pages/entities/import/entities-import/entities-import.component';
import { LogoutComponent } from './pages/logout/logout.component';
import { LoginComponent } from './pages/login/login.component';
import { TemplateDetailsComponent } from './pages/template/template-details/template-details.component';
import { TemplateListComponent } from './pages/template/template-list/template-list.component';
import { CreateRolePageComponent } from './pages/users/create-roles/create-role-page.component';

const routes: Routes = [
  { path: ':account/:tenant/dataservice_/entities', component: ListEntitiesComponent, canActivate: [AuthGuard] },
  {
    path: ':account/:tenant/dataservice_/entities/:id',
    component: EntityDetailsComponent, data: { entity: null, entities: null, choicesets: null }, canActivate: [AuthGuard]
  },
  { path: ':account/:tenant/dataservice_/choicesets', component: ListChoicesetsComponent, canActivate: [AuthGuard] },
  { path: ':account/:tenant/dataservice_/templates', component: TemplateListComponent, canActivate: [AuthGuard] },
  {
    path: ':account/:tenant/dataservice_/templates/:id', component: TemplateDetailsComponent,
    data: { template: null },
    canActivate: [AuthGuard]
  },
  {
    path: ':account/:tenant/dataservice_/templates/:id/:entity', component: TemplateDetailsComponent,
    data: { template: null },
    canActivate: [AuthGuard]
  },
  { path: ':account/:tenant/dataservice_/users', component: UserManagementComponent, canActivate: [AuthGuard] },
  { path: ':account/:tenant/dataservice_/create-role', component: CreateRolePageComponent },
  {
    path: ':account/:tenant/dataservice_/entitiesimport',
    component: EntitiesImportComponent, data: { mode: null, importSchema: null, file: null }, canActivate: [AuthGuard]
  },
  { path: ':account/:tenant/dataservice_/oidc-signin', component: LoginCallbackComponent },
  { path: ':account/:tenant/dataservice_/login', component: LoginComponent },
  { path: ':account/:tenant/dataservice_/logout', component: LogoutComponent },
  {
    path: ':account/:tenant/dataservice_',
    redirectTo: ':account/:tenant/dataservice_/entities',
    pathMatch: 'full'
  },
  { path: '**', component: PageNotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  providers: [AuthGuard],
  exports: [RouterModule]
})
export class AppRoutingModule { }
