<div class="dialog">
    <mat-icon class="font-large top-corner-button" (click)="onClose()">
        close
    </mat-icon>
    <div fxLayout="row" fxLayoutAlign="none" class="title-section">
        <mat-icon class="large material-icons icon-warning">
            error
        </mat-icon>
        <h1 mat-dialog-title class="title bold">{{data.title | async}}</h1>
    </div>

    <div mat-dialog-content class="font-medium">
        <div class="description">
            {{data.description | async}}
        </div>
        <div class="header" fxLayout="row" fxLayoutAlign="start center">
            <div class="label entity-name">{{data.dependencyKeyText | async}}</div>
            <div class="label">{{data.dependencyValueText | async}}</div>
        </div>
        <div *ngFor="let dep of dependencyList" class="dependency" fxLayout="row" fxLayoutAlign="start center">
            <div class="entity-name">{{dep.key}}</div>
            <div>{{dep.value}}</div>
        </div>
    </div>

    <div fxLayout="row" fxLayoutAlign="end strech" class="button-section">
        <button mat-stroked-button color="primary" class="btn cancel bold" [autofocus]
            (click)="onClose()">{{'CloseButtonText' | translate}}</button>
    </div>
</div>