import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of, Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import {
  ConfirmationDialogComponent,
  ConfirmationType
} from 'src/app/shared/components/confirmation-dialog/confirmation-dialog.component';
import { ErrorDialogComponent } from 'src/app/shared/components/error-dialog/error-dialog.component';
import { SnackBarComponent, SnackBarMode } from 'src/app/shared/components/snack-bar/snack-bar.component';
import { Constants } from 'src/app/shared/constants';
import { Entity } from 'src/app/shared/models/entity';
import { SortData } from 'src/app/shared/models/environment';
import { EnvironmentSetting } from 'src/app/shared/models/environmentSetting';
import { Field, FieldType } from 'src/app/shared/models/field';
import { EntitiesService } from 'src/app/shared/services/entities.service';
import { FeatureKey, FeatureService } from 'src/app/shared/services/feature.service';
import { ImportService } from 'src/app/shared/services/import.service';
import { UserService } from 'src/app/shared/services/user.service';
import { UrlUtils } from 'src/app/shared/url.utils';
import { getDialogLauncherFocusHelper, getTableSortAriaHelper, transformFieldType } from 'src/app/shared/utilities';
import { Pagination } from '../data/list-data/list-data.component';
import { EditFieldDialogData, EditFieldDialogMode, UpsertFieldDialogComponent } from '../upsert-field-dialog/upsert-field-dialog.component';
import { EnableRbacDialogComponent, EnableRbacDialogMode, EnableRbacDialogOutput } from './enable-rbac-dialog/enable-rbac-dialog.component';
import { FieldPair } from 'src/app/shared/services/import.service';
import { ViewMode } from '../../list-entities/list-entities.component';

@Component({
  selector: 'list-fields',
  templateUrl: './list-fields.component.html',
  styleUrls: ['./list-fields.component.scss']
})

export class ListFieldsComponent implements OnInit, OnChanges, OnDestroy {
  @Input('entity')
  public entity: Entity;

  @Input('entities')
  public entities: Entity[];

  @Input('choicesets')
  public choicesets: Entity[];

  @Input('mode')
  public mode = ViewMode.listFields;

  @Input('fields')
  public fields: FieldPair[];

  @Output()
  public refreshEntity: EventEmitter<Entity> = new EventEmitter<Entity>();

  public dataSource: MatTableDataSource<Field | FieldPair>;
  public unsortedDataSource: MatTableDataSource<Field | FieldPair>;
  public fieldsSnapshot: Field[] | FieldPair[];
  public isCdmEnabled: boolean;

  public displayedColumns: string[];
  public ariaHelper = getTableSortAriaHelper();
  public searchValue: string;
  public isLoadingData = true;
  public envSettings: EnvironmentSetting = null;

  public length: number;
  public pagination: Pagination;

  public UpdateEntityScheme: number = Constants.UpdateEntityScheme;

  public isRbacEnabled: boolean;
  public focusHelper = getDialogLauncherFocusHelper();

  public transformFieldType = transformFieldType;

  private deleteConfirmText: Observable<string>;
  private builtinFieldText: Observable<string>;
  private subscription: Subscription = new Subscription();
  private curEntityId = '';

  constructor(
    private route: ActivatedRoute,
    private entitiesService: EntitiesService,
    public userService: UserService,
    public dialog: MatDialog,
    private translateService: TranslateService,
    private snackBar: MatSnackBar,
    private featureService: FeatureService,
    private importService: ImportService
  ) { }

  public ngOnInit() {
    const fsSub = this.featureService.isEnabled(FeatureKey.Cdm)
      .subscribe(result => {
        this.isCdmEnabled = result;
        if (!this.isFieldsConflict()) {
          if (this.isCdmEnabled && this.entity.isModelReserved) {
            this.displayedColumns =
              ['displayName', 'name', 'isModelReserved', 'type', 'isRequired', 'isUnique', 'description', 'updateTime', 'edit'];
          } else {
            this.displayedColumns = ['displayName', 'name', 'type', 'isRequired', 'isUnique', 'description', 'updateTime', 'edit'];
          }
          this.isLoadingData = true;
          const sub = this.route.paramMap.subscribe(params => {
            this.curEntityId = params.get('id');
            this.isLoadingData = false;
            this.initFields();
          });
          this.subscription.add(sub);
        } else {
          this.displayedColumns = ['entityName', 'fieldName', 'name', 'type', 'isRequired', 'isUnique', 'description'];
          this.initFields();
          this.isLoadingData = false;
        }
      });
    this.subscription.add(fsSub);

    this.deleteConfirmText = this.translateService.get('ListFields.DeleteConfirm');
    this.builtinFieldText = this.translateService.get('ListField.SystemBuiltInField');

    this.featureService.isEnabled(FeatureKey.EntityColumnPermission).subscribe(result => {
      this.isRbacEnabled = result;
    });
    this.unsortedDataSource = new MatTableDataSource(this.dataSource.data.slice());
  }

  public ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  public isFieldsConflict(): boolean {
    return this.mode === ViewMode.fieldsConflict;
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (!this.isFieldsConflict()) {
      this.loadEntityField();
    }
  }

  public onClickRefresh() {
    this.isLoadingData = true;
    this.loadEntityDetails();
  }

  public getDeleteTooltip(field: Field): Observable<string> {
    return this.userService.checkPermission([this.UpdateEntityScheme]).pipe(switchMap(result => {
      if (!result) {
        return of('');
      }
      if (this.isSystemField(field)) {
        return this.translateService.get('ListFields.DeleteTooltipSystem');
      }
      if (this.isReservedField(field)) {
        return this.translateService.get('ListFields.DeleteTooltipReserved');
      }
      return this.translateService.get('ListFields.DeleteTooltip');
    }));
  }

  public getMoreTooltip(field: Field): Observable<string> {
    if (this.isSystemField(field)) {
      return this.translateService.get('ListFields.DeleteTooltipSystem');
    }
    if (this.isReservedField(field)) {
      return this.translateService.get('ListFields.DeleteTooltipReserved');
    }
    return this.translateService.get('MoreTooltip');
  }

  public getEditToolTip(field: Field): Observable<string> {
    return this.userService.checkPermission([this.UpdateEntityScheme]).pipe(switchMap(result => {
      if (!result) {
        return of('');
      }
      if (this.isSystemField(field)) {
        return this.translateService.get('ListFields.DeleteTooltipSystem');
      }
      return this.translateService.get('ListFields.EditTooltip');
    }));
  }

  public getNoPermissionToolTip(field: Field): Observable<string> {
    if (this.isSystemField(field)) {
      return this.translateService.get('ListFields.DeleteTooltipSystem');
    }
    return this.userService.checkPermission([this.UpdateEntityScheme]).pipe(switchMap(result => {
      if (!result) {
        return this.translateService.get('NoPermissionToolTip');
      } else {
        return of('');
      }
    }));
  }

  public onSearch() {
    const fields = [...this.fieldsSnapshot];

    this.dataSource = new MatTableDataSource(fields);

    if (!this.searchValue) {
      return;
    } else {
      const filteredFields = fields.filter(item => {
        const values = this.extractSearchableValues(item);
        return JSON.stringify(values).toLocaleLowerCase().indexOf(this.searchValue.toLocaleLowerCase()) > -1;
      });
      this.dataSource = new MatTableDataSource(filteredFields);
    }
  }

  public sortData(sort: SortData) {
    this.ariaHelper.setSortOrder(sort.active, sort.direction);
    if (sort.direction === '') {
      this.dataSource = new MatTableDataSource(this.unsortedDataSource.data.slice());
    } else {
      const isAsc = sort.direction === 'asc' ? 1 : -1;
      this.dataSource.data.sort((a, b) => (a[sort.active] < b[sort.active] ? -1 : 1) * isAsc);
      this.dataSource = new MatTableDataSource(this.dataSource.data);
    }
  }

  public isSystemField(field: Field): boolean {
    return field?.isSystemField;
  }

  public isReservedField(field: Field): boolean {
    return field?.isModelReserved;
  }

  public getElementType(element: any) {
    if (element.fieldDisplayType === Constants.File) {
      return FieldType.file;
    } else {
      return element.type;
    }
  }
  public onClickField(field: Field) {
    if (field?.isSystemField) {
      return;
    }

    if (field) {
      field.entityId = this.entity.id;
    }

    const data: EditFieldDialogData = {
      mode: !field ? EditFieldDialogMode.create : EditFieldDialogMode.update,
      field: field || new Field('idabc', '', this.entity.id, '', FieldType.text, '', false),
      entityName: this.entity.name,
      entities: this.getFilteredEntites([...this.entities], this.entity.name),
      choicesets: this.choicesets?.filter(choiceset => !choiceset.categoryId),
      recordCount: this.entities[this.entities.findIndex(entity => entity.name === this.entity.name)].recordCount,
    };

    const dialogRef = this.dialog.open(UpsertFieldDialogComponent,
      {
        width: 'auto',
        height: '100%',
        position: { top: '0', right: '0' },
        disableClose: true,
        panelClass: 'custom-sidepane',
        data,
      }
    );

    dialogRef.afterClosed().subscribe((result) => {
      if (!result) {
        this.refreshEntity.emit();
        this.dataSource = new MatTableDataSource(this.entity.fields);
        this.unsortedDataSource = new MatTableDataSource(this.dataSource.data.slice());
      }
    });
  }

  public onDeleteField(field: Field) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: 'auto',
      data: {
        title: this.translateService.get('ListFields.DeleteFieldTitle', { value: field.displayName }),
        yesText: this.translateService.get('DeleteText'),
        noText: this.translateService.get('CancelText'),
        confirmationText: this.deleteConfirmText,
        doubleConfirmNeeded: true,
        doubleConfirmText: field.displayName,
        asyncFunc: () => this.entitiesService.deleteField(this.entity, field.id)
      }
    });

    const sub = dialogRef.afterClosed().subscribe(confirmation => {
      this.focusHelper.restoreLauncherFocus();

      if (confirmation.result === ConfirmationType.yes) {
        this.refreshEntity.emit();
        this.dataSource = new MatTableDataSource(this.entity.fields);
        this.unsortedDataSource = new MatTableDataSource(this.dataSource.data.slice());
      }
    });
    this.subscription.add(sub);
  }

  public onEnableRoleBasedFieldAccess(field: Field, enabled: boolean) {
    const dialogRef = this.dialog.open(EnableRbacDialogComponent, {
      width: 'auto',
      height: '100%',
      position: { top: '0', right: '0' },
      disableClose: true,
      panelClass: 'custom-sidepane',
      data: {
        entityId: this.entity.id,
        displayName: field.displayName,
        mode: enabled ? EnableRbacDialogMode.enable : EnableRbacDialogMode.disable,
        enabledContent: this.translateService.get('EnableRbacDialog.Content'),
        disabledContent: this.translateService.get('EnableRbacDialog.DisableContent'),
        onOpenLearnMore: this.onOpenLearnMore,
        onSubmitAction: () => {
          field.isRbacEnabled = enabled;
          return this.entitiesService.updateFieldMetadata(this.entity.id, field);
        }
      }
    });

    const sub = dialogRef.afterClosed().subscribe((result: any) => {
      this.focusHelper.restoreLauncherFocus();

      if (result.data.mode === EnableRbacDialogMode.enable && result.result === EnableRbacDialogOutput.succeed) {
        // pop succeed snackbar
        this.snackBar.openFromComponent(SnackBarComponent,
          {
            duration: 5000,
            verticalPosition: 'top',
            panelClass: 'success-snack-container',
            data: {
              mode: SnackBarMode.success,
              moreAction: true,
              text: this.translateService.get('EnableSuccessText')
            },
          });
        this.refreshEntity.emit();
        this.dataSource = new MatTableDataSource(this.entity.fields);
        this.unsortedDataSource = new MatTableDataSource(this.dataSource.data.slice());
      } else if (result.data.mode === EnableRbacDialogMode.disable && result.result === EnableRbacDialogOutput.succeed) {
        this.snackBar.openFromComponent(SnackBarComponent,
          {
            duration: 5000,
            verticalPosition: 'top',
            data: {
              mode: SnackBarMode.success,
              moreAction: false,
              isDismissable: true,
              text: this.translateService.get('DisableSuccessText')
            },
          });
        this.refreshEntity.emit();
        this.dataSource = new MatTableDataSource(this.entity.fields);
        this.unsortedDataSource = new MatTableDataSource(this.dataSource.data.slice());
      } else if (result.result === EnableRbacDialogOutput.failed) {
        // pop failed snackbar
        this.snackBar.openFromComponent(SnackBarComponent,
          { duration: 5000, verticalPosition: 'top', data: { text: this.translateService.get('EnableFailedText') }, });
      }
    });
    this.subscription.add(sub);
  }

  public onChangePagination(pagination: Pagination): void {
    this.pagination = pagination;
  }

  public getDescriptionText(field: Field): Observable<string> {
    return field.isSystemField ? this.builtinFieldText : of(field.description);
  }

  public onOpenLearnMore() {
    const url = UrlUtils.getUserManagementDataAccessDocLink();
    window.open(url, '_blank');
  }

  private extractSearchableValues(data: any): string[] {
    const result = [];
    result.push(data['description']);
    result.push(data['displayName']);
    result.push(data['name']);
    result.push(data['type']);
    result.push(data['isRequired']);
    result.push(data['isUnique']);
    if (this.isCdmEnabled) {
      result.push(data['isModelReserved']);
    }
    return result;
  }

  private getFilteredEntites(entities: Entity[], filteredEntityName: string): Entity[] {
    entities.forEach((entity, index) => {
      if (entity.name === filteredEntityName) {
        entities.splice(index, 1);
      }
    });
    return entities;
  }

  private initFields() {
    if (!this.isFieldsConflict()) {
      if (!this.entity) {
        this.loadEntityDetails();
      } else {
        this.loadEntityField();
      }
    } else {
      if (!(this.fields)) {
        this.fields = [];
      }
      this.dataSource = this.fields ? new MatTableDataSource(this.fields) : new MatTableDataSource([]);
      this.unsortedDataSource = new MatTableDataSource(this.dataSource.data.slice());
      this.fieldsSnapshot = this.fields || [];
    }
  }

  private loadEntityDetails() {
    const getEntitiesSub = this.entitiesService.getEntities().subscribe(entities => {
      this.entity = entities.find(entity => entity.id === this.curEntityId);
      this.isLoadingData = false;
      this.loadEntityField();
    }, (error) => {
      this.isLoadingData = false;
      this.dialog.open(ErrorDialogComponent, { data: error });
    });
    this.subscription.add(getEntitiesSub);
  }

  private loadEntityField(): void {

    if (!(this.entity && this.entity.fields)) {
      this.entity.fields = [];
    }

    this.sortFields();
    this.dataSource = this.entity.fields ? new MatTableDataSource(this.entity.fields) : new MatTableDataSource([]);
    this.unsortedDataSource = new MatTableDataSource(this.dataSource.data.slice());
    this.fieldsSnapshot = this.entity.fields || [];
  }

  private sortFields(): void {
    for (const field of [...this.entity.fields]) {
      if (field.name === 'Id') {
        this.entity.fields.unshift(this.entity.fields.splice(this.entity.fields.indexOf(field), 1)[0]);
      } else if (field.isSystemField) {
        this.entity.fields.push(this.entity.fields.splice(this.entity.fields.indexOf(field), 1)[0]);
      }
    }
  }
}
