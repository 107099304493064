import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Entity } from '../models/entity';
import { Field } from '../models/field';
import { HttpClient } from '@angular/common/http';
import { takeUntil } from 'rxjs/operators';
import { ImportSelections } from 'src/app/pages/entities/list-entities/list-entities.component';
import { Events } from '../models/events';
import { TelemetryService } from './telemetry.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackBarComponent } from 'src/app/shared/components/snack-bar/snack-bar.component';
import { TranslateService } from '@ngx-translate/core';

export interface FieldPair {
    entityName: string;
    field: Field;
}
export interface ImportSchema {
    entities?: Entity[];
    choicesets?: Entity[];
    conflictEntities?: Entity[];
    conflictChoicesets?: Entity[];
    nonConflictEntities?: Entity[];
    nonConflictChoicesets?: Entity[];
}

@Injectable({
  providedIn: 'root'
})

export class ImportService {
  conflictEntities: Entity[] = [];
  conflictChoicesets: Entity[] = [];
  nonConflictEntities: Entity[] = [];
  nonConflictChoicesets: Entity[] = [];
  conflictFields: FieldPair[];
  file: File;
  parsedFile: { entities?: Entity[], choicesets?: Entity[] } = { entities: [], choicesets: [] };

  private options = new BehaviorSubject<ImportSelections>({ entities: {}, choicesets: {} });
  currentSelection = this.options.asObservable();

  private entitiesToImport = new BehaviorSubject<number>(0);
  currentEntitiesToImport = this.entitiesToImport.asObservable();

  private choicesetsToImport = new BehaviorSubject<number>(0);
  currentChoicesetsToImport = this.choicesetsToImport.asObservable();

  private totalEntitiesToImport = new BehaviorSubject<number>(0);
  currentTotalEntitiesToImport = this.totalEntitiesToImport.asObservable();

  constructor(
        private http: HttpClient,
        private telemetryService: TelemetryService,
        private snackBar: MatSnackBar,
        private translateService: TranslateService
  ) { }

  public updateImportFile(selections: ImportSelections): void {
    this.options.next(selections);
  }

  public getImportSchema(): ImportSchema {
    const list: ImportSchema = {
      conflictEntities: this.conflictEntities,
      conflictChoicesets: this.conflictChoicesets,
      nonConflictEntities: this.nonConflictEntities,
      nonConflictChoicesets: this.nonConflictChoicesets
    };
    return list;
  }

  public importUpdatedFile(file): Observable<undefined> {
    return this.http.post<undefined>(`/api/Entity/import`, file, {});
  }

  public shiftNumEntity(num: number) {
    this.entitiesToImport.next(this.entitiesToImport.value + num);
    this.totalEntitiesToImport.next(this.totalEntitiesToImport.value + num);
  }

  public shiftNumChoiceset(num: number) {
    this.choicesetsToImport.next(this.choicesetsToImport.value + num);
    this.totalEntitiesToImport.next(this.totalEntitiesToImport.value + num);
  }

  public resetValues() {
    this.choicesetsToImport.next(0);
    this.entitiesToImport.next(0);
    this.totalEntitiesToImport.next(0);
    this.options.next({ entities: {}, choicesets: {} });
  }

  public async entityComparison(file: File, entities: Entity[], choicesets: Entity[]): Promise<string> {
    return new Promise((resolve) => {
      const entityNames: { [key: string]: Entity } = {};
      const nonConflictEntitiesList: Entity[] = [];
      const nonConflictChoicesetsList: Entity[] = [];
      const conflictEntitiesList: Entity[] = [];
      const conflictChoiesetsList: Entity[] = [];

      for (const entity of entities) {
        entityNames[entity.name] = entity;
      }

      const choicesetNames = {};
      for (const choiceset of choicesets) {
        choicesetNames[choiceset.name] = choiceset;
      }

      const fr = new FileReader();

      // todo: security checks for file validation
      fr.addEventListener('load', e => {
        try {
          const fileRead = JSON.parse(e.target.result as string);
          this.parsedFile = fileRead;
        } catch (err) {
          this.telemetryService.logEvent(Events.ImportFileParseError);
          this.snackBar.openFromComponent(SnackBarComponent,
            {
              duration: 5000, verticalPosition: 'top', data: {
                text: this.translateService.get('ListConflict.ParseFileError')
              }
            }
          );
        }

        const parseEntities = this.parsedFile.entities === undefined ? [] : this.parsedFile.entities;
        for (let i = 0; i < parseEntities.length; i++) {
          if (entityNames[parseEntities[i].name] !== undefined) {
            conflictEntitiesList.push(entityNames[parseEntities[i].name]);
          } else {
            nonConflictEntitiesList.push(parseEntities[i]);
          }
        }

        const parseChoicesets = this.parsedFile.choicesets === undefined ? [] : this.parsedFile.choicesets;
        for (let i = 0; i < parseChoicesets.length; i++) {
          if (choicesetNames[parseChoicesets[i].name] !== undefined) {
            conflictChoiesetsList.push(choicesetNames[parseChoicesets[i].name]);
          } else {
            nonConflictChoicesetsList.push(parseChoicesets[i]);
          }
        }
        this.conflictEntities = conflictEntitiesList;
        this.conflictChoicesets = conflictChoiesetsList;
        this.nonConflictEntities = nonConflictEntitiesList;
        this.nonConflictChoicesets = nonConflictChoicesetsList;

        resolve('Resolved in parseFile');
      });
      fr.readAsText(file);
    });
  }

  public importSchema(file: File, abortSignal?: any): Observable<undefined> {
    const res = this.http.post<undefined>(`/api/Entity/import`, file, {});
    if (abortSignal) {
      res.pipe(takeUntil(abortSignal));
    }

    return res;
  }

  public fieldComparison(conflicts: {}, entities: Entity[]) {
    const entityNames = [];
    const fieldsList = {};
    const list = [];

    for (const entity of conflicts['entities']) {
      const fields = entity['fields'];
      if (fields.length === 0) {
        continue;
      }

      const currEntity = entities.filter(item => item.name === entity['name']);
      if (currEntity[0] === undefined) {
        continue;
      }
      const currFields = currEntity[0].fields;

      for (let i = 0; i < currFields.length; i++) {
        const field = currFields[i];
        fieldsList[field['name']] = field;
      }

      for (let i = 0; i < fields.length; i++) {
        const name = fields[i]['name'];
        if (fieldsList[name] !== undefined) {
          if (fieldsList[name]['fieldDisplayType'] !== fields[i]['fieldDisplayType'] ||
                        fieldsList[name]['sqlType']['name'] !== fields[i]['sqlType']['name']) {
            const conflict = { entityName: entity['name'], field: fieldsList[name] };
            list.push(conflict);
            if (!entityNames.includes(entity['name'])) {
              entityNames.push(entity['name']);
            }
          }
        }
      }
    }
    this.conflictFields = list;

    return { fieldsList: list, entityNames };
  }

}
