<div fxLayout="row" fxLayoutAlign="start strech">
    <h1 mat-dialog-title>{{'UpsertFile.Title' | translate}}</h1>
</div>

<div mat-dialog-content class="dialog-content">
    <form [style.fontSize.px]="14">
        <ng-container>
            <div fxLayout="column" fxLayoutAlign="space-around stretch">
                <label class="label">{{data.field.displayName}}</label>

                <div class="err-msg" *ngIf="showErrBox">
                    <mat-icon class="material-icons md-16 error-icon">error</mat-icon>
                    <div class="error-text">{{ 'UpsertFile.SizeInvalidateError' | translate }}</div>
                </div>
                <app-file-attachment [field]="data.field" [onFileSelected]="onFileSelected"></app-file-attachment>
                <div (click)="onCancel()" *ngIf="isLoading" class="cancel-btn">
                    x&nbsp;&nbsp;&nbsp;&nbsp;{{'CancelButtonText' | translate}}</div>
            </div>
        </ng-container>

    </form>
    <div fxLayout="row" fxLayoutAlign="end strech" class="bottom-btn">
        <button mat-stroked-button color="primary" (click)="onDismiss()" class="btn bold cancel">{{'CloseButtonText' |
            translate}}</button>
        <button *ngIf="!isLoading" mat-flat-button color="primary" type="submit" [disabled]="isSubmitDisabled()"
            class="btn bold" (click)="onSubmit()">{{'SaveButtonText' | translate}}</button>
        <div class="btn" *ngIf="isLoading">
            <mat-spinner role="alert" [attr.aria-label]="'Loading' | translate" diameter="24" class="spinner"></mat-spinner>
        </div>
    </div>
</div>