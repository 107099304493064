<div class="dialog">

    <div fxLayout="row" fxLayoutAlign="none" class="title-section">
        <h1 mat-dialog-title class="title bold">{{'ApiAccessDialog.Title' | translate}}</h1>
    </div>
    <mat-icon class="font-large top-corner-button" (click)="onClose()">
      close
    </mat-icon>

    <mat-dialog-actions class="button-section">
        <button mat-stroked-button color="primary" class="btn copy bold" (click)="onCopyLink()">
            <mat-icon class="material-icons icon md-24 standard-icon">content_copy</mat-icon>
            <div class="button-text">{{'ApiAccessDialog.CopyLink' | translate}}</div>
        </button>
        <button mat-flat-button color="primary" type="submit" class="btn bold" (click)="onDownload()" [disabled]="isDownloading">
            <mat-icon *ngIf="!isDownloading" class="material-icons icon md-24 standard-icon">download</mat-icon>
            <mat-icon *ngIf="isDownloading" class="material-icons icon md-24 standard-icon">downloading</mat-icon>
            <div class="button-text">{{'ApiAccessDialog.Download' | translate}}</div>
        </button>
      </mat-dialog-actions>
</div>
