import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { OpenApiService } from 'src/app/shared/services/open-api.service';
import { Events } from '../../models/events';
import { TelemetryService } from '../../services/telemetry.service';
import { UrlUtils } from '../../url.utils';
import { downloadBlob } from '../../utilities';
import { SnackBarComponent, SnackBarMode } from '../snack-bar/snack-bar.component';

const swaggerPath = '/api/DataService';
const swaggerDownloadFileName = 'DataService.json';

@Component({
  selector: 'app-api-access-dialog',
  templateUrl: './api-access-dialog.component.html',
  styleUrls: ['./api-access-dialog.component.scss']
})
export class ApiAccessDialogComponent {

  public isDownloading = false;
  private downloadFn = downloadBlob;

  constructor(
    private dialogRef: MatDialogRef<ApiAccessDialogComponent>,
    private openApiService: OpenApiService,
    private snackBar: MatSnackBar,
    private translateService: TranslateService,
    private telemetryService: TelemetryService,
  ) { }

  public onDownload() {
    this.isDownloading = true;
    this.telemetryService.logEvent(Events.DownloadOpenAPIClick);
    this.openApiService.getSwagger(swaggerPath).subscribe(
      (swagger) => {
        const indent = 2;
        const blobContent = [JSON.stringify(swagger, null, indent)];
        const blob = new Blob(blobContent, { type: 'application/json' });
        this.downloadFn(blob);
        this.onClose();
      },
      () => {
        this.snackBar.openFromComponent(
          SnackBarComponent,
          {
            duration: 5000, verticalPosition: 'top', data: {
              mode: SnackBarMode.error, text: this.translateService.get('ApiAccessDialog.DownloadError')
            }
          }
        );

        this.isDownloading = false;
      }
    );
  }

  public async onCopyLink() {
    this.telemetryService.logEvent(Events.CopyOpenAPILinkClick);

    const swaggerUrl = UrlUtils.buildUrl(swaggerPath);
    await this.copyToClipboard(swaggerUrl);

    this.snackBar.openFromComponent(
      SnackBarComponent,
      {
        duration: 5000, verticalPosition: 'top', data: {
          mode: SnackBarMode.success, text: this.translateService.get('ApiAccessDialog.CopySuccess')
        }
      }
    );

    this.onClose();
  }

  public onClose() {
    this.dialogRef.close();
  }

  public getFileName() {
    return swaggerDownloadFileName;
  }

  private async copyToClipboard(text: string) {
    await navigator.clipboard.writeText(text);
  }

}
