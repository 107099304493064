<div class="side-pane-container">
  <div mat-dialog-title>{{getTitleText() | async}}</div>

  <div mat-dialog-content class="dialog-content">
    <form [formGroup]="fieldForm" [style.fontSize.px]="14">
      <div fxLayout="column" fxLayoutAlign="space-around strech">
        <mat-form-field floatLabel="always" subscriptSizing="dynamic">
          <mat-label>{{'UpsertChoiceSet.FieldDisplayName' | translate}}</mat-label>
          <input matInput id="displayName" required formControlName="displayName" name="displayName"
            [placeholder]="'UpsertChoiceSet.FieldDisplayName' | translate" (keyup)="onSetDisplayName()"
            autocomplete="off" />
          <mat-error *ngIf="fieldForm.get('displayName').errors">
            {{'UpsertChoiceSet.FieldDisplayNameRequired' | translate}}
          </mat-error>
        </mat-form-field>

        <mat-form-field floatLabel="always" subscriptSizing="dynamic">
          <mat-label>{{'UpsertChoiceSet.FieldName' | translate}}</mat-label>
          <input matInput id="name" required formControlName="name" name="name"
            [placeholder]="'UpsertChoiceSet.FieldName' | translate" autocomplete="off"
            [matTooltip]="'UpsertChoiceSet.FieldNameFormat' | translate" />
          <mat-error *ngIf="fieldForm.get('name').errors">
            {{'UpsertChoiceSet.FieldNameFormat' | translate}}
          </mat-error>
        </mat-form-field>
      </div>
    </form>
    <div class="choice-heading-row" fxLayout="row" fxLayoutAlign="space-between center">
      <div class="choice-heading font-medium bold">{{'UpsertChoiceSet.Choices' | translate}}</div>
      <mat-checkbox [(ngModel)]="isMemberNameVisible" class="primary font-semi-small">
        {{'UpsertChoiceSet.ShowName' | translate}}
      </mat-checkbox>
    </div>
    <mat-spinner *ngIf="isLoadingMembers && !showNoPermission" role="alert" [attr.aria-label]="'Loading' | translate" diameter="24" class="spinner"></mat-spinner>
    <ng-container *ngIf="!isLoadingMembers">
      <portal-alert-bar *ngIf="deletedMemberIds.length > 0" class="removal-warning" fxLayout="row"
        fxLayoutAlign="space-around start" status="warning" cancelable="false">
        <div>{{'UpsertChoiceSet.RemovalWarning' | translate}}</div>
      </portal-alert-bar>
      <ng-container>
        <form [formGroup]="existingMembersForm" [style.fontSize.px]="14" class="member-list">
          <div fxLayout="column" fxLayoutAlign="space-around strech">
            <div *ngFor="let member of existingMembers"
              [class.disabled]="(userService.checkPermission([UpdateEntityScheme]) | async) === false">

              <div fxLayout="row" fxLayoutAlign="space-between flex-start">
                <mat-form-field fxFlex subscriptSizing="dynamic">
                  <div fxLayout="row" fxLayoutAlign="start center">
                    <span matPrefix class="prefix font-medium">{{'UpsertChoiceSet.FieldDisplayName'
                      | translate}}:</span>
                    <input matInput [id]="'displayName' + member.Id" required
                      [formControlName]="'displayName' + member.Id" autocomplete="off" />
                  </div>
                  <mat-error *ngIf="existingMembersForm.get('displayName' + member.Id).errors">
                    {{'UpsertChoiceSet.FieldDisplayNameRequired' | translate}}
                  </mat-error>
                </mat-form-field>
                <button *ngIf="!isMemberDeleted(member.Id)" [class.disabled]="data.isModelReserved" mat-icon-button
                  (click)="onDeleteChoice(member.Id)" class="deletion">
                  <mat-icon class="font-large" aria-hidden="false" aria-label="delete icon">
                    delete
                  </mat-icon>
                </button>
                <button *ngIf="isMemberDeleted(member.Id)" mat-icon-button
                  [matTooltip]="'UpsertChoiceSet.UndoAction' | translate" (click)="onRestoreChoice(member.Id)"
                  class="deletion">
                  <mat-icon class="font-large" aria-hidden="false" aria-label="refresh icon">
                    refresh
                  </mat-icon>
                </button>
              </div>

              <mat-form-field *ngIf="isMemberNameVisible" class="member-name-field">
                <div fxLayout="row" fxLayoutAlign="start center">
                  <span matPrefix class="prefix font-medium disabled">{{'UpsertChoiceSet.FieldName' |
                    translate}}:</span>
                  <input matInput [id]="'name' + member.Id" required [formControlName]="'name' + member.Id"
                    autocomplete="off" />
                </div>
              </mat-form-field>
            </div>
          </div>
        </form>
        <form [formGroup]="newMembersForm" [style.fontSize.px]="14" class="member-list">
          <div fxLayout="column" fxLayoutAlign="space-around strech"
            [class.disabled]="(userService.checkPermission([UpdateEntityScheme]) | async) === false">
            <div *ngFor="let id of newMemberIds">
              <div fxLayout="row" fxLayoutAlign="space-between center">
                <mat-form-field fxFlex subscriptSizing="dynamic">
                  <div fxLayout="row" fxLayoutAlign="start center">
                    <span matPrefix class="prefix font-medium">{{'UpsertChoiceSet.FieldDisplayName'
                      | translate}}:</span>
                    <input matInput [id]="'displayName' + id" [formControlName]="'displayName' + id"
                      (keydown.enter)="onAddChoice()" (keyup)="onSetNewMemberDisplayName(id)" autofocus
                      autocomplete="off" />
                  </div>
                  <mat-error *ngIf="newMembersForm.get('displayName' + id).errors">
                    {{'UpsertChoiceSet.FieldDisplayNameRequired' | translate}}
                  </mat-error>
                </mat-form-field>
                <button type="button" mat-icon-button (click)="onDiscardAddedChoice(id)" class="deletion">
                  <mat-icon class="font-large" aria-hidden="false" aria-label="delete icon">
                    delete
                  </mat-icon>
                </button>
              </div>
              <mat-form-field
                *ngIf="isMemberNameVisible || newMembersForm.get('name' + id).value && newMembersForm.get('name' + id).errors"
                class="member-name-field" subscriptSizing="dynamic">
                <div fxLayout="row" fxLayoutAlign="start center">
                  <span matPrefix class="prefix font-medium">{{'UpsertChoiceSet.FieldName' |
                    translate}}:</span>
                  <input matInput [id]="'name' + id" required [formControlName]="'name' + id" autocomplete="off" />
                </div>
                <mat-error
                  *ngIf="newMembersForm.get('name' + id).errors && !newMembersForm.get('name' + id).errors.duplicateNameError">
                  {{'UpsertChoiceSet.FieldNameFormat' | translate}}
                </mat-error>
                <mat-error
                  *ngIf="newMembersForm.get('name' + id).errors && newMembersForm.get('name' + id).errors.duplicateNameError">
                  {{'UpsertChoiceSet.FieldNameDuplicate' | translate}}
                </mat-error>
              </mat-form-field>
            </div>
          </div>
        </form>
      </ng-container>
      <div
        [matTooltip]="(userService.checkPermission([UpdateEntityScheme]) | async) === false? ('NoPermissionToolTip' | translate) : ''">
        <button
          [class.disabled]="(userService.checkPermission([UpdateEntityScheme]) | async) === false || data.isModelReserved"
          mat-button [attr.aria-label]="'UpsertChoiceSet.AddChoice' | translate" class="tool-bar-btn bold"
          color="primary" type="button" (click)="onAddChoice()">
          <mat-icon class="material-icons md-20" aria-hidden="false" aria-label="add icon">add</mat-icon>
          <span>
            {{'UpsertChoiceSet.AddChoice' | translate}}
          </span>
        </button>
      </div>
    </ng-container>
    <ng-container>
      <mat-expansion-panel class="expansion" togglePosition="after" (opened)="panelOpenState = true"
        (closed)="panelOpenState = false">
        <mat-expansion-panel-header class="panel-header">
          <mat-panel-title class="bold">
            {{'UpsertField.MoreDetails' | translate}}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
          <form [formGroup]="fieldForm" [style.fontSize.px]="14">
            <div fxLayout="column" fxLayoutAlign="space-around strech">
              <mat-form-field floatLabel="always" subscriptSizing="dynamic">
                <mat-label class="label semi-bold">{{'UpsertChoiceSet.FieldDescription' | translate}}
                </mat-label>
                <textarea matInput type="text" id="description" formControlName="description"
                  class="description-content" name="description"
                  [placeholder]="'UpsertChoiceSet.FieldDescription' | translate">
                                            </textarea>
                <mat-error *ngIf="fieldForm.get('description').errors">
                  {{'UpsertChoiceSet.FieldDescriptionLength' | translate}}
                </mat-error>
              </mat-form-field>
            </div>
          </form>
          <div *ngIf="data.mode === 'Update'" fxLayout="column" fxLayoutAlign="start none" class="font-medium">
            <div fxLayout="row" fxLayoutAlign="space-between none">
              <div>{{'UpsertField.CreatedBy' | translate}}</div>
              <div class="detail-content">
                {{createdBy}}
              </div>
            </div>
            <div fxLayout="row" fxLayoutAlign="space-between none">
              <div>{{'UpsertField.Created' | translate}}</div>
              <div class="detail-content">
                {{createTime}}
              </div>
            </div>
            <div fxLayout="row" fxLayoutAlign="space-between none">
              <div>{{'UpsertField.LastModifiedBy' | translate}}</div>
              <div class="detail-content">
                {{updatedBy}}
              </div>
            </div>
            <div fxLayout="row" fxLayoutAlign="space-between none">
              <div>{{'UpsertField.LastModified' | translate}}</div>
              <div class="detail-content">
                {{updateTime}}
              </div>
            </div>
          </div>
        </ng-template>
      </mat-expansion-panel>
    </ng-container>
  </div>

  <div fxLayout="row" fxLayoutAlign="end strech" class="bottom-btn bottom-buttons-container">
    <button mat-stroked-button color="primary" class="btn close bold" (click)="onDismiss()">{{'CancelButtonText' |
      translate}}</button>
    <button *ngIf="!isSaving" mat-flat-button color="primary" type="submit" class="btn bold"
      [disabled]="isSubmitDisabled()" (click)="onSubmit()">
      {{'SaveButtonText' | translate}}
    </button>
    <div class="btn" *ngIf="isSaving">
      <mat-spinner role="alert" [attr.aria-label]="'Loading' | translate" diameter="24" class="spinner"></mat-spinner>
    </div>
  </div>
  <button mat-icon-button (click)="onDismiss()" class="close-button" [attr.aria-label]="'CloseButtonText' | translate">
    <mat-icon class="font-large">
      close
    </mat-icon>
  </button>
</div>
