import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ErrorData } from '../../interceptors/http-error-interceptors';
import { Constants } from '../../constants';

@Component({
  selector: 'error-dialog',
  templateUrl: './error-dialog.component.html',
  styleUrls: ['./error-dialog.component.scss']
})
export class ErrorDialogComponent implements OnInit {

  public sessionId: string;
  public currentTimeStamp: Date;
  public traceId: string;

  constructor(
    public dialogRef: MatDialogRef<ErrorDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ErrorData
  ) {
  }

  public ngOnInit() {
    this.sessionId = sessionStorage.getItem(Constants.SessionId);
    this.currentTimeStamp = new Date();
    this.traceId = this.data.traceId || sessionStorage.getItem(Constants.TraceId);
  }

  public onClick() {

    this.dialogRef.close();
  }
}
