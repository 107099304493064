import { UrlUtils } from 'src/app/shared/url.utils';
import { Component } from '@angular/core';

@Component({
  selector: 'app-no-permission',
  templateUrl: './no-permission.component.html',
  styleUrls: ['./no-permission.component.scss']
})
export class NoPermissionComponent {

  constructor() {
    // intentional empty constructor
  }

  public getHomeRoute(): string {
    return `${UrlUtils.getHomeRouteUrl()}`;
  }
}
