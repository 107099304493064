<mat-dialog-title>
    <div fxLayout="row" class="title bold">
        <mat-icon class="material-icons md-36 error-icon standard-icon">error</mat-icon>
        {{'Import.ErrorTitle' | translate}}
    </div>
</mat-dialog-title>

<mat-dialog-content>
    <div fxLayout="column" fxLayoutAlign="space-around" class="error-text-container">
        {{errorText | async}}
    </div>
</mat-dialog-content>
<div fxLayout="row" fxLayoutAlign="end" class="button-section">
    <button mat-flat-button color="primary" type="submit" class="btn bold" (click)="onClose()">{{'CloseButtonText' |
        translate}}</button>
</div>
