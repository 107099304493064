import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { Field } from 'src/app/shared/models/field';
import { Entity } from 'src/app/shared/models/entity';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EntitiesDataService } from 'src/app/shared/services/entities-data.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackBarComponent, SnackBarMode } from 'src/app/shared/components/snack-bar/snack-bar.component';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { Constants } from 'src/app/shared/constants';

export interface UpsertFileDialogData {
  entity: Entity;
  recordId: string;
  field: Field;
}

@Component({
  selector: 'app-upsert-file-dialog',
  templateUrl: './upsert-file-dialog.component.html',
  styleUrls: ['./upsert-file-dialog.component.scss']
})
export class UpsertFileDialogComponent implements OnInit, OnDestroy {

  public isLoading: boolean;

  public fileToUpload: File;

  public showErrBox: boolean;

  private SIZE_LIMIT = 10485760;

  private abortCtrl: any;
  private subscription: Subscription = new Subscription();

  constructor(
    public dialogRef: MatDialogRef<UpsertFileDialogComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: UpsertFileDialogData,
    public entitiesDataService: EntitiesDataService,
    private snackBar: MatSnackBar,
    private translateService: TranslateService,
  ) { }

  ngOnInit() {
  }

  public ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  public onFileSelected = (fieldName: string, files: File[]): void => {
    this.showErrBox = false;
    this.fileToUpload = files[0];

    if (this.fileToUpload && !this.isSizeValid()) {
      this.showErrBox = true;
      this.fileToUpload = null;
    }
  };

  public onSubmit(): void {
    this.abortCtrl = new AbortController();
    const cancelUploadSignal = this.abortCtrl.signal;

    this.isLoading = true;
    const sub = this.entitiesDataService.uploadFile(this.data.entity.name, this.data.field.name,
      this.data.recordId, this.fileToUpload, cancelUploadSignal).subscribe(
      () => {
        this.dialogRef.close({
          data: 'succeed'
        });
        this.snackBar.openFromComponent(
          SnackBarComponent,
          {
            duration: 5000,
            verticalPosition: 'top',
            data: { mode: SnackBarMode.success, text: this.translateService.get('File.SuccessText') }
          }
        );
      }, (err) => {
        this.isLoading = false;

        if (err.errorText != null)
        {
          this.snackBar.openFromComponent(SnackBarComponent,
            { duration: 5000, verticalPosition: 'top',
              data: err });
        }
        else
        {
          this.snackBar.openFromComponent(SnackBarComponent,
            { duration: 5000, verticalPosition: 'top',
              data: {text: this.translateService.get('File.FailedText', {value: sessionStorage.getItem(Constants.TraceId)})} });
        }
      });
    this.subscription.add(sub);
  }

  public onCancel(): void {
    this.abortCtrl.abort();
    this.isLoading = false;
  }

  public isSubmitDisabled(): boolean {
    return this.isPristine();
  }

  public onDismiss(): void {
    this.dialogRef.close();
  }

  private isPristine(): boolean {
    return !this.fileToUpload;
  }

  private isSizeValid(): boolean {
    return this.fileToUpload.size < this.SIZE_LIMIT;
  }
}
