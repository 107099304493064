<mat-spinner *ngIf="isLoadingMembers" diameter="24" class="spinner" role="alert" [attr.aria-label]="'Loading' | translate"></mat-spinner>
<mat-form-field *ngIf="!isLoadingMembers" class="choiceset-options">
  <mat-select #matSelect [formControl]="formControl" multiple={{isMultiple}} (closed)="onClose()">
    <div class="search-bar" fxLayout="row" fxAlign="center center">
      <mat-icon class="search-icon">search</mat-icon>
      <input fxFlex class="search" type="text" [placeholder]="'SearchText' | translate" [(ngModel)]="searchText"
        matInput autocomplete="off" tabindex="0"  [autofocus]/>
    </div>
    <div class="custom-panel" #customPanel>
      <mat-option *ngFor="let m of members" class="table-text" [ngClass]="{'hidden': isHidden(m.DisplayName)}"
        [value]="m.NumberId" (click)="onSelect($event)" (onSelectionChange)="onSelect($event)">{{m.DisplayName}}
      </mat-option>
      <mat-option *ngIf="members.length === 0" class="no-item-placeholder">{{'NoItems' | translate}}</mat-option>
    </div>
  </mat-select>
</mat-form-field>
