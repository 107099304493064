import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Constants } from 'src/app/shared/constants';
import { Entity } from 'src/app/shared/models/entity';
import { Events } from 'src/app/shared/models/events';
import { isSystemEntity } from 'src/app/shared/utilities';
import { TelemetryService } from 'src/app/shared/services/telemetry.service';
import { UrlUtils } from 'src/app/shared/url.utils';
import { SnackBarComponent, SnackBarMode } from 'src/app/shared/components/snack-bar/snack-bar.component';
import { ImportService, ImportSchema } from 'src/app/shared/services/import.service';
import { ImportSelections, ViewMode } from '../../list-entities/list-entities.component';
import { Breadcrumb } from 'src/app/shared/components/breadcrumb/breadcrumb.component';

export enum ImportOptions {
  keep = 1,
  skip = 2
}
@Component({
  selector: 'app-entities-import',
  templateUrl: './entities-import.component.html',
  styleUrls: ['./entities-import.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class EntitiesImportComponent implements OnInit {
  public mode: ViewMode;
  public importSchema: ImportSchema = {
    entities: [],
    choicesets: [],
    conflictEntities: [],
    conflictChoicesets: [],
    nonConflictEntities: [],
    nonConflictChoicesets: []
  };
  public fieldConflict = false;
  public entity: Entity;
  public entities: Entity[] = [];
  public choicesets: Entity[] = [];
  public file: File;
  public entitiesLink: string;
  public fields: object[];
  public importContent: { entities?: Entity[], choicesets?: Entity[] };
  public entityNames: string[];
  public importSelections: ImportSelections;
  public totalEntitiesToImport = 0;
  public breadcrumbs: Breadcrumb[];

  public viewIndex = 0;
  public isParsingFile = false;

  public UpdateEntityScheme: number = Constants.UpdateEntityScheme;
  public ReadEntityScheme: number = Constants.ReadEntityScheme;
  public ReadEntityData: number = Constants.ReadEntityData;
  public CreateEntityData: number = Constants.CreateEntityData;
  public UpdateEntityData: number = Constants.UpdateEntityData;
  public DeleteEntityData: number = Constants.DeleteEntityData;

  public isSystemEntity = isSystemEntity;

  private CHOICESET_VIEW_INDEX = 1;
  private ENTITY_VIEW_INDEX = 0;

  constructor(
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router,
    private telemetryService: TelemetryService,
    private snackBar: MatSnackBar,
    private translateService: TranslateService,
    private importService: ImportService
  ) { }

  ngOnInit(): void {
    const stateData = window.history.state;
    this.setTab();
    if (stateData && stateData.mode && stateData.importSchema) {
      this.mode = stateData.mode;
      this.importSchema = stateData.importSchema;
      this.file = stateData.file;
      this.parseFile(this.file);
      this.choicesets = this.concatArrays(this.importSchema['nonConflictChoicesets'], this.importSchema['conflictChoicesets']);
      this.entities = this.concatArrays(this.importSchema['nonConflictEntities'], this.importSchema['conflictEntities']);
    } else {
      this.router.navigate([`${UrlUtils.getBaseRouteUrl()}/entities`]);
    }
    this.breadcrumbs = [{ key: 'EntityDetails.Title', url: `${UrlUtils.getBaseRouteUrl()}/entities` }, { key: 'EntityConflict.Title' }];
    this.importService.currentSelection.subscribe(options => this.importSelections = options);
    this.importService.currentTotalEntitiesToImport.subscribe(entitiesToImport => this.totalEntitiesToImport = entitiesToImport);
    this.importService.resetValues();
  }

  public get isLoadingData(): boolean {
    return this.isParsingFile;
  }

  public isFieldsConflict(): boolean {
    return this.mode === ViewMode.fieldsConflict;
  }

  public continueImport(): void {
    const restoreContent = {...this.importContent};
    this.isParsingFile = true;
    const skippedEntities = [];
    const skippedChoicesets = [];
    for (const choiceset of this.choicesets) {
      if (this.importSelections['choicesets'][choiceset.name] === ImportOptions.skip  ||
          !this.importSelections['choicesets'][choiceset.name]) {
        skippedChoicesets.push(choiceset.name);
      }
    }

    for (const entity of this.entities) {
      if (this.importSelections['entities'][entity.name] === ImportOptions.skip ||
          !this.importSelections['entities'][entity.name]) {
        skippedEntities.push(entity.name);
      }
    }
    const lists = { entities: skippedEntities, choicesets: skippedChoicesets };
    this.removeUnwanted(lists);
    if (this.importContent.entities.length === 0 && this.importContent.choicesets.length === 0) {
      this.router.navigate([`${UrlUtils.getBaseRouteUrl()}/entities`]);
      this.snackBar.openFromComponent(
        SnackBarComponent,
        {
          duration: 5000, verticalPosition: 'top', data: {
            mode: SnackBarMode.success,
            text: this.translateService.get('Import.SuccessText')
          }
        }
      );
      return;
    }
    const fields = this.importService.fieldComparison(this.importContent, this.entities);
    if (fields['fieldsList'].length === 0) {
      this.importService.importUpdatedFile(this.importContent).subscribe(result => {
        this.isParsingFile = false;
        this.router.navigate([`${UrlUtils.getBaseRouteUrl()}/entities`]);
        this.snackBar.openFromComponent(
          SnackBarComponent,
          {
            duration: 5000,
            verticalPosition: 'top',
            data: { mode: SnackBarMode.success, text: this.translateService.get('Import.SuccessText') }
          }
        );
      }, err => {
        this.importContent = restoreContent;
        this.isParsingFile = false;
        this.snackBar.openFromComponent(SnackBarComponent, { duration: 5000, verticalPosition: 'top', data: err });
      });
    } else {
      this.isParsingFile = false;
      this.mode = ViewMode.fieldsConflict;
      this.fields = fields['fieldsList'];
      this.entityNames = fields['entityNames'];
    }
  }

  public removeUnwanted(lists): void {
    const choicesetOptions = lists['choicesets'];
    if (this.importContent.choicesets !== undefined) {
      const filteredChoicesets = this.importContent.choicesets.filter(item => !choicesetOptions.includes(item.name));
      this.importContent.choicesets = filteredChoicesets;
    } else {
      this.importContent.choicesets = [];
    }
    const entityOptions = lists['entities'];
    const filteredEntities = this.importContent.entities.filter(item => !entityOptions.includes(item.name));
    this.importContent.entities = filteredEntities;
  }

  public parseFile(file: File): void {
    // todo: security checks to validate json file
    this.isParsingFile = true;
    const fr = new FileReader();

    fr.addEventListener('load', e => {
      try {
        const fileRead = JSON.parse(e.target.result as string);
        this.importContent = fileRead;
        this.isParsingFile = false;
      } catch (err) {
        this.isParsingFile = false;
        this.telemetryService.logEvent(Events.ImportFileParseError);
        this.snackBar.openFromComponent(SnackBarComponent,
          {
            duration: 5000, verticalPosition: 'top', data: {
              text: this.translateService.get('ListConflict.ParseFileError')
            }
          }
        );
      }
    });
    fr.readAsText(file);
  }

  public conflictFieldsImport(): void {
    this.isParsingFile = true;
    this.removeConflictEntities();
    if (this.importContent.entities.length === 0) {
      this.router.navigate([`${UrlUtils.getBaseRouteUrl()}/entities`]);
      this.snackBar.openFromComponent(
        SnackBarComponent,
        {
          duration: 5000, verticalPosition: 'top', data: {
            mode: SnackBarMode.success,
            text: this.translateService.get('Import.SuccessText')
          }
        }
      );
      return;
    } else {
      this.importService.importUpdatedFile(this.importContent).subscribe(result => {
        this.isParsingFile = false;
        this.router.navigate([`${UrlUtils.getBaseRouteUrl()}/entities`]);
        this.snackBar.openFromComponent(
          SnackBarComponent,
          {
            duration: 5000,
            verticalPosition: 'top',
            data: { mode: SnackBarMode.success, text: this.translateService.get('Import.SuccessText') }
          });
      }, err => {
        this.isParsingFile = false;
        this.snackBar.openFromComponent(SnackBarComponent, { duration: 5000, verticalPosition: 'top', data: err });
      });
    }
  }

  public isImportDisabled(): boolean {
    return this.totalEntitiesToImport === 0;
  }

  public removeConflictEntities(): void {
    const filteredEntities = this.importContent.entities.filter(item => !this.entityNames.includes(item.name));
    this.importContent.entities = filteredEntities;
  }

  public tabClick(event: any): void {
    if (event.index === 1) {
      this.telemetryService.logEvent(Events.DataTabClick);
    }
  }

  public onClose(): void {
    this.router.navigate([`${UrlUtils.getBaseRouteUrl()}/entities`]);
  }

  private setTab(): void {
    this.route.queryParamMap.subscribe(queryParams => {
      const view = queryParams.get('view');
      this.viewIndex = view === Constants.DataView ? this.CHOICESET_VIEW_INDEX : this.ENTITY_VIEW_INDEX;
    });
  }

  private concatArrays(...arrays) {
    return [].concat(...arrays.filter(Array.isArray));
  }
}
