import {
  filter,
  Observable,
  OperatorFunction,
  pipe,
  UnaryFunction,
} from 'rxjs';

export const filterFalsy = <T>(): UnaryFunction<Observable<T | null | undefined>, Observable<T>> => pipe(
    filter(x => !!x) as OperatorFunction<T | null | undefined, T>,
);