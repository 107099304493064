import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of, Subject, Subscription } from 'rxjs';
import { SnackBarComponent } from 'src/app/shared/components/snack-bar/snack-bar.component';
import { Constants } from 'src/app/shared/constants';
import { ChoiceSetMember } from 'src/app/shared/models/choiceset';
import { Entity } from 'src/app/shared/models/entity';
import { EntitiesDataService } from 'src/app/shared/services/entities-data.service';
import { EntitiesService } from 'src/app/shared/services/entities.service';
import { UserService } from 'src/app/shared/services/user.service';
import { isReservedEntity } from 'src/app/shared/utilities';
import { duplicateNameValidator, forbiddenNameValidator } from 'src/app/shared/validators';

export enum ChoiceSetDialogMode {
  create = 'Create',
  update = 'Update'
}

export interface ChoiceSetDialogData {
  mode: ChoiceSetDialogMode;
  choiceset: Entity;
  isModelReserved?: boolean;
}

export interface ChoiceSetDialogResult {
  succeed: boolean;
  data: any;
}

@Component({
  selector: 'upsert-choiceset-dialog',
  templateUrl: './upsert-choiceset-dialog.component.html',
  styleUrls: ['./upsert-choiceset-dialog.component.scss']
})
export class UpsertChoicesetDialogComponent implements OnInit, OnDestroy {

  public isSaving = false;
  public isLoadingMembers = false;

  public fieldForm: UntypedFormGroup;
  private snapshot: Entity;

  public existingMembers: ChoiceSetMember[] = [];
  public existingMembersForm: UntypedFormGroup;
  public isMemberNameVisible = false;
  public showNoPermission = false;
  public panelOpenState = false;

  public newMembersForm: UntypedFormGroup;
  public newMemberIds: string[] = [];
  private memberNames = new Set<string>();
  public deletedMemberIds: string[] = [];

  public createTime: Date;
  public createdBy: string;
  public updateTime: Date;
  public updatedBy: string;

  public isReservedEntity = isReservedEntity;
  public UpdateEntityScheme: number = Constants.UpdateEntityScheme;

  private newMemberNameTimer: number;
  private subscription: Subscription = new Subscription();

  constructor(
    public dialogRef: MatDialogRef<UpsertChoicesetDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ChoiceSetDialogData,
    private fb: UntypedFormBuilder,
    private entitiesService: EntitiesService,
    private dataService: EntitiesDataService,
    private snackBar: MatSnackBar,
    public userService: UserService,
    private translateService: TranslateService,
  ) {
  }

  public ngOnInit() {
    const formContent = {
      name: [{ value: this.data.choiceset.name, disabled: this.shouldDisableInput() },
        [Validators.required, forbiddenNameValidator(new RegExp('^[a-zA-Z][a-zA-Z0-9]{2,29}$'))]],
      displayName: [this.data.choiceset.displayName, Validators.required],
      description: [{value: this.data.choiceset.description,
        disabled: this.isReservedEntity(this.data.choiceset)}, Validators.maxLength(512)],
    };

    this.fieldForm = this.fb.group(formContent);
    this.snapshot = { ...this.fieldForm.value };

    this.existingMembersForm = this.fb.group({});
    this.newMembersForm = this.fb.group({});
    this.newMembersForm.valueChanges.subscribe(() => {
      this.setNewMembersFormValidators();
    });

    if (this.data.mode === ChoiceSetDialogMode.create) {
      this.onAddChoice();
    }

    this.loadMembers();
  }

  public ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  public onSubmit(): void {
    this.isSaving = true;
    const upsertChoiceSetAction = this.getUpsertChoiceSetAction();

    const sub = upsertChoiceSetAction.subscribe((id) => {
      const addMembersAction = this.getAddChoiceMembersAction();

      addMembersAction.subscribe(() => {
        const updateMembersAction = this.getUpdateChoiceMembersAction();

        updateMembersAction.subscribe(() => {

          const deleteMembersAction = this.getDeleteChoiceMembersAction();

          deleteMembersAction.subscribe(() => {
            this.dialogRef.close({ succeed: true, data: id } as ChoiceSetDialogResult);
          });
        });
      },
      this.onActionError);
    },
    this.onActionError);
    this.subscription.add(sub);
  }

  public isSubmitDisabled(): boolean {
    const isDialogPristine = this.isChoiceSetEntityPristine()
      && !this.hasNewMembers()
      && !this.hasEditedExistingMembers()
      && !this.hasDeletedMembers();

    const isValid = this.fieldForm.valid && this.existingMembersForm.valid && this.newMembersForm.valid;

    return isDialogPristine || !isValid;
  }

  public onDismiss(): void {
    this.dialogRef.close();
  }

  public shouldDisableInput(): boolean {
    return this.data.mode === ChoiceSetDialogMode.update;
  }

  public onSetDisplayName(): void {
    if (this.data.mode === ChoiceSetDialogMode.create) {
      const diaplayNameValue = this.fieldForm.value.displayName;
      const nameValue = diaplayNameValue && diaplayNameValue.replace(/[^a-zA-Z0-9]/g, '');
      this.fieldForm.controls.name.setValue(nameValue);
      this.fieldForm.controls.name.markAsTouched();
      this.fieldForm.controls.name.updateValueAndValidity();
    }
  }

  public onSetNewMemberDisplayName(id: string): void {
    const diaplayNameValue = this.newMembersForm.value['displayName' + id];
    const nameValue = diaplayNameValue && diaplayNameValue.replace(/[^a-zA-Z0-9]/g, '');

    window.clearTimeout(this.newMemberNameTimer);
    this.newMemberNameTimer = window.setTimeout(() => {
      this.newMembersForm.controls['name' + id].setValue(nameValue);
      this.newMembersForm.controls['name' + id].markAllAsTouched();
    }, 500);
  }

  public getTitleText(): Observable<string> {
    if (this.data.mode === ChoiceSetDialogMode.create) {
      return this.translateService.get('UpsertChoiceSet.CreateChoiceSet');
    } else {
      return this.translateService.get('UpsertChoiceSet.EditChoiceSet', { value: this.data.choiceset.name });
    }
  }

  public onAddChoice(): void {
    const { length } = this.newMemberIds;
    const lastId = this.newMemberIds[length - 1] || '0';
    const newId = (Number(lastId) + 1).toString();
    this.newMemberIds.push(newId);

    this.newMembersForm.addControl(
      'name' + newId,
      new UntypedFormControl('', [
        Validators.required,
        forbiddenNameValidator(new RegExp('^[a-zA-Z][a-zA-Z0-9]{2,29}$')),
        duplicateNameValidator(this.memberNames)
      ])
    );

    this.newMembersForm.addControl(
      'displayName' + newId,
      new UntypedFormControl('', [])
    );

    setTimeout(() => {
      // Wrapping with a timeout so the new field don't appear together with a validation error.
      this.newMembersForm.get('displayName' + newId).setValidators([Validators.required]);
    }, 200);
  }

  public onDiscardAddedChoice(id: string): void {
    this.newMemberIds = this.newMemberIds.filter(i => i !== id);
    this.newMembersForm.removeControl('displayName' + id);
    this.newMembersForm.removeControl('name' + id);
  }

  public onDeleteChoice(id: string): void {
    this.deletedMemberIds.push(id);
    this.existingMembersForm.controls['displayName' + id].disable();
  }

  public onRestoreChoice(id: string): void {
    this.deletedMemberIds = this.deletedMemberIds.filter(i => i !== id);
    this.existingMembersForm.controls['displayName' + id].enable();
  }

  public isMemberDeleted(id: string): boolean {
    return this.deletedMemberIds.findIndex(i => i === id) >= 0;
  }

  private setNewMembersFormValidators(): void {
    this.newMemberIds.forEach((id, index) => {
      const precedingNames = this.newMemberIds.slice(0, index).map(i => this.newMembersForm.value['name' + i]);
      const memberNames = new Set([...this.memberNames, ...precedingNames]);

      this.newMembersForm.get('name' + id).setValidators([
        Validators.required,
        forbiddenNameValidator(new RegExp('^[a-zA-Z][a-zA-Z0-9]{2,29}$')),
        duplicateNameValidator(memberNames)
      ]);
    });
  }

  private getUpsertChoiceSetAction(): Observable<string> {
    const { description, displayName, name } = this.fieldForm.getRawValue();

    if (this.data.mode === ChoiceSetDialogMode.create) {
      return this.entitiesService.createChoiceSet({ description, displayName, entityDefinition: { name, fields: [] } });
    }

    if (!this.isChoiceSetEntityPristine()) {
      return this.entitiesService.updateEntity({ ...this.data.choiceset, description, displayName });
    }

    return of(this.data.choiceset.id);
  }

  private getAddChoiceMembersAction(): Observable<undefined> {
    if (!this.hasNewMembers()) {
      return of(undefined);
    }

    const subject = new Subject<undefined>();
    let completedRequestCount = 0;

    this.newMemberIds.forEach(id => {
      const name = this.newMembersForm.value['name' + id];
      const displayName = this.newMembersForm.value['displayName' + id];
      const choiceSetName = this.data.mode === ChoiceSetDialogMode.create ? this.fieldForm.value.name : this.data.choiceset.name;

      const sub = this.dataService.insertChoiceSetData(choiceSetName, { Name: name, DisplayName: displayName }).subscribe(result => {
        completedRequestCount++;
        if (completedRequestCount === this.newMemberIds.length) {
          subject.next(undefined);
        }
      },
      error => {
        subject.error(error);
      });
      this.subscription.add(sub);
    });

    return subject.asObservable();
  }

  private getUpdateChoiceMembersAction(): Observable<undefined> {
    if (!this.hasEditedExistingMembers()) {
      return of(undefined);
    }

    const subject = new Subject<undefined>();
    let completedRequestCount = 0;

    const updatedMembers = this.existingMembers
      .filter(m => this.deletedMemberIds.findIndex(i => i === m.Id) < 0)
      .filter(m => m.DisplayName !== this.existingMembersForm.value['displayName' + m.Id]);

    updatedMembers.forEach(m => {
      const choiceSetName = this.data.choiceset.name;
      const displayName = this.existingMembersForm.value['displayName' + m.Id];

      const sub = this.dataService.updateChoiceSetData(choiceSetName, m.Id, { DisplayName: displayName, Name: m.Name }).subscribe({
        next: (res) => {
          completedRequestCount++;
          if (completedRequestCount === updatedMembers.length) {
            subject.next(undefined);
          }
        },
        error: (err) => {
          subject.error(err);
        }});
      this.subscription.add(sub);
    });

    return subject.asObservable();
  }

  private getDeleteChoiceMembersAction(): Observable<boolean> {
    if (!this.hasDeletedMembers()) {
      return of(undefined);
    }

    return this.dataService.deleteChoiceSetData(this.data.choiceset.id, this.deletedMemberIds);
  }

  private isChoiceSetEntityPristine(): boolean {
    return JSON.stringify(this.fieldForm.value) === JSON.stringify(this.snapshot);
  }

  private hasNewMembers(): boolean {
    return this.newMemberIds.length > 0;
  }

  private hasEditedExistingMembers(): boolean {
    return this.existingMembers
      && this.existingMembers
        .filter(m => this.deletedMemberIds.findIndex(i => i === m.Id) < 0)
        .some(m => {
          return m.DisplayName !== this.existingMembersForm.value['displayName' + m.Id];
        });
  }

  private hasDeletedMembers(): boolean {
    return this.deletedMemberIds.length > 0;
  }

  private loadMembers(): void {
    if (this.data.mode === ChoiceSetDialogMode.update) {
      this.isLoadingMembers = true;
      const sub = this.dataService.queryChoiceSetMembers(this.data.choiceset.id).subscribe(result => {
        const members: ChoiceSetMember[] = result.value as any;

        const existingContent = members.reduce((c, member) => {
          c['name' + member.Id] = [{ value: member.Name, disabled: true },
            [Validators.required, forbiddenNameValidator(new RegExp('^[a-zA-Z][a-zA-Z0-9]{2,29}$'))]];

          c['displayName' + member.Id] = [{ value: member.DisplayName, disabled: false },
            [Validators.required]];
          return c;
        }, {});

        this.existingMembers = members;
        this.existingMembersForm = this.fb.group(existingContent);

        members.forEach(m => this.memberNames.add(m.Name));

        this.isLoadingMembers = false;

        this.loadAuditInfo();
      }, (error) => {
        this.isLoadingMembers = false;
        if (error && JSON.stringify(error).indexOf('403') > -1) {
          this.showNoPermission = true;
        }
        this.snackBar.openFromComponent(SnackBarComponent,
          { duration: 5000, verticalPosition: 'top', data: error });
      });
      this.subscription.add(sub);
    }
  }

  private loadAuditInfo(): void {
    if (this.data.mode === ChoiceSetDialogMode.create) {
      return;
    }

    const userIds: string[] = [];

    const { createdBy, updatedBy } = this.data.choiceset;
    userIds.push(createdBy);
    if (updatedBy) {
      userIds.push(updatedBy);
    }

    const sub = this.userService.getUsersByIds(userIds).subscribe(users => {
      this.createdBy = users.get(createdBy).name;
      this.createTime = this.data.choiceset.createTime;
      this.updatedBy = updatedBy && users.get(updatedBy).name;
      this.updateTime = updatedBy && this.data.choiceset.updateTime;
    });
    this.subscription.add(sub);
  }

  private onActionError = (error: any) => {
    this.isSaving = false;
    this.snackBar.openFromComponent(SnackBarComponent,
      { duration: 5000, verticalPosition: 'top', data: error });
  };

}
