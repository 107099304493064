<button *ngIf="!fileInfo" id="file-cell" class="file-cell upload-button bold font-medium" fxLayout="row"
  fxLayoutAlign="center center" (blur)="onBlur($event)" (click)="onUpload()" [disabled]="this.disabled">
  <mat-icon class="material-icons icon md-24 standard-icon">cloud_upload</mat-icon>
  {{'UploadText' | translate}}
</button>
<div *ngIf="fileInfo" id="file-cell" fxLayout="row"
  fxLayoutAlign="space-between center" (blur)="onBlur($event)">
  <div class="filename">{{getFileName()}}</div>
  <mat-icon
    [matMenuTriggerFor]="menu"
    [matTooltip]="'MoreTooltip' | translate"
    class="icon"
  >more_vert</mat-icon>
</div>
<mat-menu #menu="matMenu">
  <button mat-menu-item (blur)="onBlur($event)" (click)="onDownload()">
    <span>{{'DownloadText' | translate}}</span>
  </button>
  <button mat-menu-item (blur)="onBlur($event)" (click)="onUpload()" [disabled]= "this.disabled">
    <span>{{'ChangeText' | translate}}</span>
  </button>
  <button mat-menu-item (blur)="onBlur($event)" (click)="onDelete()" [disabled]="this.disabled">
    <span>{{'RemoveText' | translate}}</span>
  </button>
</mat-menu>