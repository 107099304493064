
<div class="header">
  <div *ngIf="shouldShowDeleteTemplates" class="alert-bar">
    <portal-alert-bar status="info" cancelable="false">{{'TemplateDetails.DeleteTemplateAlert' | translate}}</portal-alert-bar>
  </div>
  <div fxLayout="row" fxLayoutAlign="space-between">
    <div fxLayout="row" fxLayoutAlign="space-between center" class="title-section">
      <h1 class="bold font-large">
        <app-breadcrumb-secondary [breadcrumbs]="breadcrumbs"></app-breadcrumb-secondary>
        <ng-container *ngIf="breadcrumbs.length > 0">
          {{ breadcrumbs[breadcrumbs.length - 1].key | translate }}
        </ng-container>
        <ng-container *ngIf="breadcrumbs.length === 0">
          {{ currentTemplate.name }}
        </ng-container>
      </h1>
    </div>
    <ng-container *ngIf="!isLoading">
      <ng-container *ngIf="shouldShowDeleteTemplates">
        <button mat-stroked-button color="primary"
          [attr.aria-label]="'TemplateDetails.DeleteTemplate' | translate"          
          class="new-field-button bold" (click)="onDeleteTemplate()">
          {{'TemplateDetails.DeleteTemplate' | translate}}
        </button>
      </ng-container>
      <ng-container *ngIf="!shouldShowDeleteTemplates">
        <button mat-stroked-button color="primary"
          [attr.aria-label]="'TemplateDetails.ApplyTemplate' | translate"
          class="new-field-button bold" (click)="onApplyTemplate()">
          {{'TemplateDetails.ApplyTemplate' | translate}}
        </button>
      </ng-container>
    </ng-container>
  </div>
</div>

<ng-container *ngIf="isLoading">
    <mat-spinner diameter="48" role="alert" [attr.aria-label]="'Loading' | translate" class="spinner"></mat-spinner>
</ng-container>
<ng-container *ngIf="!isLoading">
  <ng-container *ngIf="currentView === 'Detail'">
    <mat-tab-group [selectedIndex]="tabIndex" (selectedTabChange)="changeTab($event)">
      <mat-tab [label]="'EntitiesText' | translate">
        <app-template-data-model [pagination]="entityPagination.pagination" [sort]="entityPagination.sort" [entities]="templateData.entities" [dataType]="'Entity'" [onClickEntity]="onClickEntity" [currentTemplate]="currentTemplate"></app-template-data-model>
      </mat-tab>
      <mat-tab [label]="'ChoicesetText' | translate">
        <app-template-data-model [pagination]="choicesetPagination.pagination" [sort]="choicesetPagination.sort" [entities]="templateData.choicesets" [dataType]="'Choiceset'" [onClickEntity]="onClickChoiceset" [currentTemplate]="currentTemplate"></app-template-data-model>
      </mat-tab>
    </mat-tab-group>
  </ng-container>

  <ng-container *ngIf="currentView === 'Schema'">
    <app-template-entity-details [entity]="selectedEntity" [entityType]="selectedEntityType" [currentTemplate]="currentTemplate"></app-template-entity-details>
  </ng-container>
</ng-container>