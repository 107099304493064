import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/shared/guards/auth.service';
import { ServerStatus } from 'src/app/shared/models/ServerStatus';
import { clientStamp } from 'src/dataservice_/assets/build/BuildStamp';
import { AccountService } from 'src/app/shared/services/account.service';
import { StorageService } from 'src/app/shared/services/storage.service';
import { UrlUtils } from 'src/app/shared/url.utils';
import { StatusService } from 'src/app/shared/services/status.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackBarComponent } from 'src/app/shared/components/snack-bar/snack-bar.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  constructor(
    private authService: AuthService,
    private accountService: AccountService,
    private statusService: StatusService,
    private storageService: StorageService,
    private snackBar: MatSnackBar,
    private translateService: TranslateService,
    private router: Router) { }

  public ngOnInit(): void {
    this.accountService.getProfile().subscribe(profile => {
      this.authService.setUserProfile(profile);
      if (!profile.environment) {
        this.authService.setRequestUri();
        this.authService.provision();
        return;
      }

      this.handleLoginSuccess();
    }, (error) => {
      this.snackBar.openFromComponent(SnackBarComponent,
        { duration: 5000, verticalPosition: 'top',
          data: {text: this.translateService.get('GetProfileFailedText')} });
    });
  }

  private handleLoginSuccess() {
    this.statusService.getStatus().subscribe(
      status => this.logDiagnosticInfo(status),
      () => this.logDiagnosticInfo({ region: 'N/A', buildNumber: 'N/A' }),
    );

    const returnTo = this.storageService.get('request_uri') || 'entities';
    this.router.navigateByUrl(`${UrlUtils.getBaseRouteUrl()}/${returnTo}`);
  }

  private logDiagnosticInfo(serverStatus: ServerStatus) {
    const { buildNumber, region } = serverStatus;
    const serverStamp = `${region}:${buildNumber}`;

    console.log('======= Data Service Diagnostics =========');
    console.log('Client:', clientStamp);
    console.log('Server:', serverStamp);
    console.log('==========================================');
  }
}
