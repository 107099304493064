import { HttpClient } from '@angular/common/http';
import {
  Component,
  Inject,
  Input,
  OnInit,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

import { SnackBarComponent } from 'src/app/shared/components/snack-bar/snack-bar.component';
import { EntitiesService } from 'src/app/shared/services/entities.service';
import { downloadBlob } from 'src/app/shared/utilities';

@Component({
  selector: 'app-import-partialimported-dialog',
  templateUrl: './import-partialimported-dialog.component.html',
  styleUrls: ['./import-partialimported-dialog.component.scss']
})
export class ImportPartialImportedDialogComponent implements OnInit {

  @Input() dialogTitleKey;
  @Input() dialogInfoKey;
  @Input() dialogIcon;
  @Input() iconClass;

  public notImportedText: string;
  public isDownloading = false;
  private downloadFn = downloadBlob;

  constructor(
    public dialogRef: MatDialogRef<ImportPartialImportedDialogComponent>,
    private http: HttpClient,
    private snackBar: MatSnackBar,
    private entitiesService: EntitiesService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    this.notImportedText = this.data.text;
    this.iconClass = this.data.iconClass || this.iconClass;
    this.dialogTitleKey = this.data.dialogTitleKey || this.dialogTitleKey;
    this.dialogInfoKey = this.data.dialogInfoKey || this.dialogInfoKey;
    this.dialogIcon = this.data.dialogIcon || this.dialogIcon;
  }

  public onClose() {
    this.dialogRef.close();
  }

  public onDownload() {
    this.isDownloading = true;
    const url = `api/EntityService/entity/${this.data.entityId}/bulk-upload-errors/${encodeURIComponent(this.data.errorFileLink)}`;
    this.entitiesService.downloadErrorFile(this.data.entityId, this.data.errorFileLink).subscribe(
      (blob) => {
        this.downloadFn(blob);
      },
      (error) => {
        this.snackBar.openFromComponent(SnackBarComponent, { duration: 5000, verticalPosition: 'top', data: error });
      }
    );
    this.dialogRef.close();
  }

  public getFileName(): string | undefined {
    const fileName = this.data.errorFileLink.split('/').pop();
    return fileName;
  }

}
