import { TelemetryService } from './../services/telemetry.service';
import { map, takeUntil } from 'rxjs/operators';
import { StorageService } from './../services/storage.service';
import { User, UserManager } from 'oidc-client-ts';
import { Injectable, OnDestroy } from '@angular/core';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { UserLoginProfile } from '../models/user';
import { AccountService } from '../services/account.service';
import { Router } from '@angular/router';
import { UrlUtils } from '../url.utils';
import { EnvironmentsService } from '../services/environments.service';
import { Constants } from '../constants';
import { Events } from '../models/events';
import { UiPathAuthService } from '@uipath/auth-angular';
import { filterFalsy } from '../observable.utils';

export { User };

@Injectable({
  providedIn: 'root',
})
export class AuthService implements OnDestroy {

  public get userProfile(): UserLoginProfile | null {
    return this.userProfileDummy$.value;
  }

  constructor(
    private storageService: StorageService,
    public accountService: AccountService,
    private router: Router,
    private environmentsService: EnvironmentsService,
    private telemetryService: TelemetryService,
    private authService: UiPathAuthService
  ) {

  }
  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  private static ClientId = 'dab206b7-0388-41c9-ab0f-208c102cf8c2';
  private static Scope = 'openid profile DataServiceApiUserAccess IdentityServerApi Directory offline_access';
  private static GrantType = 'code';
  private static OidcMetadataUrlPath = '.well-known/openid-configuration';

  public readonly userProfile$: Observable<UserLoginProfile | null>;

  public userProfileDummy$ = new BehaviorSubject<UserLoginProfile | null>(null);

  private userManager: UserManager;

  private readonly _destroyed$ = new Subject<void>();

  public setUserProfile(userProfile: UserLoginProfile) {
    this.userProfileDummy$.next(userProfile);
    if (userProfile) {
      this.telemetryService.setUserId(userProfile);
    } else {
      this.telemetryService.clearUserId();
    }
  }

  public getUser(): Observable<User> {
    return this.authService.user$;
  }

  public logout(reason: string) {
    this.telemetryService.logEvent(Events.UserLogout, { reason });
    this.authService.signoutRedirect().pipe(takeUntil(this._destroyed$),).subscribe();
  }

  public async handleLoginCallback() {
    this.authService.user$.subscribe( user => {
      if(user){
        this.accountService.getProfile().subscribe(profile => {
          this.setUserProfile(profile);
          if (this.userProfile.environment) {
            const returnTo = this.storageService.get('request_uri') || 'entities';
            this.router.navigateByUrl(`${UrlUtils.getBaseRouteUrl()}/${returnTo}`);
          } else {
            this.provision();
          }
          this.storageService.remove('request_uri');
        });
      }
    }
    );

  }

  public provision(): void {
    const returnTo = this.storageService.get('request_uri') || 'entities';
    this.telemetryService.logEvent(Events.ProvisionStart);

    // todo first run: select pland and provision enviroment
    // 1-Basic, 0-Standard
    this.environmentsService.createEnvironment().subscribe(
      (guid) => {
        const checkStatus = setInterval(() => {
          this.environmentsService.getEnvironment().subscribe(environment => {
            if (environment) {

              this.telemetryService.logEvent(Events.ProvisionEnd);

              this.accountService.getProfile().subscribe(profile => {
                this.setUserProfile(profile);
                clearInterval(checkStatus);
                // for first run
                sessionStorage.setItem(Constants.FirstRun, 'true');

                this.router.navigateByUrl('/', { skipLocationChange: true }).then(
                  () =>
                    this.router.navigate([`${UrlUtils.getBaseRouteUrl()}/${returnTo}`]));
              });
            }
          });
        }, 4 * 1000);
      }, (error) => {
        // todo handle error
      });
  }

  public setRequestUri(): void {
    const context = UrlUtils.getRequestContext();
    if (context.requestUri !== '' && context.requestUri.indexOf('oidc-signin') < 0
      && context.requestUri.indexOf('login') < 0) {
      this.storageService.set('request_uri', context.requestUri);
    }
  }

  getAccessToken$$(): Observable<string> {
    return this.authService.accessToken$.pipe(filterFalsy());
  }

  getAccessTokenPayload$$(): Observable<string> {
    return this.authService.user$
      .pipe(
        filterFalsy(),
        map(u => u.profile['prt_id'] as string),
      );
  }
}
