import { Component, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { Observable, Subscription } from 'rxjs';
import { ConfirmationDialogComponent, ConfirmationType } from 'src/app/shared/components/confirmation-dialog/confirmation-dialog.component';
import { ErrorDialogComponent } from 'src/app/shared/components/error-dialog/error-dialog.component';
import { Constants } from 'src/app/shared/constants';
import { AuthService } from 'src/app/shared/guards/auth.service';
import { Entity, EntityType } from 'src/app/shared/models/entity';
import { SortData } from 'src/app/shared/models/environment';
import { ErrorType, Events } from 'src/app/shared/models/events';
import { EntitiesService } from 'src/app/shared/services/entities.service';
import {
  getLanguage,
  getDialogLauncherFocusHelper,
  getTableSortAriaHelper,
  isSystemEntity,
  invalidIdentifiersToString,
  populateInvalidIdentifiers,
  isReservedEntity
} from 'src/app/shared/utilities';
import { FeatureKey, FeatureService } from 'src/app/shared/services/feature.service';
import { TelemetryService } from 'src/app/shared/services/telemetry.service';
import { UserService } from 'src/app/shared/services/user.service';
import { UrlUtils } from 'src/app/shared/url.utils';
import { SnackBarComponent, SnackBarMode } from 'src/app/shared/components/snack-bar/snack-bar.component';
import { ExportEntitiesDialogComponent } from 'src/app/pages/entities/export-entities-dialog/export-entities-dialog.component';
import { Pagination } from '../entity-details/data/list-data/list-data.component';
import {
  EnableRbacDialogComponent,
  EnableRbacDialogMode,
  EnableRbacDialogOutput } from '../entity-details/list-fields/enable-rbac-dialog/enable-rbac-dialog.component';
import { EntityDialogMode, EntityDialogResult, UpsertEntityDialogComponent } from '../upsert-entity-dialog/upsert-entity-dialog.component';
import { ImportErrorDialogComponent } from '../import-error-dialog/import-error-dialog.component';
import { ImportService, ImportSchema } from 'src/app/shared/services/import.service';
import { isChoiceSetKeyValidate, isChoiceSetSchemaValidate, isChoiceSetValueValidate, isEntityKeyValidate,
  isEntitySchemaValidate, isEntityValueValidate } from 'src/app/shared/models/exportEntity';
import { SelectionModel } from '@angular/cdk/collections';
import { ImportOptions } from '../import/entities-import/entities-import.component';
import { Breadcrumb } from 'src/app/shared/components/breadcrumb/breadcrumb.component';
import { Template, TemplateData, TemplateService } from 'src/app/shared/services/template.service';

export interface ImportSelections {
  entities: { [key: string]: number };
  choicesets?: { [key: string]: number };
}

export enum ViewMode {
  listEntities = 'listEntities',
  listChoicesets = 'listChoicesets',
  listFields = 'listFields',
  entitiesImport = 'entitiesImport',
  fieldsConflict = 'fieldsConflict'
}

@Component({
  selector: 'list-entities',
  templateUrl: './list-entities.component.html',
  styleUrls: ['./list-entities.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class ListEntitiesComponent implements OnInit, OnDestroy {
  @Input('mode')
  public mode = ViewMode.listEntities;

  @Input('importSchema')
  public importSchema: ImportSchema;

  public entities: Entity[] = [];
  public choicesets: Entity[] = [];
  public conflictEntities: Entity[] = [];
  public conflictChoicesets: Entity[] = [];
  public nonConflictEntities: Entity[] = [];
  public nonConflictChoicesets: Entity[] = [];
  public dataSource: MatTableDataSource<Entity>;
  public displayedColumns: string[];
  public ariaHelper = getTableSortAriaHelper();
  public snapshot: Entity[] = [];
  public searchValue: string;
  public isLoadingEntity = false;
  public pagination: Pagination;
  public showNoPermission = false;
  public isDataLevelPermissionEnabled = false;
  public isAdminConsistencyEnabled = false;
  public isCdmEnabled = false;
  public UpdateEntityScheme: number = Constants.UpdateEntityScheme;
  public focusHelper = getDialogLauncherFocusHelper();
  public fileToUpload: File;
  public isSelectedFileDismissed: boolean;
  public importSelections: ImportSelections;
  public selection = new SelectionModel<any>(true, []);
  public showOnlyConflictEntities = false;
  public invalidIdentifiers: {[key: string]: string[]}[] = [];
  public invalidIdentifiersIsDismissed = false;
  public totalNumInvalidIdentifiers = 0;
  public breadcrumbs: Breadcrumb[];
  public currentTemplates: Template[] = [];
  public appliedTemplate: Template;

  public selectedTemplates: Template[] = [];
  public sortDirection: string;
  public sortActive: string;

  public isSystemEntity = isSystemEntity;
  public isReservedEntity = isReservedEntity;

  private subscription: Subscription = new Subscription();
  private deleteConfirmText: Observable<string>;
  private isEntityDetailsLoaded = false;
  private SIZE_LIMIT_IN_BYTE = 31457280; // file size limit is 30MB

  @ViewChild(MatSort, { static: true }) private sort: MatSort;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private entitiesService: EntitiesService,
    public userService: UserService,
    private telemetryService: TelemetryService,
    private translateService: TranslateService,
    private authService: AuthService,
    private featureService: FeatureService,
    private snackBar: MatSnackBar,
    public importService: ImportService,
    public templateService: TemplateService,
  ) { }

  public ngOnInit() {
    const fsSub1 = this.featureService.isEnabled(FeatureKey.EntityDataRecordPermission)
      .subscribe(result => {
        this.isDataLevelPermissionEnabled = result;
      });

    const fsSub2 = this.featureService.isEnabled(FeatureKey.AdminConsistency)
      .subscribe(result => {
        this.isAdminConsistencyEnabled = result;
      });

    this.subscription.add(fsSub1);
    this.subscription.add(fsSub2);

    this.breadcrumbs = [{ key: 'EntityDetails.Title' }];
    if (this.importSchema) {
      this.telemetryService.startTimedEvent(Events.EntitiesConflictView);
      this.entities = this.concatArrays(this.importSchema['nonConflictEntities'], this.importSchema['conflictEntities']);
      this.choicesets = this.concatArrays(this.importSchema['nonConflictChoicesets'], this.importSchema['conflictChoicesets']);
      this.conflictEntities = this.importSchema['conflictEntities'];
      this.conflictChoicesets = this.importSchema['conflictChoicesets'];
      this.nonConflictEntities = this.importSchema['nonConflictEntities'];
      this.nonConflictChoicesets = this.importSchema['nonConflictChoicesets'];
      this.setPageProperties(this.nonConflictEntities.concat(this.conflictEntities));
    }
    if (!this.isEntitiesImport()) {
      this.telemetryService.startTimedEvent(Events.EntityListView);
      this.deleteConfirmText = this.translateService.get('ListEntities.DeleteConfirm');
      this.displayedColumns = ['displayName', 'name', 'description', 'fieldNumber', 'recordCount', 'updateTime', 'edit'];
      this.isLoadingEntity = true;
      const fsSub3 = this.featureService.isEnabled(FeatureKey.Cdm)
        .subscribe(result => {
          this.isCdmEnabled = result;
          if (this.isCdmEnabled) {
            this.templateService.getTemplates().subscribe(templates => {
              this.currentTemplates = templates;
              this.loadEntitiesStats();
              this.loadChoiceSets();
            });
          } else {
            this.loadEntitiesStats();
            this.loadChoiceSets();
          }
        });
      this.subscription.add(fsSub3);
      this.telemetryService.endTimedEvent(Events.EntityListView);
    } else {
      this.displayedColumns = ['displayName', 'name', 'description', 'fieldNumber', 'action'];
      this.telemetryService.endTimedEvent(Events.EntitiesConflictView);
      this.importService.currentSelection.subscribe(options => this.importSelections = options);
    }

    this.translateService.get('BrowserTitleForEntities').subscribe(s => {
      document.title = s;
    });

    this.invalidIdentifiersIsDismissed = sessionStorage.getItem(Constants.InvalidIdentifiersFirstDimiss) === 'true';
  }

  public onClickRefresh() {
    this.loadEntities(true);
  }

  public ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  public isEntitiesImport(): boolean {
    return this.mode === ViewMode.entitiesImport;
  }

  public toggleAllTemplates() {
    if (this.selectedTemplates.length !== this.currentTemplates.length) {
      this.selectedTemplates = [...this.currentTemplates];
    } else {
      this.selectedTemplates = [];
    }
    this.syncDataRecord();
  }

  public isTemplateSelected(template: Template) {
    return this.selectedTemplates.includes(template);
  }

  public toggleTemplate(template: Template) {
    if (this.isTemplateSelected(template)) {
      this.selectedTemplates.splice(this.selectedTemplates.indexOf(template), 1);
    } else {
      this.selectedTemplates.push(template);
    }
    this.syncDataRecord();
  }

  public onClearAllTemplates() {
    this.selectedTemplates = [];
    this.syncDataRecord();
  }

  public removeTemplate(template: Template) {
    this.selectedTemplates.splice(this.selectedTemplates.indexOf(template), 1);
    this.syncDataRecord();
  }

  public sortData(sort: SortData) {
    this.ariaHelper.setSortOrder(sort.active, sort.direction);
    this.sortDirection = sort.direction;
    this.sortActive = sort.active;

    if (sort.direction === '') {
      this.entities = this.snapshot.filter(entity => this.entities.includes(entity));
      this.dataSource = new MatTableDataSource(this.getPagedData());
      return;
    }
    this.entities.sort((item1, item2) => {
      const value1 = item1[sort.active];
      const value2 = item2[sort.active];

      if (sort.active === 'fieldNumber') {
        const value1 = item1.fields ? item1.fields.length : 0;
        const value2 = item2.fields ? item2.fields.length : 0;
        return sort.direction === 'asc' ? value1 - value2 : value2 - value1;
      } else if (typeof value1 === 'string' && typeof value2 === 'string') {
        // Sorting for strings
        const comparison = value1.toLocaleLowerCase().localeCompare(value2.toLocaleLowerCase());
        return sort.direction === 'asc' ? comparison : -comparison;
      } else {
        // Sorting for numbers
        const comparison = value1 - value2;
        return sort.direction === 'asc' ? comparison : -comparison;
      }
    });
    this.dataSource = new MatTableDataSource(this.getPagedData());
  }

  public onClickEntity(row: Entity) {
    if (!this.isEntitiesImport()) {
      this.router.navigate([row.id], {
        relativeTo: this.route,
        queryParams: this.isCdmEnabled ? { templateId: this.appliedTemplate?.id } : null,
        state: {
          entities: this.isEntityDetailsLoaded ? this.entities : null,
          entity: row,
          choicesets: this.choicesets
        }
      });
    }
  }

  public onClickChoicesets() {
    this.router.navigate([`${UrlUtils.getBaseRouteUrl()}/choicesets`]);
  }

  public toggleShowOnlyConflictEntities() {
    this.showOnlyConflictEntities = !this.showOnlyConflictEntities;
    if (this.showOnlyConflictEntities) {
      this.setPageProperties(this.conflictEntities);
    } else {
      this.setPageProperties(this.entities);
    }
  }

  public onUpsertEntity(entity?: Entity) {
    this.telemetryService.logEvent(Events.CreateEntityStart);

    if (!entity) {
      this.telemetryService.logEvent(Events.CreateNewEntityClick);
    }

    const dialogRef = this.dialog.open(UpsertEntityDialogComponent,
      {
        width: 'auto',
        height: '100%',
        position: { top: '0', right: '0' },
        disableClose: true,
        panelClass: 'custom-sidepane',
        data: {
          mode: entity ? EntityDialogMode.update :
            EntityDialogMode.create, entity: entity || new Entity('abc', '', EntityType.custom, '', '')
        }
      });

    const sub = dialogRef.afterClosed().subscribe((result: EntityDialogResult) => {
      if (result?.succeed) {
        this.telemetryService.logEvent(Events.CreateEntityEnd);
        this.loadEntities(true);
      }
    });
    this.subscription.add(sub);
  }

  public onClickUseInStudio() {
    this.telemetryService.logEvent(Events.UseInStudioButtonClick);
    const language = getLanguage();
    const url = `https://docs.uipath.com/studio/lang-${language}/docs/importing-entities`;
    window.open(url, '_blank');
  }

  public onExportEntities() {
    const dialogRef = this.dialog.open(ExportEntitiesDialogComponent,
      {
        width: '505px',
        height: '260px',
        autoFocus: false
      });
  }

  public isExportImportVisible() {
    return this.featureService.isEnabled(FeatureKey.EntitySchemaImportEmport);
  }

  public isConflictEntity(element: Entity) {
    return this.conflictEntities.map(item => item.name).includes(element.name);
  }

  public importFile() {
    this.isLoadingEntity = true;
    if (this.fileToUpload) {
      this.importService.entityComparison(
        this.fileToUpload, this.isCdmEnabled ? this.snapshot : this.entities, this.choicesets).then(() => {
        const list = this.importService.getImportSchema();
        if (list['conflictEntities'].length === 0 && list['nonConflictEntities'].length === 0) {
          this.importService.importSchema(this.fileToUpload).subscribe(res => {
            this.isLoadingEntity = false;
            this.snackBar.openFromComponent(
              SnackBarComponent,
              {
                duration: 5000,
                verticalPosition: 'top',
                data: { mode: SnackBarMode.success, text: this.translateService.get('Import.SuccessText') }
              });
            this.loadEntities(true);
          }, (err) => {
            this.isLoadingEntity = false;
            this.snackBar.openFromComponent(SnackBarComponent,
              { duration: 5000, verticalPosition: 'top', data: err });
          });
        } else {
          this.isLoadingEntity = false;
          this.entities = this.concatArrays(list['nonConflictEntities'], list['conflictEntities']);
          this.choicesets = this.concatArrays(list['nonConflictChoicesets'], list['conflictChoicesets']);
          this.conflictEntities = list['conflictEntities'];
          this.conflictChoicesets = list['conflictChoicesets'];
          this.nonConflictEntities = list['nonConflictEntities'];
          this.nonConflictChoicesets = list['nonConflictChociesets'];

          this.router.navigate([`${UrlUtils.getBaseRouteUrl()}/entitiesimport`], {
            state: {
              mode: 'entitiesImport',
              importSchema: list,
              file: this.fileToUpload
            }
          });
        }
      });
      this.isLoadingEntity = false;
    }
  }

  public storeSelection(value: number, entity: Entity) {
    if (value === ImportOptions.keep &&  this.importSelections['entities'][entity.name] !== ImportOptions.keep) {
      this.importService.shiftNumEntity(1);
    } else if (value === ImportOptions.skip && this.importSelections['entities'][entity.name] !== ImportOptions.skip) {
      this.importService.shiftNumEntity(-1);
    }

    this.importSelections['entities'][entity.name] = value;
    this.importService.updateImportFile(this.importSelections);
  }

  public toggleRow(event: any, row: any) {
    this.selection.toggle(row);
    this.storeSelection(event.checked ? ImportOptions.keep : ImportOptions.skip, row);
  }

  public async onFileSelected(files: File[]) {
    this.isLoadingEntity = true;
    this.fileToUpload = null;
    let errorText = null;
    const isFileValidated = await this.validateFileContent(files[0]);
    if (!files[0]) {
      errorText = this.translateService.get('Import.OtherErrorText');
    } else if (!this.isSizeValid(files[0])) {
      errorText = this.translateService.get('Import.SizeErrorText');
    } else if (!this.validateFile(files[0].name)) {
      errorText = this.translateService.get('Import.FormatErrorText');
    } else if (isFileValidated != null) {
      errorText = isFileValidated;
    }

    if (errorText != null) {
      this.isLoadingEntity = false;
      this.dialog.open(ImportErrorDialogComponent,
        {
          width: '450px',
          autoFocus: false,
          data: {
            text: errorText
          }
        });
      this.fileToUpload = null;
      return;
    }

    this.fileToUpload = files[0];
    this.importFile();
  }

  private validateFile(name: string) {
    const ext = name.lastIndexOf('.') < 0 ? '' : name.substring(name.lastIndexOf('.') + 1);
    return ext.toLowerCase() === 'json';
  }

  private validateFileContent(file: File): Promise<Observable<string>> {
    return new Promise<Observable<string>>((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        try {
          const fileRead = JSON.parse(e.target.result as string);
          if (!isEntityKeyValidate(fileRead)) {
            resolve(this.translateService.get('Import.MissingEntityKeyErrorText'));
            return;
          }
          if (!isEntityValueValidate(fileRead)) {
            resolve(this.translateService.get('Import.InvalidEntityValueErrorText'));
            return;
          }

          fileRead.entities.forEach(entity => {
            if (!isEntitySchemaValidate(entity)) {
              resolve(this.translateService.get('Import.InvalidEntitySchemaText'));
              return;
            }
          });

          if (isChoiceSetKeyValidate(fileRead)) {
            if (!isChoiceSetValueValidate(fileRead)) {
              resolve(this.translateService.get('Import.InvalidChoiceSetValueErrorText'));
              return;
            }

            fileRead.choicesets.forEach(entity => {
              if (!isChoiceSetSchemaValidate(entity)) {
                resolve(this.translateService.get('Import.InvalidChoiceSetSchemaText'));
                return;
              }
            });
          }

          resolve(null);
        } catch (err) {
          this.telemetryService.logEvent(Events.ImportFileParseError);
          resolve(this.translateService.get('Import.OtherErrorText'));
        }
      };
      reader.readAsText(file);
    });
  }

  public isSizeValid(file: File): boolean {
    return file.size < this.SIZE_LIMIT_IN_BYTE;
  }

  public selectFile(fileList: FileList): void {
    this.telemetryService.logEvent(Events.ImportButtonClick);
    this.isSelectedFileDismissed = false;
    const files: File[] = [];
    for (let i = 0; i < fileList.length; i++) {
      files.push(fileList[i]);
    }
    this.onFileSelected(files);
  }

  public getTemplate(templateId: number) {
    return this.currentTemplates.find(template => template.id === templateId);
  }

  private resetView() {
    this.currentTemplates = this.currentTemplates.filter(template => template.id !== this.appliedTemplate.id);
    const noTemplate = { id: undefined, name: 'ListEntities.NoTemplate' } as Template;
    this.appliedTemplate = noTemplate;
    this.displayedColumns = ['displayName', 'name', 'description', 'fieldNumber', 'recordCount', 'updateTime', 'edit'];
  }

  public onDeleteEntity(entity: Entity) {
    let dialogRef;
    let deleteType = 'entity';
    if (entity.categoryId && entity.isModelReserved) {
      deleteType = 'template';
      const template = this.currentTemplates.find(template => template.id === entity.categoryId);
      dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        width: 'auto',
        data: {
          title: this.translateService.get('ListEntities.DeleteTemplateTitle', { templateName: template.name }),
          yesText: this.translateService.get('DeleteText'),
          noText: this.translateService.get('CancelText'),
          confirmationText: this.translateService.get('ListEntities.DeleteTemplateDescription', { templateName: template.name }),
          doubleConfirmNeeded: true,
          doubleConfirmText: template.name,
          asyncFunc: () => this.templateService.deleteTemplate(entity.categoryId)
        }
      });
    } else {
      const template = this.currentTemplates.find(template => template.id === entity.categoryId);
      dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        width: 'auto',
        data: {
          title: this.translateService.get('ListEntities.DeleteEntityTitle', { value: entity.displayName }),
          yesText: this.translateService.get('DeleteText'),
          noText: this.translateService.get('CancelText'),
          confirmationText: entity.categoryId ?
            this.translateService.get('ListEntities.DeleteNotModelReserved', { templateName: template.name}) : this.deleteConfirmText,
          doubleConfirmNeeded: true,
          doubleConfirmText: entity.displayName,
          asyncFunc: () => this.entitiesService.deleteEntity(entity)
        }
      });
    }

    const sub = dialogRef.afterClosed().subscribe(confirmation => {
      this.focusHelper.restoreLauncherFocus();

      if (confirmation.result === ConfirmationType.yes) {
        if (deleteType === 'template') {
          this.resetView();
        }
        this.loadEntities(true);
      }
    }, (error) => {
      dialogRef.close();
      this.dialog.open(ErrorDialogComponent, { width: 'auto', data: error });
    });
    this.subscription.add(sub);
  }

  public onSearch() {
    this.syncDataRecord();
  }

  public onSelectTemplate(template: Template) {
    this.appliedTemplate = template;
    if (this.appliedTemplate.id) {
      this.displayedColumns = ['displayName', 'name', 'isModelReserved', 'description', 'fieldNumber', 'recordCount', 'updateTime', 'edit'];
    } else {
      this.displayedColumns = ['displayName', 'name', 'description', 'fieldNumber', 'recordCount', 'updateTime', 'edit'];

    }
    this.syncDataRecord();
  }

  public onChangePagination(pagination: Pagination) {
    this.pagination = pagination;
    this.dataSource = new MatTableDataSource(this.getPagedData());
  }

  public convertTime(time: Date): string {
    return moment(time).startOf('day').fromNow();
  }

  public getEditTooltip(entity: Entity, tooltipKey: string): Observable<string> {
    if (entity.entityType === EntityType.system) {
      return this.translateService.get('ListEntities.CannotModifySystemEntity');
    }
    return this.translateService.get(tooltipKey);
  }

  public getDeleteTooltip(entity: Entity, tooltipKey: string): Observable<string> {
    if (entity.entityType === EntityType.system) {
      return this.translateService.get('ListEntities.CannotModifySystemEntity');
    }

    if (entity.isModelReserved) {
      return this.translateService.get('ListEntities.DeleteTemplateOption');
    }

    return this.translateService.get(tooltipKey);
  }

  public onEnableRoleBasedDataAccess(entity: Entity, enabled: boolean) {
    const dialogRef = this.dialog.open(EnableRbacDialogComponent, {
      width: 'auto',
      height: '100%',
      position: { top: '0', right: '0' },
      disableClose: true,
      panelClass: 'custom-sidepane',
      data: {
        entityId: entity.id,
        displayName: entity.displayName,
        mode: enabled ? EnableRbacDialogMode.enable : EnableRbacDialogMode.disable,
        enabledContent: this.translateService.get('EnableRbacDialog.Content'),
        disabledContent: this.translateService.get('EnableRbacDialog.DisableContent'),
        onOpenLearnMore: this.onOpenLearnMore,
      }
    });

    const sub = dialogRef.afterClosed().subscribe((result: any) => {
      this.focusHelper.restoreLauncherFocus();

      if (result.data.mode === EnableRbacDialogMode.enable && result.result === EnableRbacDialogOutput.succeed) {
        // pop succeed snackbar
        this.snackBar.openFromComponent(SnackBarComponent,
          {
            duration: 5000,
            verticalPosition: 'top',
            panelClass: 'success-snack-container',
            data: {
              mode: SnackBarMode.success,
              moreAction: true,
              text: this.translateService.get('EnableSuccessText')
            },
          });

        this.dataSource = new MatTableDataSource(this.entities);
      } else if (result.data.mode === EnableRbacDialogMode.disable && result.result === EnableRbacDialogOutput.succeed) {
        this.snackBar.openFromComponent(SnackBarComponent,
          {
            duration: 5000,
            verticalPosition: 'top',
            data: {
              mode: SnackBarMode.success,
              moreAction: false,
              isDismissable: true,
              text: this.translateService.get('DisableSuccessText')
            },
          });

        this.dataSource = new MatTableDataSource(this.entities);
      } else if (result.result === EnableRbacDialogOutput.failed) {
        // pop failed snackbar
        this.snackBar.openFromComponent(SnackBarComponent,
          { duration: 5000, verticalPosition: 'top', data: { text: this.translateService.get('EnableFailedText') }, });
      }
    });
    this.subscription.add(sub);
  }

  public onOpenLearnMore() {
    // todo nanz update external link
    const url = UrlUtils.getUserManagementDataAccessDocLink();
    window.open(url, '_blank');
  }

  public isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.entities.length;
    return numSelected === numRows;
  }

  public masterToggle() {
    if (this.isAllSelected()) {
      this.selection.clear();
      this.entities.forEach(row => this.storeSelection(2, row));
    } else {
      this.entities.forEach(row => {
        this.selection.select(row);
        this.storeSelection(1, row);
      });
    }
  }

  public checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.Id}`;
  }

  public getInvalidIdentifiers(): Observable<string> {
    return this.translateService.get('Record.InvalidIdentifiers',
      {
        numIdentifiers: this.totalNumInvalidIdentifiers,
        value: invalidIdentifiersToString(this.invalidIdentifiers)
      });
  }

  public invalidIdentifierOnDimiss() {
    this.invalidIdentifiersIsDismissed = true;
    sessionStorage.setItem(Constants.InvalidIdentifiersFirstDimiss, 'true');
  }

  public shouldShowInvalidIdentifiers() {
    return this.invalidIdentifiers.length > 0 && !this.invalidIdentifiersIsDismissed;
  }

  private loadEntitiesStats() {
    this.isLoadingEntity = true;
    const sub = this.entitiesService.getEntitiesStats().subscribe(result => {
      if (this.isCdmEnabled) {
        this.currentTemplates = this.currentTemplates.filter(template => result.some(entity => entity.categoryId === template.id));
        if (this.currentTemplates.length > 0) {
          const noTemplate = { id: undefined, name: 'ListEntities.NoTemplate' } as Template;
          this.currentTemplates = [noTemplate, ...this.currentTemplates];
          this.appliedTemplate = noTemplate;
          // if url has templateId, filter on template
          this.route.queryParams.subscribe(params => {
            const templateId = Number(params['templateId']);
            if (templateId) {
              this.displayedColumns =
                ['displayName', 'name', 'isModelReserved', 'description', 'fieldNumber', 'recordCount', 'updateTime', 'edit'];
              this.appliedTemplate = this.getTemplate(templateId);
            }
          });
        }
      }
      this.isLoadingEntity = false;
      this.setPageProperties(result);
      this.telemetryService.endTimedEvent(Events.EntityListView);
      this.telemetryService.endTimedEvent(Events.AppLoad);
      this.loadEntities();
    }, (result) => {
      this.handleErrorResult(result);
    });
    this.subscription.add(sub);
  }

  private loadEntities(shouldSpin?: boolean) {
    if (this.isEntitiesImport()) {
      return;
    }
    if (shouldSpin) {
      this.isLoadingEntity = true;
    }
    const sub = this.entitiesService.getEntities().subscribe(result => {
      this.isLoadingEntity = false;
      this.setPageProperties(result);
      this.isEntityDetailsLoaded = true;
      if (result.length > 0) {
        const invalidIdentifierResult = populateInvalidIdentifiers(result);
        this.invalidIdentifiers = invalidIdentifierResult.invalidIdentifiers;
        this.totalNumInvalidIdentifiers = invalidIdentifierResult.totalCount;
      }
    }, (result) => {
      this.handleErrorResult(result);
    });
    this.subscription.add(sub);
  }

  private handleErrorResult(result: any): void {
    this.telemetryService.logError({ type: ErrorType.warning, message: `list entities faild ${JSON.stringify(result)}` });
    const errorResp = result && JSON.stringify(result);
    if (errorResp && errorResp.indexOf('403') > -1
      && errorResp.indexOf(Constants.BandwidthThrottleMessage) < 0) {
      this.showNoPermission = true;
    } else if (result.errorText && result.errorText.indexOf('SyntaxError: Unexpected token') > -1) {
      // todo - nanz this is a workaround for bug https://uipath.atlassian.net/browse/OR-26046
      // we cannnot get response status error code unless GATEWAY return Access-Control-Allow-Origin: * in response
      location.reload();
    } else if (result.errorText && result.errorText.indexOf('Tenant has not provisioned environment') > -1) {
      this.authService.provision();
    } else if (result && JSON.stringify(result).indexOf('401') < 0) {
      this.dialog.open(ErrorDialogComponent, { width: 'auto', data: result });
    }
  }

  private setPageProperties(entities: Entity[]): void {
    if (this.showOnlyConflictEntities) {
      this.conflictEntities = this.sortByDisplayName(entities);
    } else {
      this.entities = this.sortByDisplayName(entities);
    }
    this.dataSource = new MatTableDataSource(this.getPagedData());
    this.dataSource.sort = this.sort;
    this.snapshot = [...this.entities];
    this.syncDataRecord();
  }

  private syncDataRecord() {
    this.entities = [...this.snapshot];

    if (this.searchValue) {
      this.entities = this.entities.filter(item => {
        const searchFields = this.extractSearchableValues(item);
        return JSON.stringify(searchFields).toLocaleLowerCase().indexOf(this.searchValue.toLocaleLowerCase()) >= 0;
      });
    }

    if (this.isCdmEnabled && this.appliedTemplate) {
      this.entities = this.entities.filter(entity => entity.categoryId === this.appliedTemplate.id);
    }

    this.dataSource = new MatTableDataSource(this.getPagedData());
    this.dataSource.sort = this.sort;

    if (this.sortDirection === 'asc' || this.sortDirection === 'desc') {
      this.sortData({ active: this.sortActive, direction: this.sortDirection });
    }
  }

  private loadChoiceSets() {
    this.entitiesService.getChoiceSet().subscribe(result => {
      this.choicesets = result;
    });
  }

  private sortByDisplayName(result: any) {
    return [...result].sort((e1, e2) => e1['displayName']?.toLocaleLowerCase().localeCompare(e2['displayName']?.toLocaleLowerCase()));
  }

  private getPagedData(): Entity[] {
    if (this.pagination) {
      const start = this.pagination.pageIndex * this.pagination.pageSize;
      const end = start + this.pagination.pageSize;
      if (this.showOnlyConflictEntities) {
        return [...this.conflictEntities].slice(start, end);
      } else {
        return [...this.entities].slice(start, end);
      }
    } else {
      if (this.showOnlyConflictEntities) {
        return [...this.conflictEntities].slice(0, 20);
      } else {
        return [...this.entities].slice(0, 20);
      }
    }
  }

  private extractSearchableValues(data: any): string[] {
    const result = [];
    result.push(data['description']);
    result.push(data['displayName']);
    result.push(data['name']);
    return result;
  }

  private concatArrays(...arrays) {
    return [].concat(...arrays.filter(Array.isArray));
  }

  private getDataModel(templateId: number): () => Observable<TemplateData> {
    return () => this.templateService.getDataModel(templateId);
  }
}
