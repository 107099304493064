<div class="dialog">
  <mat-icon class="font-large top-corner-button" (click)="onClose()">
    close
</mat-icon>
<mat-dialog-title>
  <div fxLayout="row" class="title bold">
      <mat-icon [ngClass]="iconClass" class="material-icons md-36 standard-icon">{{ dialogIcon }}</mat-icon>
      {{ dialogTitleKey | translate }}
  </div>
</mat-dialog-title>

<mat-dialog-content>
  <div fxLayout="column" fxLayoutAlign="space-around">
    {{ dialogInfoKey | translate }}
  </div>
</mat-dialog-content>
<div fxLayout="row" fxLayoutAlign="end" class="button-section">
  <button mat-flat-button color="primary" type="submit" class="cancel btn bold" (click)="onClose()">
    {{'CloseButtonText' | translate}}</button>
  <button mat-flat-button color="primary" type="submit" class="btn bold long-button" (click)="onDownload()">
    <mat-icon class="material-icons">get_app</mat-icon>
    {{'Import.OutputFileDownload' | translate}}</button>

</div>
</div>
