import { AfterViewInit, Component, EventEmitter, Input, OnInit, OnDestroy, Output, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Constants } from 'src/app/shared/constants';
import { ChoiceSetMember } from 'src/app/shared/models/choiceset';
import {
  Entity, EntityDataQueryResponseWithExpansion, Expansion,
  FilterLogicalOperator, SortOption
} from 'src/app/shared/models/entity';
import { Field } from 'src/app/shared/models/field';
import { EntitiesDataService, FilterFieldEntry } from 'src/app/shared/services/entities-data.service';
import { UrlUtils } from 'src/app/shared/url.utils';
import * as urljoin from 'url-join';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-record-flyout',
  templateUrl: './record-flyout.component.html',
  styleUrls: ['./record-flyout.component.scss']
})
export class RecordFlyoutComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input('entity')
  public entity: Entity;

  @Input('field')
  public field: any;

  @Input('value')
  public value: any;

  @Input('isEditMode')
  public isEditMode: boolean;

  @Input('originField')
  public originField: Field;

  @Input('queryUserMode')
  public queryUserMode?: boolean;

  @Output()
  public selectedEvent: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  public blurEvent: EventEmitter<void> = new EventEmitter<void>();

  public members: ChoiceSetMember[] = [];
  public isLoadingMembers = false;
  public formControl = new UntypedFormControl();
  public currentSearchValue: string;
  public debounceTimeMs = 1000;
  public searchValue: string;
  public isSearchMode: boolean;

  private subscription: Subscription = new Subscription();
  private start: number;
  private limit: number;
  private noMoreResult = false;
  private searchDecouncer: Subject<string> = new Subject();

  @ViewChild('matSelect') matSelect;
  @ViewChild('customPanel') customPanel;

  constructor(
    private entitiesDataService: EntitiesDataService,
    private userService: UserService,
  ) { }

  ngOnInit(): void {
    this.start = 0;
    this.limit = 20;
    this.formControl.setValue(this.value);
    this.loadMembers();
    this.setupSearchDebouncer();
    if (!!this.value) {
      this.searchValue = this.value[this.field.definition.name] + ' -- ' + this.value['Id'];
    }
  }

  public ngAfterViewInit(): void {
    // https://blog.angular-university.io/angular-debugging/
    setTimeout(() => {
      if (this.matSelect) {
        this.matSelect.open();
      }
    }, 10);
  }

  public onClick() {
    if (this.matSelect) {
      this.matSelect.open();
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  public get selectedMemberIds(): any {
    return this.value;
  }

  public onSelect(event: any): void {
    if (event.isUserInput) {
      this.selectedEvent.emit(event.source.value);
    }
  }

  public onClearValue(): void {
    if (!this.originField.isRequired) {
      this.formControl.setValue(null);
      this.selectedEvent.emit(null);
    }

    this.matSelect.close();
  }

  public onClose(): void {
    if (this.formControl.value) {
      this.selectedEvent.emit(this.formControl.value);
    }
    this.blurEvent.emit();
  }

  public onOpenNewTab() {
    const url = urljoin(UrlUtils.getBaseRouteUrl(), `/entities/${this.entity.id}?view=${Constants.DataView}`);
    window.open(url, '_blank');
  }

  public onSearchInputChange(value: string) {
    this.searchDecouncer.next(value.trim());
    this.currentSearchValue = value;
    this.noMoreResult = false;
  }

  public searchRecord(start: number) {
    if (this.queryUserMode) {
      this.searchUser();
    } else {
      this.searchByQueryData(start);
    }
  }

  public handleScroll() {
    this.customPanel.nativeElement.addEventListener('scroll', event => {
      this.handleScrollEvent(event);
    });
  }

  private handleScrollEvent(event: any): void {
    const isScrolledToBottom = event
      && (event.target.scrollHeight - event.target.scrollTop) === event.target.clientHeight;
    if (!this.noMoreResult && isScrolledToBottom) {
      if (this.isLoadingMembers) {
        return;
      }
      this.isEditMode = true;
      if (!!this.currentSearchValue) {
        this.searchRecord(this.start);
      } else {
        this.loadMembers();
      }
    }
  }

  private searchUser() {
    this.userService.searchUserV2(this.currentSearchValue)
      .subscribe(result => {
        this.handleQueryResponse(result);
      });
  }

  private searchByQueryData(start: number) {
    const queryFields: Field[] = [...this.getQueryFields()];
    const filterFields: FilterFieldEntry[] = [];
    queryFields.forEach(f => {
      f.value = this.currentSearchValue;
      const filterField: FilterFieldEntry = {
        field: f,
        operator: 'contains',
        filteredOptionFields: []
      };
      filterFields.push(filterField);
    });
    this.start = start;
    this.isSearchMode = true;
    this.isLoadingMembers = true;
    this.queryData(this.entity, queryFields, filterFields, [], []);
  }

  private setupSearchDebouncer(): void {
    this.searchDecouncer.pipe(
      debounceTime(this.debounceTimeMs),
      distinctUntilChanged(),
    ).subscribe((value: string) => {

      this.searchRecord(0);
    });
  }

  private loadMembers(): void {
    const queryFields: Field[] = [...this.getQueryFields()];
    const filterFields: FilterFieldEntry[] = [];
    this.isSearchMode = false;
    this.isLoadingMembers = true;
    this.queryData(this.entity, queryFields, filterFields, [], []);
  }

  private getQueryFields(): Field[] {
    const primaryField: Field = new Field();
    primaryField.name = 'Id';
    const relationField: Field = this.field.definition as Field;
    return [primaryField, relationField];
  }

  private queryData(
    entity: Entity,
    queryFields: Field[],
    filterFields: FilterFieldEntry[],
    sortOptions: SortOption[],
    expansions: Expansion[]): void {

    const getRelationSub = this.entitiesDataService.queryEntityData(
      entity,
      queryFields,
      filterFields,
      FilterLogicalOperator.or,
      [],
      this.start,
      this.limit,
      sortOptions,
      expansions).subscribe(
      result => {
        this.handleQueryResponse(result);
      }
    );
    this.subscription.add(getRelationSub);
  }

  private handleQueryResponse(result: EntityDataQueryResponseWithExpansion) {
    this.isLoadingMembers = false;
    this.isSearchMode = false;

    if (result.totalRecordCount <= this.start + this.limit) {
      this.noMoreResult = true;
    }
    if (this.start === 0) {
      this.members = result.value as any;
      this.start = result.value.length;
    } else if (this.start < result.totalRecordCount) {
      this.members = this.members.concat(result.value as any);
      this.start += result.value.length;
    }
  }
}
