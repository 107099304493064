<div class="side-pane-container">
    <div mat-dialog-title class="font-large rbac-title bold">{{getTitleText() | async}}</div>

    <div mat-dialog-content class="dialog-content font-medium">
        <ng-container *ngIf="isEnableMode()">
            {{data.enabledContent | async}}
        </ng-container>
        <ng-container *ngIf="!isEnableMode()">
            {{data.disabledContent | async}}
        </ng-container>
        <div class="learn-more bold" (click)="onOpenLearnMore()" tabindex="0" (keydown.enter)="onOpenLearnMore()">
            <a>
                {{'LearnMoreText' | translate}}
                <mat-icon matSuffix class="material-icons md-16 launch-tab-button-icon">launch
                </mat-icon>
            </a>
        </div>
    </div>

    <div fxLayout="row" fxLayoutAlign="end strech" class="bottom-btn">
        <button *ngIf="!isLoading" mat-flat-button color="primary" type="submit" class="btn bold btn-text"
            (click)="onSubmit()">{{ (isEnableMode() ? 'EnableButtonText' : 'DisableButtonText') | translate}}</button>
        <div class="btn" *ngIf="isLoading">
            <mat-spinner role="alert" [attr.aria-label]="'Loading' | translate" diameter="24" class="spinner"></mat-spinner>
        </div>
        <button mat-stroked-button color="primary" class="btn close bold" (click)="onDismiss()">
            {{'CancelButtonText' | translate}}
        </button>
    </div>
    <button mat-icon-button (click)="onDismiss()" class="close-button" [attr.aria-label]="'CloseButtonText' | translate">
        <mat-icon class="font-large">
            close
        </mat-icon>
    </button>
</div>