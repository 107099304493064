<div class="dialog">
  <div fxLayout="column" fxLayoutAlign="space-around" class="container">
    <h1 mat-dialog-title class="title bold">{{'ExportEntitiesDialog.Title' | translate}}</h1>
  </div>
  <mat-icon class="font-large top-corner-button" (click)="onClose()">
    close
  </mat-icon>
  <mat-dialog-content>
    <p>{{'ExportEntitiesDialog.Description' | translate}}
      <a rel="noopener noreferrer" routerlink="" (click)="onClickHere()" tabindex="0"
        (keydown.enter)="onClickHere()">{{'ExportEntitiesDialog.Hyperlink' | translate}}</a>.
    </p>
  </mat-dialog-content>
  <mat-dialog-actions class="button-section">
    <button mat-stroked-button color="primary" class="btn close bold" (click)="onClose()">{{'CancelButtonText' |
      translate}}</button>
    <button mat-flat-button color="primary" type="submit" class="btn bold" (click)="onDownload()"
      [disabled]="isDownloading">
      <div class="button-text">{{'ExportEntitiesDialog.Confirm' | translate}}</div>
    </button>
  </mat-dialog-actions>
</div>