import { Constants } from './constants';
import { Entity, EntityType, InvalidIdentifiers } from './models/entity';
import { FieldType } from './models/field';

export function isSystemEntity(entity: Entity | undefined): boolean {
  return entity && entity.entityType === EntityType.system;
}

export function isReservedEntity(entity: Entity | undefined): boolean {
  return entity?.isModelReserved;
}

export function isSystemUserEntity(entity: Entity | undefined): boolean {
  return isSystemEntity(entity) && entity.name === Constants.SystemUserEntity;
}

export function isUnImportTypeRequired(entity: Entity | undefined): boolean {
  if (entity === undefined) {return false;}
  return entity.fields.some(field => ((field.type === FieldType.relationship)
  || (field.type === FieldType.choiceSetMultiple)
  || (field.type === FieldType.choiceSetSingle)
  || (field.type === FieldType.autonumber)) && (field.isRequired)
  && ((field.displayName!=="CreatedBy") && (field.displayName!=="UpdatedBy")));
}

export function convertBooleanToYesNo(value: boolean): string {
  if (value === true) {
    return 'yes';
  } else if (value === false) {
    return 'no';
  } else {
    return null;
  }
}

export function convertYesNoToBoolean(value: string): boolean {
  if (value === 'yes') {
    return true;
  } else if (value === 'no') {
    return false;
  } else {
    return null;
  }
}

export function isNumber(value: any) {
  return /^\d+$/.test(value);
}

export function isNullOrUndefined(value: any): boolean {
  return value === null || value === undefined;
}

export function getLanguage(): string {
  return localStorage.getItem(Constants.PortalLang) || localStorage.getItem(Constants.Lang) || Constants.En;
}

interface DialogLauncherFocusHelper {
    onLauncherClick(event: MouseEvent): void;
    restoreLauncherFocus(): void;
}

export function getDialogLauncherFocusHelper(): DialogLauncherFocusHelper {
  let launcher: HTMLElement;

  const onLauncherClick = (event: MouseEvent) => {
    launcher = event.target as HTMLElement;
  };

  const restoreLauncherFocus = () => {
    if (launcher && launcher.focus) {
      launcher.focus();
    }
  };

  return { onLauncherClick, restoreLauncherFocus };
}

export function downloadBlob(blob: Blob): void {
  const a = document.createElement('a');
  const url = window.URL.createObjectURL(blob);
  a.href = url;
  a.download = this.getFileName();
  document.body.appendChild(a);
  a.click();
  a.remove();
  window.URL.revokeObjectURL(url);
}

interface TableSortAriaHelper {
    setSortOrder(column: string, order: string): void;
    getOrderKey(column: string): 'SortNone' | 'SortAscending' | 'SortDescending';
}

export function getTableSortAriaHelper(): TableSortAriaHelper {
  let ascendingColumn = '';
  let descendingColumn = '';

  const setSortOrder = (column: string, order: string) => {
    ascendingColumn = '';
    descendingColumn = '';

    if (order === 'asc') {
      ascendingColumn = column;
    } else if (order === 'desc') {
      descendingColumn = column;
    }
  };

  const getOrderKey = (column: string) => {
    if (column === ascendingColumn) {
      return 'SortAscending';
    }

    if (column === descendingColumn) {
      return 'SortDescending';
    }

    return 'SortNone';
  };

  return { setSortOrder, getOrderKey };
}

export function transformFieldType(fieldType: FieldType, displayType: string): string {
  if (displayType === Constants.File) {
    return 'File';
  }

  switch (fieldType) {
  case FieldType.boolean:
    return 'Yes/No';
  case FieldType.text:
    return 'Text';
  case FieldType.number:
    return 'Number';
  case FieldType.dateTime:
    return 'Date-Time';
  case FieldType.autonumber:
    return 'Auto-number';
  case FieldType.relationship:
    return 'Relationship';
  case FieldType.date:
    return 'Date';
  case FieldType.uniqueid:
    return 'UniqueId';
  case FieldType.choiceSetSingle:
  case FieldType.choiceSetMultiple:
    return 'Choice Set';
  default:
    return '';
  }
}

export function populateInvalidIdentifiers(records: Entity[]) {
  const invalidIdentifiers: {[key: string]: string[]}[] = [];
  let totalNumInvalidIdentifiers = 0;
  records.forEach((record) => {
    if (record.invalidIdentifiers && record.invalidIdentifiers.length > 0) {
      const key = record.name;
      const nameToIdentifiersMap = {};
      nameToIdentifiersMap[key] = record.invalidIdentifiers;
      totalNumInvalidIdentifiers += record.invalidIdentifiers.length;
      invalidIdentifiers.push(nameToIdentifiersMap);
    }
  });

  return {
    invalidIdentifiers,
    totalCount: totalNumInvalidIdentifiers
  } as InvalidIdentifiers;
}

export function invalidIdentifiersToString(invalidIdentifiers: {[key: string]: string[]}[]) {
  const INVALID_IDENTIFIER_MAX_LENGTH = 200;
  let result = '';
  let keyIndex = 0;
  let exceedLimit = false;
  for (let identifyIndex = 0; identifyIndex < invalidIdentifiers.length; identifyIndex++) {
    for (const [key, value] of Object.entries(invalidIdentifiers[identifyIndex])) {
      result += '{' + key + ': [';
      result += appendValue(value);
      result += ']}';
      if (keyIndex !== invalidIdentifiers.length - 1) {
        result += ', ';
      }
      keyIndex++;
    }
    if (result.length > INVALID_IDENTIFIER_MAX_LENGTH) {
      exceedLimit = true;
      break;
    }
  };
  if (exceedLimit) {
    result = result.substring(0, INVALID_IDENTIFIER_MAX_LENGTH - 3);
    result += '...';
  }
  return result;
}

export function appendValue(value: string[]) {
  let result = '';
  for (let valueIndex = 0; valueIndex < value.length; valueIndex++) {
    result += value[valueIndex];
    if (valueIndex !== value.length - 1) {
      result += ', ';
    }
  }
  return result;
}
