import { Constants } from 'src/app/shared/constants';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { TelemetryService } from '../services/telemetry.service';
import { ErrorType } from '../models/events';
import { Injectable, Injector } from '@angular/core';
import { AuthService } from '../guards/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { UrlUtils } from '../url.utils';

export interface ErrorData {
  errorText?: string;
  errorTitle?: string;
  disableCloseBtn?: boolean;
  traceId?: string;
}

@Injectable({
  providedIn: 'root'
})
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(
    private telemetryService: TelemetryService,
    private authService: AuthService,
    private injector: Injector
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(request)
      .pipe(
        catchError((response: HttpErrorResponse) => {
          const errorData: ErrorData = {};

          let traceId = response.headers && response.headers['traceid'];
          if (!traceId) {
            // client side error might not arrive server yet, hence might not have trace id header
            const traceparent = request.headers[Constants.Traceparent];
            if (traceparent) {
              traceId = traceparent.split('-')[1];
            }
          }
          errorData.traceId = traceId;
          if (response.error) {
            errorData.traceId = errorData.traceId || response.error['traceId'];
            if (response.error instanceof ErrorEvent) {
              // client-side error
              errorData.errorText = `Error: ${response.error.error}`;
            } else {
              // server-side error
              if (response.status === 401 && JSON.stringify(response.error).toUpperCase().indexOf(Constants.UserTokenExpiredError) >= 0) {
                return;
              } else if (response.status === 440) {
                errorData.disableCloseBtn = true;
                const context = UrlUtils.getRequestContext();
                const logoutRoute = `${context.account}/${context.tenant}/${context.serviceType}/logout`;

                this.injector.get(TranslateService).get([
                  'ErrorDialog.SessionExpired',
                  'HttpInterceptor.Error440',
                  'HttpInterceptor.ClickHere',
                  'HttpInterceptor.SignOut'])
                  .subscribe(vals => {
                    errorData.errorTitle = vals['ErrorDialog.SessionExpired'];
                    errorData.errorText = `${vals['HttpInterceptor.Error440']} <a href='${logoutRoute}'>
                                            ${vals['HttpInterceptor.ClickHere']}</a> ${vals['HttpInterceptor.SignOut']}`;
                  });

                setTimeout(() => {
                  this.authService.logout('Received status 440');
                }, 10000);

                return;
              } else if (response.status >= 500) {
                this.injector.get(TranslateService).get('HttpInterceptor.Error500').subscribe(val => {
                  errorData.errorText = val;
                });
                return throwError(errorData);
              }
              errorData.errorText = `Error Code: ${response.status}\nMessage: ${response.error.error}`;
            }
          }
          this.telemetryService.logError({ message: errorData.errorText, type: ErrorType.fatal, data: JSON.stringify(response) });
          return throwError(errorData);
        })
      );
  }
}
