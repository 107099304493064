import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserLoginProfile } from '../models/user';
import { TelemetryService } from './telemetry.service';
import { Events } from '../models/events';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AccountService {

  constructor(
    private http: HttpClient,
    private telemetryService: TelemetryService
  ) { }

  public getProfile(): Observable<UserLoginProfile> {
    this.telemetryService.logEvent(Events.GetProfile);
    return this.http.get<UserLoginProfile>('/api/Account/Profile');
  }
}
