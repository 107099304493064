import { Directive, ElementRef, HostListener, Input, ViewContainerRef, OnDestroy } from '@angular/core';
import { Overlay, OverlayPositionBuilder, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { HtmlTooltipComponent } from './html-tooltip.component';

@Directive({
  selector: '[appHtmlTooltip]'
})
export class HtmlTooltipDirective implements OnDestroy {
  @Input('appHtmlTooltip') text = '';
  private overlayRef: OverlayRef;

  constructor(
    private overlayPositionBuilder: OverlayPositionBuilder,
    private elementRef: ElementRef,
    private overlay: Overlay,
    private viewContainerRef: ViewContainerRef
  ) {}

  @HostListener('mouseenter') show() {
    // Check if there is text and it's not just whitespace
    if (!this.text.trim()) {
      return; // If there's no text, don't show the tooltip
    }

    if (this.overlayRef) {
      return; // Tooltip already shown, no action needed
    }

    const positionStrategy = this.overlayPositionBuilder
      .flexibleConnectedTo(this.elementRef)
      .withPositions([{ originX: 'center', originY: 'bottom', overlayX: 'center', overlayY: 'top' }]);

    this.overlayRef = this.overlay.create({ positionStrategy });
    const tooltipPortal = new ComponentPortal(HtmlTooltipComponent, this.viewContainerRef);
    const tooltipRef = this.overlayRef.attach(tooltipPortal);
    tooltipRef.instance.text = this.text;
  }

  @HostListener('mouseleave') hide() {
    if (this.overlayRef) {
      this.overlayRef.dispose();
      this.overlayRef = null;
    }
  }

  ngOnDestroy() {
    if (this.overlayRef) {
      this.overlayRef.dispose();
    }
  }
}
