import { formatDate } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { FormArray, FormControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { ChoiceSetMember } from 'src/app/shared/models/choiceset';
import { FilterLogicalOperator } from 'src/app/shared/models/entity';
import { FieldType } from 'src/app/shared/models/field';
import { isNumber } from 'src/app/shared/utilities';
import { dateTimeYearValidator, dateYearValidator, guidValidator, numberValidator } from 'src/app/shared/validators';

export enum AllAnyKey {
  all = "AdvancedSearch.All",
  any = "AdvancedSearch.Any",
}

export enum AndOrKey {
  and = "AdvancedSearch.And",
  or = "AdvancedSearch.Or",
}

export enum Condition {
  contains = "contains",
  not_contains = "notcontains",
  equals = "equals",
  does_not_equal = "doesnotequal",
  ends_with = "endswith",
  starts_with = "startswith",
  is_empty = "isempty",
  is_not_empty = "isnotempty",
  is_more_than = "ismorethan",
  is_less_than = "islessthan",
  is_not_more_than = "isnotmorethan",
  is_not_less_than = "isnotlessthan",
  is_in = "isin",
  is_not_in = "isnotin",
}

export enum ConditionKey {
  contains = "AdvancedSearch.Contains",
  notcontains = "AdvancedSearch.NotContains",
  equals = "AdvancedSearch.Equals",
  doesnotequal = "AdvancedSearch.DoesNotEqual",
  endswith = "AdvancedSearch.EndsWith",
  startswith = "AdvancedSearch.StartsWith",
  isempty = "AdvancedSearch.IsEmpty",
  isnotempty = "AdvancedSearch.IsNotEmpty",
  ismorethan = "AdvancedSearch.IsMoreThan",
  islessthan = "AdvancedSearch.IsLessThan",
  isnotmorethan = "AdvancedSearch.IsNotMoreThan",
  isnotlessthan = "AdvancedSearch.IsNotLessThan",
  isin = "AdvancedSearch.IsIn",
  isnotin = "AdvancedSearch.IsNotIn",
}

export enum ColumnOptionKey {
  UpdateTime = "ListData.UpdateTime",
  CreateTime = "ListData.CreateTime",
  UpdatedBy = "ListData.UpdatedBy",
  CreatedBy = "ListData.CreatedBy",
}

export interface QueryRecord {
  name: string;
  fieldType: FieldType;
  condition: Condition;
  searchValue: any;
}

export interface ChoiceSetOption {
  name: string;
  numberId: number;
}

@Component({
  selector: 'advanced-search',
  templateUrl: './advanced-search.component.html',
  styleUrls: ['./advanced-search.component.scss']
})
export class AdvancedSearchComponent {
  public isLoading = false;
  public searchColumns = [];
  public operators = Object.values(AllAnyKey);
  public searchForm: UntypedFormGroup;
  public choiceSetMemberMap: Map<string, ChoiceSetMember[]>;
  public fieldTypeConditionMap = this.buildFieldTypeConditionMap();
  public choiceSetMap: Map<string, ChoiceSetOption[]>;

  private MAX_NUM_CONDITIONS = 10;
  private MAX_NUM_ADDITIONAL_SEARCH_VALUES = 4;

  constructor(
    public dialogRef: MatDialogRef<AdvancedSearchComponent>,
    private fb: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.searchColumns = data.searchColumns;
    this.choiceSetMemberMap = data.choiceSetMemberMap;
    if (this.choiceSetMemberMap) {
      this.choiceSetMap = this.buildChoiceSetMap();
    }
    this.searchForm = this.fb.group({
      operator: AllAnyKey.all,
      queryRecords: this.fb.array([]),
    });
    if (data.queryRecords) {
      this.searchForm.patchValue({operator: data.operator === 0 ? AllAnyKey.all : AllAnyKey.any});
      this.convertQueryRecordsToForm(data.queryRecords);
    } else {
      this.addQueryRecord();
    }
  }

  public onDismiss(): void {
    this.dialogRef.close();
  }

  public getConditionTranslationKey(condition: Condition): string {
    return ConditionKey[condition];
  }

  private specialCasesMapping = {
    "UpdatedBy.CreateTime": ["ListData.UpdatedBy", "ListData.CreateTime"],
    "UpdatedBy.Email": ["ListData.UpdatedBy", "Email"],
    "UpdatedBy.Id": ["ListData.UpdatedBy", "Id"],
    "UpdatedBy.IsActive": ["ListData.UpdatedBy", "AdvancedSearch.IsActive"],
    "UpdatedBy.Name": ["ListData.UpdatedBy", "ListField.Name"],
    "UpdatedBy.Type": ["ListData.UpdatedBy", "ListField.Type"],
    "UpdatedBy.UpdateTime": ["ListData.UpdatedBy", "ListData.UpdateTime"],
    "CreatedBy.CreateTime": ["ListData.CreatedBy", "ListData.CreateTime"],
    "CreatedBy.Email": ["ListData.CreatedBy", "AdvancedSearch.Email"],
    "CreatedBy.Id": ["ListData.CreatedBy", "Id"],
    "CreatedBy.IsActive": ["ListData.CreatedBy", "AdvancedSearch.IsActive"],
    "CreatedBy.Name": ["ListData.CreatedBy", "ListField.Name"],
    "CreatedBy.Type": ["ListData.CreatedBy", "ListField.Type"],
    "CreatedBy.UpdateTime": ["ListData.CreatedBy", "ListData.UpdateTime"],
  };

  public getOptionTranslationKey(option: string): string[] {
    if (this.specialCasesMapping.hasOwnProperty(option)) {
      return this.specialCasesMapping[option];
    }

    const translationKey = ColumnOptionKey[option];
    return translationKey !== undefined ? [translationKey] : [option];
  }

  private buildFieldTypeConditionMap(): Map<FieldType, []> {
    const conditionMap = {} as Map<FieldType, []>;
    Object.values(FieldType).forEach((fieldType) =>
    {
      switch(fieldType) {
      case (FieldType.uniqueid):
        conditionMap[fieldType] = Object.values(Condition);
        break;
      case (FieldType.dateTime):
      case (FieldType.date):
        conditionMap[fieldType] = [
          Condition.equals,
          Condition.does_not_equal,
          Condition.is_empty,
          Condition.is_not_empty,
          Condition.is_more_than,
          Condition.is_less_than,
          Condition.is_not_more_than,
          Condition.is_not_less_than,
          Condition.is_in,
          Condition.is_not_in
        ];
        break;
      case (FieldType.autonumber):
      case (FieldType.number):
        conditionMap[fieldType] = [
          Condition.contains,
          Condition.not_contains,
          Condition.equals,
          Condition.does_not_equal,
          Condition.is_empty,
          Condition.is_not_empty,
          Condition.is_more_than,
          Condition.is_less_than,
          Condition.is_not_more_than,
          Condition.is_not_less_than,
          Condition.is_in,
          Condition.is_not_in
        ];
        break;
      case (FieldType.text):
        conditionMap[fieldType] = [
          Condition.contains,
          Condition.not_contains,
          Condition.equals,
          Condition.does_not_equal,
          Condition.ends_with,
          Condition.starts_with,
          Condition.is_empty,
          Condition.is_not_empty,
          Condition.is_in,
          Condition.is_not_in
        ];
        break;
      case(FieldType.boolean):
        conditionMap[fieldType] = [
          Condition.equals,
          Condition.does_not_equal,
          Condition.is_empty,
          Condition.is_not_empty
        ];
        break;
      case (FieldType.relationship):
      case (FieldType.file):
        conditionMap[fieldType] = [
          Condition.is_empty,
          Condition.is_not_empty
        ];
        break;
      case (FieldType.choiceSetSingle):
      case (FieldType.choiceSetMultiple):
        conditionMap[fieldType] = [
          Condition.contains,
          Condition.not_contains,
          Condition.equals,
          Condition.does_not_equal,
          Condition.is_empty,
          Condition.is_not_empty,
        ];
        break;
      default:
        break;
      }
    });
    return conditionMap;
  }

  public addQueryRecord(): void {
    this.queryRecords.push(this.fb.group(
      {
        name: new FormControl(undefined, [Validators.required]),
        fieldType: new FormControl(undefined),
        condition: new FormControl({value: undefined, disabled: true}, [Validators.required]),
        searchValue: new FormControl({value: undefined, disabled: true}, [Validators.required]),
        additionalSearchValues: new FormArray([]),
      }
    ));
  }

  get queryRecords(): FormArray {
    return this.searchForm.get('queryRecords') as FormArray;
  }

  public isMaxConditionsExceeded() {
    return this.queryRecords.length >= this.MAX_NUM_CONDITIONS;
  }

  public removeQueryRecord(index: number): void {
    if (this.isInOrNotInCondition(this.queryRecords.at(index).value['condition'])) {
      const additionalValues = this.queryRecords.at(index).value['additionalSearchValues'] as FormArray;
      if (additionalValues.length > 0) {
        this.queryRecords.at(index).patchValue({searchValue: additionalValues[0]['additionalSearchValue']});
        (this.queryRecords.at(index).get('additionalSearchValues') as FormArray).removeAt(0);
        return;
      }
    }
    this.queryRecords.removeAt(index);
  }

  public shouldDisplayNameRequiredError(queryRecord: any): boolean {
    return queryRecord['controls'].name.errors
        && queryRecord['controls'].name.errors.required
        && queryRecord['controls'].name.touched;
  }

  public shouldDisplayConditionRequiredError(queryRecord: any): boolean {
    return queryRecord['controls'].condition.errors
        && queryRecord['controls'].condition.errors.required
        && queryRecord['controls'].condition.touched;
  }

  public shouldDisplayNumberError(value: any): boolean {
    return value.errors
        && value.errors.numberError
        && value.touched;
  }

  public shouldDisplayGUIDError(value: any): boolean {
    return value.errors
        && value.errors.guidError
        && value.touched;
  }

  public shouldDisplayValueRequiredError(value: any): boolean {
    return value.errors
        && value.errors.required
        && value.touched;
  }

  public shouldDisplayInvalidDateError(value: any): boolean {
    return value.errors
      && (value.errors.dateYearError || value.errors.matDatepickerParse)
      && value.touched;
  }

  public shouldDisplayInvalidDateTimeError(value: any): boolean {
    return value.errors
      && value.errors.dateTimeYearError
      && value.touched;
  }

  public updateField(index: number, event: MatSelectChange) {
    this.queryRecords.at(index).patchValue({condition: undefined, searchValue: undefined});
    this.queryRecords.at(index).get('searchValue').disable();
    this.queryRecords.at(index).markAsUntouched();
    this.queryRecords.at(index).patchValue({fieldType: this.searchColumns.find((item) =>
      item.name === event.value
    ).fieldType});
    const fieldType = this.queryRecords.at(index).value['fieldType'];
    if (this.isDateOrDateTime(fieldType) || fieldType === FieldType.boolean) {
      this.queryRecords.at(index).patchValue({searchValue: undefined});
    }
    this.queryRecords.controls[index].get('searchValue').setValidators(this.getValidators(fieldType));
    if (fieldType === FieldType.autonumber || fieldType === FieldType.number) {
      this.queryRecords.controls[index].get('searchValue').setValidators([Validators.required, numberValidator(true)]);
    } else if (fieldType === FieldType.dateTime) {
      this.queryRecords.controls[index].get('searchValue').setValidators([Validators.required, dateTimeYearValidator()]);
    } else if (fieldType === FieldType.date) {
      this.queryRecords.controls[index].get('searchValue').setValidators([Validators.required, dateYearValidator()]);
    } else {
      this.queryRecords.controls[index].get('searchValue').setValidators([Validators.required]);
    }
    this.queryRecords.at(index).get('condition').enable();
    (this.queryRecords.at(index).get('additionalSearchValues') as FormArray).clear();
  }

  private getValidators(fieldType: FieldType) {
    if (fieldType === FieldType.autonumber || fieldType === FieldType.number) {
      return [Validators.required, numberValidator(true)];
    } else if (fieldType === FieldType.dateTime) {
      return [Validators.required, dateTimeYearValidator()];
    } else if (fieldType === FieldType.date) {
      return [Validators.required, dateYearValidator()];
    } else {
      return [Validators.required];
    }
  }

  public updateCondition(index: number) {
    const fieldType = this.queryRecords.at(index).value['fieldType'];
    if (fieldType === FieldType.uniqueid) {
      if (this.checkConditionIsEqualOrNotEqual(this.queryRecords.at(index).value['condition'])) {
        this.queryRecords.controls[index].get('searchValue').setValidators([Validators.required, guidValidator(true)]);
        this.queryRecords.controls[index].get('searchValue').updateValueAndValidity();
      } else {
        this.queryRecords.controls[index].get('searchValue').setValidators([Validators.required]);
        this.queryRecords.controls[index].get('searchValue').updateValueAndValidity();
      }
    }

    if (this.shouldBeDisabled(this.queryRecords.at(index).value['condition'])) {
      this.queryRecords.at(index).get('searchValue').disable();
      this.queryRecords.at(index).patchValue({searchValue: undefined});
    } else {
      this.queryRecords.at(index).get('searchValue').enable();
    }
    (this.queryRecords.at(index).get('additionalSearchValues') as FormArray).clear();
  }

  public shouldBeDisabled(condition: string): boolean {
    return condition === Condition.is_empty || condition === Condition.is_not_empty;
  }

  public getFieldType(name: string) {
    if (!name) {
      return '';
    }
    return this.searchColumns.find(field => field.name === name).fieldType;
  }

  public getChoiceSetId(name: string) {
    return this.searchColumns.find(field => field.name === name).choiceSetId;
  }

  public buildChoiceSetMap(): Map<string, ChoiceSetOption[]> {
    const choiceSetMap = new Map();
    Object.keys(this.choiceSetMemberMap).forEach(choiceSetId => {
      const choiceSetOptions = [];
      this.choiceSetMemberMap[choiceSetId].forEach((choiceSetMember) => {
        choiceSetOptions.push({name: choiceSetMember.Name, numberId: choiceSetMember.NumberId} as ChoiceSetOption);
      });
      choiceSetMap[choiceSetId] = choiceSetOptions;

    });
    return choiceSetMap;
  }

  public onSubmit() {
    if (this.validateQuery()) {
      this.dialogRef.close(
        {
          operator: this.searchForm.value['operator'] === AllAnyKey.all ? FilterLogicalOperator.and : FilterLogicalOperator.or,
          queryRecords: this.convertFormToQueryRecords(this.queryRecords),
        }
      );
    }
  }

  public validateQuery(): boolean {
    if (this.queryRecords.controls.length === 0) {
      return false;
    }

    return this.searchForm.valid;
  }

  public disableEnterForForm(event: any) {
    return event.key !== 'Enter';
  }

  public isInOrNotInCondition(condition: string): boolean {
    return condition === Condition.is_in || condition === Condition.is_not_in;
  }

  public addAdditionalSearchValue(index: number) {
    const values = this.queryRecords.controls[index].get('additionalSearchValues') as FormArray;
    values.push(this.fb.group({additionalSearchValue: new FormControl(
      undefined, this.getValidators(this.queryRecords.controls[index].value['fieldType']))}));
  }

  public removeAdditionalSearchValue(recordIndex: number, valueIndex: number) {
    const values = this.queryRecords.controls[recordIndex].get('additionalSearchValues') as FormArray;
    values.removeAt(valueIndex);
  }

  public isMaxSearchValuesExceeded(recordIndex: number) {
    const values = this.queryRecords.controls[recordIndex].get('additionalSearchValues') as FormArray;
    return values.length >= this.MAX_NUM_ADDITIONAL_SEARCH_VALUES;
  }

  private checkConditionIsEqualOrNotEqual(condition: string) {
    if (condition) {
      return condition === Condition.equals || condition === Condition.does_not_equal;
    }
    return false;
  }

  private convertFormToQueryRecords(queryRecords: FormArray) {
    const convertedQueryRecords = [];
    for (const queryRecord of queryRecords.controls) {
      let searchValue = queryRecord.value['searchValue'];
      if (queryRecord.value['fieldType'] === FieldType.choiceSetMultiple &&
          (queryRecord.value['condition'] === Condition.contains || queryRecord.value['condition'] === Condition.not_contains)) {
        const arr = queryRecord.value['searchValue'].sort();
        searchValue = arr[0];
        for (let i = 1; i < arr.length; i++) {
          searchValue += ',' + arr[i];
        }
      }
      if (this.isInOrNotInCondition(queryRecord.value['condition']) && queryRecord.value['searchValue']) {
        searchValue = this.convertValuesToArray(
          queryRecord.value['searchValue'], queryRecord.value['additionalSearchValues'], queryRecord.value['fieldType']);
      }

      if (this.isDateOrDateTime(
        queryRecord.value['fieldType']) && !this.isInOrNotInCondition(queryRecord.value['condition']) && queryRecord.value['searchValue']) {
        searchValue = this.convertDateToLocal(queryRecord.value['searchValue']);
      }

      convertedQueryRecords.push({
        name: queryRecord.value['name'],
        fieldType: queryRecord.value['fieldType'],
        condition: queryRecord.value['condition'],
        searchValue,
      } as QueryRecord);
    }
    return convertedQueryRecords;
  }

  private isDateOrDateTime(type: FieldType) {
    return type === FieldType.date || type === FieldType.dateTime;
  }

  private convertDateToLocal(value: any) {
    const date = new Date(value);
    return new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toISOString();
  }

  private reverseConvertDateToLocal(value: any) {
    const date = new Date(value);
    return new Date(date.getTime() + (date.getTimezoneOffset() * 60000)).toISOString();
  }

  private convertValuesToArray(searchValue: string, additionalSearchValues: any[], fieldType: FieldType) {
    let result = '[\"';
    if (this.isDateOrDateTime(fieldType)) {
      result = '[\"' + this.convertDateToLocal(searchValue) + '\"';
      additionalSearchValues.forEach((record) => result += ',\"' + this.convertDateToLocal(record['additionalSearchValue']) + '\"');
      result += ']';
    } else {
      result = '[\"' + searchValue + '\"';
      additionalSearchValues.forEach((record) => result += ',\"' + record['additionalSearchValue'] + '\"');
      result += ']';
    }
    return result;
  }

  private convertQueryRecordsToForm(savedQueryRecords: QueryRecord[]) {
    savedQueryRecords.forEach((queryRecord) => {
      const validators = [Validators.required];
      if (queryRecord.fieldType === FieldType.autonumber || queryRecord.fieldType === FieldType.number) {
        validators.push(numberValidator());
      }
      if (queryRecord.fieldType === FieldType.uniqueid) {
        validators.push(guidValidator());
      }
      if (queryRecord.fieldType === FieldType.date) {
        validators.push(dateYearValidator());
      }
      if (queryRecord.fieldType === FieldType.dateTime) {
        validators.push(dateTimeYearValidator());
      }
      let searchValue = queryRecord.searchValue;
      if (queryRecord.fieldType === FieldType.boolean) {
        if (queryRecord.searchValue) {
          searchValue = 'Yes';
        } else {
          searchValue = 'No';
        }
      }
      this.queryRecords.push(this.fb.group(
        {
          name: new FormControl(queryRecord.name, [Validators.required]),
          fieldType: new FormControl(queryRecord.fieldType),
          condition: new FormControl(queryRecord.condition, [Validators.required]),
          searchValue: new FormControl(
            {
              value: this.getValue(
                this.getSearchValue(searchValue, queryRecord.condition, queryRecord.fieldType), queryRecord.fieldType),
              disabled: this.shouldBeDisabled(queryRecord.condition)
            },
            validators
          ),
          additionalSearchValues: this.buildFormArray(searchValue, queryRecord.condition, queryRecord.fieldType, validators)
        }
      ));
    });
  };

  private getSearchValue(searchValue: any, condition: Condition, fieldType: FieldType) {
    if (this.isInOrNotInCondition(condition)) {
      if (Array.isArray(searchValue)) {
        return searchValue[0];
      } else {
        return JSON.parse(searchValue)[0];
      }
    } else {
      return searchValue;
    }
  }
  private buildFormArray(values: any, condition: Condition, fieldType: FieldType, validators: any) {
    if (!this.isInOrNotInCondition(condition)) {
      return new FormArray([]);;
    }

    const res = new FormArray([]);
    const resValues = Array.isArray(values) ? values: JSON.parse(values);
    resValues.slice(1).forEach((val) => res.push(this.fb.group({
      additionalSearchValue: new FormControl(this.getValue(val, fieldType), validators)
    })));
    return res;
  }

  private getValue(searchValue: any, fieldType: FieldType) {
    if (fieldType === FieldType.dateTime) {
      return searchValue ? formatDate(this.reverseConvertDateToLocal(searchValue), 'yyyy-MM-ddTHH:mm', 'en') : searchValue;
    }
    if (fieldType === FieldType.choiceSetMultiple) {
      if (isNumber(searchValue)) {
        return [Number(searchValue)];
      } else {
        if (typeof(searchValue) === 'string' && searchValue.indexOf('[') >= 0) {
          searchValue = searchValue.substring(1, searchValue.length - 1);
        }
        if (!Array.isArray(searchValue) && typeof(searchValue) === 'string') {
          return searchValue.split(',').map(Number);
        }
      }
    }
    return searchValue;
  }
}
