import { Component, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable, Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackBarComponent } from '../snack-bar/snack-bar.component';
import { TranslateService } from '@ngx-translate/core';
export interface ConfirmationDialog {
  title: Observable<string>;
  confirmationText: Observable<string>;
  yesText?: Observable<string>;
  noText?: Observable<string>;
  doubleConfirmNeeded?: boolean;
  doubleConfirmText?: string;
  asyncFunc?: () => Observable<any>;
}
export enum ConfirmationType {
  yes = 'yes',
  no = 'no'
}
export interface ConfirmationOutput {
  result: ConfirmationType;
}
@Component({
  selector: 'confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent implements OnDestroy {
  public doubleConfirmValue: string;
  public isLoading = false;

  protected subscription: Subscription = new Subscription();

  constructor(
    public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    protected snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmationDialog,
    protected translateService: TranslateService,
  ) {
    dialogRef.disableClose = true;
    if (this.data.asyncFunc) {
      this.subscription.add(this.data.asyncFunc);
    }
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
  public onClick(text: string): void {
    const isConfirmed = text === 'yes' ? ConfirmationType.yes : ConfirmationType.no;

    if (this.data.asyncFunc && text === 'yes') {
      this.isLoading = true;
      this.data.asyncFunc().subscribe((resultData) => {
        this.isLoading = false;
        this.dialogRef.close({
          result: isConfirmed,
          data: resultData
        });
      }, (error) => {
        this.isLoading = false;
        this.snackBar.openFromComponent(
          SnackBarComponent,
          { duration: 5000, verticalPosition: 'top', data: error }
        );
        this.dialogRef.close({
          result: 'error'
        });
      });
    } else {
      this.dialogRef.close({
        result: isConfirmed
      });
    }
  }
  public isDoubleConfirmNeeded(): boolean {
    return this.data.doubleConfirmNeeded ? this.data.doubleConfirmNeeded : false;
  }
  public getDoubleConfirmNote(): Observable<string> {
    return this.translateService.get('Confirmation.ConfirmNote', { value: this.data.doubleConfirmText });
  }
  public isSubmitDisabled(): boolean {
    return this.isDoubleConfirmNeeded() && this.doubleConfirmValue !== this.data.doubleConfirmText;
  }
}