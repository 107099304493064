import { Injectable } from '@angular/core';
import { Entity } from '../models/entity';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

export interface Template {
  id: number;
  name: string;
  rank: number;
  description: string;
  createTime: Date;
}

export interface TemplateData {
  entities: Entity[];
  choicesets: Entity[];
  categoryId: number;
  schemaVersion: number;
}

@Injectable({
  providedIn: 'root'
})
export class TemplateService {

  private TEMPLATE_URL = '/api/CDM';

  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  constructor(
    private http: HttpClient,
  ) { }

  public getTemplates(): Observable<Template[]> {
    return this.http.get<Template[]>(`${this.TEMPLATE_URL}/categories`, this.httpOptions);
  }

  public getDataModel(categoryId: number): Observable<TemplateData> {
    return this.http.get<TemplateData>(`${this.TEMPLATE_URL}/categories/${categoryId}/datamodel`, this.httpOptions);
  }

  public applyTemplate(templateData: TemplateData) {
    return this.http.post<TemplateData>(`${this.TEMPLATE_URL}/applyTemplate`, templateData, this.httpOptions);
  }

  public deleteTemplate(categoryId: number) {
    return this.http.delete<boolean>(`${this.TEMPLATE_URL}/categories/${categoryId}/entitiesandchoicesets`, this.httpOptions);
  }

  public getCurrentAppliedEntitiesFromTemplate(categoryId: number): Observable<Entity[]> {
    return this.http.get<Entity[]>(`${this.TEMPLATE_URL}/categories/${categoryId}/entities`, this.httpOptions);
  }

  /*
  TODO: Triggers
  public addTriggers(entity: Entity, trigger: Trigger): Template[] {
    const existed = !!this.templates.find(t => t.id === '1').
    entities.find(ent => ent.name === entity.name).triggers.find(tr => tr.description === trigger.description);
    if (!existed) {
      this.templates.find(t => t.id === '1').entities.find(ent => ent.name === entity.name).triggers.push(
        trigger
      );
    }

    return this.getTemplates();
  }

  public deleteTriggers(entity: Entity, trigger: Trigger): Template[] {
    const triggers = this.templates.find(t => t.id === '1').entities.find(ent => ent.name === entity.name).triggers.filter(
      tr => tr.description !== trigger.description
    );
    this.templates.find(t => t.id === '1').entities.find(ent => ent.name === entity.name).triggers = cloneDeep(trigger);
    return this.getTemplates();
  }
  */
}
