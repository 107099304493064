import { Field } from './field';

export enum EntityType {
  system = 'SystemEntity',
  choiceSet = 'ChoiceSet',
  custom = 'Entity'
}

export class Trigger {
  constructor(
    public id?: string,
    public description?: string,
    public condition?: string,
    public operations?: string[],
  ) { }
}

export class Entity {
  constructor(
    public id: string,
    public name: string,
    public entityType: EntityType,
    public displayName?: string,
    public description?: string,
    public fields?: Field[],
    public relationships?: Field[],
    public createdBy?: string,
    public createTime?: Date,
    public updatedBy?: string,
    public updateTime?: Date,
    public recordCount?: number,
    public invalidIdentifiers?: string[],
    public isModelReserved?: boolean,
    public version?: number,
    public triggers?: Trigger[],
    public categoryId?: number,
    // for CDM choiceset
    public data?: Map<string, any>[],
    // for role-based record access
    public isRbacEnabled?: boolean,
  ) { }
}

export class EntityRequest {
  constructor(
    public id?: string,
    public displayName?: string,
    public description?: string,
    public entityDefinition?: EntityDefinition,
    public isRbacEnabled?: boolean,
  ) { }
}

export class SearchEntityResponse {
  constructor(
    public id: string,
    public name: string,
    public displayName: string,
    public description?: string,
    public referenceEntityIds?: string[],
    public isRbacEnabled?: boolean,
    public fields?: Field[],
    public isRecordLevelPermissionEnabled?: boolean,
  ) { }
}

export class EntityDeleteRequest {
  constructor(
    entityId: string
  ) { }
}

export class EntityUpdateRequest {
  constructor(
    public entityId?: string,
    public displayName?: string,
    public description?: string,
    public isRbacEnabled?: boolean,
  ) { }
}

export class BatchDeleteResponse {
  constructor(
    public failureRecords?: FailureRecord[],
    public successRecords?: Record[],
  ) { }
}

export class FailureRecord {
  constructor(
    public error: string,
    public record: Record,
  ) { }
}

export class Record {
  constructor(
    public id: string,
  ) { }
}

export class EntityDefinition {
  public name: string;
  public fields: Field[];
  public isRbacEnabled?: boolean;
}

export class QueryFilter {
  constructor(
    public fieldName: string,
    public operator: string,
    public value: string,
    public typeName: string
  ) { }
}

export class SortOption {
  constructor(
    public fieldName: string,
    public isDescending: boolean,
  ) { }
}

export class Expansion {
  constructor(
    public expandedField: string,
    public selectedFields: string[],
    public expansions?: Expansion[],
  ) { }
}

export class QueryFilterGroup {
  constructor(
    public logicalOperator: FilterLogicalOperator,
    public queryFilters?: QueryFilter[],
    public filterGroups?: QueryFilterGroup[],
  ) { }
}

export enum FilterLogicalOperator {
  and = 0,
  or = 1
}

export class EntityDataQueryRequest {
  constructor(
    public entityId: string,
    public selectedFields: string[],
    public filterGroup: QueryFilterGroup,
    public start: number,
    public limit: number,
    public sortOptions: SortOption[],
    public expansions?: Expansion[]
  ) { }
}

export class EntityDataQueryResponseWithExpansion {
  public value: Map<string, any>[];
  constructor(
    public totalRecordCount: number,
    public jsonValue: string,
  ) { }
}
export class EntityDataQueryResponse {
  constructor(
    public totalRecordCount: number,
    public value: Map<string, string>[],
    public relationValue: Map<string, string>[]
  ) { }
}

export class BulkDeleteDataRequest {
  constructor(
    public ids: string[]
  ) { }
}

export class ImportSchemaResponse {
  public AddedEntities: string[];
  public SkippedEntities: string[];
  public UpdatedEntities: EntityUpdateDetail[];
  public AddedChoicesets: string[];
  public SkippedChoicesets: string[];
  public UpdatedChoicesets: ChoiceSetUpdateDetail[];
}

export class EntityUpdateDetail {
  public Name: string;
  public UpdatedProperties: string[];
  public AddedFields: string[];
  public SkippedFields: string[];
  public UpdatedFields: string[];
  public FieldTypeConflicts: FieldTypeConflict[];
}

export class ChoiceSetUpdateDetail {
  public Name: string;
  public UpdatedProperties: string[];
  public AddedMembers: string[];
  public UpdatedMembers: string[];
}

export class FieldTypeConflict {
  public FieldName: string;
  public CurrentType: string;
  public NewType: string;
}

export interface InvalidIdentifiers {
  invalidIdentifiers: { [key: string]: string[] }[];
  totalCount: number;
}
