import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { TranslateLoader } from '@ngx-translate/core';
import { catchError, map } from 'rxjs/operators';

export class PruningTranslationLoader implements TranslateLoader {

  constructor(
        private http: HttpClient,
        private prefix: string = '/dataservice_/assets/i18n/',
        private suffix: string = '.json') {
  }

  public getTranslation(lang: string): any {

    return this.http.get(`${this.prefix}${lang}${this.suffix}`)
      .pipe(map(
        (result: object) => this.process(result)
      ), catchError((response: HttpErrorResponse) => {
        const idx = lang.indexOf('-');
        if (idx > -1) {
          lang = lang.substring(0, idx + 1) + lang.substring(idx + 1).toUpperCase();
        }
        return this.http.get(`${this.prefix}${lang}${this.suffix}`);
      }));
  }

  private process(object: object) {
    return Object.keys(object)
      .filter(key => object.hasOwnProperty(key) && object[key] !== '')
      .reduce((result, key) => (result[key] = typeof object[key] === 'object' ? this.process(object[key]) : object[key], result), {});
  }

}

export function HttpLoaderFactory(http: HttpClient) {
  return new PruningTranslationLoader(http);
}
