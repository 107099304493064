import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Entity, EntityType } from 'src/app/shared/models/entity';
import { SortData } from 'src/app/shared/models/environment';
import { Events } from 'src/app/shared/models/events';
import { EntitiesService } from 'src/app/shared/services/entities.service';
import { TelemetryService } from 'src/app/shared/services/telemetry.service';
import { UserService } from 'src/app/shared/services/user.service';
import { UrlUtils } from 'src/app/shared/url.utils';
import { Pagination } from '../../entities/entity-details/data/list-data/list-data.component';
import { EntityDialogResult } from '../../entities/upsert-entity-dialog/upsert-entity-dialog.component';
import { ChoiceSetDialogMode, UpsertChoicesetDialogComponent } from '../upsert-choiceset-dialog/upsert-choiceset-dialog.component';
import { Constants } from 'src/app/shared/constants';
import { ConfirmationDialogComponent, ConfirmationType } from 'src/app/shared/components/confirmation-dialog/confirmation-dialog.component';
import { ErrorDialogComponent } from 'src/app/shared/components/error-dialog/error-dialog.component';
import { Observable, of, Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { DependencyDialogComponent, DependencyDialogData } from 'src/app/shared/components/dependency-dialog/dependency-dialog.component';
import { map, switchMap } from 'rxjs/operators';
import { ImportService, ImportSchema } from 'src/app/shared/services/import.service';
import { getTableSortAriaHelper, invalidIdentifiersToString, populateInvalidIdentifiers } from 'src/app/shared/utilities';
import { ImportSelections, ViewMode } from '../../entities/list-entities/list-entities.component';
import { SelectionModel } from '@angular/cdk/collections';
import { ImportOptions } from '../../entities/import/entities-import/entities-import.component';
import { Breadcrumb } from 'src/app/shared/components/breadcrumb/breadcrumb.component';
import { FeatureKey, FeatureService } from 'src/app/shared/services/feature.service';
import { Template, TemplateService } from 'src/app/shared/services/template.service';

@Component({
  selector: 'list-choicesets',
  templateUrl: './list-choicesets.component.html',
  styleUrls: ['./list-choicesets.component.scss']
})
export class ListChoicesetsComponent implements OnInit, OnDestroy {
  @Input('mode')
  public mode = ViewMode.listChoicesets;

  @Input('importSchema')
  public importSchema: ImportSchema;
  public importSelections: ImportSelections;
  public displayedColumns: string[];
  public ariaHelper = getTableSortAriaHelper();
  public choicesets: Entity[] = [];
  public conflictChoicesets: Entity[] = [];
  public entities: Entity[] = [];
  public snapshot: Entity[] = [];
  public searchValue: string;
  public dataSource: MatTableDataSource<Entity>;
  public isLoading = false;
  public pagination: Pagination;
  public UpdateEntityScheme: number = Constants.UpdateEntityScheme;
  public showNoPermission = false;
  public showOnlyConflictChoicesets = false;
  public selection = new SelectionModel<any>(true, []);
  public numChoicesetsToImport = 0;
  public invalidIdentifiers: {[key: string]: string[]}[] = [];
  public invalidIdentifiersIsDismissed = false;
  public totalNumInvalidIdentifiers = 0;
  public breadcrumbs: Breadcrumb[];
  public isCdmEnabled: boolean;
  public currentTemplates: Template[] = [];
  public appliedTemplate: Template;

  private deleteConfirmText: Observable<string>;
  private subscription: Subscription = new Subscription();

  @ViewChild(MatSort, { static: true }) private sort: MatSort;

  constructor(
    private router: Router,
    public userService: UserService,
    private entitiesService: EntitiesService,
    private telemetryService: TelemetryService,
    private translateService: TranslateService,
    public dialog: MatDialog,
    private importService: ImportService,
    private featureService: FeatureService,
    public templateService: TemplateService,
  ) { }

  ngOnInit() {
    if (this.isEntitiesImport()) {
      this.telemetryService.startTimedEvent(Events.ChoicesetsConflictView);
      this.displayedColumns = ['displayName', 'name', 'description', 'action'];
      this.choicesets = this.concatArrays(this.importSchema['nonConflictChoicesets'], this.importSchema['conflictChoicesets']);
      this.conflictChoicesets = this.importSchema['conflictChoicesets'];
      this.snapshot = this.sortByDisplayName(this.choicesets);
      this.syncDataRecord();
      this.telemetryService.endTimedEvent(Events.ChoicesetsConflictView);
      this.importService.currentSelection.subscribe(options => this.importSelections = options);
    } else {
      this.telemetryService.startTimedEvent(Events.ChoicesetsView);
      this.isLoading = true;
      this.displayedColumns = ['displayName', 'name', 'description', 'recordCount', 'updateTime', 'edit'];
      const fsSub1 = this.featureService.isEnabled(FeatureKey.Cdm)
        .subscribe(result => {
          this.isCdmEnabled = result;
          if (this.isCdmEnabled) {
            this.templateService.getTemplates().subscribe(templates => {
              this.currentTemplates = templates;
              this.loadChoiceSetsAndEntities();
            });
          } else {
            this.loadChoiceSetsAndEntities();
          }
        });
      this.subscription.add(fsSub1);

      this.telemetryService.endTimedEvent(Events.ChoicesetsView);
    }

    this.breadcrumbs = [
      {
        key: 'ListChoiceSets.EntitiesText',
        url: `${UrlUtils.getBaseRouteUrl()}/entities`
      },
      {
        key: 'ListChoiceSets.Title',
      }
    ];

    this.deleteConfirmText = this.translateService.get('ListChoicesets.DeleteConfirm');

    this.translateService.get('BrowserTitleForChoiceSets').subscribe(s => {
      document.title = s;
    });

    this.invalidIdentifiersIsDismissed = sessionStorage.getItem(Constants.InvalidIdentifiersFirstDimiss) === 'true';
  }

  public ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  public onSearch() {
    this.syncDataRecord();
  }

  public isEntitiesImport(): boolean {
    return this.mode === ViewMode.entitiesImport;
  }

  public isConflictChoiceset(element: Entity) {
    return this.conflictChoicesets.map(item => item.name).includes(element.name);
  }

  public sortData(sort: SortData) {
    this.ariaHelper.setSortOrder(sort.active, sort.direction);

    const isString = (value: any) => typeof value === 'string';
    const isNumber = (value: any) => typeof value === 'number';

    const compareFunction = (item1: any, item2: any) => {
      const value1 = item1[sort.active];
      const value2 = item2[sort.active];

      if (isString(value1) && isString(value2)) {
        return value1.toLocaleLowerCase().localeCompare(value2.toLocaleLowerCase());
      } else if (isNumber(value1) && isNumber(value2)) {
        return value1 - value2;
      } else {
        // Handle cases where the column contains mixed data types.
        return 0;
      }
    };

    const multiplier = sort.direction === 'asc' ? 1 : -1;
    this.choicesets.sort((item1, item2) => compareFunction(item1, item2) * multiplier);

    this.dataSource = new MatTableDataSource(this.choicesets);
  }

  public onUpsertChoiceSet(choiceset: Entity) {
    this.telemetryService.logEvent(Events.UpsertChoiceSetStart);
    if (!this.isEntitiesImport()) {
      const dialogRef = this.dialog.open(UpsertChoicesetDialogComponent,
        {
          width: 'auto',
          height: '100%',
          position: { top: '0', right: '0' },
          disableClose: true,
          panelClass: 'custom-sidepane',
          data: {
            mode: choiceset ? ChoiceSetDialogMode.update :
              ChoiceSetDialogMode.create,
            choiceset: choiceset || new Entity('', '', EntityType.custom, '', ''),
            isModelReserved: choiceset ? choiceset.isModelReserved : false,
          }
        });

      const sub = dialogRef.afterClosed().subscribe((result: EntityDialogResult) => {
        if (result?.succeed) {
          this.telemetryService.logEvent(Events.UpsertChoiceSetEnd);
          this.loadChoiceSetsAndEntities(true);
        }
      });
      this.subscription.add(sub);
    }
  }

  public onDeleteChoiceSet(choiceset: Entity) {
    this.telemetryService.logEvent(Events.DeleteChoiceSetStart);

    const dependencyTable = this.getDependentEntitiesAndFields(choiceset);

    if (Object.keys(dependencyTable).length > 0) {
      const data: DependencyDialogData = {
        title: this.translateService.get('ListChoiceSets.CannotDeleteTitle'),
        description: this.translateService.get('ListChoiceSets.CannotDeleteDescription'),
        dependencyKeyText: this.translateService.get('ListChoiceSets.EntityName'),
        dependencyValueText: this.translateService.get('ListChoiceSets.FieldName'),
        dependencyTable,
      };

      this.dialog.open(DependencyDialogComponent, {
        width: 'auto',
        data,
      });
    } else {
      let deleteType = 'choiceset';
      let dialogRef;
      if (choiceset.isModelReserved) {
        const template = this.currentTemplates.find(template => template.id === choiceset.categoryId);
        deleteType = 'template';
        dialogRef = this.dialog.open(ConfirmationDialogComponent, {
          width: 'auto',
          data: {
            title: this.translateService.get('ListEntities.DeleteTemplateTitle', { templateName: template.name }),
            yesText: this.translateService.get('DeleteText'),
            noText: this.translateService.get('CancelText'),
            confirmationText: this.translateService.get('ListEntities.DeleteTemplateDescription', { templateName: template.name }),
            doubleConfirmNeeded: true,
            doubleConfirmText: template.name,
            asyncFunc: () => this.templateService.deleteTemplate(choiceset.categoryId)
          }
        });
      } else {
        dialogRef = this.dialog.open(ConfirmationDialogComponent, {
          width: 'auto',
          data: {
            title: this.translateService.get('ListChoiceSets.DeleteTitle', { value: choiceset.displayName }),
            yesText: this.translateService.get('DeleteText'),
            noText: this.translateService.get('CancelText'),
            confirmationText: this.deleteConfirmText,
            doubleConfirmNeeded: true,
            doubleConfirmText: choiceset.displayName,
            asyncFunc: () => this.entitiesService.deleteEntity(choiceset)
          }
        });
      }

      const sub = dialogRef.afterClosed().subscribe(confirmation => {
        if (confirmation.result === ConfirmationType.yes) {
          if (deleteType === 'template') {
            this.resetView();
          }
          this.loadChoiceSetsAndEntities(true);
        }
      }, (error) => {
        this.dialog.open(ErrorDialogComponent, { width: 'auto', data: error });
      });
      this.subscription.add(sub);
    }
  }

  public shouldUpdateButtonDisabled(choiceSet: Entity): Observable<boolean> {
    return this.userService.checkPermission([this.UpdateEntityScheme]).pipe(map(result => {
      return !result || choiceSet.name === Constants.UserType;
    }));
  }

  public getEditDeleteTooltip(choiceSet: Entity): Observable<string> {
    return this.userService.checkPermission([this.UpdateEntityScheme]).pipe(switchMap(result => {
      if (!result) {
        return this.translateService.get('NoPermissionToolTip');
      } else if (choiceSet.name === Constants.UserType) {
        return this.translateService.get('ListChoicesets.CannotModifySystemChoiceset');
      } else {
        return of('');
      }
    }));
  }

  public onSelectTemplate(template: Template) {
    this.appliedTemplate = template;
    if (this.appliedTemplate.id) {
      this.displayedColumns = ['displayName', 'name', 'isModelReserved', 'description', 'recordCount', 'updateTime', 'edit'];
    } else {
      this.displayedColumns = ['displayName', 'name', 'description', 'recordCount', 'updateTime', 'edit'];
    }
    this.syncDataRecord();
  }

  public loadChoiceSetsAndEntities(skipLoadTemplates = false) {
    this.isLoading = true;

    const choiceSetSub = this.entitiesService.getChoiceSet().subscribe(result => {
      if (this.isCdmEnabled && !skipLoadTemplates) {
        if (this.currentTemplates.length > 0 && this.currentTemplates[0].id !== undefined) {
          this.currentTemplates = this.currentTemplates.filter(template => result.some(entity => entity.categoryId === template.id));
          const noTemplate = { id: undefined, name: 'ListEntities.NoTemplate' } as Template;

          if (this.currentTemplates.length > 0) {
            this.currentTemplates = [noTemplate, ...this.currentTemplates];

          }
          this.appliedTemplate = noTemplate;
        }
      }
      this.isLoading = false;
      this.snapshot = this.sortByDisplayName(result);
      this.syncDataRecord();
      if (result.length > 0) {
        const invalidIdentifiersResult = populateInvalidIdentifiers(result);
        this.invalidIdentifiers = invalidIdentifiersResult.invalidIdentifiers;
        this.totalNumInvalidIdentifiers = invalidIdentifiersResult.totalCount;
      }
      this.telemetryService.endTimedEvent(Events.ChoicesetsView);
    }, (error) => {
      this.isLoading = false;
      const errorResp = error && JSON.stringify(error);
      if (errorResp && errorResp.indexOf('403') > -1
        && errorResp.indexOf(Constants.BandwidthThrottleMessage) < 0) {
        this.showNoPermission = true;
      } else {
        this.dialog.open(ErrorDialogComponent, { width: 'auto', data: error });
      }
    });

    const entitySub = this.entitiesService.getEntities().subscribe(result => {
      this.entities = result;
      this.telemetryService.endTimedEvent(Events.AppLoad);
    });

    this.subscription.add(choiceSetSub);
    this.subscription.add(choiceSetSub);
  }

  public toggleShowOnlyConflictChoicesets() {
    this.showOnlyConflictChoicesets = !this.showOnlyConflictChoicesets;
    if (this.showOnlyConflictChoicesets) {
      this.setPageProperties(this.conflictChoicesets);
    } else {
      this.setPageProperties(this.choicesets);
    }
  }

  public toggleRow(event: any, row: any) {
    this.selection.toggle(row);
    this.storeSelection(event.checked ? ImportOptions.keep : ImportOptions.skip, row);
  }

  public storeSelection(value: number, choiceset: Entity) {
    if (value === 1 &&  this.importSelections['choicesets'][choiceset.name] !== 1) {
      this.importService.shiftNumChoiceset(1);
    } else if (value === 2 && this.importSelections['choicesets'][choiceset.name] !== 2) {
      this.importService.shiftNumChoiceset(-1);
    }

    this.importSelections['choicesets'][choiceset.name] = value;
    this.importService.updateImportFile(this.importSelections);
  }

  public getInvalidIdentifiers(): Observable<string> {
    return this.translateService.get('Record.InvalidIdentifiers',
      {
        numIdentifiers: this.totalNumInvalidIdentifiers,
        value: invalidIdentifiersToString(this.invalidIdentifiers)
      });
  }

  public invalidIdentifierOnDimiss() {
    this.invalidIdentifiersIsDismissed = true;
    sessionStorage.setItem(Constants.InvalidIdentifiersFirstDimiss, 'true');
  }

  public shouldShowInvalidIdentifiers() {
    return !this.showNoPermission && this.invalidIdentifiers.length > 0 && !this.invalidIdentifiersIsDismissed;
  }

  private syncDataRecord() {
    this.choicesets = [...this.snapshot];
    if (this.searchValue) {
      this.choicesets = this.choicesets.filter(item => {
        const searchFields = this.extractSearchableValues(item);
        return JSON.stringify(searchFields).toLocaleLowerCase().indexOf(this.searchValue.toLocaleLowerCase()) >= 0;
      });
    }

    if (this.isCdmEnabled) {
      this.choicesets = this.choicesets.filter(choiceset => choiceset.categoryId === this.appliedTemplate.id);
    }

    this.dataSource = new MatTableDataSource(this.getPagedData());
    this.dataSource.sort = this.sort;
  }

  public onChangePagination(pagination: Pagination) {
    this.pagination = pagination;
    this.syncDataRecord();
  }

  public isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.choicesets.length;
    return numSelected === numRows;
  }

  public masterToggle() {
    if (this.isAllSelected()) {
      this.selection.clear();
      this.choicesets.forEach(row => this.storeSelection(2, row));
    } else {
      this.choicesets.forEach(row => {
        this.selection.select(row);
        this.storeSelection(1, row);
      });
    }
  }

  public checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.Id}`;
  }

  private extractSearchableValues(data: any): string[] {
    const result = [];
    result.push(data['description']);
    result.push(data['displayName']);
    result.push(data['name']);
    return result;
  }

  private getDependentEntitiesAndFields(choiceset: Entity) {
    const dependencyTable: Record<string, string> = {};

    this.entities.forEach(entity => {
      const dependentFields = entity.fields.filter(f => f.choiceSetId === choiceset.id);
      if (dependentFields.length > 0) {
        dependencyTable[entity.displayName] = dependentFields.map(f => f.displayName).join(', ');
      }
    });

    return dependencyTable;
  }

  private sortByDisplayName(result: any) {
    return [...result].sort((e1, e2) => e1['displayName'] > e2['displayName'] ? 1 : -1);
  }

  private setPageProperties(choicesets: Entity[]): void {

    if (this.showOnlyConflictChoicesets) {
      this.conflictChoicesets = this.sortByDisplayName(choicesets);
    } else {
      this.choicesets = this.sortByDisplayName(choicesets);
    }
    this.dataSource = new MatTableDataSource(this.getPagedData());
    this.dataSource.sort = this.sort;
    this.snapshot = this.choicesets;
  }

  private getPagedData(): Entity[] {
    if (this.pagination) {
      const start = this.pagination.pageIndex * this.pagination.pageSize;
      const end = start + this.pagination.pageSize;
      if (this.showOnlyConflictChoicesets) {
        return this.conflictChoicesets.slice(start, end);
      } else {
        return this.choicesets.slice(start, end);
      }
    } else {
      if (this.showOnlyConflictChoicesets) {
        return this.conflictChoicesets.slice(0, 20);
      } else {
        return this.choicesets.slice(0, 20);
      }
    }
  }

  private concatArrays(...arrays) {
    return [].concat(...arrays.filter(Array.isArray));
  }

  private resetView() {
    this.currentTemplates = this.currentTemplates.filter(template => template.id !== this.appliedTemplate.id);
    const noTemplate = { id: undefined, name: 'ListEntities.NoTemplate' } as Template;
    this.appliedTemplate = noTemplate;
  }
}
