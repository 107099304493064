<confirmation-dialog>
  <div class="entity-list">
    <ng-container *ngIf="isLoadingEntities">
      <div class="spinner" fxLayout="row" fxLayoutAlign="center center">
        <mat-spinner role="alert" [attr.aria-label]="'Loading' | translate" diameter="48"></mat-spinner>
      </div>
    </ng-container>
    <ng-container *ngIf="!isLoadingEntities">
      <div class="header font-large bold">
        {{'EntitiesText' | translate}}
      </div>
      <div *ngFor="let entity of entities">
        • {{entity}}
      </div>
      <div class="header second-header font-large bold">
        {{'ChoicesetText' | translate}}
      </div>
      <div *ngFor="let choiceset of choicesets">
        • {{choiceset}}
      </div>
    </ng-container>
  </div>
</confirmation-dialog>