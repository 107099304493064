import { Component, OnInit, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { TemplateData } from 'src/app/shared/services/template.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationDialog, ConfirmationDialogComponent } from '../confirmation-dialog.component';

export interface DeleteEntitiesConfirmationDialog extends ConfirmationDialog {
  getDataModel: () => Observable<TemplateData>;
}

@Component({
  selector: 'delete-entities-confirmation-dialog',
  templateUrl: './delete-entities-confirmation-dialog.component.html',
  styleUrls: ['./delete-entities-confirmation-dialog.component.scss']
})
export class DeleteEntitiesConfirmationDialogComponent extends ConfirmationDialogComponent implements OnInit {
  public isLoadingEntities = false;
  public entities: string[] = [];
  public choicesets: string[] = [];

  constructor(
    public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    protected snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: DeleteEntitiesConfirmationDialog,
    protected translateService: TranslateService,
  ) {
    super(dialogRef, snackBar, data, translateService);
  }
  ngOnInit(): void {
    this.isLoadingEntities = true;
    const sub = this.data.getDataModel().subscribe(entities => {
      this.entities = entities.entities.map(entity => entity.displayName);
      this.choicesets = entities.choicesets.map(choiceset => choiceset.displayName);
      this.isLoadingEntities = false;
    });
    this.subscription.add(sub);
  }
}
