<div class="side-pane-container">
    <h1 mat-dialog-title>{{getTitleText() | async}}</h1>
    <div mat-dialog-content class="dialog-content">
        <form [formGroup]="fieldForm" (submit)="onSubmit()" [style.fontSize.px]="14">
            <div>
                <div fxLayout="column" fxLayoutAlign="space-around stretch">
                    <mat-form-field floatLabel="always">
                        <mat-label>{{'UpsertField.DisplayName' | translate}}</mat-label>
                        <input matInput type="text" id="displayName" required formControlName="displayName"
                            name="displayName" [placeholder]="'Placeholder.DisplayName' | translate"
                            (keyup)="onSetDisplayName()" (keydown.enter)="enterToSubmit()" />
                        <mat-error *ngIf="fieldForm.get('displayName').errors && fieldForm.get('displayName').errors">
                            {{'EntityDetails.DisplayNameErrorText' | translate}}
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field floatLabel="always" subscriptSizing="dynamic">
                        <mat-label>{{'UpsertField.Name' | translate}}</mat-label>
                        <input matInput id="name" name="name" formControlName="name" required
                            [placeholder]="'Placeholder.Name' | translate" (keydown.enter)="enterToSubmit()"
                            [attr.aria-label]="'UpsertField.NameErrorText' | translate" [matTooltip]="'UpsertField.NameErrorText' | translate"/>
                        <mat-error *ngIf="fieldForm.get('name').errors">
                            <ng-container *ngIf="fieldForm.get('name').errors.forbiddenName">
                                {{'UpsertField.NameErrorText' | translate}}</ng-container>
                            <ng-container *ngIf="fieldForm.get('name').errors.reservedNameError">
                                {{'UpsertField.ReservedNameErrorText' | translate}}</ng-container>
                        </mat-error>
                    </mat-form-field>
                    <div class="margin24" *ngIf="fieldForm.get('name').errors && !!fieldForm.get('name').value"></div>

                    <mat-form-field floatLabel="always">
                        <mat-label>{{'UpsertField.Type' | translate}}</mat-label>
                        <mat-select id="type" required formControlName="type" name="type"
                            [aria-label]="'UpsertField.Type' | translate" panelClass="type-select-dropdown" (selectionChange)="onChooseType()">
                            <mat-option *ngFor="let displayType of displayTypes" [value]="displayType.type">
                                <app-field-icon class="field-icon" [fieldType]="displayType.type"></app-field-icon>
                                {{displayType.displayName}}
                            </mat-option>
                        <mat-select-trigger>
                            <app-field-icon class="field-icon" [fieldType]="selectedType"></app-field-icon>
                            {{selectedName}}
                        </mat-select-trigger>
                        </mat-select>
                    </mat-form-field>

                    <mat-checkbox *ngIf="fieldForm.getRawValue()['type'] === 'dateTime'" class="field-section semi-bold"
                        name="shouldHideTime" formControlName="shouldHideTime"
                        [matTooltip]="'UpsertField.ExcludeTimeTooltip' | translate"
                        [aria-label]="('UpsertField.ExcludeTimeTooltip' | translate) + ' ' + ('UpsertField.HideTimeField' | translate)">
                        {{'UpsertField.HideTimeField' | translate}}
                    </mat-checkbox>


                    <mat-form-field floatLabel="always" *ngIf="fieldForm.getRawValue()['type'] === relationshipStr">
                        <mat-label>{{'UpsertField.RelatedEntity' | translate}}</mat-label>
                        <mat-select id="entity" required formControlName="entity" name="entity">
                            <mat-option *ngFor="let entity of data.entities" [value]="entity">
                                {{entity.name}}
                            </mat-option>
                            <button class="action table-text" mat-button fxLayout="row" fxLayoutAlign="start center"
                                (click)="data.addEntity()">
                                {{'CreateNewText' | translate}}
                            </button>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field
                        *ngIf="fieldForm.getRawValue()['entity'] !== null && fieldForm.getRawValue()['type'] === relationshipStr"
                        class="field-section" floatLabel="always">
                        <mat-label>{{'UpsertField.RelatedEntityDisplayField' | translate}}</mat-label>
                        <mat-select id="relatedEntityDisplayField" required formControlName="relatedEntityDisplayField"
                            name="relatedEntityDisplayField">
                            <mat-option
                                *ngFor="let field of getNoneforeignField(fieldForm.getRawValue()['entity'].fields)"
                                [value]="field">
                                {{field.displayName}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field *ngIf="fieldForm.getRawValue()['type'] === choicesetStr" floatLabel="always">
                        <mat-label>{{'UpsertField.ChoiceSet' | translate}}</mat-label>
                        <mat-select id="choiceset" required formControlName="choiceset" name="choiceset">
                            <mat-option *ngFor="let choiceset of data.choicesets" [value]="choiceset">
                                {{choiceset.name}}
                            </mat-option>
                            <mat-option *ngIf="data.choicesets.length === 0" class="no-item-placeholder">
                                {{'NoChoiceSetsFound' | translate}}
                            </mat-option>
                            <button class="action table-text" mat-button fxLayout="row" fxLayoutAlign="start center"
                                (click)="onViewAllChoiceSets()">
                                {{'ViewAllChoiceSets' | translate}}
                                <mat-icon matSuffix class="material-icons md-18 launch-tab-button-icon">launch
                                </mat-icon>
                            </button>
                            <button class="action table-text" mat-button fxLayout="row" fxLayoutAlign="start center"
                                (click)="data.addChoiceSet()">
                                {{'CreateNewText' | translate}}
                            </button>
                        </mat-select>
                    </mat-form-field>

                    <mat-checkbox *ngIf="fieldForm.getRawValue()['type'] === choicesetStr"
                        class="field-section semi-bold" name="isMultipleChoice" formControlName="isMultipleChoice">
                        {{'UpsertField.MultipleSelection' | translate}}
                    </mat-checkbox>

                    <mat-checkbox *ngIf="fieldForm.getRawValue()['type'] !== autonumberStr && !isFileAttachment()"
                        class="field-section semi-bold" name="isRequired" formControlName="isRequired">
                        {{'UpsertField.Required' | translate}}
                    </mat-checkbox>

                    <ng-container *ngIf="fieldForm.getRawValue()['type'] !== autonumberStr && !isFileAttachment()">
                        <mat-checkbox
                            class="field-section semi-bold"
                            name="isUnique"
                            formControlName="isUnique"
                            [attr.aria-label]="shouldUniqueCheckboxShowTooltip() ? (uniqueCheckboxTooltip() | translate) : null"
                            [matTooltip]="shouldUniqueCheckboxShowTooltip() ? (uniqueCheckboxTooltip() | translate) : null"
                            (change)="onIsUniqueChange($event)"
                            >
                            {{'UpsertField.Unique' | translate}}
                        </mat-checkbox>
                        <ng-container class="rbac-subtext" *ngIf="shouldShowUniqueWarning()">
                            <div class="warning-container">
                                <app-misc-icon [miscIconType]="'uniqueWarning'"></app-misc-icon>
                                <div class="warning-text-container">{{'UpsertField.UncheckUniqueWarning' | translate}}</div>
                            </div>
                        </ng-container>
                    </ng-container>
                </div>
                <div>
                    <mat-slide-toggle class="rbac-margin field-section semi-bold" name="isRbacEnabled"
                            formControlName="isRbacEnabled">
                            {{'UpsertField.IsRbacEnabled' | translate}}
                    </mat-slide-toggle>
                    <mat-icon appRbacTooltip="{{ 'UpsertField.RbacInformation' | translate }}"
                        hrefLink="{{ learnMoreUrl }}"
                        class="info-icon"
                        tabindex="0"
                        (click)="openEntityRoleCustomizationPage()"
                        (keydown)="handleKeyDown($event)"
                        (keypress)="handleKeyPress($event)">
                        info_outline
                    </mat-icon>
                </div>
                <ng-container>
                    <mat-expansion-panel togglePosition="after" (opened)="panelOpenState = true"
                        (closed)="panelOpenState = false">
                        <mat-expansion-panel-header class="panel-header">
                            <mat-panel-title class="bold">
                                {{'UpsertField.MoreDetails' | translate}}
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <ng-template matExpansionPanelContent>
                            <ng-container *ngIf="fieldForm.value['type'] === 'number' || data.field.type === 'number'">
                                <mat-form-field floatLabel="always" class="full-width constraint-input">
                                    <mat-label>{{'UpsertField.MaximumValue' | translate}}</mat-label>
                                    <input matInput id="maxValue" name="maxValue" formControlName="maxValue"
                                        [placeholder]="'Placeholder.MaxValue' | translate"
                                        (keydown.enter)="enterToSubmit()" />
                                    <mat-error
                                        *ngIf="fieldForm.get('maxValue').errors && fieldForm.get('maxValue').errors">
                                        {{'UpsertField.NumberError' | translate}}
                                    </mat-error>
                                </mat-form-field>

                                <mat-form-field floatLabel="always" class="full-width constraint-input">
                                    <mat-label>{{'UpsertField.MinimumValue' | translate}}</mat-label>
                                    <input matInput id="minValue" name="minValue" formControlName="minValue"
                                        [placeholder]="'Placeholder.MinValue' | translate"
                                        (keydown.enter)="enterToSubmit()" />
                                    <mat-error
                                        *ngIf="fieldForm.get('minValue').errors && fieldForm.get('minValue').errors">
                                        {{'UpsertField.NumberError' | translate}}
                                    </mat-error>
                                </mat-form-field>

                                <mat-form-field floatLabel="always" class="full-width constraint-input">
                                    <mat-label>{{'UpsertField.DecimalPlaces' | translate}}</mat-label>
                                    <input matInput id="decimalPrecision" name="decimalPrecision"
                                        formControlName="decimalPrecision" (keydown.enter)="enterToSubmit()"
                                        [placeholder]="'Placeholder.DecimalPrecision' | translate" />
                                    <mat-error
                                        *ngIf="fieldForm.get('decimalPrecision').errors && fieldForm.get('decimalPrecision').errors">
                                        {{'UpsertField.NumberError' | translate}}
                                    </mat-error>
                                </mat-form-field>
                            </ng-container>
                            <ng-container
                                *ngIf="(fieldForm.value['type'] === 'text' || (data.mode === 'Update' && data.field.type === 'text' && !data.field.isForeignKey))">
                                <mat-form-field floatLabel="always" class="full-width constraint-input">
                                    <mat-label>{{'UpsertField.MaximumLength' | translate}}</mat-label>
                                    <input matInput id="lengthLimit" name="lengthLimit" formControlName="lengthLimit"
                                        [placeholder]="'Placeholder.LengthLimit' | translate"
                                        (keydown.enter)="enterToSubmit()" />
                                    <mat-error
                                        *ngIf="fieldForm.get('lengthLimit').errors && fieldForm.get('lengthLimit').errors">
                                        {{'UpsertField.PositiveInteger' | translate}}
                                    </mat-error>
                                </mat-form-field>
                            </ng-container>
                            <!-- default value -->
                            <ng-container *ngIf="shouldShowAllowDefaultValue">
                                <form [formGroup]="defaultFieldForm">
                                    <div class="field-section">
                                        <ng-container [ngSwitch]="fieldForm.getRawValue()['type']">
                                            <ng-container *ngSwitchCase="'dateTime'">
                                                <ng-container
                                                    *ngIf="fieldForm.value['shouldHideTime'] || data.field.type==='date'">
                                                    <mat-form-field floatLabel="always">
                                                        <mat-label>{{'UpsertField.DefaultValue' | translate}}
                                                        </mat-label>
                                                        <input matInput [matDatepicker]="picker" id="defaultValue"
                                                            formControlName="defaultValue" name="defaultValue"
                                                            class="edit-date-input" (keydown.enter)="enterToSubmit()">
                                                        <mat-datepicker-toggle matSuffix [for]="picker">
                                                        </mat-datepicker-toggle>
                                                        <mat-datepicker #picker></mat-datepicker>
                                                    </mat-form-field>
                                                </ng-container>
                                                <ng-container
                                                    *ngIf="fieldForm.value['shouldHideTime']===false || data.field.type==='dateTime'">
                                                    <mat-form-field floatLabel="always">
                                                        <mat-label>{{'UpsertField.DefaultValue' | translate}}
                                                        </mat-label>
                                                        <input matInput type="datetime-local" id="defaultValue"
                                                            formControlName="defaultValue" name="defaultValue"
                                                            (keydown.enter)="enterToSubmit()"
                                                            [attr.aria-label]="'UpsertField.DateTimeDefaultValue' | translate" />
                                                    </mat-form-field>
                                                </ng-container>
                                            </ng-container>
                                            <ng-container *ngSwitchCase="'autonumber'">
                                                <mat-form-field floatLabel="always" class="full-width">
                                                    <mat-label>{{'UpsertField.StartingValue' | translate}}</mat-label>
                                                    <input matInput (keydown.enter)="enterToSubmit()"
                                                        [placeholder]="'Placeholder.EnterStartingValue' | translate"
                                                        id="startingValue" formControlName="defaultValue"
                                                        name="startingValue">
                                                </mat-form-field>
                                            </ng-container>
                                            <ng-container *ngSwitchCase="'boolean'">
                                                <mat-form-field floatLabel="always">
                                                    <mat-label>{{'UpsertField.DefaultValue' | translate}}</mat-label>
                                                    <mat-select id="defaultValue" formControlName="defaultValue"
                                                        name="defaultValue">
                                                        <mat-option [value]="null">
                                                            None
                                                        </mat-option>
                                                        <mat-option [value]="true">
                                                            Yes
                                                        </mat-option>
                                                        <mat-option [value]="false">
                                                            No
                                                        </mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                            </ng-container>
                                            <ng-container *ngSwitchCase="'number'">
                                                <mat-form-field floatLabel="always" class="full-width">
                                                    <mat-label>{{'UpsertField.DefaultValue' | translate}}</mat-label>
                                                    <input matInput (keydown.enter)="enterToSubmit()"
                                                        [placeholder]="'Placeholder.EnterNewValue' | translate"
                                                        id="defaultValue" formControlName="defaultValue"
                                                        name="defaultValue">
                                                </mat-form-field>
                                            </ng-container>
                                            <div *ngSwitchDefault>
                                                <mat-form-field floatLabel="always" class="full-width">
                                                    <mat-label>{{'UpsertField.DefaultValue' | translate}}</mat-label>
                                                    <input matInput id="defaultValue" (keydown.enter)="enterToSubmit()"
                                                        formControlName="defaultValue" name="defaultValue"
                                                        [placeholder]="'Placeholder.EnterDefaultValue' | translate">
                                                </mat-form-field>
                                            </div>
                                            <mat-error *ngIf="shouldShowDefaultValueValidationError">
                                                {{'UpsertField.DefaultValueError' | translate}}
                                            </mat-error>
                                        </ng-container>
                                    </div>
                                </form>
                            </ng-container>

                            <div class="description">
                                <mat-form-field floatLabel="always" class="full-width">
                                    <mat-label>{{'UpsertField.Description' | translate}}</mat-label>
                                    <textarea matInput type="text" id="description" formControlName="description"
                                        name="description" class="description-content"
                                        [placeholder]="'Placeholder.Description' | translate">
                                    </textarea>
                                    <mat-error *ngIf="fieldForm.get('description').errors">
                                        {{'UpsertField.DescriptionError' | translate}}
                                    </mat-error>
                                </mat-form-field>
                            </div>

                            <!-- enabled field level access -->
                            <div *ngIf="data.mode === 'Update'" fxLayout="column" fxLayoutAlign="start none"
                                class="font-medium">
                                <div fxLayout="row" fxLayoutAlign="space-between none">
                                    <div>{{'UpsertField.CreatedBy' | translate}}</div>
                                    <div class="detail-content" [matTooltip]="data.field.createdBy">
                                        {{this.users.get(data.field.createdBy).name}}
                                    </div>
                                </div>
                                <div fxLayout="row" fxLayoutAlign="space-between none">
                                    <div>{{'UpsertField.Created' | translate}}</div>
                                    <div class="detail-content" [matTooltip]="data.field.createTime">
                                        {{data.field.createTime}}
                                    </div>
                                </div>
                                <div fxLayout="row" fxLayoutAlign="space-between none">
                                    <div>{{'UpsertField.LastModifiedBy' | translate}}</div>
                                    <div class="detail-content" [matTooltip]="data.field.updatedBy">
                                        {{this.users.get(data.field.updatedBy)?this.users.get(data.field.updatedBy).name:this.users.get(data.field.createdBy).name}}
                                    </div>
                                </div>
                                <div fxLayout="row" fxLayoutAlign="space-between none">
                                    <div>{{'UpsertField.LastModified' | translate}}</div>
                                    <div class="detail-content" [matTooltip]="data.field.updateTime">
                                        {{data.field.updateTime}}
                                    </div>
                                </div>
                            </div>

                        </ng-template>
                    </mat-expansion-panel>
                </ng-container>
            </div>
        </form>

        <ng-container *ngIf="!isUpdateMode()">
            <div fxLayout="row" fxLayoutAlign="space-between end strech" class="bottom-btn-createanother">
                <mat-checkbox class="create-another-margin semi-bold" name="createAnother" (click)="toggleCreateAnother()">
                    {{'CreateAnother' | translate}}
                </mat-checkbox>
                <div fxLayout="row" fxLayoutAlign="end strech">
                    <button mat-stroked-button color="primary" class="btn bold cancel" (click)="onDismiss()"
                        type="button">{{'CancelButtonText' | translate}}</button>
                    <button *ngIf="!isLoading" mat-flat-button color="primary" type="submit" class="btn bold"
                        [disabled]="isSubmitDisabled()" (click)="onSubmit()">{{'SaveButtonText' | translate}}</button>
                    <div class="btn" *ngIf="isLoading">
                        <mat-spinner role="alert" [attr.aria-label]="'Loading' | translate" diameter="24" class="spinner"></mat-spinner>
                    </div>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="isUpdateMode()">
            <div fxLayout="row" fxLayoutAlign="end strech" class="bottom-btn-createanother">
                <button mat-stroked-button color="primary" class="btn bold cancel" (click)="onDismiss()"
                    type="button">{{'CancelButtonText' | translate}}</button>
                <button *ngIf="!isLoading" mat-flat-button color="primary" type="submit" class="btn bold"
                    [disabled]="isSubmitDisabled()" (click)="onSubmit()">{{'SaveButtonText' | translate}}</button>
                <div class="btn" *ngIf="isLoading">
                    <mat-spinner class="spinner" role="alert" [attr.aria-label]="'Loading' | translate" diameter="24"></mat-spinner>
                </div>
            </div>
        </ng-container>
        <button mat-icon-button (click)="onDismiss()" class="close-button"
            [attr.aria-label]="'CloseButtonText' | translate">
            <mat-icon class="font-large">
                close
            </mat-icon>
        </button>
    </div>
</div>
