import { Component, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-rbac-tooltip',
  templateUrl: './rbac-tooltip.component.html',
  styleUrls: ['./rbac-tooltip.component.scss']
})
export class RbacTooltipComponent {
  @Input() tooltipText: string;
  @Input() hrefLink: string;
  @Output() mouseEnter = new EventEmitter<void>();
  @Output() mouseLeave = new EventEmitter<void>();

  onMouseEnter() {
    this.mouseEnter.emit();
  }

  onMouseLeave() {
    this.mouseLeave.emit();
  }
}
