import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UserService } from './user.service';
import { UpsertCustomRoleDialogComponent } from '../../pages/users/upsert-custom-role-dialog/upsert-custom-role-dialog.component';
import { GetRolePermissionResponse, GetRoleResponse, RoleType } from '../models/user';
import { DataMode, UpsertUserDialogComponent } from '../../pages/users/upsert-user-dialog/upsert-user-dialog.component';
import { Constants } from '../constants';
import { Observable, catchError, map, throwError } from 'rxjs';
import { ErrorDialogComponent } from '../components/error-dialog/error-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class RolesService {

  constructor(
    public userService: UserService,
    public dialog: MatDialog
  )
  { }

  public openUpsertUserDialog(roles: GetRoleResponse[]): Observable<any> {
    const dialogRef = this.dialog.open(UpsertUserDialogComponent, {
      width: 'auto',
      height: '100%',
      position: { top: '0', right: '0' },
      disableClose: true,
      panelClass: 'custom-sidepane',
      data: { mode: DataMode.create, roles },
    });

    return dialogRef.afterClosed();
  }

  public openUpsertRoleDialog(): Observable<any> {
    const dialogRef = this.dialog.open(UpsertCustomRoleDialogComponent, {
      width: 'auto',
      height: '100%',
      position: { top: '0', right: '0' },
      disableClose: true,
      panelClass: 'custom-sidepane',
      data: {
        mode: DataMode.create,
        role: new GetRolePermissionResponse(
          '',
          '',
          RoleType.UserDefined,
          [],
          []
        ),
      },
    });

    return dialogRef.afterClosed();
  }

  public loadRolesV2(): Observable<GetRoleResponse[]> {
    return this.userService.getRolesV2().pipe(
      map((roles: GetRoleResponse[]) => {
        roles.sort((u1, u2) => (u1.name > u2.name ? 1 : -1));
        return [...roles].map((rl) => {
          rl.displayName = this.userService.convertToRoleDisplayName(rl.name);
          return rl;
        });
      }),
      catchError((error) => {
        const errorResp = error && JSON.stringify(error);
        if (
          errorResp &&
            ((errorResp.indexOf('403') < 0 && errorResp.indexOf('401') < 0) ||
              errorResp.indexOf(Constants.BandwidthThrottleMessage) > -1)
        ) {
          this.dialog.open(ErrorDialogComponent, { data: error });
        }
        return throwError(error);
      })
    );
  }
}