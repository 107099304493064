<table mat-table [dataSource]="dataSource" matSort (matSortChange)="sortData($event)" class="template-data-table">
    <caption></caption>
    <ng-container matColumnDef="displayName">
      <th scope="cell" mat-header-cell *matHeaderCellDef mat-sort-header
        class="table-header-overflow table-header-text font-medium bold">
        {{'ListEntities.DisplayName' | translate}}
      </th>
      <td mat-cell *matCellDef="let element" (click)="onSelectRow(element)" class="th-first-cell font-medium bold"
        tabindex="0" (keydown.enter)="onSelectRow(element)">
        <div class="display-name color-foreground-link">{{getDisplayNameText(element) | async}}</div>
      </td>
    </ng-container>
  
    <ng-container matColumnDef="name">
      <th scope="cell" mat-header-cell *matHeaderCellDef mat-sort-header
        class="table-header-overflow table-header-text font-medium bold">
        {{'ListEntities.Name' | translate}}
      </th>
      <td mat-cell *matCellDef="let element" class="table-name-text"> {{element.name}} </td>
    </ng-container>
  
    <ng-container matColumnDef="isModelReserved">
      <th scope="cell" mat-header-cell *matHeaderCellDef mat-sort-header
        class="table-header-overflow table-header-text font-medium bold">
        {{'ListEntities.IsModelReserved' | translate}}
      </th>
      <td mat-cell *matCellDef="let element" class="table-name-text"> {{(element.isModelReserved ? 'ListField.UniqueYes' : 'ListField.UniqueNo') | translate}} </td>
    </ng-container>
  
    <ng-container matColumnDef="description">
      <th scope="cell" mat-header-cell *matHeaderCellDef mat-sort-header
        class="table-header-overflow description-column table-header-text  font-medium bold">
        {{'ListEntities.Description' | translate}}
      </th>
      <td mat-cell *matCellDef="let element" class="table-text">
        {{getDescriptionText(element) | async}}
      </td>
    </ng-container>
  
    <ng-container matColumnDef="fieldNumber">
      <th scope="cell" mat-header-cell *matHeaderCellDef mat-sort-header
        class="table-header-overflow table-header-text  font-medium bold">
        {{'ListEntities.NumberOfFields' | translate}}
      </th>
      <td mat-cell *matCellDef="let element" class="table-text">
        {{element.fields && element.fields.length}} </td>
    </ng-container>

    <ng-container matColumnDef="recordCount">
      <th scope="cell" mat-header-cell *matHeaderCellDef mat-sort-header
        class="table-header-overflow table-header-text  font-medium bold">
        {{'ListEntities.NumberOfRecords' | translate}}
      </th>
      <td mat-cell *matCellDef="let element" class="table-text"> {{element.recordCount}} </td>
    </ng-container>

    <ng-container matColumnDef="version">
      <th scope="cell" mat-header-cell *matHeaderCellDef mat-sort-header
        class="table-header-overflow table-header-text font-medium bold">
        {{"ListEntities.Version" | translate}}
      </th>
      <td mat-cell *matCellDef="let element" class="table-text"> {{element.version}}
      </td>
    </ng-container>
  
    <tr mat-header-row *matHeaderRowDef="displayedColumns" class="table-header themed-list-header"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="table-row" (dblclick)="onSelectRow(row)"></tr>
  </table>
  <mat-paginator #paginator
    [pageSizeOptions]="[10, 20, 50]" (page)="onChangePagination($event)">
  </mat-paginator>