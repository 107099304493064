import { Injectable } from '@angular/core';
import { Observable, forkJoin, of } from 'rxjs';
import { Entity, EntityRequest, EntityUpdateRequest, SearchEntityResponse } from '../models/entity';
import { Field, FieldType, FieldDefinition, SqlType, FieldCreateRequest, FieldUpdateRequest } from '../models/field';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { TelemetryService } from './telemetry.service';
import { Events } from '../models/events';
import { Constants } from '../constants';
import { BulkImportResponse } from 'src/app/pages/entities/entity-details/entity-details.component';
import cloneDeep from 'lodash/cloneDeep';

@Injectable({
  providedIn: 'root'
})
export class EntitiesService {

  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  constructor(
    private http: HttpClient,
    private telemetryService: TelemetryService
  ) { }

  /**
   * getEntities
   */
  public getEntities(): Observable<Entity[]> {
    this.telemetryService.logEvent(Events.GetEntities);
    return this.http.get<Entity[]>('/api/Entity', this.httpOptions)
      .pipe(
        map(entities => {
          return entities.map(entity => {
            entity.fields.map(fd => {
              fd.type = this.convertSqlType(fd.sqlType, fd.isForeignKey, fd.fieldDisplayType);
              if (fd.referenceField) {
                fd.referenceField.type = this.convertSqlType(fd.referenceField.definition['sqlType'] as SqlType,
                  fd.referenceField.definition['isForeignKey'],
                  fd.referenceField.fieldDisplayType);
              }
              fd.constraint = {
                DecimalPrecision: fd.sqlType.decimalPrecision,
                LengthLimit: fd.sqlType.lengthLimit,
                MaxValue: fd.sqlType.maxValue,
                MinValue: fd.sqlType.minValue
              };
            });
            return entity;
          });
        })
      );
  }

  public getEntitiesStats(): Observable<Entity[]> {
    this.telemetryService.logEvent(Events.GetEntitiesStats);
    return this.http.get<Entity[]>('/api/Entity/simple', this.httpOptions);
  }

  public searchEntities(): Observable<SearchEntityResponse[]> {
    this.telemetryService.logEvent(Events.SearchEntities);
    return this.http.get<SearchEntityResponse[]>('/api/Entity/entities', this.httpOptions);
  }

  public createEntity(entity: Entity): Observable<string> {
    this.telemetryService.logEvent(Events.CreateEntity);
    const request: EntityRequest = {
      description: entity.description,
      displayName: entity.displayName,
      entityDefinition:
      {
        name: entity.name,
        fields: entity.fields,
        isRbacEnabled: entity.isRbacEnabled,
      }
    };

    return this.http.post<string>('/api/Entity', request, this.httpOptions);
  }

  public createEntities(entities: Entity[]): Observable<string[]> {
    entities = cloneDeep(entities);
    if (!entities || entities.length === 0) {
      // Return an empty observable array if the input is empty
      return of([]);
    }

    // Map each entity to an observable created by the createEntity function
    const observables = entities.map(entity => {
      entity.name = `${entity.name}cdm${Date.now().toString().slice(-5)}`;
      entity.displayName = `${entity.displayName}_cdm_${Date.now().toString().slice(-5)}`;
      entity.fields = cloneDeep(entity.fields).map(fd => {
        fd.name = `${fd.name}cdm`;
        fd.sqlType = this.transformType(fd.type);
        return fd;
      });

      return this.createEntity(entity);
    });

    // Use forkJoin to wait for all observables to complete
    return forkJoin(observables);
  };

  public updateEntity(entity: Entity): Observable<string> {
    this.telemetryService.logEvent(Events.UpdateEntity);
    const request: EntityUpdateRequest = {
      entityId: entity.id,
      description: entity.description,
      displayName: entity.displayName,
      isRbacEnabled: entity.isRbacEnabled,
    };

    return this.http.patch<string>(`/api/Entity/${entity.id}/metadata`, request, this.httpOptions);
  }

  public deleteEntity(entity: Entity): Observable<boolean> {
    this.telemetryService.logEvent(Events.DeleteEntity);

    return this.http.post<boolean>(`/api/Entity/${entity.id}/delete`, null, this.httpOptions);
  }

  public convertEntity(entity: Entity): Observable<boolean> {
    this.telemetryService.logEvent(Events.ConvertEntity);

    return this.http.post<boolean>(`/api/v2/Entity/convert`, { ids: [entity.id] }, this.httpOptions);
  }

  public addField(entityId: string, field: Field): Observable<boolean> {
    this.telemetryService.logEvent(Events.AddField);

    const fieldDefinition: FieldDefinition = {
      Name: field.name,
      IsPrimaryKey: false,
      IsForeignKey: !!field.referenceEntity,
      SqlType: field.sqlType,
      Description: field.description,
      DisplayName: field.displayName,
      IsRequired: field.isRequired,
      FieldDisplayType: field.fieldDisplayType || this.convertFieldDisplayType(field.type),
      ReferenceEntity: field.referenceEntity,
      ReferenceField: field.referenceField,
      DefaultValue: field.defaultValue,
      ChoiceSetId: field.choiceSetId,
      IsRbacEnabled: field.isRbacEnabled,
      IsUnique: field.isUnique,
    };

    if (field.type === FieldType.number || field.type === FieldType.text) {
      fieldDefinition.FieldConstraint = JSON.stringify(field.constraint);
    }

    const request: FieldCreateRequest = {
      entityId,
      fieldDefinition
    };

    return this.http.post<boolean>(`/api/Entity/${entityId}/field`, request, this.httpOptions);
  }

  public deleteField(entity: Entity, recordId: string): Observable<boolean> {
    this.telemetryService.logEvent(Events.AddField);

    return this.http.post<boolean>(`/api/Entity/${entity.id}/field/${recordId}/delete`, this.httpOptions);
  }

  public updateFieldMetadata(entityId: string, field: Field): Observable<boolean> {
    this.telemetryService.logEvent(Events.UpdateFieldMetadata);

    const request: FieldUpdateRequest = {
      entityId,
      id: field.id,
      fieldName: field.name,
      displayName: field.displayName,
      description: field.description,
      isRequired: field.isRequired,
      defaultValue: field.defaultValue,
      sqlType: field.sqlType,
      referenceFieldId: field.referenceField ? field.referenceField.id : null,
      isRbacEnabled: field.isRbacEnabled,
      isUnique: field.isUnique,
    };

    return this.http.patch<boolean>(`/api/Entity/${entityId}/field/${field.id}`, request, this.httpOptions);
  }

  public transformType(fieldType: FieldType): SqlType {
    switch (fieldType) {
    case FieldType.choiceSetSingle:
      return new SqlType('INT');
    case FieldType.choiceSetMultiple:
      return new SqlType('NVARCHAR', 4000);
    case FieldType.relationship:
      return new SqlType('UNIQUEIDENTIFIER', 300);
    case FieldType.number:
      return new SqlType('DECIMAL', 1000);
    case FieldType.boolean:
      return new SqlType('BIT', 100);
    case FieldType.date:
      return new SqlType('DATE', 1000);
    case FieldType.dateTime:
      return new SqlType('DATETIMEOFFSET', 1000);
    case FieldType.autonumber:
      return new SqlType('DECIMAL');
    default:
      return new SqlType('NVARCHAR', 512);
    }
  }

  public convertSqlType(sqlType: SqlType, isForeignKey: boolean, fieldDisplayType: string): FieldType {

    switch (sqlType.name) {
    case 'FLOAT':
      return FieldType.number;
    case 'DECIMAL':
      if (fieldDisplayType === Constants.Autonumber) {
        return FieldType.autonumber;
      }
      return FieldType.number;
    case 'DATETIMEOFFSET':
      return FieldType.dateTime;
    case 'DATE':
      return FieldType.date;
    case 'BIT':
      return FieldType.boolean;
    case 'UNIQUEIDENTIFIER':
      if (isForeignKey) {
        return FieldType.relationship;
      }
      return FieldType.uniqueid;
    default:
      if (fieldDisplayType === Constants.ChoiceSetSingle) {
        return FieldType.choiceSetSingle;
      }

      if (fieldDisplayType === Constants.ChoiceSetMultiple) {
        return FieldType.choiceSetMultiple;
      }

      return FieldType.text;
    }
  }

  public convertFieldDisplayType(fieldType: FieldType): string {
    switch (fieldType) {
    case FieldType.relationship:
      return Constants.Relationship;
    case FieldType.choiceSetSingle:
      return Constants.ChoiceSetSingle;
    case FieldType.choiceSetMultiple:
      return Constants.ChoiceSetMultiple;
    default:
      return Constants.Basic;
    }
  }

  public createChoiceSet(entityRequest: EntityRequest) {
    this.telemetryService.logEvent(Events.CreateChoiceSet);
    return this.http.post<string>(`/api/Entity/choiceset`, entityRequest, this.httpOptions);
  }

  public getChoiceSet() {
    this.telemetryService.logEvent(Events.GetChoiceSet);
    return this.http.get<Entity[]>(`/api/Entity/choiceset`, this.httpOptions);
  }

  public getExportSchema() {
    this.telemetryService.logEvent(Events.ExportButtonClick);
    return this.http.get<Entity[]>(`/api/Entity/export`, this.httpOptions);
  }

  public bulkImportData(fileToUpload: File, entityId: string): Observable<BulkImportResponse> {
    const formData = new FormData();
    formData.append('file', fileToUpload, fileToUpload.name);
    formData.append('entityId', entityId);

    return this.http.post<BulkImportResponse>(`/api/EntityService/entity/${entityId}/bulk-upload`, formData);
  }

  public downloadErrorFile(entityId: string, errorFileLink: string): Observable<Blob> {
    const url = `api/EntityService/entity/${entityId}/bulk-upload-errors/${encodeURIComponent(errorFileLink)}`;
    const options: any = { responseType: 'blob', observe: 'body' };

    return this.http.get<Blob>(url, options) as unknown as Observable<Blob>;
  }

}
