import { Constants } from 'src/app/shared/constants';
import { Component, OnInit, Input, SimpleChanges, OnChanges, Output, EventEmitter, OnDestroy } from '@angular/core';
import { UserService, UserEntry } from 'src/app/shared/services/user.service';
import { MatTableDataSource } from '@angular/material/table';
import { Role, UserGroup, RoleResponse, GetRoleResponse, RoleType } from 'src/app/shared/models/user';
import { MatDialog } from '@angular/material/dialog';
import { UpsertUserDialogComponent, DataMode } from '../upsert-user-dialog/upsert-user-dialog.component';
import { SelectionModel } from '@angular/cdk/collections';
import { SortData } from 'src/app/shared/models/environment';
import { Pagination } from '../../entities/entity-details/data/list-data/list-data.component';
import { ConfirmationDialogComponent, ConfirmationType } from 'src/app/shared/components/confirmation-dialog/confirmation-dialog.component';
import { TelemetryService } from 'src/app/shared/services/telemetry.service';
import { Events } from 'src/app/shared/models/events';
import { Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { FeatureKey, FeatureService } from 'src/app/shared/services/feature.service';
import { getTableSortAriaHelper } from 'src/app/shared/utilities';
import { RolesService } from 'src/app/shared/services/roles.service';

export interface DisplayFilter {
  displayName: string;
  value: string;
  id?: string;
  getRoleResponse?: GetRoleResponse;
}

@Component({
  selector: 'app-list-users',
  templateUrl: './list-users.component.html',
  styleUrls: ['./list-users.component.scss']
})
export class ListUsersComponent implements OnInit, OnChanges, OnDestroy {
  @Input('users')
  public userGroups: UserGroup[];

  @Input('roles')
  public roles: GetRoleResponse[];

  @Output()
  public refreshUsers: EventEmitter<void> = new EventEmitter<void>();

  public dataSource: MatTableDataSource<UserGroup>;
  public snapshot: UserGroup[];
  public displayedColumns = ['select', 'name', 'email', 'role', 'action'];
  public ariaHelper = getTableSortAriaHelper();
  public searchValue: string;
  public searchPeopleList: UserEntry[];
  public isLoading = true;
  public pagination: Pagination;
  public isAdminConsistencyEnabled = false;

  public selection = new SelectionModel<any>(true, []);

  public displayFilters: DisplayFilter[] =
    [
      { displayName: 'ListUser.Administrator', value: Role.admin },
      { displayName: 'ListUser.Designer', value: Role.designer },
      { displayName: 'ListUser.DataWriter', value: Role.dataWriter },
      { displayName: 'ListUser.DataReader', value: Role.dataReader }
    ];

  public selectedFilters: DisplayFilter[] = [];

  public deleteConfirmText: Observable<string>;
  private subscription: Subscription = new Subscription();

  constructor(
    public usersService: UserService,
    public rolesService: RolesService,
    public dialog: MatDialog,
    private telemetryService: TelemetryService,
    private router: Router,
    private translateService: TranslateService,
    private featureService: FeatureService,
  ) { }

  public ngOnInit() {
    this.telemetryService.logEvent(Events.UserListView);
    this.deleteConfirmText = this.translateService.get('ListUsers.DeleteConfirmText');
    const fsSub = this.featureService.isEnabled(FeatureKey.AdminConsistency)
      .subscribe(result => {
        this.isAdminConsistencyEnabled = result;
      });

    this.subscription.add(fsSub);
  }

  public ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (this.userGroups) {
      this.snapshot = [...this.userGroups];
      this.isLoading = false;
      this.syncDataRecord();
    }
    if (this.roles) {
      this.displayFilters = this.roles.map(rl => this.convertToDisplayFilter(rl));
    }
  }

  public onUpsertUserGroup() {
    this.telemetryService.logEvent(Events.AddUserGroupClick);

    const sub = this.rolesService.openUpsertUserDialog(this.roles).subscribe(result => {
      if (result) {
        this.loadUsersAndGroups();
      }
    });

    this.subscription.add(sub);
  }

  public isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  public masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }

  public checkboxLabel(row?: any, index?: number): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${index + 1}`;
  }

  public isItemSelected() {
    return !(this.selection.selected && this.selection.selected.length > 0);
  }

  public shouldDisableEdit() {
    return this.selection.selected && this.selection.selected.length === 1;
  }

  public sortData(sort: SortData) {
    this.ariaHelper.setSortOrder(sort.active, sort.direction);

    const data = [...this.dataSource.data].sort((item1, item2) => {
      let value1 = item1[sort.active];
      let value2 = item2[sort.active];

      // Check for array and handle accordingly
      if (Array.isArray(value1) && Array.isArray(value2)) {
        value1 = value1.map(role => role.name).join(', ').toLowerCase();
        value2 = value2.map(role => role.name).join(', ').toLowerCase();
      } else {
        // Handle undefined or null values for non-array types
        value1 = value1 ? value1.toString().toLocaleLowerCase() : '';
        value2 = value2 ? value2.toString().toLocaleLowerCase() : '';
      }

      // Sort based on the processed values
      return value1 > value2 ? (sort.direction === 'asc' ? 1 : -1) : value1 < value2 ? (sort.direction === 'asc' ? -1 : 1) : 0;
    });

    this.dataSource = new MatTableDataSource(data);
  }

  public shouldFilterChecked(filter: DisplayFilter) {
    return this.selectedFilters.indexOf(filter) >= 0;
  }

  public simulateClickAllFilters() {
    if (this.selectedFilters.length !== this.displayFilters.length) {
      this.selectedFilters = [...this.displayFilters];
    } else {
      this.selectedFilters = [];
    }
    this.syncDataRecord();
  }

  public onChangeAllFilter(event: any) {
    if (event.checked) {
      this.selectedFilters = [...this.displayFilters];

    } else {
      this.selectedFilters = [];
    }
    this.syncDataRecord();
  }

  public simulateClickFilter(selectedFilter: DisplayFilter) {
    if (this.selectedFilters.includes(selectedFilter)) {
      this.selectedFilters.splice(this.selectedFilters.indexOf(selectedFilter), 1);
    } else {
      this.selectedFilters.push(selectedFilter);
    }
    this.syncDataRecord();
  }

  public onChangeFilter(event: any, selectedFilter: DisplayFilter) {
    if (event.checked) {
      this.selectedFilters.push(selectedFilter);
    } else {
      this.selectedFilters.splice(this.selectedFilters.indexOf(selectedFilter), 1);
    }
    this.syncDataRecord();
  }

  public removeFilterChip(selectedFilter: DisplayFilter) {
    this.selectedFilters.splice(this.selectedFilters.indexOf(selectedFilter), 1);
    this.syncDataRecord();
  }

  public onClearAll() {
    this.selectedFilters = [];
    this.syncDataRecord();
  }

  public onUpsertRole(input?: any) {
    this.telemetryService.logEvent(Events.AddUserGroupClick);

    let user = null;
    if (input && input === 'selected') {
      user = this.selection.selected[0];
      this.telemetryService.logEvent(Events.EditUserGroupClick);
    } else if (input) {
      user = input;
    }

    const dialogRef = this.dialog.open(UpsertUserDialogComponent, {
      width: 'auto',
      height: '100%',
      position: { top: '0', right: '0' },
      disableClose: true,
      panelClass: 'custom-sidepane',
      data: user ? { mode: DataMode.update, user, roles: this.roles } : { mode: DataMode.create }
    });

    const sub = dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.loadUsersAndGroups();
      }
    });
    this.subscription.add(sub);
  }

  public onSearch() {
    this.syncDataRecord();
  }

  public onClickRefresh() {
    this.loadUsersAndGroups();
  }

  public onDeleteUserGroup(user: UserGroup) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: 'auto',
      data: {
        title: this.translateService.get('ListUser.RemoveUser'),
        yesText: this.translateService.get('RemoveText'),
        noText: this.translateService.get('CancelText'),
        confirmationText: this.deleteConfirmText,
        doubleConfirmNeeded: false,

        asyncFunc: () => this.usersService.deleteUserOrGroup([user])
      }
    });

    const sub = dialogRef.afterClosed().subscribe(confirmation => {
      if (confirmation.result === ConfirmationType.yes) {
        this.loadUsersAndGroups();
      }
    });
    this.subscription.add(sub);
  }

  public onBulkDelete(): void {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: 'auto',
      data: {
        title: this.translateService.get('ListUser.RemoveUser'),
        yesText: this.translateService.get('RemoveText'),
        noText: this.translateService.get('CancelText'),
        confirmationText: this.deleteConfirmText,
        doubleConfirmNeeded: false,

        asyncFunc: () => this.deleteBulkUser()
      }
    });

    const sub = dialogRef.afterClosed().subscribe(confirmation => {
      if (confirmation.result === ConfirmationType.yes) {
        this.loadUsersAndGroups();
      }
    });
    this.subscription.add(sub);
  }

  public deleteBulkUser() {
    const users: UserGroup[] = this.selection.selected;

    return this.usersService.deleteUserOrGroup(users);
  }

  public getRemoveTooltip(userGroup: UserGroup): Observable<string> {
    if (userGroup.id === Constants.OrganizationAdmin) {
      return this.translateService.get('ListUser.RemoveTooltopTextAdmin');
    } else {
      return this.translateService.get('ListUser.RemoveTooltopText');
    }
  }

  public shouldRemoveDisabled(userGroup: UserGroup) {
    return userGroup.id === Constants.OrganizationAdmin;
  }

  public onChangePagination(pagination: Pagination) {
    this.pagination = pagination;
    this.dataSource = new MatTableDataSource(this.getPagedData());
  }

  public onChoosePeople(user: UserEntry) {
    let users = [...this.userGroups];
    users = users.filter(itm => itm.id === user.id);

    this.dataSource = new MatTableDataSource(users);
    this.searchPeopleList = [];
  }

  private syncDataRecord() {
    let newData = [...this.userGroups];

    if (this.searchValue) {
      newData = newData.filter(itm => JSON.stringify(itm)
        .toLocaleLowerCase().indexOf(this.searchValue.toLocaleLowerCase()) >= 0);
    }

    if (this.selectedFilters.length > 0) {
      newData = newData.filter(itm => {
        let result = false;
        for (const filter of this.selectedFilters) {
          if (!!itm.role.find(itm => this.existRole(itm, filter))) {
            result = true;
            break;
          }
        }
        return result;
      });
    }
    this.snapshot = [...newData];
    this.dataSource = new MatTableDataSource(this.getPagedData());
  }

  private existRole(roleResponse: RoleResponse, displayFilter: DisplayFilter): boolean {
    // todo nanz remove this when retire v1
    if (!displayFilter.getRoleResponse) {
      return roleResponse.name === displayFilter.value as string;
    }

    if (displayFilter.getRoleResponse.type === RoleType.System) {
      return roleResponse.name === displayFilter.getRoleResponse.name;
    } else {
      return roleResponse.name === displayFilter.getRoleResponse.name;
    }
  }

  private getPagedData(): UserGroup[] {
    if (this.pagination) {
      const start = this.pagination.pageIndex * this.pagination.pageSize;
      const end = start + this.pagination.pageSize;
      return [...this.snapshot].slice(start, end);
    } else {
      return [...this.snapshot].slice(0, 20);
    }
  }

  private loadUsersAndGroups() {
    this.isLoading = true;
    this.refreshUsers.emit();
    this.selection.clear();
  }

  private convertToDisplayFilter(role: GetRoleResponse): DisplayFilter {
    if (role.name === 'Admin') {
      return {
        value: Role.admin,
        displayName: 'ListUser.Administrator',
        id: role.id,
        getRoleResponse: role
      };
    } else if (role.name === 'Designer') {
      return {
        value: Role.designer,
        displayName: 'ListUser.Designer',
        id: role.id,
        getRoleResponse: role
      };
    } else if (role.name === 'DataWriter') {
      return {
        value: Role.dataWriter,
        displayName: 'ListUser.DataWriter',
        id: role.id,
        getRoleResponse: role
      };
    } else if (role.name === 'DataReader') {
      return {
        value: Role.dataReader,
        displayName: 'ListUser.DataReader',
        id: role.id,
        getRoleResponse: role
      };
    } else {
      return {
        value: Role.custom,
        displayName: role.name,
        id: role.id,
        getRoleResponse: role
      };
    }
  }
}
