import { HttpBaseHrefInterceptor } from './shared/interceptors/http-base-href-interceptor';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import {
  UiPathAuthConfig,
  UiPathAuthModule,
} from '@uipath/auth-angular';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatTabsModule } from '@angular/material/tabs';
import { MatChipsModule } from '@angular/material/chips';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSortModule } from '@angular/material/sort';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule, Location } from '@angular/common';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { ClipboardModule } from 'ngx-clipboard';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ListEntitiesComponent } from './pages/entities/list-entities/list-entities.component';
import { EntityDetailsComponent } from './pages/entities/entity-details/entity-details.component';
import { UpsertEntityDialogComponent } from './pages/entities/upsert-entity-dialog/upsert-entity-dialog.component';
import { UpsertFieldDialogComponent } from './pages/entities/entity-details/upsert-field-dialog/upsert-field-dialog.component';
import { ListFieldsComponent } from './pages/entities/entity-details/list-fields/list-fields.component';
import { ListDataComponent } from './pages/entities/entity-details/data/list-data/list-data.component';
import { ConfirmationDialogComponent } from './shared/components/confirmation-dialog/confirmation-dialog.component';
import { DeleteEntitiesConfirmationDialogComponent }
  from './shared/components/confirmation-dialog/delete-entities-confirmation-dialog/delete-entities-confirmation-dialog.component';
import { ListUsersComponent } from './pages/users/list-users/list-users.component';
import { UpsertUserDialogComponent } from './pages/users/upsert-user-dialog/upsert-user-dialog.component';
import { ErrorDialogComponent } from './shared/components/error-dialog/error-dialog.component';
import { HttpErrorInterceptor } from './shared/interceptors/http-error-interceptors';
import { MatMomentDateModule, MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MomentDateAdapter, MAT_MOMENT_DATE_FORMATS  } from '@angular/material-moment-adapter';
import { MatNativeDateModule, DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { AuthGuard } from './shared/guards/auth.guard';
import { UpsertDataDialogComponent } from './pages/entities/entity-details/data/upsert-data-dialog/upsert-data-dialog.component';
import { TelemetryService } from './shared/services/telemetry.service';
import { HttpAuthInterceptor } from './shared/interceptors/http-auth-interceptors';
import { HttpVersioningInterceptor } from './shared/interceptors/http-versioning-interceptor';
import { HttpLocalizationInterceptor } from './shared/interceptors/http-localization-interceptor';
import { MatPaginatorModule, MatPaginatorIntl } from '@angular/material/paginator';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatMenuModule } from '@angular/material/menu';
import { RelativeTimePipe } from './shared/pipes/relative-time.pipe';
import { LoginCallbackComponent } from './pages/login/login-callback.component';
import { AuthService } from './shared/guards/auth.service';
import { StorageService } from './shared/services/storage.service';
import { AccountService } from './shared/services/account.service';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { AutoFocusDirective } from './shared/directives/auto-focus.directive';
import { PaginatorOverrideService } from './shared/services/paginator-override.service';
import { ResizableModule } from 'angular-resizable-element';
import { UserManagementComponent } from './pages/users/user-management/user-management.component';
import { ListRolesComponent } from './pages/users/list-roles/list-roles.component';
import { UpsertRoleDialogComponent } from './pages/users/upsert-role-dialog/upsert-role-dialog.component';
import { NoPermissionComponent } from './shared/components/no-permission/no-permission.component';
import { PopoverDialogService } from './shared/services/popover-dialog.service';
import { A11yModule } from '@angular/cdk/a11y';
import { OverlayModule } from '@angular/cdk/overlay';
import { HttpTraceparentInterceptor } from './shared/interceptors/http-traceparent-interceptor';
import { SnackBarComponent } from './shared/components/snack-bar/snack-bar.component';
import { FileAttachmentComponent } from './shared/components/file-attachment/file-attachment.component';
import { UpsertCustomRoleDialogComponent } from './pages/users/upsert-custom-role-dialog/upsert-custom-role-dialog.component';
import { EntityPickerComponent } from './shared/components/entity-picker/entity-picker.component';
import { FieldPickerComponent } from './shared/components/field-picker/field-picker.component';
import { FileCellComponent } from './pages/entities/entity-details/data/file-cell/file-cell.component';
import { UpsertFileDialogComponent } from './pages/entities/entity-details/data/upsert-file-dialog/upsert-file-dialog.component';
import { HttpLoaderFactory } from './shared/services/pruning-loader';
import { ListChoicesetsComponent } from './pages/choicesets/list-choicesets/list-choicesets.component';
import { UpsertChoicesetDialogComponent } from './pages/choicesets/upsert-choiceset-dialog/upsert-choiceset-dialog.component';
import { ViewSystemRoleDialogComponent } from './pages/users/view-system-role-dialog/view-system-role-dialog.component';
import { ChoicesetOptionsComponent } from './pages/entities/entity-details/data/choiceset-options/choiceset-options.component';
import { DependencyDialogComponent } from './shared/components/dependency-dialog/dependency-dialog.component';
import { EnableRbacDialogComponent } from './pages/entities/entity-details/list-fields/enable-rbac-dialog/enable-rbac-dialog.component';
import { LogoutComponent } from './pages/logout/logout.component';
import { ApiAccessDialogComponent } from './shared/components/api-access-dialog/api-access-dialog.component';
import { UpgradeEntityBarComponent } from './pages/entities/entity-details/upgrade-entity-bar/upgrade-entity-bar.component';
import { RecordFlyoutComponent } from './shared/components/record-flyout/record-flyout.component';
import { MatDividerModule } from '@angular/material/divider';
import { LoginComponent } from './pages/login/login.component';
import { MigrationBarComponent } from './shared/components/migration-bar/migration-bar.component';
import { ExportEntitiesDialogComponent } from './pages/entities/export-entities-dialog/export-entities-dialog.component';
import { ImportErrorDialogComponent } from './pages/entities/import-error-dialog/import-error-dialog.component';
import {EnableRbacSnackbarComponent} from './pages/entities/import/enable-rbac-bar/enable-rbac-bar.component';
import { ImportPartialImportedDialogComponent } from './pages/entities/import/partial/import-partialimported-dialog.component';
import { EntitiesImportComponent } from './pages/entities/import/entities-import/entities-import.component';
import { EntitiesImportBarComponent } from './pages/entities/import/entities-import-bar/entities-import-bar.component';
import { FieldsConflictBarComponent } from './pages/entities/import/fields-conflict-bar/fields-conflict-bar.component';
import { AdvancedSearchComponent } from './pages/entities/entity-details/data/advanced-search/advanced-search.component';
import { BasicSearchComponent } from './pages/entities/entity-details/data/basic-search/basic-search.component';
import { FieldIconComponent } from './shared/components/field-icon/field-icon.component';
import { AuthConfigFactory } from './shared/guards/auth-config.factory';
import { MiscIconComponent } from './shared/components/misc-icon/misc-icon.component';
import { TemplateDetailsComponent } from './pages/template/template-details/template-details.component';
import { TemplateListComponent } from './pages/template/template-list/template-list.component';
import { TemplateEntityDetailsComponent } from './pages/template/template-entity-details/template-entity-details.component';
import { BreadcrumbComponent } from './shared/components/breadcrumb/breadcrumb.component';
import { BreadcrumbSecondaryComponent } from './shared/components/breadcrumb-secondary/breadcrumb-secondary.component';
import { TemplateDataModelComponent } from './pages/template/template-data-model/template-data-model.component';
import { CreateRolePageComponent } from './pages/users/create-roles/create-role-page.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { RbacTooltipComponent } from './pages/entities/import/rbac-tooltip/rbac-tooltip.component';
import { RbacTooltipDirective } from './pages/entities/import/rbac-tooltip/rbac-tooltip.directive';
import { HtmlTooltipComponent } from './pages/entities/import/html-tooltip/html-tooltip.component';
import { HtmlTooltipDirective } from './pages/entities/import/html-tooltip/html-tooltip.directive';

@NgModule({
  declarations: [
    AppComponent,
    ListEntitiesComponent,
    EntityDetailsComponent,
    UpsertEntityDialogComponent,
    UpsertFieldDialogComponent,
    ListFieldsComponent,
    ListDataComponent,
    UpsertDataDialogComponent,
    ConfirmationDialogComponent,
    ListUsersComponent,
    UpsertUserDialogComponent,
    ErrorDialogComponent,
    LoginCallbackComponent,
    PageNotFoundComponent,
    RelativeTimePipe,
    AutoFocusDirective,
    SnackBarComponent,
    UserManagementComponent,
    ListRolesComponent,
    UpsertRoleDialogComponent,
    UpsertCustomRoleDialogComponent,
    NoPermissionComponent,
    FileAttachmentComponent,
    UpsertCustomRoleDialogComponent,
    EntityPickerComponent,
    FieldPickerComponent,
    FileCellComponent,
    UpsertFileDialogComponent,
    ListChoicesetsComponent,
    UpsertChoicesetDialogComponent,
    ViewSystemRoleDialogComponent,
    ChoicesetOptionsComponent,
    DependencyDialogComponent,
    EnableRbacDialogComponent,
    CreateRolePageComponent,
    RbacTooltipComponent,
    RbacTooltipDirective,
    HtmlTooltipComponent,
    HtmlTooltipDirective,
    LogoutComponent,
    ApiAccessDialogComponent,
    UpgradeEntityBarComponent,
    RecordFlyoutComponent,
    LoginComponent,
    MigrationBarComponent,
    ExportEntitiesDialogComponent,
    ImportErrorDialogComponent,
    EnableRbacSnackbarComponent,
    ImportPartialImportedDialogComponent,
    EntitiesImportComponent,
    EntitiesImportBarComponent,
    FieldsConflictBarComponent,
    AdvancedSearchComponent,
    BasicSearchComponent,
    FieldIconComponent,
    MiscIconComponent,
    TemplateDetailsComponent,
    TemplateListComponent,
    TemplateEntityDetailsComponent,
    BreadcrumbComponent,
    BreadcrumbSecondaryComponent,
    TemplateDataModelComponent,
    DeleteEntitiesConfirmationDialogComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    UiPathAuthModule,
    BrowserAnimationsModule,
    FormsModule,
    FlexLayoutModule,
    HttpClientModule,
    MatInputModule,
    MatSelectModule,
    MatButtonModule,
    MatIconModule,
    MatExpansionModule,
    MatCardModule,
    MatDialogModule,
    MatProgressBarModule,
    MatTableModule,
    MatToolbarModule,
    MatProgressSpinnerModule,
    MatPaginatorModule,
    MatTabsModule,
    MatChipsModule,
    MatTooltipModule,
    MatSortModule,
    MatCheckboxModule,
    MatRadioModule,
    MatSnackBarModule,
    MatMenuModule,
    ReactiveFormsModule,
    ResizableModule,
    CommonModule,
    ClipboardModule,
    MatDatepickerModule,
    MatNativeDateModule,
    OverlayModule,
    A11yModule,
    MatDividerModule,
    MatDatepickerModule,
    MatMomentDateModule,
    MatSlideToggleModule,
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [
    AuthGuard,
    AuthService,
    AccountService,
    StorageService,
    TelemetryService,
    PopoverDialogService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpBaseHrefInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpTraceparentInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpAuthInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpVersioningInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpLocalizationInterceptor,
      multi: true
    },
    {
      provide: UiPathAuthConfig ,
      useFactory: AuthConfigFactory,
      deps: [Location],
    },
    { provide: MatPaginatorIntl, useClass: PaginatorOverrideService },
    { provide: MAT_DATE_LOCALE, useValue: 'en-US' }, // Change 'en-US' to your desired locale
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS] },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ],
  bootstrap: [AppComponent],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA // Tells Angular we will have custom tags in our templates
  ]
})
export class AppModule { }
