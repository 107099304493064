import {
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import {
  concatMap,
  map,
} from 'rxjs/operators';

import { Constants } from '../constants';
import { AuthService } from '../guards/auth.service';
import { UrlUtils } from '../url.utils';

@Injectable({
  providedIn: 'root'
})
export class HttpAuthInterceptor implements HttpInterceptor {
  private addHeader: boolean;

  constructor(private authService: AuthService) {
    const apiHost = UrlUtils.getApiHost();
    this.addHeader = (!apiHost.endsWith('.azure-api.net/')) && (!apiHost.endsWith('.uipath.com/'));
  }

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url.indexOf('/dataservice_/api') < 0) {
      return next.handle(request);
    }

    const authHeaders = this.authService.getAccessToken$$().pipe(map(token => {
      if (token) {
        return this.buildHttpHeaders(request).append(`${Constants.Authorization}`, `${Constants.Bearer} ${token}`);
      } else {
        throw new Error(Constants.UserNotAuthorizedError);
      }
    }));
    return authHeaders.pipe(concatMap(headers => this.doHandle(request, next, headers)));
  }

  private buildHttpHeaders(request: HttpRequest<any>) {
    let headers: HttpHeaders = request.headers;
    if (this.addHeader) {
      // add account id to account id and tenant id header as there is no way to get tenant id from token
      // for localhost runtime. could set tenant id if know the value
      this.authService.getAccessTokenPayload$$().pipe(map(accountId => {
        headers = headers.append(Constants.AccountIdHeaderName, accountId)
          .append(Constants.TenantIdHeaderName, accountId);
      })).subscribe();
    }
    return headers;
  }

  private doHandle(request: HttpRequest<any>, next: HttpHandler, headers: HttpHeaders): Observable<HttpEvent<any>> {
    return next.handle(request.clone({ headers }));
  }
}
