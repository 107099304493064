<div class="list-tool-bar" fxLayout="row" fxLayoutAlign="space-between center">
    <mat-form-field floatLabel="never" class="apollo-textfield-small search-bar">
        <mat-label>{{'SearchText' | translate}}</mat-label>
        <mat-icon matSuffix>search</mat-icon>
        <input matInput autocomplete="false" [placeholder]="'SearchText' | translate" [(ngModel)]="searchValue"
            (keyup)="onSearch()" />
    </mat-form-field>
    <button mat-button color="primary" *ngIf="!isFieldsConflict()" [attr.aria-label]="'RefreshButtonText' | translate"
        class="refresh bold" (click)="onClickRefresh()">
        <mat-icon class="material-icons md-24 standard-icon">refresh</mat-icon>
        <span>{{'RefreshButtonText' | translate}}</span>
    </button>
</div>
<div *ngIf="isLoadingData" class="spinner-container">
    <mat-spinner class="spinner" role="alert" [attr.aria-label]="'Loading' | translate" diameter="48"></mat-spinner>
</div>
<div *ngIf="!isFieldsConflict() && !(entity.fields && entity.fields.length>0)" class="no-fields">{{'ListField.NoField' |
    translate}}</div>
<div *ngIf="(!isFieldsConflict() && !isLoadingData && entity.fields && entity.fields.length>0) || (isFieldsConflict() && !isLoadingData && fields)"
    class="table-container table-scroll-wrapper">
    <table mat-table [dataSource]="dataSource" matSort (matSortChange)="sortData($event)">
        <caption></caption>
        <ng-container *ngIf="!isFieldsConflict()" matColumnDef="displayName">
            <th scope="cell" mat-header-cell *matHeaderCellDef mat-sort-header
                class="table-header-overflow table-header-text font-medium bold">
                {{'ListField.DisplayName' | translate}}
                <span role="status" [attr.aria-label]="ariaHelper.getOrderKey('displayName') | translate"></span>
            </th>
            <th mat-cell *matCellDef="let element" class="th-first-cell display-name" tabindex="0">
                {{element.displayName}} </th>
        </ng-container>

        <ng-container *ngIf="isFieldsConflict()" matColumnDef="entityName">
            <th scope="cell" mat-header-cell *matHeaderCellDef mat-sort-header
                class="table-header-overflow table-header-text font-medium bold">
                {{'FieldConflict.EntityName' | translate}}
                <span role="status" [attr.aria-label]="ariaHelper.getOrderKey('entityName') | translate"></span>
            </th>
            <td mat-cell *matCellDef="let element" tabindex="0"> {{element['entityName']}} </td>
        </ng-container>

        <ng-container *ngIf="isFieldsConflict()" matColumnDef="fieldName">
            <th scope="cell" mat-header-cell *matHeaderCellDef mat-sort-header
                class="table-header-overflow table-header-text font-medium bold">
                {{'FieldConflict.FieldName' | translate}}
                <span role="status" [attr.aria-label]="ariaHelper.getOrderKey('fieldName') | translate"></span>
            </th>
            <td mat-cell *matCellDef="let element" tabindex="0"> {{element['field']['displayName']}} </td>
        </ng-container>

        <ng-container matColumnDef="name">
            <th scope="cell" mat-header-cell *matHeaderCellDef mat-sort-header
                class="table-header-overflow table-header-text font-medium bold">
                {{'ListField.Name' | translate}}
                <span role="status" [attr.aria-label]="ariaHelper.getOrderKey('name') | translate"></span>
            </th>
            <ng-container *ngIf="!isFieldsConflict()">
                <td mat-cell *matCellDef="let element"> {{element.name}} </td>
            </ng-container>
            <ng-container *ngIf="isFieldsConflict()">
                <td mat-cell *matCellDef="let element"> {{element['field']['name']}} </td>
            </ng-container>
        </ng-container>
        <ng-container *ngIf="isCdmEnabled">
            <ng-container matColumnDef="isModelReserved">
                <th scope="cell" mat-header-cell *matHeaderCellDef mat-sort-header
                    class="table-header-overflow table-header-text font-medium bold">
                    {{'ListField.IsModelReserved' | translate}}
                    <span role="status"
                        [attr.aria-label]="ariaHelper.getOrderKey('isModelReserved') | translate"></span>
                </th>
                <td mat-cell *matCellDef="let element"> {{(element.isModelReserved ? 'ListField.RequiredYes' :
                    'ListField.RequiredNo') | translate}} </td>
            </ng-container>
        </ng-container>
        <ng-container matColumnDef="type">
            <th scope="cell" mat-header-cell *matHeaderCellDef mat-sort-header
                class="table-header-overflow table-header-text font-medium bold">
                {{'ListField.Type' | translate}}
                <span role="status" [attr.aria-label]="ariaHelper.getOrderKey('type') | translate"></span>
            </th>
            <ng-container *ngIf="!isFieldsConflict()">
                <td mat-cell *matCellDef="let element" class="no-wrap">
                    <app-field-icon class="field-icon" [fieldType]="getElementType(element)"></app-field-icon>
                    {{transformFieldType(element.type, element.fieldDisplayType)}}
                </td>
            </ng-container>
            <ng-container *ngIf="isFieldsConflict()">
                <td mat-cell *matCellDef="let element">
                    {{transformFieldType(element.field.type, element.field.fieldDisplayType)}}
                </td>
            </ng-container>
        </ng-container>

        <ng-container matColumnDef="isRequired">
            <th scope="cell" mat-header-cell *matHeaderCellDef mat-sort-header
                class="table-header-overflow table-header-text font-medium bold">
                {{'ListField.Required' | translate}}
                <span role="status" [attr.aria-label]="ariaHelper.getOrderKey('isRequired') | translate"></span>
            </th>
            <ng-container *ngIf="!isFieldsConflict()">
                <td mat-cell *matCellDef="let element"> {{(element.isRequired ? 'ListField.RequiredYes' :
                    'ListField.RequiredNo') | translate}} </td>
            </ng-container>
            <ng-container *ngIf="isFieldsConflict()">
                <td mat-cell *matCellDef="let element"> {{(element['field']['isRequired'] ? 'ListField.RequiredYes' :
                    'ListField.RequiredNo') | translate}} </td>
            </ng-container>
        </ng-container>

        <ng-container matColumnDef="isUnique">
            <th scope="cell" mat-header-cell *matHeaderCellDef mat-sort-header
                class="table-header-overflow table-header-text font-medium bold">
                {{'ListField.Unique' | translate}}
                <span role="status" [attr.aria-label]="ariaHelper.getOrderKey('isUnique') | translate"></span>
            </th>
            <ng-container *ngIf="!isFieldsConflict()">
                <td mat-cell *matCellDef="let element"> {{ (element.type === 'autonumber' || element.type === 'uniqueid'
                    || element.isUnique ? 'ListField.UniqueYes' : 'ListField.UniqueNo') | translate }} </td>
            </ng-container>
            <ng-container *ngIf="isFieldsConflict()">
                <td mat-cell *matCellDef="let element"> {{(element['field']['isUnique'] ? 'ListField.UniqueYes' :
                    'ListField.UniqueNo') | translate}} </td>
            </ng-container>
        </ng-container>

        <ng-container matColumnDef="description">
            <th scope="cell" mat-header-cell *matHeaderCellDef mat-sort-header
                class="table-header-overflow table-header-text font-medium bold">
                {{'ListField.Description' | translate}}
                <span role="status" [attr.aria-label]="ariaHelper.getOrderKey('description') | translate"></span>
            </th>
            <ng-container *ngIf="!isFieldsConflict()">
                <td mat-cell *matCellDef="let element" class="description-column"> {{getDescriptionText(element) |
                    async}} </td>
            </ng-container>
            <ng-container *ngIf="isFieldsConflict()">
                <td mat-cell *matCellDef="let element" class="description-column">
                    {{getDescriptionText(element['field']) | async}} </td>
            </ng-container>
        </ng-container>

        <ng-container *ngIf="!isFieldsConflict()" matColumnDef="updateTime">
            <th scope="cell" mat-header-cell *matHeaderCellDef mat-sort-header
                class="table-header-overflow table-header-overflow table-header-text font-medium bold">
                {{'ListField.LastModifiedOn' | translate}}
                <span role="status" [attr.aria-label]="ariaHelper.getOrderKey('updateTime') | translate"></span>
            </th>
            <td mat-cell *matCellDef="let element"> {{element.updateTime | relativeTime}} </td>
        </ng-container>

        <ng-container *ngIf="!isFieldsConflict()" matColumnDef="edit">
            <th scope="cell" mat-header-cell *matHeaderCellDef> </th>
            <td mat-cell *matCellDef="let element" class="action-column">
                <div fxLayout="row" fxLayoutAlign="end" [matTooltip]="getNoPermissionToolTip(element) | async">
                    <div>
                        <button mat-icon-button [matTooltip]="getEditToolTip(element) | async"
                            [disabled]="(userService.checkPermission([UpdateEntityScheme]) | async) === false || isSystemField(element)"
                            [attr.aria-label]="'EditFieldText' | translate:{value: element.displayName}" class="add-button action-button"
                            (click)="onClickField(element)" tabindex="0">
                            <mat-icon class="material-icons md-24 standard-icon"
                                [disabled]="(userService.checkPermission([UpdateEntityScheme]) | async) === false || isSystemField(element)">
                                edit
                            </mat-icon>
                        </button>
                    </div>

                    <div *ngIf="!isRbacEnabled" [matTooltip]="getDeleteTooltip(element) | async">
                        <button mat-icon-button [attr.aria-label]="'DeleteFieldText' | translate:{value: element.displayName}" class="action-button"
                            [disabled]="(userService.checkPermission([UpdateEntityScheme]) | async) === false || isSystemField(element) || isReservedField(element)"
                            (click)="onDeleteField(element)" tabindex="0">
                            <mat-icon class="material-icons md-24 standard-icon"
                                [disabled]="(userService.checkPermission([UpdateEntityScheme]) | async) === false || isSystemField(element) || isReservedField(element)">
                                delete
                            </mat-icon>
                        </button>
                    </div>

                    <div *ngIf="isRbacEnabled" [matTooltip]="getMoreTooltip(element) | async">
                        <button mat-icon-button [matMenuTriggerFor]="menu" [attr.aria-label]="'MoreTooltipOn' | translate:{value: element.displayName}"
                            class="more action-button"
                            [disabled]="(userService.checkPermission([UpdateEntityScheme]) | async) === false || isSystemField(element) || isReservedField(element)"
                            tabindex="0" (click)="focusHelper.onLauncherClick($event)">
                            <mat-icon
                                [disabled]="(userService.checkPermission([UpdateEntityScheme]) | async) === false || isSystemField(element) || isReservedField(element)">
                                more_vert
                            </mat-icon>
                        </button>
                    </div>
                    <mat-menu #menu="matMenu">
                        <button *ngIf="!element.isRbacEnabled" mat-menu-item class="menu-item"
                            [attr.aria-label]="'EnableRbacText' | translate"
                            (click)="onEnableRoleBasedFieldAccess(element, true)">
                            {{'EnableRbacText' | translate}}
                        </button>
                        <button *ngIf="element.isRbacEnabled" mat-menu-item class="menu-item"
                            [attr.aria-label]="'DisableRbacText' | translate"
                            (click)="onEnableRoleBasedFieldAccess(element, false)">
                            {{'DisableRbacText' | translate}}
                        </button>
                        <button mat-menu-item class="menu-item" [attr.aria-label]="'DeleteText' | translate"
                            (click)="onDeleteField(element)">
                            {{'DeleteText' | translate}}
                        </button>
                    </mat-menu>

                </div>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky: true" class="table-header themed-list-header">
        </tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="table-row"></tr>
    </table>
</div>