import { Component } from '@angular/core';
import { UrlUtils } from 'src/app/shared/url.utils';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundComponent {

  constructor() {
    // intentional empty constructor
  }

  public getHomeRoute(): string {
    return `${UrlUtils.getPreviousPath()}/entities`;
  }
}
