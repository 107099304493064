import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { UrlUtils } from '../url.utils';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard  {

  constructor(private authService: AuthService, private router: Router) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this.authService.userProfile) {
      return true;
    }
    this.authService.setRequestUri();
    const context = UrlUtils.getRequestContext();
    const loginUri = `${context.account}/${context.tenant}/${context.serviceType}/login`;
    this.router.navigate([loginUri]);
    return false;
  }
}
