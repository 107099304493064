import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Constants } from 'src/app/shared/constants';
import { ChoiceSetMember } from 'src/app/shared/models/choiceset';
import { EntitiesDataService } from 'src/app/shared/services/entities-data.service';

@Component({
  selector: 'app-choiceset-options',
  templateUrl: './choiceset-options.component.html',
  styleUrls: ['./choiceset-options.component.scss']
})
export class ChoicesetOptionsComponent implements OnInit, AfterViewInit {
  @Input('choiceSetId')
  public choiceSetId: string;

  @Input('fieldDisplayType')
  public fieldDisplayType: string;

  @Input('value')
  public value: number | number[];

  @Input('choicesetMembers')
  public choicesetMembers: ChoiceSetMember[];

  @Output()
  public selectedEvent: EventEmitter<number | number[]> = new EventEmitter<number | number[]>();

  @Output()
  public blurEvent: EventEmitter<void> = new EventEmitter<void>();

  public members: ChoiceSetMember[] = [];

  public isLoadingMembers = false;

  public formControl = new UntypedFormControl();

  public searchText = '';

  @ViewChild('matSelect') matSelect;

  constructor(
    private dataService: EntitiesDataService,
  ) { }

  ngOnInit(): void {
    this.formControl.setValue(this.value);
    this.loadMembers();
  }

  ngAfterViewInit(): void {
    this.matSelect.open();
  }

  public get isMultiple(): boolean {
    return this.fieldDisplayType === Constants.ChoiceSetMultiple;
  }

  public get selectedMemberIds(): number[] {
    return (this.isMultiple ? this.value : [this.value]) as number[];
  }

  public isHidden(displayName: string): boolean {
    if (!this.searchText) {
      return false;
    }

    return !displayName.toLocaleLowerCase().includes(this.searchText.toLocaleLowerCase());
  }

  public onSelect(event: any): void {
    if (!this.isMultiple && event.isUserInput) {
      this.selectedEvent.emit(event.source.value);
    }
  }

  public onClearValue(): void {
    this.formControl.setValue(null);

    if (!this.isMultiple) {
      this.selectedEvent.emit(null);
      this.matSelect.close();
    }
  }

  public onClose(): void {
    if (this.isMultiple) {
      this.selectedEvent.emit(this.formControl.value);
    }

    this.blurEvent.emit();
  }

  private loadMembers(): void {
    if (this.choicesetMembers) {
      this.members = this.choicesetMembers;
      return;
    }

    this.isLoadingMembers = true;

    this.dataService.queryChoiceSetMembers(this.choiceSetId).subscribe(result => {
      this.members = result.value as any;
      this.isLoadingMembers = false;
    });
  }
}
