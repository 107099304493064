import {
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';

import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Constants } from '../constants';
import { getLanguage } from '../utilities';

@Injectable({
  providedIn: 'root'
})
export class HttpLocalizationInterceptor implements HttpInterceptor {
  constructor() {}

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const headers: HttpHeaders = request.headers.append(Constants.LocalizationHeader, getLanguage());
    return next.handle(request.clone({ headers }));
  }
}