import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { EMPTY, Subscription } from 'rxjs';
import { expand, first, reduce } from 'rxjs/operators';
import { ErrorDialogComponent } from 'src/app/shared/components/error-dialog/error-dialog.component';
import { Constants } from 'src/app/shared/constants';
import { Events } from 'src/app/shared/models/events';
import {
  GetRoleResponse,
  Role,
  RoleResponse,
  UserGroup } from 'src/app/shared/models/user';
import { FeatureKey, FeatureService } from 'src/app/shared/services/feature.service';
import { TelemetryService } from 'src/app/shared/services/telemetry.service';
import { UserService } from 'src/app/shared/services/user.service';
import { UrlUtils } from '../../../shared/url.utils';
import { DisplayFilter } from '../list-users/list-users.component';

import { Breadcrumb } from 'src/app/shared/components/breadcrumb/breadcrumb.component';
import { RolesService } from 'src/app/shared/services/roles.service';

@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UserManagementComponent implements OnInit, OnDestroy {
  public userGroups: UserGroup[];
  public roles: GetRoleResponse[];
  public breadcrumbs: Breadcrumb[];
  public ManagePermission: number = Constants.ManagePermission;
  public tabIndex = 0;
  public isAdminConsistencyEnabled = false;
  public isLoading = false;
  public displayFilters: DisplayFilter[] = [
    { displayName: 'Administrator', value: Role.admin },
    { displayName: 'Designer', value: Role.designer },
    { displayName: 'Data Writer', value: Role.dataWriter },
    { displayName: 'Data Reader', value: Role.dataReader },
  ];
  private subscription: Subscription = new Subscription();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public userService: UserService,
    public dialog: MatDialog,
    private telemetryService: TelemetryService,
    public featureService: FeatureService,
    public translateService: TranslateService,
    public rolesService: RolesService,
  ) {}

  public ngOnInit() {
    this.telemetryService.startTimedEvent(Events.UserManagementView);

    const fsSub = this.featureService.isEnabled(FeatureKey.AdminConsistency)
      .subscribe(result => {
        this.isAdminConsistencyEnabled = result;
      });

    this.subscription.add(fsSub);

    this.breadcrumbs = [
      {
        key: 'UserManagement.EntitiesText',
        url: `${UrlUtils.getBaseRouteUrl()}/entities`},
      {
        key: 'UserManagement.Title'
      }
    ];
    const sub = this.route.queryParams.subscribe((params) => {
      const index = params['index'];
      if (index === 'role') {
        this.tabIndex = 1;
      }
    });
    this.subscription.add(sub);

    this.refreshUserAndRole();

    this.translateService
      .get('BrowserTitleForUserManagement')
      .subscribe((s) => {
        document.title = s;
      });
  }

  public ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  public goBack() {
    window.history.back();
  }

  public refreshUserAndRole(event?: any): void {
    if (event) {
      this.tabIndex = event.index;
    }
    this.isLoading = true;
    this.loadUsersAndGroups();
    this.loadRolesV2();
  }

  public onUpsertRole() {
    this.telemetryService.logEvent(Events.CreateNewRoleClick);

    const sub = this.rolesService.openUpsertRoleDialog().subscribe(result => {
      if (result) {
        this.loadRolesV2();
      }
    });
    this.subscription.add(sub);
  }

  public loadRolesV2() {
    const sub = this.rolesService.loadRolesV2().subscribe(
      roles => {
        this.roles = roles;
        if (this.tabIndex === 1) {
          this.isLoading = false;
        }
      }
    );
    this.subscription.add(sub);
  }

  public onUpsertUserGroup() {
    this.telemetryService.logEvent(Events.AddUserGroupClick);

    const sub = this.rolesService.openUpsertUserDialog(this.roles).subscribe(result => {
      if (result) {
        this.loadUsersAndGroups();
      }
    });

    this.subscription.add(sub);
  }

  public retreiveUsersAndGroups() {
    let skip = 0;
    const top = 20;
    return this.userService.getUsersAndGroups(skip, top).pipe(first()).pipe(
      expand((resp) => {
        skip = skip + resp.results.length;
        return skip < resp.totalCount
          ? this.userService.getUsersAndGroups(skip, top).pipe(first())
          : EMPTY;
      }),
      reduce((a, v) => [...a, ...v.results], [])
    );
  }

  public loadUsersAndGroups(): void {
    // todo we need to refactor this to properly handle pagination on the list-users.component
    const sub = this.retreiveUsersAndGroups().subscribe(
      (results) => {
        const users = results.map((itm) => {
          return new UserGroup(
            itm.externalId,
            itm.name,
            itm.email,
            itm.type,
            itm.objectType,
            true,
            itm.roles,
            this.convertToDisplayRole(itm.roles)
          );
        });

        users.sort((u1, u2) => (u1.type > u2.type ? 1 : -1));
        this.userGroups = users;
        this.telemetryService.endTimedEvent(Events.UserManagementView);
        this.telemetryService.endTimedEvent(Events.AppLoad);
        if (this.tabIndex === 0) {
          this.isLoading = false;
        }
      },
      (error) => {
        if (error.errorText && error.errorText.indexOf('403') > -1) {
          this.router.navigate([`${UrlUtils.getBaseRouteUrl()}/entities`]);
        } else if (error && JSON.stringify(error).indexOf('401') < 0) {
          this.dialog.open(ErrorDialogComponent, { data: error });
        }
      }
    );
    this.subscription.add(sub);
  }

  private convertToDisplayRole(roles: RoleResponse[]): string {
    const result: string[] = [];

    if (roles && roles.length > 0) {
      for (const role of roles) {
        const systemRole = this.displayFilters.find(
          (df) => df.value === role.name
        );
        if (systemRole) {
          this.translateService
            .get('ListUser.' + systemRole.displayName.replace(/\s/g, ''))
            .subscribe((translated) => {
              result.push(translated);
            });
        } else {
          result.push(role.name);
        }
      }
    }
    return result.toString().replace(new RegExp(',', 'g'), ', ');
  }
}
