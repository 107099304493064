import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { Entity, SearchEntityResponse } from '../../models/entity';
import { EntitiesService } from '../../services/entities.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Field } from '../../models/field';
import { UrlUtils } from '../../url.utils';
import { Subscription } from 'rxjs';

export interface EntityEntry {
  entity: Entity;
  checked: boolean;
}

@Component({
  selector: 'field-picker',
  templateUrl: './field-picker.component.html',
  styleUrls: ['./field-picker.component.scss']
})
export class FieldPickerComponent implements OnInit, OnDestroy {
  public entityId: string = null;
  public fields: Field[] = null;
  public isLoading: boolean;

  public selectedFieldIds: string[] = [];
  public selectedFields: Field[] = [];

  public snapshot: Field[] = [];

  public searchValue: string;

  private entities: SearchEntityResponse[] = null;
  private offsetX: number;
  private offsetY: number;
  private isClosed: boolean;
  private subscription: Subscription = new Subscription();

  constructor(
    public dialogRef: MatDialogRef<FieldPickerComponent>,
    private entitiesService: EntitiesService,
    @Inject(MAT_DIALOG_DATA) public options: {
      offsetX: number,
      offsetY: number,
      entityId: string,
      fields: Field[],
      selectedFieldIds: string[]
    }
  ) {
    this.offsetX = options.offsetX;
    this.offsetY = options.offsetY;
    this.entityId = options.entityId;
    this.fields = options.fields;
    this.selectedFieldIds = options.selectedFieldIds;
  }

  public ngOnInit() {
    const topPosition = this.offsetY + 300 > window.innerHeight ? this.offsetY - 300 : this.offsetY;
    this.dialogRef.updatePosition({ left: `${this.offsetX}px`, top: `${topPosition}px` });
    this.isClosed = false;
    if (this.fields === null) {
      this.isLoading = true;
      this.entitiesService.searchEntities().subscribe((result) => {
        this.isLoading = false;
        this.entities = result;
        this.fields = result.find(ent => ent.id === this.entityId).fields;
        this.setFields();
      }, (error) => {
        this.isLoading = false;
      });
    } else {
      this.setFields();
    }

  }

  public ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  public onSelectField(event: any, field: Field) {
    if (event.checked) {
      this.selectedFields.push(field);
    } else {
      this.selectedFields.splice(this.selectedFields.indexOf(field), 1);
    }
    // emit
    const sub = this.dialogRef.beforeClosed().subscribe(itm => {
      if (!this.isClosed) {
        this.isClosed = true;
        this.dialogRef.close(
          {
            selected: this.selectedFields,
            fields: this.fields,
            entities: this.entities,
          });
      }
    });
    this.subscription.add(sub);
  }

  public onAddFields(): void {
    if (!this.isClosed) {
      this.isClosed = true;
      this.dialogRef.close({
        selected: this.selectedFields,
        fields: this.fields,
        entities: this.entities,
      });
    }
  }

  public shouldFieldChecked(field: Field) {
    return this.selectedFields.indexOf(field) >= 0;
  }

  public onSearch() {
    if (!this.searchValue) {
      this.fields = [...this.snapshot];
      return;
    }

    this.fields = this.fields.filter(itm => itm.displayName.toLocaleLowerCase().indexOf(this.searchValue.toLocaleLowerCase()) > -1);
  }

  public onClickEnableRbac() {
    const url = `${UrlUtils.getBaseRouteUrl()}/entities/${this.entityId}`;
    window.open(url, '_blank');
  }

  private setFields(): void {
    for (const fieldIdToRemove of this.selectedFieldIds) {
      this.fields = this.fields.filter(fld => fld.id !== fieldIdToRemove);
    }
    this.snapshot = [...this.fields];
  }
}
