<mat-tab-group>
  <mat-tab [label]="'TemplateDetails.Schema' | translate">
    <table class="template-entity-details-table" mat-table [dataSource]="dataSource" matSort (matSortChange)="sortData($event)">
      <caption></caption>
      <ng-container matColumnDef="displayName">
        <th scope="cell" mat-header-cell *matHeaderCellDef mat-sort-header
          class="table-header-overflow table-header-text font-medium bold first-column">
          {{'ListField.DisplayName' | translate}}
        </th>
        <td mat-cell *matCellDef="let element" class="th-first-cell display-name color-foreground-link" tabindex="0">
          {{getDisplayNameText(element) | async}}
        </td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th scope="cell" mat-header-cell *matHeaderCellDef mat-sort-header
          class="table-header-overflow table-header-text font-medium bold">
          {{'ListField.Name' | translate}}
        </th>
        <ng-container>
          <td mat-cell *matCellDef="let element"> {{element.name}} </td>
        </ng-container>
      </ng-container>

      <ng-container matColumnDef="isModelReserved">
        <th scope="cell" mat-header-cell *matHeaderCellDef mat-sort-header
          class="table-header-overflow table-header-text font-medium bold">
          {{'ListField.IsModelReserved' | translate}}
        </th>
        <ng-container>
          <td mat-cell *matCellDef="let element"> {{(element.isModelReserved ? 'ListField.UniqueYes' : 'ListField.UniqueNo') | translate}} </td>
        </ng-container>
      </ng-container>

      <ng-container matColumnDef="type">
        <th scope="cell" mat-header-cell *matHeaderCellDef mat-sort-header
          class="table-header-overflow table-header-text font-medium bold type-column">
          {{'ListField.Type' | translate}}
        </th>
        <ng-container>
          <td mat-cell *matCellDef="let element" class="no-wrap">
            <app-field-icon class="field-icon" [fieldType]="getElementType(element)"></app-field-icon>
            {{transformFieldType(element.type, element.fieldDisplayType)}}
          </td>
        </ng-container>
      </ng-container>

      <ng-container matColumnDef="isRequired">
        <th scope="cell" mat-header-cell *matHeaderCellDef mat-sort-header
          class="table-header-overflow table-header-text font-medium bold">
          {{'ListField.Required' | translate}}
        </th>
        <ng-container>
          <td mat-cell *matCellDef="let element"> {{(element.isRequired ? 'ListField.RequiredYes' :
            'ListField.RequiredNo') | translate}} </td>
        </ng-container>
      </ng-container>

      <ng-container matColumnDef="isUnique">
        <th scope="cell" mat-header-cell *matHeaderCellDef mat-sort-header
          class="table-header-overflow table-header-text font-medium bold">
          {{'ListField.Unique' | translate}}
        </th>
        <ng-container>
          <td mat-cell *matCellDef="let element"> {{ (element.type === 'autonumber' || element.type === 'uniqueid' ||
            element.isUnique ? 'ListField.UniqueYes' : 'ListField.UniqueNo') | translate }} </td>
        </ng-container>
      </ng-container>

      <ng-container matColumnDef="description">
        <th scope="cell" mat-header-cell *matHeaderCellDef mat-sort-header
          class="table-header-overflow table-header-text font-medium bold">
          {{'ListField.Description' | translate}}
        </th>
        <ng-container>
          <td mat-cell *matCellDef="let element" class="description-column"> {{getDescriptionText(element) |
            async}} </td>
        </ng-container>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky: true" class="table-header themed-list-header">
      </tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="table-row"></tr>
    </table>
    <mat-paginator *ngIf="(usedData.length > 0)" [length]="usedData.length" [pageSize]="10"
        [pageSizeOptions]="[10, 20, 50]" (page)="onChangePagination($event)">
    </mat-paginator>
  </mat-tab>
  
  <!--
    TODO: Triggers
  <div *ngIf="displayTriggers && displayTriggers.length > 0">
    <div *ngFor="let trigger of displayTriggers">
      <div fxLayout="row" fxLayoutAlign="start center">
        <div class="font-small trigger-chip">
          {{trigger.description}}
        </div>
        <button mat-icon-button color="primary" class="font-small close-btn" (click)="onRemoveTrigger(trigger)">
          <mat-icon class="close-icon">close</mat-icon>
        </button>
      </div>

    </div>
  </div>
  <div>
    <form>
      <mat-form-field floatLabel="always" class="trigger-input">
        <mat-label>Select Event</mat-label>
        <mat-select [(ngModel)]="selectedCondition" name="field1">
          <mat-option *ngFor="let option of conditions" [value]="option.value">
            {{ option.viewValue }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field floatLabel="always" *ngIf="selectedCondition===1" class="trigger-input">
        <mat-label>Select Column</mat-label>
        <mat-select [(ngModel)]="selectedField" name="field2">
          <mat-option *ngFor="let field of entity.fields" [value]="field">
            {{ field.displayName }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field floatLabel="always" *ngIf="selectedCondition>=0" class="trigger-input">
        <mat-label>Select Operation</mat-label>
        <mat-select [(ngModel)]="selectedOperation" name="field3">
          <mat-option *ngFor="let option of conditions" [value]="option.value">
            {{ option.viewValue }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field floatLabel="always" *ngIf="selectedOperation>=0" class="trigger-input">
        <mat-label>Select target entity</mat-label>
        <mat-select [(ngModel)]="targetEntity" name="field4">
          <mat-option *ngFor="let entity of template.entities" [value]="entity">
            {{ entity.displayName }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field floatLabel="always" *ngIf="targetEntity && selectedOperation===1" class="trigger-input">
        <mat-label>Select target field</mat-label>
        <mat-select [(ngModel)]="targetField" name="field4">
          <mat-option *ngFor="let field of targetEntity.fields" [value]="field">
            {{ field.displayName }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field floatLabel="always" *ngIf="targetEntity && selectedOperation===1" class="trigger-input">
        <mat-label>Input new value</mat-label>
        <input matInput [(ngModel)]="targetValue" name="targetValue">
      </mat-form-field>

      <mat-form-field floatLabel="always" *ngIf="targetEntity" class="trigger-input">
        <mat-label>Based on field</mat-label>
        <mat-select [(ngModel)]="baseField" name="baseField">
          <mat-option *ngFor="let field of targetEntity.fields" [value]="field">
            {{ field.displayName }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </form>
    <button mat-stroked-button (click)="onAddTrigger()">Add trigger</button>
  </div>
  -->
</mat-tab-group>
