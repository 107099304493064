/**
 * simplify version of AppInsights implementation for generating traceparent
 * @see https://github.com/microsoft/ApplicationInsights-node.js/blob/63d53a0ce36f65b98d17a9df616e58dfb3e8f005/Library/Traceparent.ts
 */
export class Traceparent {
  private static DEFAULT_TRACE_FLAG = '01';
  private static DEFAULT_VERSION = '00';

  public traceId: string;
  private spanId: string;
  private traceFlag = Traceparent.DEFAULT_TRACE_FLAG;
  private version = Traceparent.DEFAULT_VERSION;

  constructor() {
    this.traceId = this.w3cTraceId();
    this.spanId = this.w3cTraceId().substr(0, 16);
  }

  public toString(): string {
    return `${this.version}-${this.traceId}-${this.spanId}-${this.traceFlag}`;
  }

  /**
   * generate W3C-compatible trace id
   * @see https://github.com/microsoft/ApplicationInsights-node.js/blob/63d53a0ce36f65b98d17a9df616e58dfb3e8f005/Library/Util.ts
   */
  private w3cTraceId() {
    const hexValues = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'a', 'b', 'c', 'd', 'e', 'f'];

    // rfc4122 version 4 UUID without dashes and with lowercase letters
    let oct = '';
    let tmp: number;
    for (let a = 0; a < 4; a++) {
      tmp = this.random32();
      oct +=
        hexValues[tmp & 0xF] +
        hexValues[tmp >> 4 & 0xF] +
        hexValues[tmp >> 8 & 0xF] +
        hexValues[tmp >> 12 & 0xF] +
        hexValues[tmp >> 16 & 0xF] +
        hexValues[tmp >> 20 & 0xF] +
        hexValues[tmp >> 24 & 0xF] +
        hexValues[tmp >> 28 & 0xF];
    }

    // "Set the two most significant bits (bits 6 and 7) of the clock_seq_hi_and_reserved to zero and one, respectively"
    const clockSequenceHi = hexValues[8 + (Math.random() * 4) | 0];
    return oct.substr(0, 8) + oct.substr(9, 4) + '4' + oct.substr(13, 3) + clockSequenceHi + oct.substr(16, 3) + oct.substr(19, 12);
  }

  private random32() {
    return (0x100000000 * Math.random()) | 0;
  }
}
