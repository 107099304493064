import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackBarComponent, SnackBarMode } from 'src/app/shared/components/snack-bar/snack-bar.component';
import { TranslateService } from '@ngx-translate/core';
import { EntitiesService } from 'src/app/shared/services/entities.service';
import { downloadBlob } from 'src/app/shared/utilities';
import { getLanguage } from 'src/app/shared/utilities';

const DownloadFileName = 'Schema.json';

@Component({
  selector: 'app-export-entities-dialog',
  templateUrl: './export-entities-dialog.component.html',
  styleUrls: ['./export-entities-dialog.component.scss']
})
export class ExportEntitiesDialogComponent{

  public isDownloading = false;
  private downloadFn = downloadBlob;

  constructor(
    private dialogRef: MatDialogRef<ExportEntitiesDialogComponent>,
    private snackBar: MatSnackBar,
    private translateService: TranslateService,
    private entitiesService: EntitiesService,
  ) { }

  public onClose() {
    this.dialogRef.close();
  }

  public onClickHere() {
    let language = getLanguage();
    if (language.toLowerCase() === "zh-cn") {
      language = "zh-CN";
    }
    const url = `https://docs.uipath.com/${language}/data-service/automation-cloud/latest/user-guide/schema-export-and-import/`;
    window.open(url, '_blank');
  }

  public onDownload() {
    this.isDownloading = true;
    const content = this.entitiesService.getExportSchema().subscribe((result) => {
      const indent = 2;
      const blobContent = [JSON.stringify(result, null, indent)];
      const blob = new Blob(blobContent, { type: 'application/json' });
      this.downloadFn(blob);
      this.onClose();
    },
    () => {
      this.snackBar.openFromComponent(
        SnackBarComponent,
        {
          duration: 5000, verticalPosition: 'top', data: {
            mode: SnackBarMode.error, text: this.translateService.get('ExportEntitiesDialog.DownloadError')
          }
        }
      );

      this.isDownloading = false;
    }
    );
  }

  public getFileName() {
    return DownloadFileName;
  }

}
