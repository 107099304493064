<div class="side-pane-container">
    <h1 mat-dialog-title class="bold font-large">{{'UpsertRoleDialog.ViewPermissions' | translate}}</h1>
    <div mat-dialog-content class="dialog-content">
        <ng-container *ngIf="data.mode === 'Update'">
            <mat-form-field floatLabel="always" class="full-width">
                <mat-label>{{'UpsertRoleDialog.Role' | translate}}</mat-label>
                <input matInput id="name" name="name" disabled [value]="data.role.displayName" />
            </mat-form-field>
            <div class="label">{{'UpsertRoleDialog.Permissions' | translate}}</div>
            <div fxLayout="row" class="manage-permissions">
                <div class="permission-mark">
                    <mat-icon *ngIf="data.role.name===Admin" class="right">done</mat-icon>
                    <mat-icon *ngIf="data.role.name!==Admin" class="wrong">clear</mat-icon>
                </div>
                <div class="permission-text font-medium">
                    {{'UpsertRoleDialog.ManagePermissions' | translate}}
                </div>
                <mat-icon matTooltip="Users with this permission can grant or revoke roles for groups or users">
                    info_outline
                </mat-icon>
            </div>

            <div fxLayout="row" class="list-header-text list-row">
                <div class="cell-1"></div>
                <div class="cell font-semi-small">{{'ReadText' | translate}}</div>
                <div class="cell font-semi-small">{{'CreateText' | translate}}</div>
                <div class="cell font-semi-small">{{'EditText' | translate}}</div>
                <div class="cell font-semi-small">{{'DeleteText' | translate}}</div>
            </div>
            <div fxLayout="row" class="list-row list-row-text">
                <div class="cell-1 font-medium">{{'SchemaText' | translate}}</div>
                <div class="cell">
                    <mat-icon *ngIf="shouldShowCheck(ReadEntityScheme, data.role)" class="right cell-icon">
                        done
                    </mat-icon>
                    <mat-icon *ngIf="!shouldShowCheck(ReadEntityScheme, data.role)" class="wrong cell-icon">
                        clear
                    </mat-icon>
                </div>
                <div class="cell">
                    <mat-icon *ngIf="shouldShowCheck(UpdateEntityScheme, data.role)" class="right cell-icon">
                        done
                    </mat-icon>
                    <mat-icon *ngIf="!shouldShowCheck(UpdateEntityScheme, data.role)" class="wrong cell-icon">
                        clear
                    </mat-icon>
                </div>
                <div class="cell">
                    <mat-icon *ngIf="shouldShowCheck(UpdateEntityScheme, data.role)" class="right cell-icon">
                        done
                    </mat-icon>
                    <mat-icon *ngIf="!shouldShowCheck(UpdateEntityScheme, data.role)" class="wrong cell-icon">
                        clear
                    </mat-icon>
                </div>
                <div class="cell">
                    <mat-icon *ngIf="shouldShowCheck(UpdateEntityScheme, data.role)" class="right cell-icon">
                        done
                    </mat-icon>
                    <mat-icon *ngIf="!shouldShowCheck(UpdateEntityScheme, data.role)" class="wrong cell-icon">
                        clear
                    </mat-icon>
                </div>
            </div>
            <div fxLayout="row" class="list-row">
                <div class="cell-1 font-medium">{{'DataText' | translate}}</div>
                <div class="cell">
                    <mat-icon *ngIf="shouldShowCheck(ReadEntityData, data.role)" class="right cell-icon">
                        done
                    </mat-icon>
                    <mat-icon *ngIf="!shouldShowCheck(ReadEntityData, data.role)" class="wrong cell-icon">
                        clear
                    </mat-icon>
                </div>
                <div class="cell">
                    <mat-icon *ngIf="shouldShowCheck(CreateEntityData, data.role)" class="right cell-icon">
                        done
                    </mat-icon>
                    <mat-icon *ngIf="!shouldShowCheck(CreateEntityData, data.role)" class="wrong cell-icon">
                        clear
                    </mat-icon>
                </div>
                <div class="cell">
                    <mat-icon *ngIf="shouldShowCheck(UpdateEntityData, data.role)" class="right cell-icon">
                        done
                    </mat-icon>
                    <mat-icon *ngIf="!shouldShowCheck(UpdateEntityData, data.role)" class="wrong cell-icon">
                        clear
                    </mat-icon>
                </div>
                <div class="cell">
                    <mat-icon *ngIf="shouldShowCheck(DeleteEntityData, data.role)" class="right cell-icon">
                        done
                    </mat-icon>
                    <mat-icon *ngIf="!shouldShowCheck(DeleteEntityData, data.role)" class="wrong cell-icon">
                        clear
                    </mat-icon>
                </div>
            </div>
            <div fxLayout="row"></div>
        </ng-container>
    </div>

    <div mat-dialog-actions fxLayout="row" fxLayoutAlign="end strech">
        <button mat-stroked-button color="primary" class="btn bold" (click)="onDismiss()">Close</button>
    </div>
</div>