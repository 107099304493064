<div class="entity-picker">
    <ng-container *ngIf="isLoading">
        <mat-spinner role="alert" [attr.aria-label]="'Loading' | translate" class="spinner" diameter="24"></mat-spinner>
    </ng-container>
    <ng-container *ngIf="!isLoading">
        <div class="mat-small search-bar">
            <input class="input-box" [placeholder]="'SearchText' | translate" [(ngModel)]="searchValue"
                (keyup)="onSearch()">
        </div>
        <div class="no-entity font-semi-small" *ngIf="entities && entities.length===0">
            <div>{{'EntityPicker.NoEntityText' | translate}}</div>
        </div>
        <div class="option-list" *ngIf="entities && entities.length>0">
            <ng-container *ngFor="let entityEntry of entities">
                <div class="entity-content option" (click)="onSelectEntity(entityEntry)" tabindex="0"
                    (keydown.enter)="onSelectEntity(entityEntry)" [matTooltip]="entityEntry.displayName">
                    {{entityEntry.displayName}}</div>
            </ng-container>
        </div>
        <div class="divider"></div>
        <div class="bottom-section font-semi-small">
            {{'EntityPicker.HelpText' | translate}}
        </div>
    </ng-container>
</div>