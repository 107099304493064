import { Component, OnInit, Inject} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-import-error-dialog',
  templateUrl: './import-error-dialog.component.html',
  styleUrls: ['./import-error-dialog.component.scss']
})
export class ImportErrorDialogComponent implements OnInit {

  public errorText: Observable<string>;

  constructor(
    public dialogRef: MatDialogRef<ImportErrorDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    this.errorText = this.data.text;
  }

  public onClose() {
    this.dialogRef.close();
  }

}
