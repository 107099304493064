import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { zip } from 'rxjs';
import { EnvironmentsService } from '../../services/environments.service';

@Component({
  selector: 'app-migration-bar',
  templateUrl: './migration-bar.component.html',
  styleUrls: ['./migration-bar.component.scss']
})
export class MigrationBarComponent implements OnInit {

  public isDismissed = false;
  public tierMigrationMessage = '';
  public regionMigrationMessage = '';

  constructor(
    private environmentService: EnvironmentsService,
    private translateService: TranslateService,
  ) { }

  ngOnInit(): void {
    this.pollMigrationStatus();
  }

  public onDismiss() {
    this.isDismissed = true;
  }

  private pollMigrationStatus(): void {
    this.environmentService.getEnvironment().subscribe(env => {
      const { tierMigration, regionMigration } = env;
      const isTierMigrationActive = tierMigration && tierMigration.isActive;
      const isRegionMigrationActive = regionMigration && regionMigration.isActive;

      if (isTierMigrationActive) {
        this.translateService.get('MigrationBar.TierMigrationMessage').subscribe(msg => {
          this.tierMigrationMessage = msg;
        });
      }

      if (isRegionMigrationActive) {
        const { sourceRegion, targetRegion } = regionMigration;

        zip(
          this.translateService.get(`Region${sourceRegion}`),
          this.translateService.get(`Region${targetRegion}`)
        ).subscribe(regionNames => {
          const [src, dst] = regionNames;
          this.translateService.get('MigrationBar.RegionMigrationMessage', { src, dst }).subscribe(msg => {
            this.regionMigrationMessage = msg;
          });
        });
      }

      if (isTierMigrationActive || isRegionMigrationActive) {
        const delay = 10 * 60 * 1000; // Poll every ten minutes
        setTimeout(() => this.pollMigrationStatus(), delay);
      } else {
        this.isDismissed = true;
      }
    });
  }
}
