<app-migration-bar></app-migration-bar>
<app-upgrade-entity-bar *ngIf="entity" [entity]="entity" (postUpgradeAction)="onRefreshEntity()">
</app-upgrade-entity-bar>

<div fxLayout="row" fxLayoutAlign="space-between center" class="title-section">
  <h1 class="bold font-large">
    <app-breadcrumb-secondary [breadcrumbs]="breadcrumbs"></app-breadcrumb-secondary>
    {{ entity.displayName }}
  </h1>
  <div *ngIf="entity" fxLayout="row" fxLayoutAlign=" center">
    <mat-icon class="material-icons md-16 swap-icon mat-icon">swap_vert</mat-icon>
    <div [matTooltip]="getTooltipText(entity) | async">
      <span class="mat-button-wrapper bulk-import-button bold" mat-stroked-button [attr.aria-label]="'ListEntities.BulkImport' | translate"
        (click)="file.click()" [class.disabled]="isSystemEntity(entity)
        || isUnImportTypeRequired(entity) || (userService.checkPermission([CreateEntityData], entity.id) | async) === false">
        {{'ListEntities.BulkImport' | translate}}
        <input #file type="file" (change)="selectFile($event.srcElement.files)" style="display:none;"
          accept=".csv,text/csv" (click)="file.value='';" />
      </span>
    </div>
    <div [matTooltip]="getAddFieldTooltip(entity) | async">
      <button mat-stroked-button [attr.aria-label]="'EntityDetails.CreateNewField' | translate"
        class="new-field-button bold" (click)="onCreateField()" [class.disabled]="isSystemEntity(entity)
        || (userService.checkPermission([UpdateEntityScheme]) | async)===false">
        {{'EntityDetails.CreateNewField' | translate}}
      </button>
    </div>
    <div [matTooltip]="getAddDataToolTip() | async">
      <button mat-stroked-button [attr.aria-label]="'ListData.AddData' | translate" class="add-data-button bold"
        [class.disabled]="isSystemEntity(entity)
        || (userService.checkPermission([CreateEntityData], entity.id) | async) === false
        || (entity.fields.length === 5 && !entity.isRbacEnabled)
        || (entity.fields.length === 6 && entity.isRbacEnabled)" (click)="onClickAddData()">
        {{'ListData.AddData' | translate}}
      </button>
    </div>
  </div>
</div>

<ng-container *ngIf="isLoadingData">
  <mat-spinner class="spinner" role="alert" [attr.aria-label]="'Loading' | translate" diameter="48"></mat-spinner>
</ng-container>
<ng-container *ngIf="entity">
  <mat-tab-group [selectedIndex]=viewIndex (selectedTabChange)="tabClick($event)">
    <mat-tab [label]="'FieldsText' | translate">
      <ng-container
        *ngIf="userService.checkPermission([ReadEntityScheme, UpdateEntityScheme, ReadEntityData, UpdateEntityData, CreateEntityData, DeleteEntityData], entity.id) | async">
        <list-fields [entity]="entity" [entities]="entities" [choicesets]="choicesets"
          (refreshEntity)="onRefreshEntity()">
        </list-fields>
      </ng-container>
      <ng-container
        *ngIf="(userService.checkPermission([ReadEntityScheme, UpdateEntityScheme, ReadEntityData, UpdateEntityData, CreateEntityData, DeleteEntityData], entity.id) | async)===false">
        <app-no-permission></app-no-permission>
      </ng-container>
    </mat-tab>
    <mat-tab [label]="'DataText' | translate">
      <ng-container
        *ngIf="userService.checkPermission([ReadEntityData, UpdateEntityData, CreateEntityData, DeleteEntityData], entity.id) | async">
        <list-data [entity]="entity" [entities]="entities" [choicesets]="choicesets" [advancedQueryRequest]="advancedQueryRequest"
          [addDataToolTip]="getAddDataToolTip" (refreshEntity)="onRefreshEntity()"></list-data>
      </ng-container>
      <ng-container
        *ngIf="(userService.checkPermission([ReadEntityData, UpdateEntityData, CreateEntityData, DeleteEntityData], entity.id) | async)===false">
        <app-no-permission></app-no-permission>
      </ng-container>
    </mat-tab>
  </mat-tab-group>
</ng-container>
