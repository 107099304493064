import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'basic-search',
  templateUrl: './basic-search.component.html',
  styleUrls: ['./basic-search.component.scss']
})
export class BasicSearchComponent {
  constructor(
        public dialogRef: MatDialogRef<BasicSearchComponent>,
  ) {
  }

  public onDismiss(): void {
    this.dialogRef.close();
  }
  public onSubmit(): void {
    this.dialogRef.close(
      {
        confirmClose: true,
      }
    );
  }
}