export interface Event {
    Name: string;
    SessionId?: string;
    CloudUserId?: string;
    CloudOrganizationId?: string;
    CloudTenantId?: string;
    LanguageCode?: string;
    Time?: number;
    DurationMilliSeconds?: number;
    Data?: {};
}

export interface ErrorDetails {
    name?: string;
    type: ErrorType;
    message: string;
    data?: {};
}

export enum ErrorType {
    fatal = 'fatal',
    warning = 'warning'
}

export class Events {
  public static AppLoad: Event = {
    Name: 'Application.Load'
  };

  public static readonly EntityRecordLevelEnable: Event = {
    Name: 'Entity.RecordLevelEnable'
  };

  public static readonly EntityRecordLevelDisable: Event = {
    Name: 'Entity.RecordLevelDisable'
  };

  public static AppStart: Event = {
    Name: 'Application.Start'
  };

  public static OnTenantChange: Event = {
    Name: 'Tenant.Change'
  };

  public static ClientError: Event = {
    Name: 'Client.Error'
  };

  public static LogInSuccess: Event = {
    Name: 'Authenticate.Login'
  };

  public static ProvisionStart: Event = {
    Name: 'Provision.Start'
  };

  public static ProvisionEnd: Event = {
    Name: 'Provision.End'
  };

  public static CreateNewEntityClick: Event = {
    Name: 'Entity.ClickCreateNew'
  };

  public static ExportButtonClick: Event = {
    Name: 'Schema.ClickExport'
  };

  public static ImportButtonClick: Event = {
    Name: 'Schema.ClickImport'
  };

  public static ImportFileParseError: Event = {
    Name: 'File.ImportParseError'
  };

  public static ChoiceSetTabClick: Event = {
    Name: 'ChoiceSetTabClick'
  };

  public static UseInStudioButtonClick: Event = {
    Name: 'UseInStudioButton.Click'
  };

  public static CreateEntityStart: Event = {
    Name: 'Entity.CreateStart'
  };

  public static CreateEntityEnd: Event = {
    Name: 'Entity.CreateEnd'
  };

  public static EntityDataView: Event = {
    Name: 'Entity.DataView'
  };

  public static EntityListView: Event = {
    Name: 'Entity.ListView'
  };

  public static EntitiesConflictView: Event = {
    Name: 'Entities.ConflictView'
  };

  public static EntityDetailView: Event = {
    Name: 'Entity.DetailView'
  };

  public static CreateNewFieldClick: Event = {
    Name: 'Field.ClickCreateNew'
  };

  public static CreateNewFieldEnd: Event = {
    Name: 'Field.EndCreateNewField'
  };

  public static DownloadOpenAPIClick: Event = {
    Name: 'OpenAPI.ClickDownload'
  };

  public static CopyOpenAPILinkClick: Event = {
    Name: 'CopyOpenAPILink.Click'
  };

  public static ChoicesetsView: Event = {
    Name: 'Choicesets.View'
  };

  public static ChoicesetsConflictView: Event = {
    Name: 'Choicesets.ConflictView'
  };

  public static DataTabClick: Event = {
    Name: 'DataTab.Click'
  };

  public static AddNewDataClick: Event = {
    Name: 'AddNewData.Click'
  };

  public static UpsertChoiceSetStart: Event = {
    Name: 'ChoiceSet.UpsertStart'
  };

  public static UpsertChoiceSetEnd: Event = {
    Name: 'ChoiceSet.UpsertEnd'
  };

  public static DeleteChoiceSetStart: Event = {
    Name: 'ChoiceSet.Delete'
  };

  public static DeleteChoiceSetEnd: Event = {
    Name: 'DeleteChoiceSetEnd'
  };

  public static UserManagementView: Event = {
    Name: 'UserManagement.View'
  };

  public static UserListView: Event = {
    Name: 'User.ListView'
  };

  public static CreateNewRoleClick: Event = {
    Name: 'Role.ClickCreateNew'
  };

  public static DeleteCustomRoleClick: Event = {
    Name: 'DeleteCustomRoleClick'
  };

  public static AddUserGroupClick: Event = {
    Name: 'AddUserGroup.Click'
  };

  public static EditUserGroupClick: Event = {
    Name: 'UserGroup.ClickEdit'
  };

  public static DataPageChange: Event = {
    Name: 'DataPage.Change'
  };

  public static CreateEnvironment: Event = {
    Name: 'Environment.Create'
  };

  public static GetEnvironment: Event = {
    Name: 'Environment.Get'
  };

  public static CheckPermission: Event = {
    Name: 'Permission.Check'
  };

  public static SearchUser: Event = {
    Name: 'User.Search'
  };

  public static GetUsersAndGroups: Event = {
    Name: 'UsersAndGroups.Get'
  };

  public static DeleteUserOrGroup: Event = {
    Name: 'UserOrGroup.Delete'
  };

  public static GetRoles: Event = {
    Name: 'Roles.Get'
  };

  public static GetRolesV2: Event = {
    Name: 'RolesV2.Get'
  };

  public static GetRoleByIdAsync: Event = {
    Name: 'Role.GetByIdAsync'
  };

  public static DeleteRoles: Event = {
    Name: 'Role.Delete'
  };

  public static CreateCustomRole: Event = {
    Name: 'CustomRole.Create'
  };

  public static UpdateCustomRole: Event = {
    Name: 'CustomRole.Edit'
  };

  public static DeleteCustomRoles: Event = {
    Name: 'CustomRoles.Delete'
  };

  public static UpsertUserRole: Event = {
    Name: 'Role.UpsertUser'
  };

  public static UpdateUserData: Event = {
    Name: 'UpdateUserData'
  };

  public static GetUsersByIds: Event = {
    Name: 'Users.GetByIds'
  };

  public static GetStatus: Event = {
    Name: 'Status.Get'
  };

  public static GetEntities: Event = {
    Name: 'Entities.Get'
  };

  public static GetEntitiesStats: Event = {
    Name: 'Entities.GetStats'
  };

  public static SearchEntities: Event = {
    Name: 'Entities.Search'
  };

  public static CreateEntity: Event = {
    Name: 'Entity.Create'
  };

  public static UpdateEntity: Event = {
    Name: 'Entity.Edit'
  };

  public static DeleteEntity: Event = {
    Name: 'Entity.Delete'
  };

  public static ConvertEntity: Event = {
    Name: 'Entity.Convert'
  };

  public static AddField: Event = {
    Name: 'Field.Add'
  };

  public static UpdateFieldMetadata: Event = {
    Name: 'Field.UpdateMetadata'
  };

  public static QueryEntityData: Event = {
    Name: 'EntityData.Query'
  };

  public static DeleteEntityData: Event = {
    Name: 'Entity.DeleteData'
  };

  public static DeleteEntityDataInBulk: Event = {
    Name: 'Entity.DeleteDataMultiSelect'
  };

  public static UpdateEntityData: Event = {
    Name: 'Entity.UpdateData'
  };

  public static InsertEntityData: Event = {
    Name: 'Entity.InsertData'
  };

  public static DownloadFileAttachment: Event = {
    Name: 'Attachment.DownloadFile'
  };

  public static UploadFileAttachment: Event = {
    Name: 'FileAttachment.Upload'
  };

  public static DeleteFileAttachment: Event = {
    Name: 'Attachment.DeleteFile'
  };

  public static GetProfile: Event = {
    Name: 'Profile.Get'
  };

  public static InsertChoiceSetData: Event = {
    Name: 'ChoiceSet.InsertData'
  };

  public static UpdateChoiceSetData: Event = {
    Name: 'ChoiceSet.EditData'
  };

  public static DeleteChoiceSetData: Event = {
    Name: 'ChoiceSet.DeleteData'
  };

  public static CreateChoiceSet: Event = {
    Name: 'ChoiceSet.Create'
  };

  public static GetChoiceSet: Event = {
    Name: 'ChoiceSet.Get'
  };

  public static UserLogout: Event = {
    Name: 'User.Logout'
  };

  public static SwaggerDownload: Event = {
    Name: 'Swagger.Download'
  };

  public static AdvancedSearchClick: Event = {
    Name: 'AdvancedSearch.Click'
  };

  public static AdvancedSearchQuerySubmitted: Event = {
    Name: 'AdvancedSearch.QuerySubmitted'
  };

  public static AdvancedSearchQueryLinkCopied: Event = {
    Name: 'AdvancedSearch.QueryLinkCopied'
  };

  public static AdvancedSearchQueryThroughDeepLink: Event = {
    Name: 'AdvancedSearch.QueryThroughDeepLink'
  };

  public static BulkImportButtonClick: Event = {
    Name: 'BulkUpload.ImportButtonClick'
  };

  public static BulkPartialImport: Event = {
    Name: 'BulkUpload.PartialImport'
  };

  public static PortalAdministrator: Event = {
    Name: 'Portal.Administrator'
  };
}
