import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { Entity, SearchEntityResponse } from '../../models/entity';
import { EntitiesService } from '../../services/entities.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';

export interface EntityEntry {
  entity: Entity;
  checked: boolean;
}

@Component({
  selector: 'entity-picker',
  templateUrl: './entity-picker.component.html',
  styleUrls: ['./entity-picker.component.scss']
})
export class EntityPickerComponent implements OnInit, OnDestroy {

  public entities: SearchEntityResponse[] = null;
  public selectedEntityIds: string[];
  public selectedEntity: SearchEntityResponse;
  public snapshot: SearchEntityResponse[] = [];
  public isLoading: boolean;
  public searchValue: string;

  private offsetX: number;
  private offsetY: number;
  private subscription: Subscription = new Subscription();

  constructor(
    public dialogRef: MatDialogRef<EntityPickerComponent>,
    private entitiesService: EntitiesService,
    @Inject(MAT_DIALOG_DATA) public options: {
      offsetX: number,
      offsetY: number,
      entities: SearchEntityResponse[],
      selectedEntityIds: string[]
    }
  ) {
    this.isLoading = true;
    this.offsetX = options.offsetX;
    this.offsetY = options.offsetY;
    this.entities = options.entities;
    this.selectedEntityIds = options.selectedEntityIds;
  }

  public ngOnInit() {
    const topPosition = this.offsetY + 300 > window.innerHeight ? this.offsetY - 300 : this.offsetY;
    this.dialogRef.updatePosition({ left: `${this.offsetX}px`, top: `${topPosition}px` });

    if (this.entities === null) {
      const sub = this.entitiesService.searchEntities().subscribe((result) => {
        this.entities = this.sortEntities(result);
        this.setEntities();
      }, (error) => {
        this.isLoading = false;
      });
      this.subscription.add(sub);
    } else {
      this.setEntities();
    }
  }

  public ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  public onSelectEntity(entityEntry: Entity) {
    this.selectedEntity = this.entities.find(ee => ee.id === entityEntry.id);
    // emit
    this.dialogRef.close(
      {
        selected: this.selectedEntity,
        entities: this.snapshot
      });
  }

  public onSearch() {
    if (!this.searchValue) {
      this.entities = [...this.snapshot];
      return;
    }

    this.entities = this.entities.filter(itm => itm.displayName.toLocaleLowerCase().indexOf(this.searchValue.toLocaleLowerCase()) > -1);
  }

  private setEntities(): void {
    for (const entityIdToRemove of this.selectedEntityIds) {
      this.entities = this.entities.filter(ent => ent.id !== entityIdToRemove);
    }
    this.snapshot = [...this.entities];
    this.isLoading = false;
  }

  private sortEntities(entities: SearchEntityResponse[]): SearchEntityResponse[] {
    return entities.sort((a, b) => a.displayName.localeCompare(b.displayName)); // Sort by displayName
  }
}
