<div fxLayout="row" fxLayoutAlign="space-between center" class="title-section">
  <h1 class="bold font-large">
    <app-breadcrumb-secondary [breadcrumbs]="breadcrumbs"></app-breadcrumb-secondary>
    {{'TemplateList.Templates' | translate}}
  </h1>
</div>

<ng-container *ngIf="isLoading">
  <mat-spinner role="alert" [attr.aria-label]="'Loading' | translate" diameter="48" class="spinner"></mat-spinner>
</ng-container>
<ng-container *ngIf="!isLoading">
  <ng-container *ngFor="let template of templates">
    <div 
      class="template-row table-row"
      (click)="onClickRow(template)"
      >
      <mat-icon class="template-icon material-icons md-18">{{ getTemplateIcon(template.name) }}</mat-icon>
      <div>
      <div class="bold">{{(template.name + 'Name') | translate}}</div>
      <div class="description">{{(template.name + 'Description') | translate}}</div>
    </div>
    </div>
    <mat-divider></mat-divider>
  </ng-container>
</ng-container>
