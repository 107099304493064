import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';

export interface DependencyDialogData {
  title: Observable<string>;
  description: Observable<string>;
  dependencyKeyText: Observable<string>;
  dependencyValueText: Observable<string>;
  dependencyTable: Record<string, string>;
}

@Component({
  selector: 'app-dependency-dialog',
  templateUrl: './dependency-dialog.component.html',
  styleUrls: ['./dependency-dialog.component.scss']
})
export class DependencyDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<DependencyDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DependencyDialogData,
  ) { }

  public get dependencyList(): Array<{ key: string, value: string}> {
    return Object.keys(this.data.dependencyTable).map(key => {
      return { key, value: this.data.dependencyTable[key] };
    });
  }

  public onClose(): void {
    this.dialogRef.close();
  }
}
