<mat-spinner *ngIf="isLoadingMembers && !isEditMode" role="alert" [attr.aria-label]="'Loading' | translate" diameter="24" class="spinner"></mat-spinner>
<mat-form-field class="relation-options" *ngIf="isEditMode || !isLoadingMembers"
  (click)="onClick()">
  <mat-select #matSelect (openedChange)="handleScroll()" [formControl]="formControl" multiple=false (closed)="onClose()"
    tabindex="0" panelWidth="null">
    <div class="search-bar" fxLayout="row" fxAlign="center center">
      <mat-icon class="search-icon">search</mat-icon>
      <input fxFlex class="search" type="text" [placeholder]="'RelationPanel.Search' | translate"
        [(ngModel)]="searchValue" (ngModelChange)="onSearchInputChange($event)" (keydown)="$event.stopPropagation()" matInput autocomplete="off" />
      <mat-spinner *ngIf="isLoadingMembers && isSearchMode" role="alert" [attr.aria-label]="'Loading' | translate" matSuffix [diameter]="18" class="inline-spinner">
      </mat-spinner>
    </div>

    <div class="custom-panel" #customPanel>
      <mat-spinner *ngIf="isLoadingMembers && isEditMode && !isSearchMode" role="alert" [attr.aria-label]="'Loading' | translate" diameter="24" class="spinner"></mat-spinner>
      <mat-option *ngFor="let m of members" class="table-text" [value]="m" (click)="onSelect($event)"
        (onSelectionChange)="onSelect($event)">
        {{m[field.definition.name] + ' -- ' + m['Id']}}
      </mat-option>
      <mat-option *ngIf="members.length === 0" class="no-item-placeholder">{{'NoItems' | translate}}</mat-option>
    </div>

    <button class="action table-text option-button" mat-button (click)="onClearValue()">
      {{'ClearValueText' | translate}}
    </button>
    <button mat-button [attr.aria-label]="'ViewEntityData' | translate" class="action table-text option-button" (click)="onOpenNewTab()">
      {{'ViewEntityData' | translate}}
      <mat-icon matSuffix class="material-icons md-18 launch-tab-button-icon">launch</mat-icon>
    </button>
  </mat-select>
</mat-form-field>
