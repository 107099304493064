<div class="side-pane-container">
    <h1 mat-dialog-title>{{'AdvancedSearch.Title' | translate}}</h1>
    <div mat-dialog-content class="dialog-content">
        <form [formGroup]="searchForm" (keypress)="disableEnterForForm($event)">
          <div class="column-labels">
          <div class="first-label-position">{{'AdvancedSearch.Operator' | translate}}</div>
        </div>
          <div>
                <mat-form-field class="condition-options">
                    <mat-select [aria-label]="'AdvancedSearch.Operator' | translate" formControlName="operator" panelWidth="null">
                        <mat-option *ngFor="let operator of operators" [value]="operator">
                            {{operator | translate}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <span class="condition-met"> {{'AdvancedSearch.ConditionsMet' | translate}} </span>
            </div>
            <div class="column-labels">
                <div class="first-label-position">{{'AdvancedSearch.Column' | translate}}</div>
                <div class="second-label-position">{{'AdvancedSearch.Condition' | translate}}</div>
                <div class="third-label-position">{{'AdvancedSearch.Value' | translate}}</div>
            </div>
            <div formArrayName="queryRecords">
                <ng-container *ngFor="let queryRecord of queryRecords.controls; let i=index" formGroupName="{{i}}">
                    <div fxLayout="row" fxLayoutAlign="start center">
                        <mat-form-field class="field-selection">
                            <mat-select formControlName="name" [aria-label]="'AdvancedSearch.Column' | translate" name="name" (selectionChange)="updateField(i, $event)" panelWidth="null">
                                <mat-option #option *ngFor="let searchColumn of searchColumns" [value]="searchColumn.name">
                                  <ng-container *ngIf="getOptionTranslationKey(searchColumn.name).length === 1; else twoStrings">
                                    {{getOptionTranslationKey(searchColumn.name)[0] | translate}}
                                  </ng-container>
                                  <ng-template #twoStrings>
                                    {{(getOptionTranslationKey(searchColumn.name)[0] | translate) + '.' + (getOptionTranslationKey(searchColumn.name)[1] | translate)}}
                                  </ng-template>
                                </mat-option>
                            </mat-select>
                            <mat-icon class="column-condition-error" matTooltip="{{'AdvancedSearch.ColumnRequired' | translate}}" *ngIf="shouldDisplayNameRequiredError(queryRecord)">error</mat-icon>
                        </mat-form-field>
                        <mat-form-field class="condition-selection">
                            <mat-select formControlName="condition" [aria-label]="'AdvancedSearch.Condition' | translate" name="condition" (selectionChange)="updateCondition(i)" panelWidth="null">
                                <mat-option #option *ngFor="let conditionOption of fieldTypeConditionMap[queryRecord?.value['fieldType']]" [value]="conditionOption">
                                    {{getConditionTranslationKey(conditionOption) | translate}}
                                </mat-option>
                            </mat-select>
                            <mat-icon class="column-condition-error" matTooltip="{{'AdvancedSearch.ConditionRequired' | translate}}" *ngIf="shouldDisplayConditionRequiredError(queryRecord)">error</mat-icon>
                        </mat-form-field>
                        <ng-container *ngIf="!queryRecord.get('name').value">
                            <mat-form-field class="data-selection">
                                <input (keydown.enter)="onSubmit()" formControlName="searchValue" attr.aria-label="{{'AdvancedSearch.Value' | translate}}" matInput/>
                            </mat-form-field>
                        </ng-container>
                        <ng-container *ngIf="getFieldType(queryRecord.get('name').value) === 'text'">
                            <mat-form-field class="data-selection">
                                <input (keydown.enter)="onSubmit()" formControlName="searchValue" attr.aria-label="{{'AdvancedSearch.Value' | translate}}" matInput name="text" type="text">
                                <mat-icon class="value-error" matTooltip="{{'AdvancedSearch.ValueRequired' | translate}}" *ngIf="shouldDisplayValueRequiredError(queryRecord['controls'].searchValue)">error</mat-icon>
                            </mat-form-field>
                        </ng-container>
                        <ng-container *ngIf="getFieldType(queryRecord.get('name').value) === 'uniqueid'">
                            <mat-form-field class="data-selection">
                                <input (keydown.enter)="onSubmit()" formControlName="searchValue" attr.aria-label="{{'AdvancedSearch.Value' | translate}}" matInput name="text" type="text">
                                <mat-icon class="value-error" matTooltip="{{'AdvancedSearch.ValueRequired' | translate}}" *ngIf="shouldDisplayValueRequiredError(queryRecord['controls'].searchValue)">error</mat-icon>
                                <mat-icon class="value-error" matTooltip="{{'AdvancedSearch.IdMustBeInGUIDFormat' | translate}}" *ngIf="shouldDisplayGUIDError(queryRecord['controls'].searchValue)">error</mat-icon>
                            </mat-form-field>
                        </ng-container>
                        <ng-container *ngIf="getFieldType(queryRecord.get('name').value) === 'number' || getFieldType(queryRecord.get('name').value) === 'autonumber'">
                            <mat-form-field class="data-selection"floatLabel="always">
                                <input (keydown.enter)="onSubmit()" formControlName="searchValue" attr.aria-label="{{'AdvancedSearch.Value' | translate}}" matInput name="number">
                                <mat-icon class="value-error" matTooltip="{{'AdvancedSearch.ValueRequired' | translate}}" *ngIf="shouldDisplayValueRequiredError(queryRecord['controls'].searchValue)">error</mat-icon>
                                <mat-icon class="value-error" matTooltip="{{'AdvancedSearch.NumbersOnly' | translate}}" *ngIf="shouldDisplayNumberError(queryRecord['controls'].searchValue)">error</mat-icon>
                            </mat-form-field>
                        </ng-container>
                        <ng-container *ngIf="getFieldType(queryRecord.get('name').value) === 'date'">
                            <mat-form-field class="data-selection" floatLabel="never">
                                <input placeholder="mm/dd/yy" (keydown.enter)="onSubmit()" formControlName="searchValue" attr.aria-label="{{'AdvancedSearch.Value' | translate}}"
                                    matInput name="date" [matDatepicker]="picker" [required]="false">
                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-datepicker #picker></mat-datepicker>
                                <mat-icon class="value-error" matTooltip="{{'AdvancedSearch.ValueRequired' | translate}}" *ngIf="shouldDisplayValueRequiredError(queryRecord['controls'].searchValue)">error</mat-icon>
                                <mat-icon class="value-error" matTooltip="{{'InvalidDateFormat' | translate}}" *ngIf="shouldDisplayInvalidDateError(queryRecord['controls'].searchValue)">error</mat-icon>
                            </mat-form-field>
                        </ng-container>
                        <ng-container *ngIf="getFieldType(queryRecord.get('name').value) === 'dateTime'">
                            <mat-form-field class="data-selection">
                                <input (keydown.enter)="onSubmit()" formControlName="searchValue" attr.aria-label="{{'AdvancedSearch.Value' | translate}}" matInput name="dateTime" type="datetime-local">
                                <mat-icon class="value-error-left" matTooltip="{{'AdvancedSearch.ValueRequired' | translate}}" *ngIf="shouldDisplayValueRequiredError(queryRecord['controls'].searchValue)">error</mat-icon>
                                <mat-icon class="value-error-left" matTooltip="{{'InvalidDateFormat' | translate}}" *ngIf="shouldDisplayInvalidDateTimeError(queryRecord['controls'].searchValue)">error</mat-icon>
                            </mat-form-field>
                        </ng-container>
                        <ng-container *ngIf="getFieldType(queryRecord.get('name').value) === 'relationship' ||
                                                getFieldType(queryRecord.get('name').value) === 'file'">
                            <mat-form-field class="data-selection">
                                <input (keydown.enter)="onSubmit()" formControlName="searchValue" attr.aria-label="{{'AdvancedSearch.Value' | translate}}" matInput>
                                <mat-icon class="value-error" matTooltip="{{'AdvancedSearch.ValueRequired' | translate}}" *ngIf="shouldDisplayValueRequiredError(queryRecord['controls'].searchValue)">error</mat-icon>
                            </mat-form-field>
                        </ng-container>
                        <ng-container *ngIf="getFieldType(queryRecord.get('name').value) === 'boolean'">
                            <mat-form-field class="data-selection">
                                <mat-select formControlName="searchValue" [aria-label]="'AdvancedSearch.Value' | translate" name="boolean">
                                    <mat-option [value]="'Yes'">
                                        Yes
                                    </mat-option>
                                    <mat-option [value]="'No'">
                                        No
                                    </mat-option>
                                </mat-select>
                                <mat-icon class="value-error-left" matTooltip="{{'AdvancedSearch.ValueRequired' | translate}}" *ngIf="shouldDisplayValueRequiredError(queryRecord['controls'].searchValue)">error</mat-icon>
                            </mat-form-field>
                        </ng-container>
                        <ng-container *ngIf="getFieldType(queryRecord.get('name').value) === 'choiceSetSingle'">
                            <mat-form-field class="data-selection">
                                <mat-select formControlName="searchValue" [aria-label]="'AdvancedSearch.Value' | translate" name="text">
                                    <mat-option #option *ngFor="let choiceset of choiceSetMap[getChoiceSetId(queryRecord.get('name').value)]" [value]="choiceset.numberId">
                                        {{choiceset.name}}
                                    </mat-option>
                                </mat-select>
                                <mat-icon class="value-error-left" matTooltip="{{'AdvancedSearch.ValueRequired' | translate}}" *ngIf="shouldDisplayValueRequiredError(queryRecord['controls'].searchValue)">error</mat-icon>
                            </mat-form-field>
                        </ng-container>
                        <ng-container *ngIf="getFieldType(queryRecord.get('name').value) === 'choiceSetMultiple'">
                            <mat-form-field class="data-selection">
                                <mat-select formControlName="searchValue" [aria-label]="'AdvancedSearch.Value' | translate" name="text" multiple>
                                    <mat-option #option *ngFor="let choiceset of choiceSetMap[getChoiceSetId(queryRecord.get('name').value)]" [value]="choiceset.numberId">
                                        {{choiceset.name}}
                                    </mat-option>
                                </mat-select>
                                <mat-icon class="value-error-left" matTooltip="{{'AdvancedSearch.ValueRequired' | translate}}" *ngIf="shouldDisplayValueRequiredError(queryRecord['controls'].searchValue)">error</mat-icon>
                            </mat-form-field>
                        </ng-container>
                        <button mat-icon-button attr.aria-label="{{'ListData.DeleteRow' | translate}}" type="button" class="delete-button" (click)="removeQueryRecord(i)" [matTooltip]="'ListData.Delete' | translate">
                            <mat-icon class="delete-icon">
                                delete_outline
                            </mat-icon>
                        </button>
                    </div>
                    <ng-container *ngFor="let additionalValue of queryRecord.controls['additionalSearchValues'].controls; let aI=index" [formGroup]="queryRecord.controls['additionalSearchValues'].controls[aI]">
                        <div fxLayout="row" fxLayoutAlign="end center" class="additional-row">
                            <ng-container *ngIf="!queryRecord.get('name').value">
                                <mat-form-field class="additional-value-selection">
                                    <input (keydown.enter)="onSubmit()" formControlName="additionalSearchValue" attr.aria-label="{{'AdvancedSearch.Value' | translate}}" matInput/>
                                </mat-form-field>
                            </ng-container>
                            <ng-container *ngIf="getFieldType(queryRecord.get('name').value) === 'text'">
                                <mat-form-field class="additional-value-selection">
                                    <input (keydown.enter)="onSubmit()" formControlName="additionalSearchValue" attr.aria-label="{{'AdvancedSearch.Value' | translate}}" matInput name="text" type="text">
                                    <mat-icon class="value-error" matTooltip="{{'AdvancedSearch.ValueRequired' | translate}}" *ngIf="shouldDisplayValueRequiredError(additionalValue['controls'].additionalSearchValue)">error</mat-icon>
                                </mat-form-field>
                            </ng-container>
                            <ng-container *ngIf="getFieldType(queryRecord.get('name').value) === 'uniqueid'">
                                <mat-form-field class="additional-value-selection">
                                    <input (keydown.enter)="onSubmit()" formControlName="additionalSearchValue" attr.aria-label="{{'AdvancedSearch.Value' | translate}}" matInput name="text" type="text">
                                    <mat-icon class="value-error" matTooltip="{{'AdvancedSearch.ValueRequired' | translate}}" *ngIf="shouldDisplayValueRequiredError(additionalValue['controls'].additionalSearchValue)">error</mat-icon>
                                    <mat-icon class="value-error" matTooltip="{{'AdvancedSearch.IdMustBeInGUIDFormat' | translate}}" *ngIf="shouldDisplayGUIDError(additionalValue['controls'].additionalSearchValue)">error</mat-icon>
                                </mat-form-field>
                            </ng-container>
                            <ng-container *ngIf="getFieldType(queryRecord.get('name').value) === 'number' || getFieldType(queryRecord.get('name').value) === 'autonumber'">
                                <mat-form-field class="additional-value-selection" floatLabel="always">
                                    <input (keydown.enter)="onSubmit()" formControlName="additionalSearchValue" attr.aria-label="{{'AdvancedSearch.Value' | translate}}" matInput name="number">
                                    <mat-icon class="value-error" matTooltip="{{'AdvancedSearch.ValueRequired' | translate}}" *ngIf="shouldDisplayValueRequiredError(additionalValue['controls'].additionalSearchValue)">error</mat-icon>
                                    <mat-icon class="value-error" matTooltip="{{'AdvancedSearch.NumbersOnly' | translate}}" *ngIf="shouldDisplayNumberError(additionalValue['controls'].additionalSearchValue)">error</mat-icon>
                                </mat-form-field>
                            </ng-container>
                            <ng-container *ngIf="getFieldType(queryRecord.get('name').value) === 'date'">
                                <mat-form-field class="additional-value-selection" floatLabel="never">
                                    <input placeholder="mm/dd/yy" (keydown.enter)="onSubmit()" formControlName="additionalSearchValue" attr.aria-label="{{'AdvancedSearch.Value' | translate}}"
                                        matInput name="date" [matDatepicker]="picker" [required]="false">
                                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                    <mat-datepicker #picker></mat-datepicker>
                                    <mat-icon class="value-error" matTooltip="{{'AdvancedSearch.ValueRequired' | translate}}" *ngIf="shouldDisplayValueRequiredError(additionalValue['controls'].additionalSearchValue)">error</mat-icon>
                                    <mat-icon class="value-error" matTooltip="{{'InvalidDateFormat' | translate}}" *ngIf="shouldDisplayInvalidDateError(additionalValue['controls'].additionalSearchValue)">error</mat-icon>
                                </mat-form-field>
                            </ng-container>
                            <ng-container *ngIf="getFieldType(queryRecord.get('name').value) === 'dateTime'">
                                <mat-form-field class="additional-value-selection">
                                    <input (keydown.enter)="onSubmit()" formControlName="additionalSearchValue" attr.aria-label="{{'AdvancedSearch.Value' | translate}}" matInput name="dateTime" type="datetime-local">
                                    <mat-icon class="value-error-left" matTooltip="{{'AdvancedSearch.ValueRequired' | translate}}" *ngIf="shouldDisplayValueRequiredError(additionalValue['controls'].additionalSearchValue)">error</mat-icon>
                                    <mat-icon class="value-error-left" matTooltip="{{'InvalidDateFormat' | translate}}" *ngIf="shouldDisplayInvalidDateTimeError(additionalValue['controls'].additionalSearchValue)">error</mat-icon>
                                </mat-form-field>
                            </ng-container>
                            <button mat-icon-button aria-label="'ListData.Delete' | translate" type="button" class="delete-button" (click)="removeAdditionalSearchValue(i, aI)" [matTooltip]="'ListData.Delete' | translate">
                                <mat-icon class="delete-icon">
                                    delete_outline
                                </mat-icon>
                            </button>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="queryRecord.get('condition').value && isInOrNotInCondition(queryRecord.get('condition').value)">
                        <div class="additional-value-surround" fxLayout="row" fxLayoutAlign="end">
                            <div fxLayout="row" fxLayoutAlign="end center" [matTooltip]="'AdvancedSearch.FiveSearchValuesMaxAllowed' | translate" [matTooltipDisabled]="!isMaxSearchValuesExceeded(i)" class="additional-value" matTooltipPosition="below">
                                <button type="button" [disabled]="isMaxSearchValuesExceeded(i)" color="primary" mat-button class="additional-value-button" (keydown.enter)="addAdditionalSearchValue(i)" (click)="addAdditionalSearchValue(i)">
                                    <mat-icon class="material-icons md-16">
                                        add
                                    </mat-icon>
                                    {{'AdvancedSearch.AddValue' | translate }}
                                </button>
                            </div>
                        </div>
                    </ng-container>
                </ng-container>
            </div>
        </form>
        <div [matTooltip]="'AdvancedSearch.TenConditionsMaxAllowed' | translate" [matTooltipDisabled]="!isMaxConditionsExceeded()" class="add-width" matTooltipPosition="right">
            <button mat-stroked-button [disabled]="isMaxConditionsExceeded()" color="primary" class="add-margin" mat-button (click)="addQueryRecord()">
                <mat-icon class="material-icons md-24 standard-icon">
                    add
                </mat-icon>
                {{'AdvancedSearch.AddRow' | translate }}
            </button>
        </div>
        <div fxLayout="row" fxLayoutAlign="end strech" class="button-section bottom-btn">
            <button mat-stroked-button color="primary" (click)="onDismiss()" class="btn bold cancel">{{'CancelButtonText' |
                translate}}</button>
            <button mat-flat-button color="primary" type="submit" [disabled]="!validateQuery()"
                class="btn bold" (click)="onSubmit()">{{'SaveButtonText' | translate}}</button>
            <div class="btn" *ngIf="isLoading">
                <mat-spinner role="alert" [attr.aria-label]="'Loading' | translate" diameter="24" class="spinner"></mat-spinner>
            </div>
        </div>
        <button mat-icon-button (click)="onDismiss()" class="close-button"
            [attr.aria-label]="'CloseButtonText' | translate">
            <mat-icon class="font-large">
                close
            </mat-icon>
        </button>
    </div>
</div>
