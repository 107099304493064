<app-migration-bar></app-migration-bar>
<ng-container
  *ngIf="(userService.checkPermission([ManagePermission]) | async) === false && userService.isUserProfileLoaded() === true">
  <app-no-permission></app-no-permission> 
</ng-container>
<ng-container *ngIf="isAdminConsistencyEnabled">
  <div fxLayout="row" fxLayoutAlign="space-between center" class="title-section">
    <h1 class="bold font-large">
      <app-breadcrumb-secondary [breadcrumbs]="breadcrumbs"></app-breadcrumb-secondary>
      {{'UserManagement.Title' | translate}}
    </h1>
  </div>
  <ng-container *ngIf="userService.checkPermission([ManagePermission]) | async">
    <mat-tab-group animationDuration="0ms" [selectedIndex]="tabIndex"
      (selectedTabChange)="refreshUserAndRole($event)" class="roles-tab">
      <mat-tab [label]="'UserManagement.RoleAssignments' | translate">
        <ng-container *ngIf="isLoading">
          <mat-spinner role="alert" [attr.aria-label]="'Loading' | translate" diameter="48" class="spinner"></mat-spinner>
        </ng-container>
        <ng-container *ngIf="!isLoading && tabIndex === 0">
          <app-list-users [users]="userGroups" [roles]="roles" (refreshUsers)="refreshUserAndRole()">
          </app-list-users>
        </ng-container>
      </mat-tab>
      <mat-tab [label]="'UserManagement.Roles' | translate">
        <ng-container *ngIf="isLoading">
          <mat-spinner role="alert" [attr.aria-label]="'Loading' | translate" diameter="48" class="spinner"></mat-spinner>
        </ng-container>
        <ng-container *ngIf="!isLoading && tabIndex === 1">
          <app-list-roles [roles]="roles" (refreshRoles)="refreshUserAndRole()"></app-list-roles>
        </ng-container>
      </mat-tab>
    </mat-tab-group>
  </ng-container>
</ng-container>

<ng-container *ngIf="!isAdminConsistencyEnabled">
  <div *ngIf="userService.checkPermission([ManagePermission]) | async" fxLayout="row"
    fxLayoutAlign="space-between center" class="title-section">
    <h1 class="bold font-large">
      <app-breadcrumb-secondary [breadcrumbs]="breadcrumbs"></app-breadcrumb-secondary>
      {{'UserManagement.Title' | translate}}
    </h1>
    <div fxLayout="row" fxLayoutAlign="center">
      <button *ngIf="roles && userGroups" mat-stroked-button [attr.aria-label]="'AssignRoles.Title' | translate"
        class="bold font-medium" color="primary" (click)="onUpsertUserGroup()">
          {{'AssignRoles.Title' | translate}}
      </button>
      <button mat-stroked-button [attr.aria-label]="'UserManagement.CreateNewRole' | translate"
        class="create-new-role bold font-medium" color="primary" *ngIf="roles && userGroups"
        (click)="onUpsertRole()">
        {{'UserManagement.CreateNewRole' | translate}}
      </button>
    </div>
  </div>

  <ng-container *ngIf="userService.checkPermission([ManagePermission]) | async">
    <mat-tab-group [selectedIndex]="tabIndex">
      <mat-tab [label]="'AssignRoles.Title' | translate">
        <app-list-users [users]="userGroups" [roles]="roles" (refreshUsers)="refreshUserAndRole()">
        </app-list-users>
      </mat-tab>
      <mat-tab [label]="'RolesLabelText' | translate">
        <app-list-roles [roles]="roles" (refreshRoles)="refreshUserAndRole()"></app-list-roles>
      </mat-tab>
    </mat-tab-group>
  </ng-container>
</ng-container>
