import { WebStorageStateStore } from 'oidc-client-ts';
import { UrlUtils } from '../url.utils';

export class LoginStateStore extends WebStorageStateStore {
  private store: Storage;
  private prefix: string;

  constructor({ prefix = 'entities.', store = localStorage } = {}) {
    super({ prefix: 'entities.', store: localStorage });
    this.store = store;
    this.prefix = prefix;
  }

  public set(key: string, value: any): Promise<void> {
    this.store.setItem(this._getKey(key), value);

    return Promise.resolve();
  }

  public get(key: string): Promise<any> {
    const item = this.store.getItem(this._getKey(key));

    return Promise.resolve(item);
  }

  public remove(key: string): Promise<any> {
    const realKey = this._getKey(key);
    const item = this.store.getItem(realKey);
    this.store.removeItem(realKey);

    return Promise.resolve(item);
  }

  public getAllKeys(): Promise<string[]> {
    const keys = [];
    const context = UrlUtils.getRequestContext();
    const prefix = `${this.prefix}${context.account}.`;

    for (let index = 0; index < this.store.length; index++) {
      const key = this.store.key(index);
      if (key.indexOf(prefix) === 0) {
        keys.push(key.substr(prefix.length));
      }
    }

    return Promise.resolve(keys);
  }

  private _getKey(key: string): string {
    const context = UrlUtils.getRequestContext();
    return `${this.prefix}${context.account}.${key}`;
  }
}
