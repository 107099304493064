<div class="list-role">
    <div class="list-tool-bar" fxLayout="row" fxLayoutAlign=" center">
        <mat-form-field floatLabel="never" class="apollo-textfield-small search-bar">
            <mat-label>{{'SearchText' | translate}}</mat-label>
            <mat-icon matSuffix>search</mat-icon>
            <input matInput autocomplete="false" [placeholder]="'SearchText' | translate" [(ngModel)]="searchValue"
                (keyup)="onSearch()" />
        </mat-form-field>
        <span class="spacer"></span>
        <button mat-button [attr.aria-label]="'RefreshButtonText' | translate" class="refresh bold" color="primary"
            (click)="onClickRefresh()">
            <mat-icon class="material-icons md-24 standard-icon">refresh</mat-icon>
            {{'RefreshButtonText' | translate}}
        </button>
        <ng-container *ngIf="isAdminConsistencyEnabled">
            <ap-button mat-flat-button [attr.aria-label]="'ListRoles.CreateNewRole' | translate" class="bold"
                color="primary" (click)="onUpsertRole()" [label]="'ListRoles.CreateNewRole' | translate">
            </ap-button>
        </ng-container>
    </div>
    <div *ngIf="searchValue" class="search-result font-medium" fxLayout="row" fxLayoutAlign=" center">
        <div>
            <span class="bold">{{dataSource.data.length}} {{'ListUser.SearchResultHelpText' | translate}}:</span>
            {{searchValue}}
        </div>
    </div>
    <ng-container *ngIf="isLoading">
        <mat-spinner role="alert" [attr.aria-label]="'Loading' | translate" diameter="48" class="spinner"></mat-spinner>
    </ng-container>
    <ng-container *ngIf="!isLoading">
        <table mat-table class="role-table" [dataSource]="dataSource" matSort (matSortChange)="sortData($event)">
            <caption></caption>
            <ng-container matColumnDef="role">
                <th scope="cell" mat-header-cell *matHeaderCellDef mat-sort-header class="bold font-medium header-text">
                    {{'ListRole.RoleTableHeaderText' | translate}}
                    <span role="status" [attr.aria-label]="ariaHelper.getOrderKey('role') | translate"></span>
                </th>
                <th mat-header-cell *matCellDef="let element" class="table-text display-font" tabindex="0">
                    {{element.displayName}}
                </th>
            </ng-container>

            <ng-container matColumnDef="type">
                <th scope="cell" mat-header-cell *matHeaderCellDef mat-sort-header class="bold font-medium header-text">
                    {{'ListRole.TypeTableHeaderText' | translate}}
                    <span role="status" [attr.aria-label]="ariaHelper.getOrderKey('type') | translate"></span>
                </th>
                <td mat-cell *matCellDef="let element" class="table-text">
                    {{convertToDisplayRoleType(element.type) | async}}
                </td>
            </ng-container>

            <ng-container matColumnDef="directoryEntityCount">
                <th scope="cell" mat-header-cell *matHeaderCellDef mat-sort-header class="bold font-medium header-text">
                    {{'ListRole.AssignmentsNumberTableHeaderText' | translate}}
                    <span role="status"
                        [attr.aria-label]="ariaHelper.getOrderKey('directoryEntityCount') | translate"></span>
                </th>
                <td mat-cell *matCellDef="let element" class="table-text">
                    {{element.directoryEntityCount}}
                </td>
            </ng-container>

            <ng-container matColumnDef="action">
                <th scope="cell" mat-header-cell *matHeaderCellDef> </th>
                <td mat-cell *matCellDef="let element; let i = index" class="action-column">
                    <div fxLayout="row" fxLayoutAlign="end">
                        <button *ngIf="element.type === 'System' && !isViewLoading"
                            [matTooltip]="'Tooltip.ViewPermission' | translate" mat-icon-button
                            [attr.aria-label]="'ViewRolePermission' | translate:{value: element.displayName}"
                            class="add-button action-button" (click)="onViewRow(element)" tabindex="0">
                            <mat-icon class="material-icons md-24 standard-icon">visibility</mat-icon>
                        </button>
                        <div *ngIf="isViewLoading" class="action-button">
                            <mat-spinner role="alert" [attr.aria-label]="'Loading' | translate" diameter="16" class="view-spinner"></mat-spinner>
                        </div>
                        <button *ngIf="element.type !== 'System' && !isEditLoading"
                            [matTooltip]="'EditText' | translate" mat-icon-button
                            [attr.aria-label]="'EditRoleText' | translate:{value: element.displayName}"
                            class="add-button action-button edit-role" (click)="onUpsertCustomRole(element)"
                            tabindex="0">
                            <mat-icon class="material-icons md-24 standard-icon">edit</mat-icon>
                        </button>
                        <div *ngIf="isEditLoading" class="action-button">
                            <mat-spinner role="alert" [attr.aria-label]="'Loading' | translate" diameter="16" class="edit-spinner"></mat-spinner>
                        </div>
                        <button *ngIf="element.type !== 'System'" [matTooltip]="'DeleteText' | translate"
                            mat-icon-button [attr.aria-label]="'DeleteRoleText' | translate:{value: element.displayName}"
                            class="add-button action-button delete-role" (click)="onDeleteCustomRole(element)"
                            tabindex="0">
                            <mat-icon class="material-icons md-24 standard-icon">delete</mat-icon>
                        </button>
                    </div>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns" class="table-header themed-list-header"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="table-row"></tr>
        </table>
    </ng-container>
    <mat-paginator *ngIf="(snapshot && snapshot.length>0)" [length]="snapshot.length" [pageSize]="20"
        [pageSizeOptions]="[10, 20, 50]" (page)="onChangePagination($event)">
    </mat-paginator>
</div>