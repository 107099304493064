import { AbstractControl, ValidatorFn } from '@angular/forms';

export function forbiddenNameValidator(nameRe: RegExp): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const match = nameRe.test(control.value);
    return !match ? { forbiddenName: { value: control.value } } : null;
  };
}

export function duplicateNameValidator(existingNames: Set<string>): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    return existingNames.has(control.value) ? { duplicateNameError: { value: control.value } } : null;
  };
}

export function reservedNameValidator(reservedNames: Set<string>): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    return reservedNames.has(control.value) ? { reservedNameError: { value: control.value } } : null;
  };
}

export function numberValidator(allowRequired?: boolean): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const regex = new RegExp('^(-?)(0|([1-9][0-9]*))(\\.[0-9]+)?$');
    const match = regex.test(control.value);

    if (allowRequired && !control.value) {
      return null;
    }
    return !match ? { numberError: { value: control.value } } : null;
  };
}

export function guidValidator(allowRequired?: boolean): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const regex = new RegExp('^[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}$');
    const match = regex.test(control.value);

    if (allowRequired && !control.value) {
      return null;
    }
    return !match ? { guidError: { value: control.value } } : null;
  };
}

export function positiveIntegerValidator(allowRequired?: boolean): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const regex = new RegExp('^[1-9][0-9]*$');
    const match = regex.test(control.value);

    if (allowRequired && !control.value) {
      return null;
    }
    return !match ? { numberError: { value: control.value } } : null;
  };
}

// The mat dateTime picker already does a good job validating,
// just checking date does not go over 4 digits.
export function dateTimeYearValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (!control.value) {
      return null;
    }
    const value = control.value.toString().split('-');
    if (value.length === 0) {
      return null;
    }
    const year = value[0];

    return year.length > 4 ? { dateTimeYearError: { value: control.value } } : null;
  };
}

// The mat date picker does a good job validating and has internal matDatepickerParse
// validator, just checking date does not go over 4 digits.
export function dateYearValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (!control.value) {
      return null;
    }
    const value = control.value.toString().split(' ');
    if (value.length < 4) {
      return null;
    }
    const year = value[3];

    return year.length > 4 ? { dateYearError: { value: control.value } } : null;
  };
}

export function numberValidatorWithoutControl(value: string): boolean {
  const regex = new RegExp('^((-?)(0|([1-9][0-9]*)))?(\\.[0-9]+)?$');
  const match = regex.test(value);
  return match;
}