<div class="container">
    <div fxLayout="row">
        <div><portal-custom-icon name="error" size="33px" class="error-icon"></portal-custom-icon> </div>
        <div class="basic-search-title" mat-dialog-title>{{'BasicSearch.BasicSearchWarning' | translate}}</div>
    </div>
    <div fxLayout="row" fxLayoutAlign="end strech" class="button-section">
        <button mat-stroked-button color="primary" (click)="onDismiss()" class="btn bold cancel">{{'CancelButtonText' |
            translate}}</button>
        <button mat-flat-button color="primary" type="submit"
            class="confirm-button btn bold" (click)="onSubmit()">{{'BasicSearch.ConfirmText' | translate}}</button>
    </div>
</div>