import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export enum EnableRbacDialogMode {
  enable = 'Enable',
  disable = 'Disable'
}

export enum EnableRbacDialogOutput {
  canceled = 'canceled',
  succeed = 'succeed',
  failed = 'failed'
}

export interface EnableRbacDialogData {
  entityId: string;
  displayName: string;
  mode: EnableRbacDialogMode;
  enabledContent: Observable<string>;
  disabledContent: Observable<string>;
  onOpenLearnMore?: () => void;
  onSubmitAction?: () => Observable<any>;
}

@Component({
  selector: 'app-enable-rbac-dialog',
  templateUrl: './enable-rbac-dialog.component.html',
  styleUrls: ['./enable-rbac-dialog.component.scss']
})
export class EnableRbacDialogComponent implements OnInit, OnDestroy {
  public isLoading: boolean;
  private subscription: Subscription = new Subscription();

  constructor(
    public dialogRef: MatDialogRef<EnableRbacDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: EnableRbacDialogData,
    private translateService: TranslateService,
  ) { }

  ngOnInit(): void {
    this.isLoading = false;
  }

  public ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  public isEnableMode(): boolean {
    return this.data.mode === EnableRbacDialogMode.enable;
  }

  public getTitleText(): Observable<string> {
    if (this.isEnableMode()) {
      return this.translateService.get('EnableRbacDialog.Title', { value: this.data.displayName });
    } else {
      return this.translateService.get('EnableRbacDialog.DisableTitle', { value: this.data.displayName });
    }
  }

  public onDismiss(): void {
    this.dialogRef.close({ data: this.data, result: EnableRbacDialogOutput.canceled });
  }

  public onSubmit(): void {
    this.isLoading = true;

    const sub = this.data.onSubmitAction().subscribe(() => {
      this.dialogRef.close({ data: this.data, result: EnableRbacDialogOutput.succeed });
    }, () => {
      this.isLoading = false;
      this.dialogRef.close({ data: this.data, result: EnableRbacDialogOutput.failed });
    });
    this.subscription.add(sub);
  }

  public onOpenLearnMore() {
    this.data.onOpenLearnMore();
  }
}
