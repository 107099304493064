import { Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})

export class PaginatorOverrideService extends MatPaginatorIntl {

  private ofWord;

  constructor(
    private translateService: TranslateService
  ) {
    super();
    this.getTranslations();
  }

  public getTranslations() {
    this.translateService.get([
      'Paginator.Show',
      'Paginator.NextPage',
      'Paginator.PreviousPage',
      'Paginator.Of'
    ])
      .subscribe(translate => {
        this.itemsPerPageLabel = translate['Paginator.Show'];
        this.nextPageLabel = translate['Paginator.NextPage'];
        this.previousPageLabel = translate['Paginator.PreviousPage'];
        this.ofWord = translate['Paginator.Of'];
      });
  }

  getRangeLabel = (
    page: number,
    pageSize: number,
    length: number,
  ) => {
    if (length === 0) {
      return `0 ${this.ofWord} 0`;
    }
    const start = page * pageSize;
    const end = start < length ? Math.min(start + pageSize, length) : start + pageSize;
    return `${start + 1} - ${end} ${this.ofWord} ${length}`;
  };
}
