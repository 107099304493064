import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  public remove(key: string | Array<string>) {
    if (typeof key === 'string') {
      localStorage.removeItem(key);
    } else if (key instanceof Array) {
      for (let i = 0; i < key.length; i++) {
        if (typeof key[i] === 'string') {
          localStorage.removeItem(key[i]);
        } else {
          throw new Error('Key in index ' + i + ' is not a string');
        }
      }
    } else {
      throw new Error('Key must be a string or array for function remove(key || array)');
    }
  }

  public get(key: string | null, defaultValue?: any) {
    if (typeof key !== 'string') {
      throw new Error('Key must be a string for function get(key)');
    }

    let value = localStorage.getItem(key)!;  // retrieve value
    const numberVar = parseFloat(value); // to allow for number checking

    if (value === null || value === 'null') {
      // Returns default value if key is not set, otherwise returns null
      return defaultValue != null ? defaultValue : null;
    }

    if (!isNaN(numberVar)) {
      return numberVar;  // value was of type number
    }

    if (value.toLowerCase() === 'true' || value.toLowerCase() === 'false') {
      return value === 'true';  // value was of type boolean
    }

    try {
      value = JSON.parse(value);
      return value;
    } catch (e) {
      return value;
    }
  }

  public set(key: string | null, value: any) {
    if (typeof key === 'string') {
      if (value == null) {
        this.remove(key);
        return;
      }

      if (typeof value === 'object') {
        value = JSON.stringify(value);
      }

      localStorage.setItem(key, value);
    } else {
      throw new Error('Invalid arguments for function set(key, value) or function set(object)');
    }
  }
}
