<div class="entity-picker">
    <ng-container *ngIf="isLoading">
        <mat-spinner role="alert" [attr.aria-label]="'Loading' | translate" class="spinner" diameter="24"></mat-spinner>
    </ng-container>
    <ng-container *ngIf="!isLoading">
        <div class="mat-small search-bar">
            <input class="input-box" [placeholder]="'SearchText' | translate" [(ngModel)]="searchValue"
                (keyup)="onSearch()">
        </div>
        <div class="no-field font-semi-small" *ngIf="fields && fields.length===0">
            <div>{{'FieldPicker.NoFieldText' | translate}}</div>
        </div>
        <div class="option-list" *ngIf="fields && fields.length>0">
            <div *ngFor="let field of fields" (click)="$event.stopPropagation()"
                class="menu-item-row font-semi-small option">
                <mat-checkbox (change)="onSelectField($event, field)" [checked]="shouldFieldChecked(field)">
                    <span class="font-medium">{{field.displayName}}</span>
                </mat-checkbox>
            </div>
            <div class="field-picker-container">
                <button mat-flat-button color="primary" (click)="onAddFields()">Add</button>
            </div>
        </div>
        <div class="divider"></div>
        <div class="bottom-section font-semi-small" (click)="onClickEnableRbac()">
            <a>
                {{'FieldPicker.HelpText' | translate}}
                <mat-icon matSuffix class="material-icons font-medium launch-tab-button-icon vertical-align-icon">launch
                </mat-icon>
            </a>
        </div>
    </ng-container>
</div>