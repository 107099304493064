import { Component, Inject, OnDestroy } from '@angular/core';
import { Constants } from 'src/app/shared/constants';
import { UserService } from 'src/app/shared/services/user.service';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import {
  GetRoleResponse,
  GetRolePermissionResponse,
  GetFieldDataPermissionResponse} from 'src/app/shared/models/user';
import { DataMode } from '../upsert-user-dialog/upsert-user-dialog.component';
import { Validators, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { SearchEntityResponse } from 'src/app/shared/models/entity';
import { AccountService } from 'src/app/shared/services/account.service';
import { AuthService } from 'src/app/shared/guards/auth.service';
import { FeatureKey, FeatureService } from 'src/app/shared/services/feature.service';
import { Subscription } from 'rxjs';

export interface CustomRoleDialogData {
  mode: DataMode;
  role: GetRolePermissionResponse;
}

export interface FieldGridEntry {
  field: GetFieldDataPermissionResponse;
  create: boolean;
  read: boolean;
  edit: boolean;
  delete?: boolean;
}

export interface EntityGridEntry {
  entityId: string;
  displayName: string;
  create: boolean;
  read: boolean;
  edit: boolean;
  delete: boolean;
  fields?: FieldGridEntry[];
  isRbacEnabled?: boolean;
  addFieldTextClicked?: boolean;
  recordLevelEntityGridEntries?: EntityGridEntry[];
}

@Component({
  selector: 'app-upsert-custom-role-dialog',
  templateUrl: './upsert-custom-role-dialog.component.html',
  styleUrls: ['./upsert-custom-role-dialog.component.scss']
})
export class UpsertCustomRoleDialogComponent implements OnDestroy {
  public formContent: any;
  public fieldForm: UntypedFormGroup;

  public isManageRolesChecked: boolean;
  public isViewSchemaChecked: boolean;
  public isCustomizeSchemaChecked: boolean;

  public entities: EntityGridEntry[] = [];
  public entityList: SearchEntityResponse[] = null;
  public fullEntityList: SearchEntityResponse[] = null;

  public shouldShowEntityPicker: boolean;

  public isSaving: boolean;

  public allCreate: boolean;
  public allRead: boolean;
  public allEdit: boolean;
  public allDelete: boolean;

  public isLoading = false;

  public Admin: string = Constants.Admin;
  public ReadEntityScheme: number = Constants.ReadEntityScheme;
  public UpdateEntityScheme: number = Constants.UpdateEntityScheme;
  public ReadEntityData: number = Constants.ReadEntityData;
  public CreateEntityData: number = Constants.CreateEntityData;
  public UpdateEntityData: number = Constants.UpdateEntityData;
  public DeleteEntityData: number = Constants.DeleteEntityData;

  public isRbacEnabled: boolean;
  public isRlpEnabled: boolean;

  private ManagePermissionId = 0;
  private ReadEntitySchemeId = 1;
  private UpdateEntitySchemeId = 2;

  private subscription: Subscription = new Subscription();

  constructor(
    public dialog: MatDialog,
    public usersService: UserService,
    public accountService: AccountService,
    public authService: AuthService,
    public dialogRef: MatDialogRef<UpsertCustomRoleDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CustomRoleDialogData,
    private fb: UntypedFormBuilder,
    private featureService: FeatureService,
  ) {
    this.isManageRolesChecked = this.data.role.adminPermissions.find(itm => itm === this.ManagePermissionId)
      === this.ManagePermissionId;
    this.isViewSchemaChecked = this.data.role.adminPermissions.find(itm => itm === this.ReadEntitySchemeId)
      === this.ReadEntitySchemeId;
    this.isCustomizeSchemaChecked = this.data.role.adminPermissions.find(itm => itm === this.UpdateEntitySchemeId)
      === this.UpdateEntitySchemeId;

    this.formContent = {
      name: [this.data.role.name, Validators.required],
      isManageRoles: this.isManageRolesChecked,
      isViewSchema: this.isViewSchemaChecked,
      isCustomizeSchema: this.isCustomizeSchemaChecked
    };

    this.fieldForm = this.fb.group(this.formContent);
    this.entities = this.entities.sort((a, b) => a.displayName > b.displayName ? 1 : -1);
    this.featureService.isEnabled(FeatureKey.EntityColumnPermission).subscribe(result => {
      this.isRbacEnabled = result;
    });

    this.featureService.isEnabled(FeatureKey.EntityDataRecordPermission).subscribe(result => {
      this.isRlpEnabled = result;
    });
  }

  public ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  public shouldShowCheck(permission: number, role: GetRoleResponse) {
    for (const perm of role.permissions) {
      if (perm === permission) {
        return true;
      }
    }
    return false;
  }
}
