<div class="list-user">
    <div class="list-tool-bar" fxLayout="row" fxLayoutAlign=" center">
        <mat-form-field floatLabel="never" class="apollo-textfield-small search-bar">
            <mat-label>{{'SearchText' | translate}}</mat-label>
            <mat-icon matSuffix>search</mat-icon>
            <input matInput autocomplete="false" [placeholder]="'SearchText' | translate" [(ngModel)]="searchValue"
                (keyup)="onSearch()" />
        </mat-form-field>
        <div class="role-filter" fxLayout="row" fxLayoutAlign="center center">
            <button mat-button [matMenuTriggerFor]="menu">
                <mat-icon class="md-18 filter-icon">filter_list</mat-icon>
                <span class="role-text">{{'ListUser.RolesText' | translate}}</span>
                <span *ngIf="selectedFilters.length !== 0" class="role-text">
                    ({{selectedFilters.length}})
                </span>
                <span>
                    <mat-icon class="md-18 expand-icon">expand_more</mat-icon>
                </span>
            </button>
            <mat-menu #menu="matMenu">
                <div (click)="$event.stopPropagation()" class="menu-item-row all-section" mat-menu-item
                    (keydown.space)="simulateClickAllFilters()">
                    <mat-checkbox (change)="onChangeAllFilter($event)"
                        [checked]="selectedFilters.length === displayFilters.length"
                        [indeterminate]="selectedFilters.length>0 && selectedFilters.length !== displayFilters.length"
                        class="font-semi-small">
                        {{'ListUser.FilterOptionText' | translate}}</mat-checkbox>
                </div>
                <div *ngFor="let filter of displayFilters" (click)="$event.stopPropagation()"
                    class="menu-item-row font-semi-small" mat-menu-item (keydown.space)="simulateClickFilter(filter)">
                    <mat-checkbox (change)="onChangeFilter($event, filter)" [checked]="shouldFilterChecked(filter)">
                        {{filter.displayName | translate}}</mat-checkbox>
                </div>
            </mat-menu>
        </div>
        <span class="spacer"></span>
        <button color="primary" *ngIf="!isItemSelected() && shouldDisableEdit()" mat-button
            [attr.aria-label]="'ListUser.EditUserButtonText' | translate" class="bold"
            (click)="onUpsertRole('selected')">
            <mat-icon class="material-icons md-24 standard-icon">edit</mat-icon>
            {{'ListUser.EditUserButtonText' | translate}}
        </button>
        <div *ngIf="!isItemSelected() && !shouldDisableEdit()" [matTooltip]="'Tooltip.UserEditLimit' | translate"
            fxLayout="row" fxLayoutAlign="center center">
            <button mat-button [attr.aria-label]="'ListUser.EditUserButtonText' | translate" class="bold" disabled
                (click)="onUpsertRole('selected')">
                <mat-icon class="material-icons md-24 standard-icon">edit</mat-icon>
                {{'ListUser.EditUserButtonText' | translate}}
            </button>
        </div>
        <button color="primary" *ngIf="selection.selected && selection.selected.length>0" mat-button
            [attr.aria-label]="'ListUser.RemoveUserButtonText' | translate" class="refresh bold"
            (click)="onBulkDelete()">
            <mat-icon class="material-icons md-24 standard-icon">clear</mat-icon>
            {{'ListUser.RemoveUserButtonText' | translate}}
        </button>
        <button color="primary" *ngIf="!selection.selected || selection.selected.length===0" mat-button
            [attr.aria-label]="'RefreshButtonText' | translate" class="refresh bold" (click)="onClickRefresh()">
            <mat-icon class="material-icons md-24 standard-icon">refresh</mat-icon>
            {{'RefreshButtonText' | translate}}
        </button>
        <ng-container *ngIf="isAdminConsistencyEnabled">
            <ap-button color="primary" *ngIf="!selection.selected || selection.selected.length===0" mat-flat-button
                [attr.aria-label]="'ListUser.AssignRole' | translate" class="bold" (click)="onUpsertUserGroup()"
                [label]="'ListUser.AssignRole' | translate">
            </ap-button>
        </ng-container>
    </div>
    <div *ngIf="searchValue || selectedFilters.length>0" class="search-result font-medium" fxLayout="row"
        fxLayoutAlign=" center">
        <div class="search-text" aria-live="polite">
            <span class="bold">{{dataSource.data.length}} {{'ListUser.SearchResultHelpText' | translate}}:</span>
            {{searchValue}}
        </div>
        <div fxLayout="row" fxLayoutAlign=" center" *ngIf="selectedFilters.length>0">
            <ap-typography class="roles">
                {{'ListUser.RolesText' | translate}}:
            </ap-typography>
            <ng-container *ngFor="let filter of selectedFilters">
                <mat-chip-option selectable="false" removable="true" disableRipple="true"
                    (removed)="removeFilterChip(filter)" class="filter-chip">
                    {{filter.displayName | translate}}
                    <mat-icon matChipRemove class="chip-cancel">cancel</mat-icon>
                </mat-chip-option>
            </ng-container>

            <button mat-button [attr.aria-label]="'ListUser.ClearAllText' | translate" class="clear-all bold"
                (click)="onClearAll()">
                {{'ListUser.ClearAllText' | translate}}
            </button>
        </div>
    </div>
    <div *ngIf="isLoading">
        <mat-spinner role="alert" [attr.aria-label]="'Loading' | translate" diameter="48" class="spinner"></mat-spinner>
    </div>
    <ng-container *ngIf="!isLoading">
        <table mat-table class="user-table" [dataSource]="dataSource" matSort (matSortChange)="sortData($event)">
            <caption></caption>
            <ng-container matColumnDef="select" sticky>
                <td scope="cell" *matHeaderCellDef class="th-first-cell">
                    <div class="checkbox-column">
                        <mat-checkbox (change)="$event ? masterToggle() : null"
                            [checked]="selection.hasValue() && isAllSelected()"
                            [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()">
                        </mat-checkbox>
                    </div>
                </td>
                <td mat-cell *matCellDef="let row; let i = index" class="checkbox-column">
                    <div class="checkbox-column">
                        <mat-checkbox (click)="$event.stopPropagation()"
                            (change)="$event ? selection.toggle(row) : null" [checked]="selection.isSelected(row)"
                            [aria-label]="checkboxLabel(row, i)">
                        </mat-checkbox>
                    </div>
                </td>
            </ng-container>
            <ng-container matColumnDef="name">
                <th scope="cell" mat-header-cell *matHeaderCellDef mat-sort-header class="bold font-medium header-text">
                    {{'ListUser.Name' | translate}}
                    <span role="status" [attr.aria-label]="ariaHelper.getOrderKey('name') | translate"></span>
                </th>
                <td mat-cell *matCellDef="let element" class="table-text">
                    <div fxLayout="row" fxLayoutAlign=" center" class="user-group-column">
                        <ng-container *ngIf="isAdminConsistencyEnabled">
                            <ng-container *ngIf="element.type === 'Group'">
                                <app-misc-icon [miscIconType]="'localGroup'"></app-misc-icon>
                            </ng-container>
                            <ng-container
                                *ngIf="element.type === 'User' && element.objectType !== 'DirectoryRobotUser'">
                                <app-misc-icon [miscIconType]="'localUser'"></app-misc-icon>
                            </ng-container>
                            <ng-container *ngIf="element.type === 'Application'">
                                <app-misc-icon [miscIconType]="'application'"></app-misc-icon>
                            </ng-container>
                            <ng-container *ngIf="element.objectType === 'DirectoryRobotUser'">
                                <app-misc-icon [miscIconType]="'robot'"></app-misc-icon>
                            </ng-container>
                            <div class="user-name user-margin">
                                {{element.name}}
                            </div>
                        </ng-container>
                        <ng-container *ngIf="!isAdminConsistencyEnabled">
                            <div class="user-name">
                                {{element.name}}
                            </div>
                            <ng-container *ngIf="element.type==='Group'">
                                <mat-chip-option class="group-chip bold font-small" selected>
                                    {{'ListUser.GroupTagText' | translate}}</mat-chip-option>
                            </ng-container>
                            <ng-container *ngIf="element.objectType==='DirectoryRobotUser'">
                                <mat-chip-option class="group-chip bold font-small" selected>
                                    {{'ListUser.RobotTagText' | translate}}</mat-chip-option>
                            </ng-container>
                        </ng-container>
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="email">
                <th scope="cell" mat-header-cell *matHeaderCellDef mat-sort-header class="bold font-medium header-text">
                    {{'ListUser.Email' | translate}}
                    <span role="status" [attr.aria-label]="ariaHelper.getOrderKey('email') | translate"></span>
                </th>
                <td mat-cell *matCellDef="let element" class="table-text">
                    <div *ngIf="element.email">
                        {{element.email}}
                    </div>
                </td>
            </ng-container>


            <ng-container matColumnDef="role">
                <th scope="cell" mat-header-cell *matHeaderCellDef mat-sort-header class="bold font-medium header-text">
                    {{'ListUser.RolesText' | translate}}
                    <span role="status" [attr.aria-label]="ariaHelper.getOrderKey('role') | translate"></span>
                </th>
                <td mat-cell *matCellDef="let element" class="table-text">
                    {{element.displayRoles}}
                </td>
            </ng-container>

            <ng-container matColumnDef="action">
                <th scope="cell" mat-header-cell *matHeaderCellDef> </th>
                <td mat-cell *matCellDef="let element; let i = index" class="action-column">
                    <div fxLayout="row" fxLayoutAlign="end">
                        <button [matTooltip]="'EditText' | translate" mat-icon-button
                            [attr.aria-label]="'EditRoleAssignmentText' | translate:{value: element.name}"
                            class="add-button action-button edit-user" (click)="onUpsertRole(element)" tabindex="0">
                            <mat-icon class="material-icons md-24 standard-icon">edit</mat-icon>
                        </button>
                        <div [matTooltip]="getRemoveTooltip(element) | async">
                            <button mat-icon-button [attr.aria-label]="'DeleteRoleAssignmentText' | translate:{value: element.name}"
                                class="action-button delete-user" [disabled]="shouldRemoveDisabled(element)"
                                (click)="onDeleteUserGroup(element)" tabindex="0">
                                <mat-icon class="material-icons md-24 standard-icon">clear</mat-icon>
                            </button>
                        </div>
                    </div>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns" class="table-header themed-list-header"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="table-row" class="table-row"></tr>
        </table>
    </ng-container>
    <mat-paginator *ngIf="(snapshot?.length>0)" [length]="snapshot?.length" [pageSize]="20"
        [pageSizeOptions]="[10, 20, 50]" (page)="onChangePagination($event)">
    </mat-paginator>
</div>
