<div fxLayout="row" fxLayoutAlign="start strech">
    <h1 mat-dialog-title>
        {{isCreateMode() ? ('UpsertData.AddData' | translate) : ('UpsertData.EditData' | translate)}}</h1>
</div>

<div mat-dialog-content class="dialog-content">
    <div class="upload-in-progess-box" *ngIf="shouldShowProgressBox">
        <mat-icon class="material-icons md-16 upload-in-progess-icon">error</mat-icon>
        {{'UpsertData.UploadProgressInfo' | translate}}
    </div>
    <form [formGroup]="dataForm" [style.fontSize.px]="14">
        <ng-container *ngFor="let field of data.fields">
            <div fxLayout="column" fxLayoutAlign="space-around stretch">
                <ng-container *ngIf="shouldShow(field, 'number')">
                    <mat-form-field floatLabel="always"
                        [matTooltip]="(checkFieldDisabled(field) | async) === false? ('NoPermissionToolTip' | translate) : ''">
                        <mat-label>{{field.displayName}}</mat-label>
                        <input matInput [id]="field.name" [name]="field.name" [formControlName]="field.name"
                            [placeholder]="field.type | titlecase" (keydown.enter)="enterToSubmit()"
                            [readonly]="(checkFieldDisabled(field) | async) === false" [required]="field.isRequired" />
                        <mat-error
                            *ngIf="dataForm.get(field.name).errors && dataForm.get(field.name).errors.required">
                            {{'UpsertData.FieldRequiredError' | translate}}
                        </mat-error>
                        <mat-error
                            *ngIf="dataForm.get(field.name).errors && dataForm.get(field.name).errors.numberError">
                            {{'UpsertData.NumberOnlyError' | translate}}
                        </mat-error>
                    </mat-form-field>
                </ng-container>

                <ng-container *ngIf="shouldShow(field, 'date')">
                    <mat-form-field floatLabel="always"
                        [matTooltip]="(checkFieldDisabled(field) | async) === false? ('NoPermissionToolTip' | translate) : ''">
                        <mat-label>{{field.displayName}}</mat-label>
                        <input matInput [matDatepicker]="picker" [id]="field.name" [name]="field.name"
                            placeholder="mm/dd/yy" [formControlName]="field.name"
                            (keydown.enter)="enterToSubmit()" [required]="field.isRequired"
                            [readonly]="(checkFieldDisabled(field) | async) === false">
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                        <mat-error *ngIf="dataForm.get(field.name).errors && (dataForm.get(field.name).errors.dateYearError || dataForm.get(field.name).errors.matDatepickerParse)">
                            {{'InvalidDateFormat' | translate}}
                        </mat-error>
                    </mat-form-field>
                </ng-container>
                <ng-container *ngIf="shouldShow(field, 'dateTime')">
                    <mat-form-field floatLabel="always"
                        [matTooltip]="(checkFieldDisabled(field) | async) === false? ('NoPermissionToolTip' | translate) : ''">
                        <mat-label>{{field.displayName}}</mat-label>
                        <input matInput [id]="field.name" [name]="field.name" [placeholder]="field.type | titlecase"
                            [required]="field.isRequired" [formControlName]="field.name" type="datetime-local"
                            (keydown.enter)="enterToSubmit()"
                            [readonly]="(checkFieldDisabled(field) | async) === false">
                        <mat-error *ngIf="dataForm.get(field.name).errors && dataForm.get(field.name).errors.dateTimeYearError">
                            {{'InvalidDateFormat' | translate}}
                        </mat-error>
                    </mat-form-field>
                </ng-container>
                <ng-container *ngIf="shouldShow(field, 'boolean')">
                    <div class="field-section">
                        <mat-form-field floatLabel="always" class="full-width">
                            <mat-label>{{field.displayName}}</mat-label>
                            <mat-select [required]="field.isRequired" [formControlName]="field.name" [id]="field.name"
                                [name]="field.name" *ngIf="(checkFieldDisabled(field) | async) === true">
                                <mat-option *ngIf="!field.isRequired" [value]="undefined">
                                    None
                                </mat-option>
                                <mat-option [value]="'yes'">
                                    Yes
                                </mat-option>
                                <mat-option [value]="'no'">
                                    No
                                </mat-option>
                            </mat-select>
                            <div *ngIf="(checkFieldDisabled(field) | async) === false"
                                [matTooltip]="('NoPermissionToolTip' | translate)">
                                <mat-select [disabled]="true">
                                </mat-select>
                            </div>

                        </mat-form-field>
                    </div>
                </ng-container>
                <ng-container *ngIf="shouldShow(field, null)">
                    <mat-form-field floatLabel="always"
                        [matTooltip]="(checkFieldDisabled(field) | async) === false? ('NoPermissionToolTip' | translate) : ''">
                        <mat-label>{{field.displayName}}</mat-label>
                        <input matInput type="text" [id]="field.name" [name]="field.name" [formControlName]="field.name"
                            [required]="field.isRequired" [placeholder]="field.type | titlecase"
                            (keydown.enter)="enterToSubmit()"
                            [readonly]="(checkFieldDisabled(field) | async) === false" />
                        <mat-error
                            *ngIf="dataForm.get(field.name).errors && dataForm.get(field.name).errors.required">
                            {{'UpsertData.FieldRequiredError' | translate}}
                        </mat-error>
                    </mat-form-field>
                </ng-container>
                <ng-container *ngIf="shouldShow(field, 'relation')">
                    <label *ngIf="field.isRequired" class="label">{{field.displayName}}*</label>
                    <label *ngIf="!field.isRequired" class="label">{{field.displayName}}</label>
                    <div [matTooltip]="((userService.checkPermission([ReadEntityData], field.referenceEntity.id) | async) === false)
                    || ((checkFieldDisabled(field) | async) === false)
                    ?  ('NoPermissionToolTip' | translate) : ''">
                        <div [class.disabled]="((userService.checkPermission([ReadEntityData], field.referenceEntity.id) | async) === false)
                        || ((checkFieldDisabled(field) | async) === false)">
                            <app-record-flyout #relationshipComponents [entity]="field.referenceEntity" [originField]="field"
                                [field]="field.referenceField" [value]="dataForm.get(field.name).value"
                                (selectedEvent)="dataForm.get(field.name).setValue($event)"></app-record-flyout>
                        </div>
                    </div>
                </ng-container>
                <!-- todo add shouldShow owner field -->
                <ng-container *ngIf="shouldShow(field, 'choiceset')">
                    <label class="label">{{field.displayName}}</label>
                    <div
                        [matTooltip]="(checkFieldDisabled(field) | async) === false? ('NoPermissionToolTip' | translate) : ''">
                        <div [class.disabled]="(checkFieldDisabled(field) | async) === false">
                            <app-choiceset-options #choiceSetComponents [choiceSetId]="field.choiceSetId"
                                [fieldDisplayType]="field.fieldDisplayType" [value]="dataForm.get(field.name).value"
                                (selectedEvent)="dataForm.get(field.name).setValue($event)"></app-choiceset-options>
                        </div>
                    </div>
                </ng-container>

                <ng-container *ngIf="shouldShow(field, 'file')">
                    <label class="label">{{field.displayName}}</label>
                    <div
                        [matTooltip]="(checkFieldDisabled(field) | async) === false? ('NoPermissionToolTip' | translate) : ''">
                        <div [class.disabled]="(checkFieldDisabled(field) | async) === false">
                            <app-file-attachment #fileComponents [field]="field" [onFileSelected]="onFileSelected">
                            </app-file-attachment>
                        </div>
                    </div>
                </ng-container>

            </div>
        </ng-container>
    </form>
    <div fxLayout="row" fxLayoutAlign="space-between end strech" class="bottom-btn-createanother">
        <mat-checkbox class="create-another-margin semi-bold" name="createAnother" (click)="toggleCreateAnother()">
            {{'CreateAnother' | translate}}
        </mat-checkbox>
        <div fxLayout="row" fxLayoutAlign="end strech">
            <button mat-stroked-button color="primary" class="btn bold cancel" (click)="onDismiss()"
                type="button">{{'CancelButtonText' | translate}}</button>
            <button *ngIf="!isLoading" mat-flat-button color="primary" type="submit" class="btn bold"
                [disabled]="isSubmitDisabled()" (click)="onSubmit()">{{'SaveButtonText' | translate}}</button>
            <div class="btn" *ngIf="isLoading">
                <mat-spinner role="alert" class="spinner" [attr.aria-label]="'Loading' | translate" diameter="24"></mat-spinner>
            </div>
        </div>
    </div>
    <button mat-icon-button (click)="onDismiss()" class="close-button"
            [attr.aria-label]="'CloseButtonText' | translate">
        <mat-icon class="font-large">
            close
        </mat-icon>
    </button>
</div>