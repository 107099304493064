<ng-container [ngSwitch]="true">
    <ng-container *ngSwitchCase="miscIconType === 'uniqueWarning'">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_69_4860)">
                <path d="M1 21H23L12 2L1 21Z" fill="#FFB40E"/>
                <path d="M13 18H11V16H13V18Z" fill="#273139"/>
                <path d="M13 14H11V10H13V14Z" fill="#273139"/>
            </g>
            <defs>
                <clipPath id="clip0_69_4860">
                    <rect width="24" height="24" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    </ng-container>
    <ng-container *ngSwitchCase="miscIconType === 'apiAccess'">
        <svg class="api-margin header-icon" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" >
            <path d="M10 15h5.88c.27-.31.67-.5 1.12-.5.83 0 1.5.67 1.5 1.5s-.67 
                1.5-1.5 1.5c-.44 0-.84-.19-1.12-.5H11.9c-.46 2.28-2.48 4-4.9 4-2.76 
                0-5-2.24-5-5 0-2.42 1.72-4.44 4-4.9v2.07c-1.16.41-2 1.53-2 2.83 0 
                1.65 1.35 3 3 3s3-1.35 3-3zm2.5-11c1.65 0 3 1.35 3 3h2c0-2.76-2.24-5-5-5s-5 
                2.24-5 5c0 1.43.6 2.71 1.55 3.62l-2.35 3.9c-.68.14-1.2.75-1.2 1.48 0 .83.67 
                1.5 1.5 1.5s1.5-.67 1.5-1.5c0-.16-.02-.31-.07-.45l3.38-5.63C10.49 9.61 9.5 8.42 
                9.5 7c0-1.65 1.35-3 3-3m4.5 9c-.64 0-1.23.2-1.72.54l-3.05-5.07C11.53 8.35 11 7.74 
                11 7c0-.83.67-1.5 1.5-1.5S14 6.17 14 7c0 .15-.02.29-.06.43l2.19 3.65c.28-.05.57-.08.87-.08 
                2.76 0 5 2.24 5 5s-2.24 5-5 5c-1.85 0-3.47-1.01-4.33-2.5h2.67c.48.32 1.05.5 1.66.5 1.65 0 3-1.35 3-3s-1.35-3-3-3">
                </path>
        </svg>
    </ng-container>
    <ng-container *ngSwitchCase="miscIconType === 'admin'">
        <svg class="admin-margin header-icon" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" >
            <path fill-rule="evenodd" clip-rule="evenodd" d="M0 4H18V18H13.5714V16H16V6H2V16H4.4286V18H0V4Z"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M4.16943 10.366L5.14483 10.93C5.05043 11.271 5.00003 11.629 
                5.00003 12C5.00003 12.371 5.05043 12.73 5.14483 13.071L4.16943 13.634L5.16943 15.367L6.14633 
                14.803C6.64773 15.314 7.28493 15.69 7.99943 15.874L8.00003 17.001H10L10.0006 15.874C10.7151 
                15.69 11.3523 15.314 11.8537 14.803L12.8306 15.367L13.8306 13.634L12.8552 13.071C12.9496 12.73 
                13 12.371 13 12C13 11.629 12.9496 11.271 12.8552 10.93L13.8306 10.366L12.8306 8.634L11.8537 
                9.197C11.3523 8.687 10.7151 8.31 10.0006 8.126L10 7H8.00003L7.99943 8.126C7.28493 8.31 6.64773 
                8.687 6.14633 9.197L5.16943 8.634L4.16943 10.366ZM9.00003 14C10.1046 14 11 13.105 11 12C11 10.896 
                10.1046 10 9.00003 10C7.89543 10 7.00003 10.896 7.00003 12C7.00003 13.105 7.89543 14 9.00003 14Z"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M0 0H18V6H0V0ZM2 2V4H16V2H2Z"/>
        </svg>
    </ng-container>
    <ng-container *ngSwitchCase="miscIconType === 'localUser'">
        <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="none" >
            <path class="icon-stroke" d="M12.021 11.012a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" clipRule="evenodd" />
            <path class="icon-stroke" d="M18.03 20.008a6 6 0 0 0-12 .009" />
        </svg>
    </ng-container>
    <ng-container *ngSwitchCase="miscIconType === 'localGroup'">
        <svg width="24" height="24" viewBox="0 2 24 24" xmlns="http://www.w3.org/2000/svg" fill="none">
            <path class="icon-stroke" d="M16.806 11.808a2.401 2.401 0 1 0 .008-4.803 2.401 2.401 0 0 0-.008 4.803Z" clipRule="evenodd" />
            <path class="icon-fill" d="M11 19.008a5.803 5.803 0 1 1 11.606.009l-2-.002A3.803 3.803 0 1 0 13 19.01l-2-.002Z" clipRule="evenodd" />
            <path class="icon-stroke" d="M6.807 16.808a2.401 2.401 0 1 0 .007-4.802 2.401 2.401 0 0 0-.007 4.802Z" clipRule="evenodd" />
            <path class="icon-stroke" d="M11.606 24.016A4.803 4.803 0 1 0 2 24.01" />
        </svg>
    </ng-container>
    <ng-container *ngSwitchCase="miscIconType === 'application'">
        <svg width="24" height="24" viewBox="-3 -5 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path class="icon-fill" fill-rule="evenodd" clip-rule="evenodd" d="M0 0H8V8H0V0ZM2 2V6H6V2H2Z"></path>
            <path class="icon-fill" fill-rule="evenodd" clip-rule="evenodd" d="M0 10H8V18H0V10ZM2 12V16H6V12H2Z"></path>
            <path class="icon-fill" fill-rule="evenodd" clip-rule="evenodd" d="M10 0H18V8H10V0ZM12 2V6H16V2H12Z"></path>
            <path class="icon-fill" fill-rule="evenodd" clip-rule="evenodd" d="M14.999 14.999V18H12.999V14.999H10V12.999H12.999V10H14.999V12.999H18V14.999H14.999Z"></path>
        </svg>
    </ng-container>
    <ng-container *ngSwitchCase="miscIconType === 'robot'">
        <svg width="24" height="24" viewBox="-3 -4 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path class="icon-fill" d="M6.5 2C6.5 1.44772 6.94772 1 7.5 1H10.5C11.0523 1 11.5 1.44772 11.5 2C11.5 2.55228 11.0523 3 10.5 3H7.5C6.94772 3 6.5 2.55228 6.5 2Z"></path>
            <path class="icon-fill" fill-rule="evenodd" clip-rule="evenodd" d="M4.25 8.75C4.25 8.19772 4.69772 7.75 5.25 7.75H12.75C13.3023 7.75 13.75 8.19772 13.75 8.75V11.75C13.75 
            12.3023 13.3023 12.75 12.75 12.75H5.25C4.69772 12.75 4.25 12.3023 4.25 11.75V8.75ZM6.25 9.75V10.75H11.75V9.75H6.25Z"></path>
            <path class="icon-fill" d="M8.25 13C7.69772 13 7.25 13.4477 7.25 14C7.25 14.5523 7.69772 15 8.25 15H9.75C10.3023 15 10.75 14.5523 10.75 14C10.75 13.4477 10.3023 13 9.75 13H8.25Z"></path>
            <path class="icon-fill" fill-rule="evenodd" clip-rule="evenodd" d="M10 4.25C10 3.69772 9.55229 3.25 9 3.25C8.44771 3.25 8 3.69772 8 4.25V4.75H3.25C2.14543 4.75 1.25 5.64543 
            1.25 6.75V9.5H1C0.447715 9.5 0 9.94771 0 10.5C0 11.0523 0.447715 11.5 1 11.5H1.25V15.25C1.25 16.3546 2.14543 17.25 3.25 17.25H14.75C15.8546 17.25 16.75 
            16.3546 16.75 15.25V11.25H17C17.5523 11.25 18 10.8023 18 10.25C18 9.69771 17.5523 9.25 17 9.25H16.75V6.75C16.75 5.64543 15.8546 4.75 14.75 4.75H10V4.25ZM3.25 
            6.75V15.25H14.75V6.75H3.25Z"></path>
            </svg>
    </ng-container>
</ng-container>