import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { getLanguage } from '../utilities';

@Pipe({ name: 'relativeTime' })
export class RelativeTimePipe implements PipeTransform {
  transform(value: Date): string {
    return moment(value).locale(getLanguage()).fromNow();
  }
}
