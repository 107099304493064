import { Input } from '@angular/core';
import { Component } from '@angular/core';
import { FieldType } from '../../models/field';

@Component({
  selector: 'app-field-icon',
  templateUrl: './field-icon.component.html',
  styleUrls: ['./field-icon.component.scss']
})
export class FieldIconComponent {
  @Input('fieldType')
  public fieldType: FieldType;
}
