import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Events } from '../models/events';
import { TelemetryService } from './telemetry.service';

@Injectable({
  providedIn: 'root'
})
export class OpenApiService {
  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  constructor(
    private http: HttpClient,
    private telemetryService: TelemetryService) { }

  public getSwagger(path: string): Observable<object> {
    this.telemetryService.logEvent(Events.SwaggerDownload);

    return this.http.get(path, this.httpOptions);
  }
}
