import { AuthService } from './../../../shared/guards/auth.service';
import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { Role, UserGroup, RoleResponse } from 'src/app/shared/models/user';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserService } from 'src/app/shared/services/user.service';
import { AccountService } from 'src/app/shared/services/account.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackBarComponent } from 'src/app/shared/components/snack-bar/snack-bar.component';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subscription } from 'rxjs';

export interface RoleEntry {
  role: Role;
  displayName: string;
  id?: string;
  roleResponse?: RoleResponse;
}

export enum DataMode {
  create = 'Create',
  update = 'Update'
}

export interface UserDialogData {
  mode: DataMode;
  user: UserGroup;
  roles: RoleResponse[];
}

export enum EditFieldDialogMode {
  create = 'Create',
  update = 'Update'
}

@Component({
  selector: 'upsert-user-dialog',
  templateUrl: './upsert-user-dialog.component.html',
  styleUrls: ['./upsert-user-dialog.component.scss']
})
export class UpsertUserDialogComponent implements OnInit, OnDestroy {

  public isLoading = false;
  public snapshot: RoleResponse[] = [];
  public roles: RoleEntry[] = [];
  public selectedRoles: RoleEntry[] = [];
  public accessToken: string;
  public pickedPeopleList: any[] = [];
  private subscription: Subscription = new Subscription();

  constructor(
    public usersService: UserService,
    public dialogRef: MatDialogRef<UpsertUserDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: UserDialogData,
    private accountService: AccountService,
    private authService: AuthService,
    private snackBar: MatSnackBar,
    private translateService: TranslateService,
  ) {
    this.roles = this.data.roles ? this.data.roles.map(rl => this.convertToRoleEntry(rl)) : [];
    if (this.data.user) {
      this.data.user.role = this.data.user.role || [];
    }
  }

  public ngOnInit() {
    if (this.data.mode === DataMode.update) {
      this.snapshot = this.data.user.role;

      this.selectedRoles = [...this.data.user.role].map(itm => this.convertToRoleEntry(itm));
    }

    this.authService.userProfileDummy$.subscribe(result => {
      if (result) {
        this.authService.getUser().subscribe(result => {
          this.accessToken = result && result.access_token;
        });
      }
    });
  }

  public ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  public onCheckRole(event: any, role: RoleEntry) {
    if (event.checked) {
      this.selectedRoles.push(role);
    } else {
      this.selectedRoles.splice(this.selectedRoles.findIndex(itm => itm.displayName === role.displayName), 1);
    }
  }

  public shouldRoleChecked(role: RoleEntry): boolean {
    return !!this.selectedRoles.find(itm => itm.displayName === role.displayName);
  }

  public shouldDisableCheckbox(role: RoleEntry): boolean {
    return role.role === Role.admin && this.data.user && this.data.user.name === 'OrganizationAdmin';
  }

  public getCheckBoxDisableMessage(): Observable<string> {
    return this.translateService.get('UpsertUser.DisableMessage');
  }

  public onSubmit(): void {
    this.isLoading = true;
    if (!!this.data.user) {
      const sub = this.usersService.upsertUserRole([this.data.user], this.selectedRoles).subscribe(() => {
        this.accountService.getProfile().subscribe(result => {
          this.authService.setUserProfile(result);
          this.isLoading = false;
          this.dialogRef.close('succeed');
        });
      }, (error) => {
        this.isLoading = false;
        this.snackBar.openFromComponent(SnackBarComponent, { duration: 5000, verticalPosition: 'top', data: error });
      });
      this.subscription.add(sub);
    } else {
      const sub = this.usersService.upsertUserRoleWithPeoplePick(this.pickedPeopleList, this.selectedRoles).subscribe(() => {
        this.accountService.getProfile().subscribe(result => {
          this.authService.setUserProfile(result);
          this.isLoading = false;
          this.dialogRef.close('succeed');
        });
      }, (error) => {
        this.isLoading = false;
        this.snackBar.openFromComponent(SnackBarComponent, { duration: 5000, verticalPosition: 'top', data: error });
      });
      this.subscription.add(sub);
    }
  }

  public isSubmitDisabled(): boolean {
    if (this.data.mode === DataMode.create) {
      return this.selectedRoles.length === 0 || this.pickedPeopleList.length === 0;
    } else {
      let result = true;
      for (const role of this.selectedRoles) {
        if (!this.data.user.role.includes(role.roleResponse)) {
          result = false;
        }
      }
      if (this.data.user.role.length !== this.selectedRoles.length) {
        result = false;
      }
      if (this.selectedRoles.length === 0) {
        result = true;
      }
      return result;
    }
  }

  public onDismiss(): void {
    this.dialogRef.close();
  }

  public isCreateMode(): boolean {
    return this.data.mode === DataMode.create;
  }

  public onPeoplePickerChanged(event: any) {
    this.pickedPeopleList = event.detail.data;
  }

  private convertToRoleEntry(role: RoleResponse): RoleEntry {
    if (role.name === 'Admin') {
      return {
        role: Role.admin,
        displayName: 'Administrator',
        id: role.id,
        roleResponse: role
      };
    } else if (role.name === 'Designer') {
      return {
        role: Role.designer,
        displayName: 'Designer',
        id: role.id,
        roleResponse: role
      };
    } else if (role.name === 'DataWriter') {
      return {
        role: Role.dataWriter,
        displayName: 'Data Writer',
        id: role.id,
        roleResponse: role
      };
    } else if (role.name === 'DataReader') {
      return {
        role: Role.dataReader,
        displayName: 'Data Reader',
        id: role.id,
        roleResponse: role
      };
    } else {
      return {
        role: Role.custom,
        displayName: role.name,
        id: role.id,
        roleResponse: role
      };
    }
  }
}
