<app-migration-bar></app-migration-bar>
<ng-container *ngIf="showNoPermission">
  <app-no-permission></app-no-permission>
</ng-container>
<portal-alert-bar *ngIf="shouldShowInvalidIdentifiers()" status="warning" cancelable="true"
  (alertDismissed)="invalidIdentifierOnDimiss()">{{getInvalidIdentifiers() | async}}</portal-alert-bar>
<div *ngIf="!isEntitiesImport() && !showNoPermission" fxLayout="row" fxLayoutAlign="space-between center"
  class="choiceset-title-section">
  <h1 class="bold font-large">
    <app-breadcrumb-secondary [breadcrumbs]="breadcrumbs"></app-breadcrumb-secondary>
    {{'ListChoiceSets.Title' | translate}}
  </h1>
  <div [matTooltip]="(userService.checkPermission([UpdateEntityScheme]) | async) === false? ('NoPermissionToolTip' | translate) : ''">
    <button [class.disabled]="(userService.checkPermission([UpdateEntityScheme]) | async) === false"
      mat-stroked-button [attr.aria-label]="'ListChoiceSets.AddButton' | translate" class="new-field-button bold"
      (click)="onUpsertChoiceSet(null)" color="primary">
      {{'ListChoiceSets.AddButton' | translate}}
    </button>
  </div>
</div>

<ng-container *ngIf="!showNoPermission">
  <div class="list-tool-bar" fxLayout="row" fxLayoutAlign="start center">
    <mat-form-field floatLabel="never" class="apollo-textfield-small search-bar">
      <mat-icon matSuffix>search</mat-icon>
      <input matInput autocomplete="false" [placeholder]="'SearchText' | translate" [(ngModel)]="searchValue"
        (keyup)="onSearch()" />
    </mat-form-field>
    <ng-container *ngIf="isCdmEnabled && !isEntitiesImport() && !isLoading && currentTemplates.length > 1">
      <span class="find-template-text"> {{'ListEntities.FindTemplate' | translate}}: </span>
      <mat-form-field class="apollo-textfield-small search-column template-filter" floatLabel="never">
        <mat-select [placeholder]="'ListEntities.NoTemplate' | translate" [(value)]="appliedTemplate">
          <mat-option *ngFor="let template of currentTemplates" [value]="template"
            (click)="onSelectTemplate(template)">
            {{template.name | translate}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </ng-container>
    <ng-container *ngIf="isEntitiesImport()">
      <mat-checkbox (change)="toggleShowOnlyConflictChoicesets()" class="conflict-box">
        {{'EntitiesConflict.ShowOnlyConflicting' | translate}}
      </mat-checkbox>
      <portal-custom-icon name="warning" size="24px"
        [matTooltip]="'EntitiesConflict.ChoicesetMessageTooltip' | translate"
        class=conflict-box-icon>
      </portal-custom-icon>
    </ng-container>

    <span class="spacer"></span>
    <button *ngIf="!isEntitiesImport()" class="refresh bold" color="primary" mat-button
      [attr.aria-label]="'ListChoiceSets.Refresh' | translate" (click)="loadChoiceSetsAndEntities()">
      <mat-icon class="material-icons md-24 standard-icon">refresh</mat-icon>
      {{'ListChoiceSets.Refresh' | translate}}
    </button>
  </div>
  <ng-container *ngIf="isLoading && !showNoPermission">
    <mat-spinner class="spinner list-entity-spinner" role="alert" [attr.aria-label]="'Loading' | translate" diameter="48"></mat-spinner>
  </ng-container>
  <div *ngIf="!isLoading && !showNoPermission">
    <ng-container>
      <table mat-table [dataSource]="dataSource" matSort (matSortChange)="sortData($event)">
        <caption></caption>

        <ng-container matColumnDef="displayName">
          <th scope="cell" mat-header-cell *matHeaderCellDef mat-sort-header
            class="table-header-text font-medium bold">
            {{'ListChoiceSets.ColumnDisplayName' | translate}}
            <span role="status"
              [attr.aria-label]="ariaHelper.getOrderKey('displayName') | translate"></span>
          </th>
          <th mat-cell *matCellDef="let element" (click)="onUpsertChoiceSet(element)"
            class="display-name bold">
            <span *ngIf="isEntitiesImport() && isConflictChoiceset(element); else display_name">
              <portal-custom-icon name="warning" size="24px"
                [matTooltip]="'EntitiesConflict.ChoicesetMessageTooltip' | translate"
                class=conflict-entity-icon>
              </portal-custom-icon>
              <span class="conflict-entity-name">{{element.displayName}}</span>
            </span>
            <ng-template #display_name> {{element.displayName}}</ng-template>
          </th>
        </ng-container>

        <ng-container matColumnDef="name">
          <th scope="cell" mat-header-cell *matHeaderCellDef mat-sort-header
            class="table-header-text font-medium bold">
            {{'ListChoiceSets.ColumnName' | translate}}
            <span role="status" [attr.aria-label]="ariaHelper.getOrderKey('name') | translate"></span>
          </th>
          <td mat-cell *matCellDef="let element" class="table-text"> {{element.name}} </td>
        </ng-container>

        <ng-container *ngIf="isCdmEnabled" matColumnDef="isModelReserved">
          <th scope="cell" mat-header-cell *matHeaderCellDef mat-sort-header
            class="table-header-overflow table-header-text font-medium bold">
            {{'ListEntities.IsModelReserved' | translate}}
            <span role="status" [attr.aria-label]="ariaHelper.getOrderKey('isModelReserved') | translate"></span>
          </th>
          <td mat-cell *matCellDef="let element" class="table-name-text"> {{(element.isModelReserved ?
            'ListField.UniqueYes' : 'ListField.UniqueNo') | translate}} </td>
        </ng-container>

        <ng-container matColumnDef="description">
          <th scope="cell" mat-header-cell *matHeaderCellDef mat-sort-header
            class="description-column table-header-text  font-medium bold">
            {{'ListChoiceSets.ColumnDescription' | translate}}
            <span role="status" [attr.aria-label]="ariaHelper.getOrderKey('description') | translate"></span>
          </th>
          <td mat-cell *matCellDef="let element" class="table-text">
            {{element.description}}
          </td>
        </ng-container>

        <ng-container *ngIf="!isEntitiesImport()" matColumnDef="recordCount">
          <th scope="cell" mat-header-cell *matHeaderCellDef mat-sort-header
            class="table-header-text  font-medium bold">
            {{'ListChoiceSets.ColumnChoiceCount' | translate}}
            <span role="status" [attr.aria-label]="ariaHelper.getOrderKey('recordNumber') | translate"></span>
          </th>
          <td mat-cell *matCellDef="let element" class="table-text"> {{element.recordCount}} </td>
        </ng-container>

        <ng-container *ngIf="!isEntitiesImport()" matColumnDef="updateTime">
          <th scope="cell" mat-header-cell *matHeaderCellDef mat-sort-header
            class="table-header-text font-medium bold">
            {{'ListChoiceSets.ColumnLastModifiedOn' | translate}}
            <span role="status" [attr.aria-label]="ariaHelper.getOrderKey('updateTime') | translate"></span>
          </th>
          <td mat-cell *matCellDef="let element" class="table-text"> {{element.updateTime | relativeTime}}</td>
        </ng-container>

        <ng-container *ngIf="!isEntitiesImport()" matColumnDef="edit">
          <th scope="cell" mat-header-cell *matHeaderCellDef class="action-column"> </th>
          <td mat-cell *matCellDef="let element">
            <div fxLayout="row" fxLayoutAlign="end">
              <div [matTooltip]="getEditDeleteTooltip(element) | async">
                <button mat-icon-button [attr.aria-label]="'EditText' | translate" class="menu-button"
                  [class.disabled]="(shouldUpdateButtonDisabled(element) | async) === true"
                  (click)="onUpsertChoiceSet(element)" [matTooltip]="'EditText' | translate">
                  <mat-icon class="material-icons md-24 standard-icon"
                    [class.disabled]="(shouldUpdateButtonDisabled(element) | async) === true">edit</mat-icon>
                </button>
              </div>
              <div [matTooltip]="getEditDeleteTooltip(element) | async">
                <button mat-icon-button [attr.aria-label]="'DeleteText' | translate" class="menu-button"
                  [class.disabled]="(shouldUpdateButtonDisabled(element) | async) === true"
                  (click)="onDeleteChoiceSet(element)" [matTooltip]="'DeleteText' | translate">
                  <mat-icon class="material-icons md-24 standard-icon"
                    [class.disabled]="(shouldUpdateButtonDisabled(element) | async) === true">delete</mat-icon>
                </button>
              </div>
            </div>
          </td>
        </ng-container>

        <ng-container *ngIf="isEntitiesImport()" matColumnDef="action">
          <th scope="cell" mat-header-cell *matHeaderCellDef class="checkbox-column">
            <mat-checkbox (change)="$event ? masterToggle() : null"
              [checked]="selection.hasValue() && isAllSelected()"
              [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()">
              <span class="font-medium bold">
                {{ 'Import' | translate }}?
              </span>
            </mat-checkbox>
          </th>
          <td mat-cell *matCellDef="let row" class="checkbox-column">
            <mat-checkbox (click)="$event.stopPropagation()"
              (change)="$event ? toggleRow($event, row) : null" [checked]="selection.isSelected(row)"
              [aria-label]="checkboxLabel(row)">
            </mat-checkbox>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns" class="table-header themed-list-header"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="table-row"></tr>
      </table>
    </ng-container>

    <div *ngIf="!(choicesets.length>0) && !searchValue" class="no-items" fxLayout="column"
      fxLayoutAlign="space-around center">
      <div class="no-items-text">
        {{'ListChoiceSets.NoItems' | translate}}
      </div>
      <button mat-flat-button class="create-button"
        [attr.aria-label]="'ListChoiceSets.CreateChoiceSet' | translate" (click)="onUpsertChoiceSet(null)"
        disableRipple="true">
        <span class="font-medium bold">
          {{'ListChoiceSets.CreateChoiceSet' | translate}}
        </span>
        <mat-icon class="material-icons md-12 plus">add</mat-icon>
      </button>
    </div>
  </div>

  <mat-paginator *ngIf="(choicesets.length>0)" [length]="choicesets.length" [pageSize]="20"
    [pageSizeOptions]="[10, 20, 50]" (page)="onChangePagination($event)">
  </mat-paginator>

</ng-container>