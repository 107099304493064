import { HttpClient } from '@angular/common/http';
import {
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  ActivatedRoute,
  Router,
  UrlSegment,
} from '@angular/router';

import {
  Observable,
  of,
  Subscription,
} from 'rxjs';
import { mergeMap, switchMap, take } from 'rxjs/operators';
import { ErrorDialogComponent } from 'src/app/shared/components/error-dialog/error-dialog.component';
import {
  SnackBarComponent,
  SnackBarMode,
} from 'src/app/shared/components/snack-bar/snack-bar.component';
import {
  Entity,
  EntityType,
} from 'src/app/shared/models/entity';
import {
  ErrorType,
  Events,
} from 'src/app/shared/models/events';
import {
  Field,
  FieldType,
} from 'src/app/shared/models/field';
import { AdvancedQueryRequest } from 'src/app/shared/services/entities-data.service';
import { EntitiesService } from 'src/app/shared/services/entities.service';
import { FeatureService } from 'src/app/shared/services/feature.service';
import { ImportService } from 'src/app/shared/services/import.service';
import { TelemetryService } from 'src/app/shared/services/telemetry.service';
import { UserService } from 'src/app/shared/services/user.service';
import { UrlUtils } from 'src/app/shared/url.utils';
import {
  isReservedEntity,
  isSystemEntity,
  isUnImportTypeRequired,
} from 'src/app/shared/utilities';

import { TranslateService } from '@ngx-translate/core';

import {
  ConfirmationDialogComponent,
  ConfirmationType,
} from '../../../shared/components/confirmation-dialog/confirmation-dialog.component';
import { Constants } from '../../../shared/constants';
import {
  ChoiceSetDialogMode,
  ChoiceSetDialogResult,
  UpsertChoicesetDialogComponent,
} from '../../choicesets/upsert-choiceset-dialog/upsert-choiceset-dialog.component';
import { ImportErrorDialogComponent } from '../import-error-dialog/import-error-dialog.component';
import { ImportPartialImportedDialogComponent } from '../import/partial/import-partialimported-dialog.component';
import {
  EntityDialogMode,
  EntityDialogResult,
  UpsertEntityDialogComponent,
} from '../upsert-entity-dialog/upsert-entity-dialog.component';
import { ListDataComponent } from './data/list-data/list-data.component';
import {
  EditFieldDialogData,
  EditFieldDialogMode,
  UpsertFieldDialogComponent,
} from './upsert-field-dialog/upsert-field-dialog.component';
import { Breadcrumb } from 'src/app/shared/components/breadcrumb/breadcrumb.component';

export interface BulkImportResponse {
  errorFileLink?: string;
  insertedRecords?: number;
  totalRecords?: number;
}

@Component({
  selector: 'entity-details',
  templateUrl: './entity-details.component.html',
  styleUrls: ['./entity-details.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EntityDetailsComponent implements OnInit, OnDestroy {
  public entity: Entity;
  public entities: Entity[];
  public choicesets: Entity[];
  public entitiesLink: string;
  public advancedQueryRequest: AdvancedQueryRequest;

  public viewIndex = 0;
  public isLoadingEntities = false;
  public isLoadingChoiceSets = false;
  public isSelectedFileDismissed: boolean;
  public fileToUpload: File;
  public lastSelectedFile = null;
  public isLoadingEntity = false;
  public breadcrumbs: Breadcrumb[];

  public UpdateEntityScheme: number = Constants.UpdateEntityScheme;
  public ReadEntityScheme: number = Constants.ReadEntityScheme;
  public ReadEntityData: number = Constants.ReadEntityData;
  public CreateEntityData: number = Constants.CreateEntityData;
  public UpdateEntityData: number = Constants.UpdateEntityData;
  public DeleteEntityData: number = Constants.DeleteEntityData;

  public isSystemEntity = isSystemEntity;
  public isReservedEntity = isReservedEntity;
  public isUnImportTypeRequired = isUnImportTypeRequired;

  @ViewChild(ListDataComponent)
  public listDataComponent: ListDataComponent;

  private DATA_VIEW_INDEX = 1;
  private FIELD_VIEW_INDEX = 0;
  public SIZE_LIMIT_IN_BYTE = 10485760; // file size limit is 10MB
  private subscription: Subscription = new Subscription();
  file: any;

  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private router: Router,
    private entitiesService: EntitiesService,
    public dialog: MatDialog,
    public userService: UserService,
    private telemetryService: TelemetryService,
    private snackBar: MatSnackBar,
    private translateService: TranslateService,
    private importService: ImportService,
    private featureService: FeatureService,
  ) { }

  public ngOnInit() {
    this.route.queryParams.subscribe((queryParams) => {
      if (queryParams['query']) {
        this.route.url.subscribe((url) => {
          if (sessionStorage.getItem(Constants.SavedTenant) &&
              sessionStorage.getItem(Constants.SavedTenant) !== url[1].path) {
            this.reloadPage(this.generateLinkToEntities(url));
          } else {
            const query = JSON.parse(decodeURI(queryParams.query)) as AdvancedQueryRequest;

            this.advancedQueryRequest = {
              filterLogicalOperator: query.filterLogicalOperator,
              queryFilterFields: query.queryFilterFields,
            };
            this.viewIndex = 1;
            sessionStorage.setItem(Constants.SavedTenant,  url[1].path);
          }
        });
      }
      if (queryParams['templateId']) {
        this.breadcrumbs = [{
          key: 'EntityDetails.Title',
          url: `${UrlUtils.getBaseRouteUrl()}/entities`,
          queryParams: { templateId: queryParams['templateId'] }
        }];
      } else {
        this.breadcrumbs = [{ key: 'EntityDetails.Title', url: `${UrlUtils.getBaseRouteUrl()}/entities`}];
      }
    });
    this.telemetryService.startTimedEvent(Events.EntityDetailView);
    const stateData = window.history.state;
    this.setTab();
    if (stateData && stateData.entity && stateData.entities && stateData.choicesets) {
      this.entity = stateData.entity;
      this.breadcrumbs.push({ key: this.entity.displayName });
      this.setBrowserTabTitle();
      this.entities = stateData.entities;
      this.choicesets = stateData.choicesets;
    } else {
      this.isLoadingEntities = true;
      this.isLoadingChoiceSets = true;

      const sub = this.route.paramMap.subscribe(params => {
        this.entitiesService.getEntities().subscribe(result => {
          this.entities = result;
          this.entity = this.entities.find(entity => entity.id.toLowerCase() === params.get('id').toLowerCase());
          this.breadcrumbs.push({ key: this.entity?.displayName });
          this.isLoadingEntities = false;
          if (!this.entity) {
            this.snackBar.openFromComponent(SnackBarComponent,
              { duration: 5000, verticalPosition: 'top', data: { errorText: 'The entity id you requested does not exist.' } });
          } else {
            this.setBrowserTabTitle();
          }

          this.telemetryService.endTimedEvent(Events.EntityDetailView);
          this.telemetryService.endTimedEvent(Events.AppLoad);
        }, (error) => {
          this.isLoadingEntities = false;
          this.telemetryService.logError({ type: ErrorType.warning, message: `list entities faild ${JSON.stringify(error)}` });

          if (error.errorText && error.errorText.indexOf('/api/Entity: 403') > -1) {
            this.router.navigate([`${UrlUtils.getBaseRouteUrl()}/entities`]);
          } else if (error.errorText && error.errorText.indexOf('SyntaxError: Unexpected token') > -1) {
            // todo - nanz this is a workaround for bug https://uipath.atlassian.net/browse/OR-26046
            // we cannnot get response status error code unless GATEWAY return Access-Control-Allow-Origin: * in response
            location.reload();
          } else {
            this.dialog.open(ErrorDialogComponent, { width: 'auto', data: error });
          }
        });

        this.entitiesService.getChoiceSet().subscribe(result => {
          this.choicesets = result;
          this.isLoadingChoiceSets = false;
        }, (error) => {
          this.isLoadingChoiceSets = false;
          this.telemetryService.logError({ type: ErrorType.warning, message: `list choicesets faild ${JSON.stringify(error)}` });
          this.dialog.open(ErrorDialogComponent, { width: 'auto', data: error });
        });
      });
      this.subscription.add(sub);
    }
  }

  public reloadPage(url: string) {
    location.replace(url);
  }

  private generateLinkToEntities(url: UrlSegment[]) {
    let link = '';
    for (let index = 0; index < url.length - 1; index++) {
      link += url[index];
      if (index !== url.length - 2) {
        link += '/';
      }
    }
    return link;
  }

  public ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  public get isLoadingData() {
    return this.isLoadingEntities || this.isLoadingChoiceSets;
  }

  public getAddFieldTooltip(entity: Entity) {
    return this.userService.checkPermission([this.UpdateEntityScheme]).pipe(switchMap(result => {
      if (!result) {
        return this.translateService.get('NoPermissionToolTip');
      } else if (isSystemEntity(entity)) {
        return this.translateService.get('ListEntities.CannotModifySystemEntity');
      } else {
        return of('');
      }
    }));
  }

  public onRefreshEntity() {
    const sub = this.route.paramMap.subscribe(params => {
      this.entitiesService.getEntities().subscribe(result => {
        this.entities = result;
        this.entity = this.entities.find(entity => entity.id.toLowerCase() === params.get('id').toLowerCase());
      });
    });
    this.subscription.add(sub);
  }

  public onClickAddData() {
    this.listDataComponent && this.listDataComponent.onClickEdit(null);
  }

  public onCreateField() {
    this.telemetryService.logEvent(Events.CreateNewFieldClick);

    const data: EditFieldDialogData = {
      mode: EditFieldDialogMode.create,
      field: new Field('idabc', '', this.entity.id, '', FieldType.text, '', false),
      entityName: this.entity.name,
      entities: this.getFilteredEntites([...this.entities], this.entity),
      choicesets: this.choicesets?.filter(choiceset => !choiceset.categoryId),
      addChoiceSet: this.onAddChoiceSet,
      addEntity: this.onAddEntity,
      recordCount: this.entities[this.entities.findIndex(entity => entity.name === this.entity.name)].recordCount,
    };
    const dialogRef = this.dialog.open(UpsertFieldDialogComponent,
      {
        width: 'auto',
        height: '100%',
        position: { top: '0', right: '0' },
        disableClose: true,
        panelClass: 'custom-sidepane',
        data,
      }
    );

    dialogRef.afterClosed().subscribe((result) => {

      this.onRefreshEntity();
    });
  }

  public getCreateNewFieldToolTip(): Observable<string> {
    return this.userService.checkPermission([this.CreateEntityData]).pipe(mergeMap(
      (result) => {
        if (!result) {
          return this.translateService.get('ListData.NoPermissionTooltip');
        } else if (isSystemEntity(this.entity)) {
          return this.translateService.get('ListEntities.CannotModifySystemEntity');
        } else {
          return of('');
        }
      })
    );
  }

  public getAddDataToolTip(): Observable<string> {
    return this.userService.checkPermission([this.CreateEntityData], this.entity.id).pipe(mergeMap(
      (result) => {
        if (!result) {
          return this.translateService.get('ListData.NoPermissionTooltip');
        } else if (this.entity.fields.length === 5) {
          return this.translateService.get('ListData.NoFieldTooltip');
        } else if (isSystemEntity(this.entity)) {
          return this.translateService.get('ListEntities.AddDataCannotModifySystemEntity');
        } else {
          return of('');
        }
      })
    );
  }

  public tabClick(event: any): void {
    if (event.index === 1) {
      this.telemetryService.logEvent(Events.DataTabClick);
    }
  }

  public alreadyNoPermission() {
    return this.listDataComponent.showNoPermission;
  }

  private setTab() {
    this.route.queryParamMap.subscribe(queryParams => {
      const view = queryParams.get('view');
      this.viewIndex = view === Constants.DataView ? this.DATA_VIEW_INDEX : this.FIELD_VIEW_INDEX;
    });
  }

  private getFilteredEntites(entities: Entity[], filteredEntity: Entity): Entity[] {
    return entities.filter(e =>
      !(e.name === filteredEntity.name || this.isLinked(e.fields, filteredEntity))
    );
  }

  private isLinked(fields: Field[], entity: Entity) {
    let isLinked = false;
    fields.forEach((f) => {
      if (f.referenceEntity && f.referenceEntity.id === entity.id) {
        isLinked = true;
      }
    });
    return isLinked;
  }

  public onRefreshChoiceSets() {
    this.isLoadingChoiceSets = true;

    this.route.paramMap.subscribe(params => {
      this.entitiesService.getChoiceSet().subscribe(result => {
        this.choicesets = result;
        this.isLoadingChoiceSets = false;
      });
    });
  }

  private onAddChoiceSet = () => {
    this.dialog.closeAll();

    const dialogRef = this.dialog.open(UpsertChoicesetDialogComponent,
      {
        width: 'auto',
        height: '100%',
        position: { top: '0', right: '0' },
        disableClose: true,
        panelClass: 'custom-sidepane',
        data: {
          mode: ChoiceSetDialogMode.create,
          choiceset: new Entity('', '', EntityType.custom, '', ''),
        }
      });

    dialogRef.afterClosed().subscribe((result: ChoiceSetDialogResult) => {
      if (result?.succeed) {
        this.onRefreshChoiceSets();
      }
    });
  };

  private onAddEntity = () => {
    this.dialog.closeAll();
    const dialogRef = this.dialog.open(UpsertEntityDialogComponent,
      {
        width: 'auto',
        height: '100%',
        position: { top: '0', right: '0' },
        disableClose: true,
        panelClass: 'custom-sidepane',
        data: {
          mode: EntityDialogMode.create, entity: new Entity('', '', EntityType.custom, '', '')
        }
      });

    dialogRef.afterClosed().subscribe((result: EntityDialogResult) => {
      if (result?.succeed) {
        this.onRefreshEntity();
      }
    });
  };

  private setBrowserTabTitle() {
    this.translateService.get('BrowserTitleWithContext', { value: this.entity.displayName }).subscribe(s => {
      document.title = s;
    });
  }

  public async selectFile(fileList: FileList): Promise<void> {
    this.telemetryService.logEvent(Events.BulkImportButtonClick);
    this.isSelectedFileDismissed = false;
    const files: File[] = [];
    const isFileSelectedSuccessfully = await this.onFileSelected(Array.from(fileList));
    if (isFileSelectedSuccessfully) {
      const fileToUpload = fileList[0];
      this.importData(fileToUpload, this.entity.id);
    }
  }

  public importData(fileToUpload: File, entityId: string) {
    this.isLoadingEntities = true;
    let snackBarText = this.translateService.get('BulkImport.SuccessText');

    const sub = this.entitiesService.bulkImportData(fileToUpload, entityId).pipe(take(1)).subscribe({
      next: (res) => {
        if(res.errorFileLink && res.insertedRecords!==0)
        {
          this.telemetryService.logEvent(Events.BulkPartialImport);
          const messageText = this.translateService.get('Import.OutputFileDownload');
          this.dialog.open(ImportPartialImportedDialogComponent,
            {
              width: '450px',
              autoFocus: false,
              data: {
                iconClass: 'icon-warning',
                dialogTitleKey: 'Import.PartialImportedTitle',
                dialogInfoKey: 'Import.PartialImportedInformation',
                dialogIcon: 'warning',
                text: messageText,
                entityId,
                errorFileLink: res.errorFileLink,
              }
            });
          snackBarText = this.translateService.get('BulkImport.PartialSuccessText');
        }
        else if(res.errorFileLink && res.insertedRecords===0)
        {
          this.telemetryService.logEvent(Events.BulkPartialImport);
          const messageText = this.translateService.get('Import.OutputFileDownload');
          this.dialog.open(ImportPartialImportedDialogComponent,
            {
              width: '450px',
              autoFocus: false,
              data: {
                iconClass: 'icon-error',
                dialogTitleKey: 'Import.NoRecordImportedTitle',
                dialogInfoKey: 'Import.NoRecordImportedInformation',
                dialogIcon: 'error',
                text: messageText,
                entityId,
                errorFileLink: res.errorFileLink,
              }
            });
        }
        if (res.insertedRecords!==0)
        {
          this.snackBar.openFromComponent(
            SnackBarComponent,
            {
              duration: 5000,
              verticalPosition: 'top',
              data: { mode: SnackBarMode.success, text: snackBarText }
            }
          );
        }

        this.isLoadingEntities = false;
        this.listDataComponent.onClickRefresh();
      },
      error: (err) => {
        console.error(err);
        this.isLoadingEntities = false;
        let errorText = of(err.errorText);
        if(err.errorText)
        {
          this.dialog.open(ImportErrorDialogComponent,
            {
              width: '450px',
              autoFocus: false,
              data: {
                text: errorText
              }
            });
        }
        else
        {
          errorText = this.translateService.get('Import.OtherErrorText');
          this.dialog.open(ImportPartialImportedDialogComponent,
            {
              width: '450px',
              autoFocus: false,
              data: {
                text: errorText,
                entityId,
              }
            });
        }
      }
    });
    this.subscription.add(sub);
  }

  public async onFileSelected(files: File[]): Promise<boolean> {
    this.isLoadingEntity = true;
    this.fileToUpload = null;
    let errorText = null;
    if (!files[0]) {
      errorText = this.translateService.get('Import.OtherErrorText');
    }
    else if (!this.isSizeValid(files[0])) {
      errorText = this.translateService.get('BulkImport.SizeErrorText');
    }
    else if (!this.validateFile(files[0].name)) {
      errorText = this.translateService.get('Import.FormatErrorText');
    }

    if (errorText != null) {
      this.isLoadingEntity = false;
      this.dialog.open(ImportErrorDialogComponent, {
        width: '450px',
        autoFocus: false,
        data: {
          text: errorText
        }
      });
      this.lastSelectedFile = !files[0] ? null : files[0].name;
      this.fileToUpload = null;
      return false;
    }

    if (files[0].name === this.lastSelectedFile) {
      this.isLoadingEntity = false;
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        width: 'auto',
        data: {
          title: this.translateService.get('Import.SameFileTitle'),
          yesText: this.translateService.get('ConfirmationDialog.Yes'),
          noText: this.translateService.get('ConfirmationDialog.No'),
          confirmationText: this.translateService.get('Import.SameFileConfirmationText'),
          doubleconfirmneeded: false,
        }
      });

      const sub = dialogRef.afterClosed().subscribe(confirmation => {
        if (confirmation.result === ConfirmationType.yes) {
          this.isLoadingEntity = true;
          this.lastSelectedFile = files[0].name;
          this.fileToUpload = files[0];
          this.importData(this.fileToUpload, this.entity.id);
        }
      });
      this.subscription.add(sub);
    }
    else {
      this.lastSelectedFile = files[0].name;
      this.fileToUpload = files[0];
      return true;
    }
  }

  public isSizeValid(file: File): boolean {
    return file.size < this.SIZE_LIMIT_IN_BYTE;
  }

  public validateFile(name: string) {
    return name.lastIndexOf('.') >= 0 && name.substring(name.lastIndexOf('.')+1).toLowerCase() === 'csv';
  }

  public getTooltipText(entity: Entity):  Observable<string> {
    if (!this.userService.checkPermission([this.CreateEntityData], entity.id)) {
      return this.translateService.get('ListEntities.NoPermissionBulkImport');
    }

    if (this.isSystemEntity(entity)) {
      return this.translateService.get('ListEntities.CannotBulkImportSystemEntity');
    }

    const unimportableField = entity.fields.find(field =>
      this.isUnImportTypeRequired({ ...entity, fields: [field] })
    );

    if (unimportableField) {
      return this.translateService.get('ListEntities.CannotBulkImporFieldType',
        {
          fieldName: unimportableField.displayName,
          fieldType: unimportableField.type
        });
    }

    return of('');
  }
}
