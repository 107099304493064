import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription, Observable } from 'rxjs';
import { SnackBarComponent } from 'src/app/shared/components/snack-bar/snack-bar.component';
import { Constants } from 'src/app/shared/constants';
import { Entity } from 'src/app/shared/models/entity';
import { Field, FieldType } from 'src/app/shared/models/field';
import { EntitiesService } from 'src/app/shared/services/entities.service';
import { FeatureKey, FeatureService } from 'src/app/shared/services/feature.service';
import { UserService } from 'src/app/shared/services/user.service';
import { forbiddenNameValidator, numberValidator, positiveIntegerValidator, reservedNameValidator } from 'src/app/shared/validators';
import { TranslateService } from '@ngx-translate/core';
import * as urljoin from 'url-join';
import { UrlUtils } from 'src/app/shared/url.utils';
import { TelemetryService } from 'src/app/shared/services/telemetry.service';
import { Events } from 'src/app/shared/models/events';

export enum EditFieldDialogMode {
  create = 'Create',
  update = 'Update'
}

export interface EditFieldDialogData {
  mode: EditFieldDialogMode;
  field: Field;
  entityName: string;
  entities: Entity[];
  choicesets: Entity[];
  recordCount: number;
  addChoiceSet?: () => void;
  addEntity?: () => void;
}

export interface DisplayType {
  type: FieldType;
  displayName: string;
}

@Component({
  selector: 'upsert-field-dialog',
  templateUrl: './upsert-field-dialog.component.html',
  styleUrls: ['./upsert-field-dialog.component.scss']
})
export class UpsertFieldDialogComponent implements OnInit, OnDestroy {

  public relationshipStr = FieldType.relationship;
  public choicesetStr = FieldType.choiceSetSingle;
  public autonumberStr = FieldType.autonumber;
  public numberStr = FieldType.number;
  public textStr = FieldType.text;
  public fieldForm: UntypedFormGroup;
  public defaultFieldForm: UntypedFormGroup;
  public panelOpenState = false;
  public selectedName = 'Text'; // May need to change if we have translations for field types
  public selectedType = FieldType.text;

  public isLoading = false;
  private isClosed = false;

  public displayTypes: DisplayType[];
  public submitDisable: boolean;

  public shouldShowAllowDefaultValue = true;

  public shouldShowDefaultValueValidationError = false;

  public isRbacEnabled: boolean;

  private subscription: Subscription = new Subscription();

  private formContent: any;

  private fieldSnapshot: Field;
  public users: any;

  public isFile: boolean;

  private createAnother = false;

  public isUniqueChecked: boolean;

  public learnMoreUrl = UrlUtils.getUserManagementDataAccessDocLink();

  constructor(
    public dialogRef: MatDialogRef<UpsertFieldDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: EditFieldDialogData,
    private fb: UntypedFormBuilder,
    private entitiesService: EntitiesService,
    private snackBar: MatSnackBar,
    private userService: UserService,
    private telemetryService: TelemetryService,
    private featureService: FeatureService,
    private translateService: TranslateService,
  ) {
    this.displayTypes = [
      { type: FieldType.text, displayName: 'Text' },
      { type: FieldType.number, displayName: 'Number' },
      { type: FieldType.boolean, displayName: 'Yes/No' },
      { type: FieldType.dateTime, displayName: 'Date-Time' },
      { type: FieldType.relationship, displayName: 'Relationship' },
    ];

    this.featureService.isEnabled(FeatureKey.AutoNumber).subscribe(isEnabled => {
      if (isEnabled) {
        this.displayTypes.push({ type: FieldType.autonumber, displayName: 'Auto-number' });
      }
    });

    this.featureService.isEnabled(FeatureKey.ChoiceSet).subscribe(isEnabled => {
      if (isEnabled) {
        this.displayTypes.push({ type: FieldType.choiceSetSingle, displayName: 'Choice Set' });
      }
    });

    this.featureService.isEnabled(FeatureKey.FileAttachment).subscribe(isEnabled => {
      if (isEnabled) {
        this.displayTypes.push({ type: FieldType.file, displayName: 'File' });
      }
    });

    this.featureService.isEnabled(FeatureKey.EntityColumnPermission).subscribe(isEnabled => {
      this.isRbacEnabled = isEnabled;
    });
  }

  public ngOnInit() {
    const userIds = this.extractUserFieldsValue(this.data.field);
    if (!!userIds) {
      this.userService.getUsersByIds(userIds)
        .subscribe(users => { this.users = users; });
    }

    this.formContent = this.generateFormContent(this.data.field.name, this.data.field.displayName);
    this.fieldForm = this.fb.group(this.formContent);
    this.updateShouldAllowDefaultValue();
    this.defaultFieldForm = this.fb.group({
      defaultValue: [{value: this.convertDefaultValue(this.data.field), disabled: this.isReservedField()}],
    });
    if (this.data.field.type) {
      this.translateToEditFieldType();
    }
    // snapshot field data
    this.fieldSnapshot = this.data.field;

    this.isUniqueChecked = this.data.field.isUnique ?? false;
  }

  public onSetDisplayName(): void {
    if (this.data.mode === EditFieldDialogMode.create) {
      const displayNameValue = this.fieldForm.value.displayName;
      const nameValue = displayNameValue && displayNameValue.replace(/[^a-zA-Z0-9]/g, '');
      this.fieldForm.controls.name.setValue(nameValue);
      this.fieldForm.controls.name.markAsTouched();
      this.fieldForm.controls.name.updateValueAndValidity();
    }
  }

  private disableUniqueSelection(): void {
    this.fieldForm.controls.isUnique.setValue(false);
    this.fieldForm.controls.isUnique.disable();
  }

  private enableUniqueSelection(): void {
    this.fieldForm.controls.isUnique.setValue(false);
    this.fieldForm.controls.isUnique.enable();
  }

  private translateToEditFieldType() {
    if (this.isFileAttachment()) {
      this.selectedName = Constants.File;
      this.selectedType = FieldType.file;
    } else {
      if (this.data.field.type === FieldType.choiceSetMultiple) {
        this.selectedName = 'Choice Set';
      } else if (this.data.field.type === FieldType.date) {
        this.selectedName = 'Date-Time';
      } else {
        this.selectedName = this.displayTypes.find((displayType) => displayType.type === this.data.field.type).displayName;
      }
      this.selectedType = this.data.field.type;
    }
  }
  private generateFormContent(nameValue, diaplayNameValue) {
    const formContent = {
      name: [{ value: nameValue, disabled: this.isUpdateMode() },
        [
          Validators.required,
          forbiddenNameValidator(new RegExp('^[a-zA-Z][a-zA-Z0-9]{2,29}$')),
          reservedNameValidator(new Set([this.data.entityName, ...Constants.BuiltInFieldNames]))
        ]],
      displayName: [diaplayNameValue, Validators.required],
      type: [{
        value: this.getFieldType(),
        disabled: this.isUpdateMode()
      }],
      description: [{ value: this.data.field.description, disabled: this.isReservedField() }, Validators.maxLength(512)],
      decimalPrecision: [{ value: this.data.field.sqlType && this.isNumber(this.data.field.sqlType.decimalPrecision) ?
        this.data.field.sqlType.decimalPrecision : Constants.DefaultDecimalPlace, disabled: this.isReservedField() }, numberValidator()],
      maxValue: [{ value: this.data.field.sqlType && this.isNumber(this.data.field.sqlType.maxValue) ?
        this.data.field.sqlType.maxValue : Constants.DefaultNumberMaxValue, disabled: this.isReservedField() }, numberValidator()],
      minValue: [{ value: this.data.field.sqlType && this.isNumber(this.data.field.sqlType.minValue) ?
        this.data.field.sqlType.minValue : Constants.DefaultNumberMinValue, disabled: this.isReservedField() }, numberValidator()],
      lengthLimit: [{ value: this.data.field.sqlType && this.data.field.sqlType.lengthLimit ?
        this.data.field.sqlType.lengthLimit : Constants.DefaultLengthLimit, disabled: this.isReservedField() }, positiveIntegerValidator()],
      isRequired: [{ value: this.data.field.isRequired, disabled: this.isReservedField() }],
      isRbacEnabled: [{ value: this.data.field.isRbacEnabled, disabled: this.isReservedField() }],
      entity: [{
        value: (this.isUpdateMode() && this.data.field.isForeignKey && !this.isFileAttachment()) ?
          this.getCompleteEntityByReferencedEntity(this.data.field.referenceEntity) : null,
        disabled: this.isUpdateMode()
      }],
      choiceset: [{
        value: this.data.choicesets.find(c => c.id === this.data.field.choiceSetId),
        disabled: this.isUpdateMode()
      }],
      isMultipleChoice: [{
        value: this.isUpdateMode() ? this.data.field.fieldDisplayType === Constants.ChoiceSetMultiple : false,
        disabled: this.isUpdateMode()
      }],
      shouldHideTime: [{ value: this.isUpdateMode() ? this.data.field.type === FieldType.date : false, disabled: this.isUpdateMode() }],
      relatedEntityDisplayField: [
        (this.isUpdateMode() && this.data.field.isForeignKey && !this.isFileAttachment()) ?
          this.getCompleteFieldByReferencedField(this.data.field.referenceField, this.data.field.referenceEntity) : null
      ],
      isUnique: [{
        value: this.data.field.isUnique,
        disabled: this.shouldDisableUniqueCheckbox(),
      }]
    };

    return formContent;
  }

  public ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  public extractUserFieldsValue(entityData): string[] {
    const userIds = new Set<string>();
    if (!!entityData.createdBy) {
      userIds.add(entityData.createdBy);
    }
    if (!!entityData.updatedBy && entityData.updatedBy !== '00000000-0000-0000-0000-000000000000') {
      userIds.add(entityData.updatedBy);
    }
    return Array.from(userIds);
  }

  public onChooseType(): void {
    const displayType = this.displayTypes.find((displayType) =>
      this.fieldForm.value.type === displayType.type
    );
    this.selectedName = displayType.displayName;
    this.selectedType = displayType.type;

    this.isFile = this.fieldForm.value.type === 'file';
    this.updateShouldAllowDefaultValue();
    this.defaultFieldForm.value.defaultValue = null;
    this.removeUnUsedValidators();
    if (!this.containExistingRecord() && (displayType.type === FieldType.text || displayType.type === FieldType.number)) {
      this.enableUniqueSelection();
    } else {
      this.disableUniqueSelection();
    }
  }

  public enterToSubmit() {
    if (!this.isSubmitDisabled()) {
      this.onSubmit();
    }
  }

  public toggleCreateAnother() {
    this.createAnother = !this.createAnother;
  }

  public onSubmit(): void {
    if (this.isClosed) {
      return;
    }
    this.isLoading = true;

    // handle input value
    if (!this.validateInputValue()) {
      this.isLoading = false;
      return;
    }

    this.fieldForm.value.id = this.data.field.id;
    const referenceEntity = this.fieldForm.getRawValue()['entity'] as Entity;
    if (referenceEntity) {
      this.fieldForm.value.referenceEntity = referenceEntity as Entity;
      const referenceField = this.fieldForm.value.relatedEntityDisplayField as Field;
      referenceField.definition = { Name: 'Id' };
      this.fieldForm.value.referenceField = referenceField as Field;
    }

    const choiceset = this.fieldForm.getRawValue()['choiceset'] as Entity;
    if (choiceset) {
      this.fieldForm.value.choiceSetId = choiceset.id;
    }

    if (this.fieldForm.value.type === 'dateTime') {
      const hideTime = this.fieldForm.value.shouldHideTime;

      if (hideTime) {
        this.fieldForm.value.type = 'date';
      }
    }

    const resultField = this.convertFormValueToField({
      ...this.fieldForm.value,
      ...this.fieldForm.getRawValue(),
      type: this.fieldForm.value.type
    });

    if (!this.shouldShowAllowDefaultValue) {
      resultField.defaultValue = null;
    } else {
      resultField.defaultValue = this.defaultFieldForm.value.defaultValue;
    }

    const upsertFieldAction = this.data.mode === EditFieldDialogMode.create ?
      this.entitiesService.addField(this.data.field.entityId, resultField)
      : this.entitiesService.updateFieldMetadata(this.data.field.entityId, resultField);

    const sub = upsertFieldAction.subscribe(() => {
      if (this.createAnother) {
        this.isLoading = false;
        this.fieldForm = this.fb.group(this.formContent);
        this.onChooseType();
      } else {
        this.dialogRef.close();
      }
    }, (error) => {
      this.isLoading = false;
      this.snackBar.openFromComponent(
        SnackBarComponent,
        { duration: 5000, verticalPosition: 'top', data: error });
      this.telemetryService.logEvent(Events.CreateNewFieldEnd);
    });

    this.subscription.add(sub);
  }

  public isSubmitDisabled(): boolean {
    if (this.data.mode === EditFieldDialogMode.create) {
      return this.fieldForm.invalid;
    } else {
      return !this.hasfieldChanged();
    }
  }

  public isFileAttachment(): boolean {
    return this.data.field.fieldDisplayType === Constants.File
      || (this.fieldForm && this.fieldForm.value.type === Constants.File.toLowerCase());
  }

  public onDismiss(): void {
    this.isClosed = true;
    this.dialogRef.close('cancel');
  }

  public isUpdateMode(): boolean {
    return this.data.mode === EditFieldDialogMode.update;
  }

  public isReservedField(): boolean {
    return this.data.field.isModelReserved;
  }

  public isUniquelyConstrained(): boolean {
    return this.data.field.isUnique;
  }

  public isNumberOrString(): boolean {
    const type = this.isUpdateMode() ? this.data.field.type : this.selectedType;
    return type === this.textStr || type === this.numberStr;
  }

  public onIsUniqueChange(event: any) {
    this.isUniqueChecked = event.checked;
  }

  public shouldUniqueCheckboxShowTooltip(): boolean {
    return this.uniqueCheckboxTooltip() !== '';
  }

  public uniqueCheckboxTooltip(): string {
    if (!this.isUpdateMode() && this.containExistingRecord()) {
      return 'UpsertField.DataExistError';
    }
    if (!this.isNumberOrString()) {
      return 'UpsertField.UniqueTypeError';
    }
    if (this.isUpdateMode() && !this.isUniquelyConstrained()) {
      return 'UpsertField.UniqueCreationError';
    }
    return '';
  }

  public containExistingRecord(): boolean {
    return this.data.recordCount > 0;
  }

  public shouldShowUniqueWarning(): boolean {
    return this.isUpdateMode() && this.isUniquelyConstrained() && !this.isUniqueChecked;
  }

  public shouldDisableUniqueCheckbox(): boolean {
    const existingRecordCreation = !this.isUpdateMode() && this.containExistingRecord();
    const numberOrString = !this.isNumberOrString();
    const notUniqueUpdate = this.isUpdateMode() && !this.isUniquelyConstrained();
    return existingRecordCreation || numberOrString || notUniqueUpdate;
  }

  public getTitleText(): Observable<string> {
    if (this.data.mode === EditFieldDialogMode.create) {
      return this.translateService.get('UpsertField.CreateField');
    } else {
      return this.translateService.get('UpsertField.EditField', { value: this.data.field.name });
    }
  }

  public getNoneforeignField(fields: Field[]) {
    const filteredFields = [...fields];
    return filteredFields.filter(f => !f.isForeignKey);
  }

  public onViewAllChoiceSets() {
    const url = urljoin(UrlUtils.getBaseRouteUrl(), '/choicesets');
    window.open(url, '_blank');
  }

  public onOpenLearnMore() {
    const url = UrlUtils.getUserManagementDataAccessDocLink();
    window.open(url, '_blank');
  }

  private convertFormValueToField(form: any): Field {
    const field = form as Field;
    if (this.isUpdateMode()) {
      field.type = this.data.field.type;
      field.fieldDisplayType = this.data.field.fieldDisplayType;
    } else if (field.type === FieldType.file) {
      field.type = FieldType.relationship;
      field.fieldDisplayType = Constants.File;
    } else if (field.type === FieldType.autonumber) {
      field.fieldDisplayType = Constants.Autonumber;
    }

    if (field.type !== FieldType.choiceSetSingle) {
      field.choiceSetId = undefined;

      if ([Constants.ChoiceSetSingle, Constants.ChoiceSetMultiple].includes(field.fieldDisplayType)) {
        field.fieldDisplayType = Constants.Basic;
      }
    }

    if (form.isMultipleChoice && field.type === FieldType.choiceSetSingle) {
      field.type = FieldType.choiceSetMultiple;
      field.fieldDisplayType = Constants.ChoiceSetMultiple;
    }

    if (field.type !== FieldType.relationship) {
      field.referenceEntity = undefined;
      field.referenceField = undefined;
    }

    if (!field.sqlType) {
      field.sqlType = this.entitiesService.transformType(field.type);
    }

    if (field.type === FieldType.autonumber) {
      field.sqlType.decimalPrecision = 0;
    }

    if (field.type === FieldType.number) {
      field.sqlType.decimalPrecision = this.fieldForm.getRawValue().decimalPrecision;
      field.sqlType.maxValue = this.fieldForm.getRawValue().maxValue;
      field.sqlType.minValue = this.fieldForm.getRawValue().minValue;
    } else if (field.type !== FieldType.choiceSetMultiple) {
      field.sqlType.lengthLimit = this.fieldForm.getRawValue().lengthLimit;
    }

    return field;
  }

  private hasfieldChanged(): boolean {
    const displayNameChanged = this.fieldForm.value.displayName !== this.fieldSnapshot.displayName;
    const descriptionChanged = this.fieldForm.value.description !== this.fieldSnapshot.description;

    const allowDefaultValueChanged = this.fieldForm.value.allowDefaultValue !== !!this.fieldSnapshot.defaultValue;

    const defaultValueChanged = this.fieldForm.value.allowDefaultValue
      && this.defaultFieldForm.value.defaultValue !== this.fieldSnapshot.defaultValue;
    return displayNameChanged || descriptionChanged || allowDefaultValueChanged || defaultValueChanged || this.hasConstraintChanged();
  }

  private hasConstraintChanged(): boolean {
    const decimalPrecisionChanged = this.fieldForm.value.decimalPrecision !== this.fieldSnapshot.sqlType.decimalPrecision
      && this.fieldForm.value.decimalPrecision !== Constants.DefaultDecimalPlace;
    const lengthLimitChanged = this.fieldForm.value.lengthLimit !== this.fieldSnapshot.sqlType.lengthLimit
      && this.fieldForm.value.lengthLimit !== Constants.DefaultLengthLimit;
    const maxValueChanged = this.fieldForm.value.maxValue !== this.fieldSnapshot.sqlType.maxValue
      && this.fieldForm.value.maxValue !== Constants.DefaultNumberMaxValue;
    const minValueChanged = this.fieldForm.value.minValue !== this.fieldSnapshot.sqlType.minValue
      && this.fieldForm.value.minValue !== Constants.DefaultNumberMinValue;

    return decimalPrecisionChanged || lengthLimitChanged || maxValueChanged || minValueChanged;
  }

  private getCompleteEntityByReferencedEntity(referenceEntity: Entity): Entity {
    return this.data.entities.find(entity => entity.id === referenceEntity.id);
  }

  private getCompleteFieldByReferencedField(referenceField: Field, referenceEntity: Entity): Field {
    const completeReferEntity = this.getCompleteEntityByReferencedEntity(referenceEntity);
    return completeReferEntity.fields.find(f => f.id === referenceField.id);
  }

  private validateInputValue(): boolean {
    const type = this.fieldForm.value['type'];
    let regex;
    let match = true;
    if (this.shouldShowAllowDefaultValue && this.defaultFieldForm.value['defaultValue']) {
      switch (type) {
      case 'number':
        regex = new RegExp('^(-?)(0|([1-9][0-9]*))(\\.[0-9]+)?$');
        match = regex.test(this.defaultFieldForm.value['defaultValue']);
        break;
      case 'autonumber':
        regex = new RegExp('^[1-9][0-9]*$');
        match = regex.test(this.defaultFieldForm.value['defaultValue']);
        break;
      default:
        break;
      }
    }
    if (!match) {
      this.shouldShowDefaultValueValidationError = true;
      return false;
    }
    else {
      return true;
    }
  }
  private getFieldType() {
    if (this.data.field.isForeignKey) {
      return this.isFileAttachment() ? FieldType.file : FieldType.relationship;
    } else if (this.data.field.type === FieldType.date) {
      return FieldType.dateTime;
    } else if (this.data.field.type === FieldType.choiceSetMultiple) {
      return FieldType.choiceSetSingle;
    }
    return this.data.field.type;
  }

  private updateShouldAllowDefaultValue() {
    const fieldType = this.fieldForm.getRawValue()['type'];
    const nonSupportedTypes = [this.relationshipStr, FieldType.file, FieldType.choiceSetSingle];
    if (this.data.mode === EditFieldDialogMode.update) {
      nonSupportedTypes.push(FieldType.autonumber);
    }
    this.shouldShowAllowDefaultValue = !nonSupportedTypes.includes(fieldType);
  }

  private removeUnUsedValidators() {
    const type = this.fieldForm.value.type;
    if (type !== this.relationshipStr) {
      const entityStr = 'entity';
      const relatedEntityDisplayFieldStr = 'relatedEntityDisplayField';
      (this.fieldForm.get(entityStr) as UntypedFormControl).setValidators(null);
      (this.fieldForm.get(relatedEntityDisplayFieldStr) as UntypedFormControl).setValidators(null);
      (this.fieldForm.get(entityStr) as UntypedFormControl).updateValueAndValidity();
      (this.fieldForm.get(relatedEntityDisplayFieldStr) as UntypedFormControl).updateValueAndValidity();
    }

    if (type !== this.choicesetStr) {
      const selectedChoiceSetControl = this.fieldForm.get('choiceset');
      selectedChoiceSetControl.setValidators(null);
      selectedChoiceSetControl.updateValueAndValidity();
    }
  }

  private isNumber(value: any) {
    return typeof value === FieldType.number;
  }

  private convertDefaultValue(field: Field): any {
    if (field.type === FieldType.boolean && field.defaultValue) {
      return JSON.parse(field.defaultValue);
    }
    return field.defaultValue;
  }

  public openEntityRoleCustomizationPage(): void {
    window.open(this.learnMoreUrl, '_blank', "noopener");
  }

  public handleKeyDown(event: KeyboardEvent): void {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();
      this.openEntityRoleCustomizationPage();
    }
  }

  public handleKeyPress(event: KeyboardEvent): void {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();
      this.openEntityRoleCustomizationPage();
    }
  }
}
