import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpHeaders,
  HttpRequest,
} from '@angular/common/http';

import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Traceparent } from '../models/traceparent';
import { Constants } from '../constants';
@Injectable({
  providedIn: 'root'
})
export class HttpTraceparentInterceptor implements HttpInterceptor {
  constructor() { }

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const traceparent = new Traceparent().toString();
    const traceId = traceparent.split('-')[1];
    sessionStorage.setItem(Constants.TraceId, traceId);
    const headers: HttpHeaders = request.headers.append(Constants.Traceparent, traceparent)
      .append(Constants.SessionIdHeader, sessionStorage.getItem(Constants.SessionId));
    return next.handle(request.clone({ headers }));
  }
}
