import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

const versioningParameters = {
  target: 'aks',
  designer: 'vnext',
  runtime: 'vnext',
};

// This interceptor facilitates making API requests to AKS instead of App Service,
// or further different versions of "AKS pods", by appending appropriate query params.
@Injectable({
  providedIn: 'root'
})
export class HttpVersioningInterceptor implements HttpInterceptor {
  private setParams: Partial<typeof versioningParameters> = {};

  constructor() {
    const queryParams = new URLSearchParams(window.location.search);
    for (const key of Object.keys(versioningParameters)) {
      if (queryParams.get(key) === versioningParameters[key]) {
        this.setParams[key] = versioningParameters[key];
      }
    }
  }

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request.clone({ setParams: this.setParams }));
  }
}
