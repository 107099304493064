import {
  Component,
  OnInit,
} from '@angular/core';
import { Router } from '@angular/router';

import { Breadcrumb } from 'src/app/shared/components/breadcrumb/breadcrumb.component';
import {
  Template,
  TemplateService,
} from 'src/app/shared/services/template.service';

import { UrlUtils } from '../../../shared/url.utils';

export enum Category {
  Service = 'Service',
  Finance = 'Finance',
  Sales = 'Sales',
  Marketing = 'Marketing',
}
@Component({
  selector: 'app-template-list',
  templateUrl: './template-list.component.html',
  styleUrls: ['./template-list.component.scss']
})
export class TemplateListComponent implements OnInit {

  public templates: Template[] = [];
  public isLoading: boolean;
  public breadcrumbs: Breadcrumb[];

  constructor(
    private router: Router,
    private templateService: TemplateService,
  ) { }

  public ngOnInit(): void {
    this.isLoading = true;
    this.breadcrumbs = [
      {
        key: 'TemplateList.EntitiesText',
        url: `${UrlUtils.getBaseRouteUrl()}/entities`
      },
      {
        key: 'TemplateList.Templates'
      }
    ];
    this.templateService.getTemplates().subscribe(templates => {
      this.templates = templates;
      this.isLoading = false;
    });
  }

  public onClickRow(template: Template): void {
    this.router.navigate([`${UrlUtils.getBaseRouteUrl()}/templates/${template.id}`]);
  }

  public onClickEntities(): void {
    this.router.navigate([`${UrlUtils.getBaseRouteUrl()}/entities`]);
  }

  public getTemplateIcon(templateName: Category): string {
    switch (templateName) {
    case Category.Service:
      return 'build';
    case Category.Finance:
      return 'attach_money';
    case Category.Sales:
      return 'local_offer';
    case Category.Marketing:
      return 'store';
    default:
      return '';
    }
  }
}
