import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Entity } from 'src/app/shared/models/entity';
import { SortData } from 'src/app/shared/models/environment';
import { Pagination } from '../../entities/entity-details/data/list-data/list-data.component';
import { Template } from 'src/app/shared/services/template.service';
import { TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, SortDirection } from '@angular/material/sort';
import { CHOICESET_PAGINATION, ENTITY_PAGINATION } from '../template-details/template-details.component';

export enum DataType {
  Entity = "Entity",
  Choiceset = "Choiceset",
}

@Component({
  selector: 'app-template-data-model',
  templateUrl: './template-data-model.component.html',
  styleUrls: ['./template-data-model.component.scss']
})
export class TemplateDataModelComponent implements OnInit, AfterViewInit {
  @Input('entities')
  public entities: Entity[];

  @Input('dataType')
  public dataType: DataType;

  @Input('onClickEntity')
  public onClickEntity: (row: Entity) => void;

  @Input('currentTemplate')
  public currentTemplate: Template;

  @Input('pagination')
  public pagination: Pagination;

  @Input('sort')
  public sort: SortData;

  @ViewChild('paginator', { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort) matSort: MatSort;

  public dataSource: MatTableDataSource<Entity>;
  public unsortedEntites: Entity[];
  public displayedColumns: string[];
  public selectedEntity: Entity;
  public currSort: SortData;

  constructor(
    private translateService: TranslateService,
  ) { }

  ngOnInit(): void {
    if (this.dataType === DataType.Entity) {
      this.displayedColumns = ['displayName', 'name', 'isModelReserved', 'description', 'fieldNumber', 'version'];
    } else {
      this.displayedColumns = ['displayName', 'name', 'isModelReserved','description', 'version'];
    }
  }

  ngAfterViewInit(): void {
    this.dataSource = new MatTableDataSource(this.getPagedData());
    this.unsortedEntites = this.entities.slice();

    if (this.pagination) {
      this.paginator.length = this.pagination.length;
      this.paginator.pageSize = this.pagination.pageSize;
      this.paginator.pageIndex = this.pagination.pageIndex;
    } else {
      this.paginator.length = this.entities.length;
      this.paginator.pageSize = 10;
      this.paginator.pageIndex = 0;
    }

    if (this.sort) {
      this.sortData(this.sort);
      this.matSort.active = this.sort.active;
      this.matSort.direction = this.sort.direction as SortDirection;
    }
  }

  public onSelectRow(row: Entity): void {
    if (this.dataType === DataType.Entity) {
      localStorage.setItem(ENTITY_PAGINATION, JSON.stringify({ pagination: this.pagination, sort: this.currSort }));
    } else {
      localStorage.setItem(CHOICESET_PAGINATION, JSON.stringify({ pagination: this.pagination, sort: this.currSort }));
    }
    this.onClickEntity(row);
  }

  public sortData(sort: SortData): void {
    this.currSort = sort;
    if (sort.direction === '') {
      this.entities = this.unsortedEntites.slice();
      this.dataSource = new MatTableDataSource(this.getPagedData());
      return;
    }
    this.entities.sort((item1, item2) => {
      const value1 = item1[sort.active];
      const value2 = item2[sort.active];

      if (sort.active === 'fieldNumber') {
        const value1 = item1.fields?.length ?? 0;
        const value2 = item2.fields?.length ?? 0;
        return this.compareNumbers(value1, value2, sort.direction);
      } else if (typeof value1 === 'string' && typeof value2 === 'string') {
        return this.compareStrings(value1, value2, sort.direction);
      } else {
        return this.compareNumbers(value1, value2, sort.direction);
      }
    });

    this.dataSource = new MatTableDataSource(this.getPagedData());
  }

  public onChangePagination(pagination: Pagination): void {
    this.pagination = pagination;
    this.dataSource = new MatTableDataSource(this.getPagedData());
  }

  public getDisplayNameText(entity: Entity) {
    const key = this.currentTemplate.name + '.' + entity.name + '.DisplayName';
    return this.translateService.get(key).pipe(map(value => value === key ? '': value));
  }

  public getDescriptionText(entity: Entity) {
    const key = this.currentTemplate.name + '.' + entity.name + '.Description';
    return this.translateService.get(key).pipe(map(value => value === key ? '': value));
  }

  private compareStrings(value1: string, value2: string, direction: string) {
    const comparison = value1.toLocaleLowerCase().localeCompare(value2.toLocaleLowerCase());
    return direction === 'asc' ? comparison : -comparison;
  }

  private compareNumbers(value1: number, value2: number, direction: string) {
    const comparison = value1 - value2;
    return direction === 'asc' ? comparison : -comparison;
  }

  private getPagedData(): Entity[] {
    if (this.pagination) {
      const start = this.pagination.pageIndex * this.pagination.pageSize;
      const end = start + this.pagination.pageSize;
      return [...this.entities ?? []].slice(start, end);
    } else {
      return [...this.entities ?? []].slice(0, 10);
    }
  }
}
