<div class="side-pane-container">
    <h1 mat-dialog-title>{{getTitleText() | async}}</h1>

    <div mat-dialog-content class="dialog-content">
        <form [formGroup]="fieldForm" [style.fontSize.px]="14" #form="ngForm">
            <div fxLayout="column" fxLayoutAlign="space-around stretch">
                <mat-form-field floatLabel="always" subscriptSizing="dynamic">
                    <mat-label>{{'UpsertEntity.DiaplayName' | translate}}</mat-label>
                    <input matInput id="displayName" required formControlName="displayName" name="displayName"
                        [placeholder]="'Placeholder.DisplayName' | translate" (keyup)="onSetDisplayName()"
                        (keydown.enter)="enterToSubmit()" autocomplete="off" />
                    <mat-error *ngIf="fieldForm.get('displayName').errors">
                        {{'UpsertEntity.DiaplayNameError' | translate}}
                    </mat-error>
                </mat-form-field>
                <mat-form-field floatLabel="always" subscriptSizing="dynamic">
                    <mat-label>{{'UpsertEntity.Name' | translate}}</mat-label>
                    <input matInput id="name" required formControlName="name" name="name"
                        [placeholder]="'Placeholder.Name' | translate" autocomplete="off"
                        (keydown.enter)="enterToSubmit()" [attr.aria-label]="'UpsertEntity.NameError' | translate"
                        [matTooltip]="'UpsertEntity.NameError' | translate" />
                    <mat-error *ngIf="fieldForm.get('name').errors">
                        {{'UpsertEntity.NameError' | translate}}
                    </mat-error>
                </mat-form-field>
                <div class="margin24" *ngIf="fieldForm.get('name').errors  && !!fieldForm.get('name').value"></div>

                <mat-form-field floatLabel="always">
                    <mat-label>{{'UpsertEntity.Description' | translate}}</mat-label>
                    <textarea matInput id="description" formControlName="description" class="description-content"
                        name="description" [placeholder]="'Placeholder.Description' | translate">
                                </textarea>
                    <mat-error *ngIf="fieldForm.get('description').errors">
                        {{'UpsertEntity.DescriptionError' | translate}}
                    </mat-error>
                </mat-form-field>
            </div>
            <div>
                <mat-slide-toggle
                appHtmlTooltip="{{ tooltipMessage$ | async }}"
                *ngIf="isRecordLevelPermissionVisible() | async"
                  class="record-access-margin semi-bold"
                  name="enableRbac"
                  formControlName="enableRbac"
                  (change)="toggleWarningForm()"
                >
                  {{ 'UpsertEntity.RecordAccessPermissions' | translate }}
                </mat-slide-toggle>
                <mat-icon appRbacTooltip="{{ 'UpsertEntity.RbacInformation' | translate }}"
                    hrefLink="{{ learnMoreUrl }}"
                    *ngIf="isRecordLevelPermissionVisible() | async"
                    class="info-icon"
                    tabindex="0"
                    (click)="openEntityCustomizationPage()"
                    (keydown)="handleKeyDown($event)"
                    (keypress)="handleKeyPress($event)">
                  info_outline
                </mat-icon>
              </div>
              <!-- Warning Form -->
              <div *ngIf="showWarningForm" class="warning-form">
                <div class="warning-content">
                  <mat-icon class="icon-warning">warning</mat-icon>
                  <div class="warning-text">
                    <span>{{ 'UpsertEntity.RoleLevelAccessWarnining' | translate }}</span>
                    <br>
                    <a class="manage-access-link" [href]="userManagementUrl">{{ 'UserManagement.Title' | translate }}</a>
                  </div>
                </div>
              </div>

        </form>
        <mat-accordion *ngIf="data.mode === 'Update'">
            <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                <mat-expansion-panel-header class="panel-header">
                    <mat-panel-title class="bold detail-text">
                        {{'UpsertEntity.MoreDetails' | translate}}
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <ng-template matExpansionPanelContent>
                    <div fxLayout="column" fxLayoutAlign="start none" class="font-medium">
                        <div fxLayout="row" fxLayoutAlign="space-between none">
                            <div>{{'UpsertEntity.CreatedBy' | translate}}</div>
                            <div class="detail-content" [matTooltip]="data.entity.createdBy">
                                {{this.users.get(data.entity.createdBy).name}}
                            </div>
                        </div>
                        <div fxLayout="row" fxLayoutAlign="space-between none">
                            <div>{{'UpsertEntity.Created' | translate}}</div>
                            <div class="detail-content" [matTooltip]="data.entity.createTime">{{data.entity.createTime}}
                            </div>
                        </div>
                        <div fxLayout="row" fxLayoutAlign="space-between none">
                            <div>{{'UpsertEntity.LastModifiedBy' | translate}}</div>
                            <div class="detail-content" [matTooltip]="data.entity.updatedBy">
                                {{this.users.get(data.entity.updatedBy)?this.users.get(data.entity.updatedBy).name:this.users.get(data.entity.createdBy).name}}
                            </div>
                        </div>
                        <div fxLayout="row" fxLayoutAlign="space-between none">
                            <div>{{'UpsertEntity.LastModified' | translate}}</div>
                            <div class="detail-content" [matTooltip]="data.entity.updateTime">{{data.entity.updateTime}}
                            </div>

                        </div>
                        <div fxLayout="row" fxLayoutAlign="space-between none">
                            <div>{{'UpsertEntity.NumberOfFields' | translate}}</div>
                            <div class="detail-content" [matTooltip]="data.entity.fields.length">
                                {{data.entity.fields.length}}
                            </div>
                        </div>
                        <div fxLayout="row" fxLayoutAlign="space-between none">
                            <div>{{'UpsertEntity.NumberOfRecords' | translate}}</div>
                            <div class="detail-content" [matTooltip]="data.entity.recordCount">
                                {{data.entity.recordCount}}
                            </div>
                        </div>
                    </div>
                </ng-template>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
    <ng-container *ngIf="!shouldDisableInput()">
        <div fxLayout="row" fxLayoutAlign="space-between end stretch" class="bottom-btn-createanother">
            <mat-checkbox class="create-another-margin semi-bold" name="createAnother" (click)="toggleCreateAnother()">
                {{'CreateAnother' | translate}}
            </mat-checkbox>
            <div fxLayout="row" fxLayoutAlign="end stretch">
                <button mat-stroked-button color="primary" class="btn close bold" (click)="onDismiss()"
                    type="button">{{'CancelButtonText' | translate}}</button>
                <button *ngIf="!isLoading" mat-flat-button color="primary" type="submit" class="btn bold"
                    [disabled]="isSubmitDisabled()" (click)="onSubmit()">{{'CreateText' | translate}}</button>
                <div class="btn" *ngIf="isLoading">
                    <mat-spinner role="alert" [attr.aria-label]="'Loading' | translate" diameter="24" class="spinner"></mat-spinner>
                </div>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="shouldDisableInput()">
        <div fxLayout="row" fxLayoutAlign="end stretch" class="bottom-btn-createanother">
            <button mat-stroked-button color="primary" class="btn close bold" (click)="onDismiss()"
                type="button">{{'CancelButtonText' | translate}}</button>
            <button *ngIf="!isLoading" mat-flat-button color="primary" type="submit" class="btn bold"
                [disabled]="isSubmitDisabled()" (click)="onSubmit()">{{'SaveButtonText' | translate}}</button>
            <div class="btn" *ngIf="isLoading">
                <mat-spinner role="alert" [attr.aria-label]="'Loading' | translate" diameter="24" class="spinner"></mat-spinner>
            </div>
        </div>
    </ng-container>
    <button mat-icon-button (click)="onDismiss()" class="close-button" [attr.aria-label]="'CloseButtonText' | translate">
        <mat-icon class="font-large">
            close
        </mat-icon>
    </button>
</div>
