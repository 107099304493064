import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Entity } from 'src/app/shared/models/entity';
import { EntitiesService } from 'src/app/shared/services/entities.service';
import { Subscription, Observable, of, combineLatest, map } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';
import { forbiddenNameValidator } from 'src/app/shared/validators';
import { UserService } from 'src/app/shared/services/user.service';
import { SnackBarComponent } from 'src/app/shared/components/snack-bar/snack-bar.component';
import { TranslateService } from '@ngx-translate/core';
import { Constants } from 'src/app/shared/constants';
import { FeatureKey, FeatureService } from 'src/app/shared/services/feature.service';
import { EnableRbacSnackbarComponent } from '../import/enable-rbac-bar/enable-rbac-bar.component';
import { UrlUtils } from 'src/app/shared/url.utils';
import { TelemetryService } from 'src/app/shared/services/telemetry.service';
import { Events } from 'src/app/shared/models/events';

export enum EntityDialogMode {
  create = 'Create',
  update = 'Update'
}

export interface EntityDialogData {
  mode: EntityDialogMode;
  entity: Entity;
}

export interface EntityDialogResult {
  succeed: boolean;
  data: any;
}

@Component({
  selector: 'upsert-entity-dialog',
  templateUrl: './upsert-entity-dialog.component.html',
  styleUrls: ['./upsert-entity-dialog.component.scss']
})
export class UpsertEntityDialogComponent implements OnInit, OnDestroy {

  public panelOpenState = false;
  public isLoading = false;
  public showWarningForm = false;
  public fieldForm: UntypedFormGroup;
  public formContent: any;
  public users: any;
  public ManagePermission: number = Constants.ManagePermission;
  public UpdateEntityScheme: number = Constants.UpdateEntityScheme;
  public needUpgrade$: Observable<boolean>;
  public hasRecord$: Observable<boolean>;
  public isDisabled$: Observable<boolean>;
  public tooltipMessage$: Observable<string>;
  public userManagementUrl: string;

  private subscription: Subscription = new Subscription();
  private snapshot: Entity;
  public initialEnableRbacValue: boolean;
  public learnMoreUrl = UrlUtils.getEntityCustomizationDocLink();

  @ViewChild('form') createAnotherForm;
  private createAnother = false;

  constructor(
    public dialogRef: MatDialogRef<UpsertEntityDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: EntityDialogData,
    private fb: UntypedFormBuilder,
    private entitiesService: EntitiesService,
    private snackBar: MatSnackBar,
    public userService: UserService,
    public dialog: MatDialog,
    private featureService: FeatureService,
    private readonly translateService: TranslateService,
    private readonly telemetryService: TelemetryService) { }

  public ngOnInit() {
    const userIds = this.extractUserFieldsValue(this.data.entity);
    if (!!userIds) {
      this.userService.getUsersByIds(userIds)
        .subscribe(users => { this.users = users; });
    }

    this.formContent = {
      name: [{ value: this.data.entity.name, disabled: this.shouldDisableInput() },
        [Validators.required, forbiddenNameValidator(new RegExp('^[a-zA-Z][a-zA-Z0-9]{2,29}$'))]],
      displayName: [this.data.entity.displayName, Validators.required],
      description: [{ value: this.data.entity.description, disabled: this.isModelReserved() }, Validators.maxLength(512)],
      enableRbac: [{ value: this.data.entity.isRbacEnabled, disabled: false }],
    };

    this.userManagementUrl = `${UrlUtils.getBaseRouteUrl()}/users`;

    this.fieldForm = this.fb.group(this.formContent);
    this.snapshot = { ...this.fieldForm.getRawValue() }; // Include the initial raw value in the snapshot
    this.needUpgrade$ = this.userService.determineNeedUpgrade(this.data.entity);
    this.hasRecord$ = of(this.data.entity.recordCount > 0);

    this.initialEnableRbacValue = this.fieldForm.get('enableRbac').value;

    this.isDisabled$ = combineLatest([
      this.userService.checkPermission([this.ManagePermission]),
      this.userService.checkPermission([this.UpdateEntityScheme]),
      this.needUpgrade$,
      this.hasRecord$
    ]).pipe(
      map(([hasManagePermission, hasUpdateEntityScheme, needUpgrade, hasRecord]) =>
        !hasManagePermission || !hasUpdateEntityScheme || needUpgrade || hasRecord
      )
    );

    this.tooltipMessage$ = combineLatest([
      this.userService.checkPermission([this.ManagePermission]),
      this.userService.checkPermission([this.UpdateEntityScheme]),
      this.needUpgrade$,
      this.hasRecord$,
    ]).pipe(
      map(([hasManagePermission, hasUpdateEntityScheme, needUpgrade, hasRecord]) => {
        const messages = [];
        if (!this.data.entity.isRbacEnabled && !hasManagePermission) {
          messages.push(this.translateService.instant('UpsertEntity.RecordLevelPermissionRequirement'));
        }
        if (!this.data.entity.isRbacEnabled && !hasUpdateEntityScheme || needUpgrade) {
          messages.push(this.translateService.instant('UpsertEntity.RecordLevelUpdateRequirement'));
        }
        if (!this.data.entity.isRbacEnabled && hasRecord) {
          messages.push(this.translateService.instant('UpsertEntity.RecordLevelNoRecordRequirement'));
        }

        // Conditionally format based on the number of messages
        if (messages.length > 1) {
          return messages.map(msg => `<p>&bull; ${msg}</p>`).join('');
        } else if (messages.length === 1) {
          return `<p>${messages[0]}</p>`; // No bullet for a single message
        } else {
          return ''; // No messages
        }
      })
    );

    this.isDisabled$.subscribe(isDisabled => {
      const enableRbacControl = this.fieldForm.get('enableRbac');
      if (isDisabled && !this.data.entity.isRbacEnabled) {
        enableRbacControl.disable();
      } else {
        enableRbacControl.enable();
      }
    });
  }

  public ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  public enterToSubmit() {
    if (!this.isSubmitDisabled()) {
      this.onSubmit();
    }
  }

  public toggleCreateAnother() {
    this.createAnother = !this.createAnother;
  }

  public onSubmit(): void {
    this.isLoading = true;
    let rbacChanged = false;
    const formValues = this.fieldForm.getRawValue();
    this.data.entity.name = formValues.name;
    this.data.entity.displayName = formValues.displayName;
    this.data.entity.description = formValues.description;

    if (this.data.entity.isRbacEnabled !== formValues.enableRbac) {
      rbacChanged = true;
    }
    this.data.entity.isRbacEnabled = formValues.enableRbac;
    const event: any = {
      ApplicationName: Constants.DataServiceDesigner
    };
    if (rbacChanged || this.data.mode === EntityDialogMode.create) {
      if (this.data.mode === EntityDialogMode.update) {
        event.EntityId = this.data.entity.id;
      }
      if (this.data.entity.isRbacEnabled === true) {
        this.telemetryService.logEvent(Events.EntityRecordLevelEnable, event);
      } else {
        this.telemetryService.logEvent(Events.EntityRecordLevelDisable,event);
      }
    }

    const action = this.data.mode === EntityDialogMode.update ?
      this.entitiesService.updateEntity(this.data.entity) : this.entitiesService.createEntity(this.data.entity);

    const actionSub = action.subscribe((id) => {
      if (this.createAnother) {
        this.isLoading = false;
        this.fieldForm = this.fb.group(this.formContent);
        this.createAnotherForm.resetForm();
      } else {
        this.dialogRef.close({ succeed: true, data: id } as EntityDialogResult);
      }

      if (this.data.entity.isRbacEnabled) {
        this.snackBar.openFromComponent(EnableRbacSnackbarComponent, {
          duration: 5000,
          verticalPosition: 'top',
          data: {
            message: this.translateService.instant('UpsertEntity.EnableRbacNotice', { entityname: this.data.entity.displayName }),
            action: this.translateService.instant('UserManagement.Title')
          }
        });
      }
    }, (error) => {
      this.isLoading = false;
      this.snackBar.openFromComponent(SnackBarComponent, {
        duration: 5000,
        verticalPosition: 'top',
        data: error
      });
    });
    this.subscription.add(actionSub);
  }

  public extractUserFieldsValue(entityData): string[] {
    const userIds = new Set<string>();
    if (!!entityData.createdBy) {
      userIds.add(entityData.createdBy);
    }
    if (!!entityData.updatedBy && entityData.updatedBy !== '00000000-0000-0000-0000-000000000000') {
      userIds.add(entityData.updatedBy);
    }
    return Array.from(userIds);
  }

  public isSubmitDisabled(): boolean {
    const formValue = this.fieldForm.getRawValue();
    const snapshotValue = { ...this.snapshot };

    // Include the enableRbac value even if the control is disabled
    formValue.enableRbac = this.fieldForm.get('enableRbac').value;

    const data = JSON.stringify(formValue);
    const snap = JSON.stringify(snapshotValue);

    return data === snap || !this.fieldForm.valid;
  }

  public onDismiss(): void {
    this.dialogRef.close();
  }

  public shouldDisableInput(): boolean {
    return this.data.mode === EntityDialogMode.update;
  }

  public isModelReserved(): boolean {
    return this.data.entity.isModelReserved;
  }

  public onSetDisplayName(): void {
    if (this.data.mode === EntityDialogMode.create) {
      const diaplayNameValue = this.fieldForm.value.displayName;

      const nameValue = diaplayNameValue && diaplayNameValue.replace(/[^a-zA-Z0-9]/g, '');
      this.fieldForm.controls.name.setValue(nameValue);
      this.fieldForm.controls.name.markAsTouched();
      this.fieldForm.controls.name.updateValueAndValidity();
    }
  }

  public getTitleText(): Observable<string> {
    if (this.data.mode === EntityDialogMode.create) {
      return this.translateService.get('UpsertEntity.CreateEntity');
    } else {
      return this.translateService.get('UpsertEntity.EditEntity', { value: this.data.entity.name });
    }
  }

  public isRecordLevelPermissionVisible() {
    return this.featureService.isEnabled(FeatureKey.EntityDataRecordPermission);
  }

  public toggleWarningForm(): void {
    const enableRbacControl = this.fieldForm.get('enableRbac');

    if (this.data.mode === EntityDialogMode.update && this.initialEnableRbacValue && !enableRbacControl.value) {
      this.showWarningForm = true;
    } else {
      this.showWarningForm = false;
    }
  }

  public openEntityCustomizationPage(): void {
    window.open(this.learnMoreUrl, '_blank', "noopener");
  }

  public handleKeyDown(event: KeyboardEvent): void {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();
      this.openEntityCustomizationPage();
    }
  }

  public handleKeyPress(event: KeyboardEvent): void {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();
      this.openEntityCustomizationPage();
    }
  }
}
